@import 'styles/colors.scss';
@import 'styles/variables.scss';
.dropdown_container {
  position: relative;
  //   background-color: $color-white;
  padding: 5px;
  width: fit-content;
  border-radius: 4px;
  user-select: none;
  &.dropdown_container_disabled {
    pointer-events: none;
  }
  .dc_value_container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 105px;
    justify-content: space-between;
    cursor: pointer;

    .value_text {
      color: $color-dark-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
    }
    .dropdown_icon {
      transition: transform 0.25s linear;
      :global path {
        stroke: $color-grey-text;
      }
      &.dropdown_icon_rotate {
        transform: rotate(-180deg);
      }
    }
  }
  .dc_content_container {
    display: flex;
    position: absolute;
    top: 42px;
    right: 0;
    padding: 10px 8px;
    flex-direction: column;
    gap: 16px;
    min-width: 176px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    background-color: $color-white;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
    z-index: 1;
    min-height: 174px;
    max-height: 214px;
    overflow-y: auto;
    .list_container {
      list-style: none;
      margin: 0;
      padding: 0;
      .lc_list {
        .lc_l_header_container {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          .hc_text {
            color: $color-dark-grey;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            margin: 0;
          }
        }
        .lc_l_options_container {
          padding: 8px;
          border-radius: 2px;
          cursor: pointer;
          &:hover {
            background-color: $color-secondary-grey;
            .oc_text {
              color: $color-dark-text;
            }
          }
          &.lc_l_options_container_active {
            background-color: $color-secondary-grey;
            .oc_text {
              color: $color-dark-text;
            }
          }
          .oc_text {
            color: $color-dark-grey;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            white-space: nowrap;
            margin: 0;
          }
        }
      }
    }
  }
}
