@import 'styles/titles.scss';
@import 'styles/colors.scss';

.custom-bar-chart {
  &__cell {
    fill: $color-violet;
    margin-top: 20px;

    &__selected {
      fill: $color-prime;
    }
  }

  &__bar-title {
    @include subtitle;
    fill: $color-grey-19;
    position: right;
  }

  &__total-count-text {
    fill: $color-grey-22;
    font-size: 14px;
  }
}
