@import 'styles/colors.scss';

.content-fitler-map {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;

  &__checkbox {
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-grey-600;
    }
  }
}
