$main-color: red;
$secondary-color: green;
$color-primary: #cba459;
$color-primary-2: #cca553;
$color-primary-3: #dec591;
$color-primary-4: #cca55a;
$color-primary-5: #fffaf1;
$color-primary-6: #dbaf13;
$color-primary-7: #ffecc9;
$color-primary-8: #fff9ed;
$color-dark-1: #232222;
$color-dark-2: #111;
$color-dark-3: #131316;
$color-dark-4: #ec5b56;
$color-gray-1: #dbdad8;
$color-gray-2: #bbbbbb;
$color-gray-3: #aaaaaa;
$color-gray-4: #777777;
$color-gray-5: #d8d8d8;
$color-gray-6: #5c5c5c;
$color-gray-7: #c4c4c4;
$color-gray-8: #b3bac7;
$color-gray-9: #c8c8c8;
$color-mud-10: #f5edde;
$color-blue-1: #4595ff;
$color-blue-2: #daeaff;
$color-blue-3: #f0f3f9;
$color-lighter-blue: #e2ebfa;
$color-nav-link-1: #8598ad;
$color-nav-link-1-hover: #cca55a;
$color-white: #fff;
$color-secondary: #59564f;
$color-secondary-1: #77736a;
$color-black: #1d1c1a;
$color-selector-text: #566477;
$color-selector-bg: #eff3f9;
$color-save-link-border: #f5f4f3;
$bg-select: #fcfcfc;
$color-neutrals-gray: #948f87;
$color-gold: #ebdbbd;

$color-primary-yellow: #cca55a;
$color-secondary-yellow: #dbaf13;
$color-primary-black: #16191d;
$color-secondary-black: #14192e;
$color-border: #e2ebfa;
$color-border-2: #ebeef4;
$color-primary-grey: #8598ad;
$color-secondary-grey: #f6f6f7;
$color-grey: #f7faff;
$color-selected: #fffbf4;
