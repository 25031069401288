@import 'styles/colors.scss';
@import 'styles/titles.scss';

.filter-sorter-dropdown {
  @include flex-body;
  gap: 8px;
  border-bottom: 1px solid $color-border-grey-2;
  padding-bottom: 16px;

  &__clearButton {
    @include info;
    color: $color-grey-22;
    cursor: pointer;
  }

  &__popover {
    background: $color-white;
    width: 747px;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      box-shadow: none;
    }

    .ant-popover-inner-content {
      padding: 0;
      width: 100%;
    }

    .ant-slider-track {
      background-color: $color-prime !important;
      border-radius: 4px;
      height: 8px;
    }

    .ant-slider-rail {
      border-radius: 4px;
      background-color: #e4e7ec;
      height: 8px;

      &:focus,
      &:active,
      &:hover {
        background-color: #e4e7ec;
        box-shadow: none;

        .ant-slider-handle {
          border-radius: 12px;
          border: 2px solid $color-prime;
          width: 24px;
          height: 24px;
          margin-top: -8px;
        }
      }
    }

    .ant-slider-handle {
      border-radius: 12px;
      border: 2px solid $color-prime;
      width: 24px;
      height: 24px;
      margin-top: -8px;

      &:focus,
      &:active,
      &:hover {
        border-radius: 12px;
        border: 2px solid $color-prime;
        box-shadow: none;
      }
    }
  }

  &__container {
    @include flex-body-centered;
    min-width: 64px;
    padding: 4px 8px;
    gap: 4px;
    border-radius: 6px;
    cursor: pointer;
    border-radius: 16px;
    border: 1px dashed $color-grey-17;
  }

  &__info {
    @include flex-body;
    gap: 4px;

    &__icon {
      transform: rotate(45deg);
    }

    &__title {
      @include info;
      color: $color-dark-grey;
      text-transform: capitalize;
    }

    &__lineBreaker {
      width: 1px;
      height: 14px;
      background-color: $color-grey-17;
    }

    &__value {
      @include info;
      color: $color-prime;
    }
  }

  &__content {
    @include flex-body-column;
    gap: 16px;
    width: 100%;
    padding: 24px !important;

    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: 1px solid $color-border-grey;

    &__title {
      @include info-600;
      text-transform: capitalize;
    }

    &__optionContainer {
      @include flex-body-column;
      gap: 8px;
    }

    &__sliderContainer {
      @include flex-body;
      gap: 16px;
    }

    &__slider {
      flex: 1;

      &:focus,
      &:active,
      &:hover {
        .ant-slider-rail {
          border-radius: 4px;
          background-color: #e4e7ec !important;
          height: 8px;
        }

        .ant-slider-handle {
          border-radius: 12px;
          border-color: $color-prime !important;
          width: 24px;
          height: 24px;
          margin-top: -8px;
        }
      }
    }

    &__input {
      border-radius: 8px !important;
      border: 1px solid $color-grey-17 !important;
      background: $color-white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      input {
        @include description-16;
        padding: 0;
        overflow: hidden;
        padding: 8px 12px !important;
        color: $color-dark-grey !important;
      }
    }

    &__footer {
      @include flex(row, center, flex-end);

      &__applyButton {
        @include flex-body-centered;
        @include subtitle;
        width: 132px;
        min-width: 64px;
        padding: 10px 16px;
        gap: 8px;
        border-radius: 6px;
        border: 1px solid $color-black;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
          linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
        color: $color-white;
        cursor: pointer;
      }
    }
  }
}
