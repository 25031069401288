@import 'styles/colors.scss';
.dved_container {
  background-color: $color-grey-1;
  //   height: 320px;
  padding: 67px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dved_c_heading {
    color: $color-dark-text;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 40px 0 8px 0;
  }
  .dved_c_desc {
    color: $color-dark-grey;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
}
