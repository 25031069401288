@import 'styles/variables.scss';

.c-profile {
  .ant-btn {
    width: 200px;
    margin-top: 16px;
    margin-right: auto;
    margin-left: 0;
  }

  .ant-form {
    max-width: 300px;
  }
}
