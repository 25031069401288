@import 'styles/colors.scss';

.create_flag_modal_container {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  background: rgba(96, 89, 88, 0.5);
  z-index: 9999;
  .cfmc_inner_container {
    display: flex;
    flex-direction: column;
    width: 631px;
    height: 100dvh;
    background-color: $color-white;
    .cfmc_ic_header {
      padding: 24px 24px 24px 40px;
      .cfmc_ic_h_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cfmc_ic_ht_left {
          display: flex;
          align-items: center;
          gap: 8px;
          .cfmc_ic_ht_l_flag_icon {
            stroke: $color-violet-700;
          }
          .cfmc_ic_ht_l_title {
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            color: $color-violet-700;
            margin-bottom: 0;
            &.cfmc_ic_ht_l_title_edit {
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              color: $color-dark-text;
            }
          }
        }
        .cfmc_ic_ht_l_close_icon {
          cursor: pointer;
        }
      }
      .cfmc_ic_h_step_text_container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
        margin-bottom: 8px;
        .cfmc_ic_h_icon {
          cursor: pointer;
        }
        .cfmc_ic_h_step_text {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: $color-grey-text;
          margin-bottom: 0;
          .cfmc_ic_h_st_part {
            margin-left: 16px;
            color: $color-dark-text;
          }
        }
      }
      .cfmc_ic_h_desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $color-dark-grey;
        margin-bottom: 0;
        padding-right: 35px;
        &.cfmc_ic_h_desc_edit_mode {
          margin-top: 8px;
        }
      }
    }
    .cfmc_ic_form_container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px 40px;
      height: calc(100vh - 258px);
      overflow-y: auto;
      .cfmc_ic_fc_impact_container {
        .cfmc_ic_fc_ic_text {
          color: $color-grey-19;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 12px;
        }
        .cfmc_ic_fc_ic_chunk_container {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .cfmc_ic_fc_ic_chunk {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 16px;
            border-radius: 12px;
            border: 1px solid $color-border-grey-2;
            background-color: $color-white;
            cursor: pointer;

            &.cfmc_ic_fc_ic_chunk_active {
              border: 1px solid $color-prime;
              background-color: $color-primary-6;
              .cfmc_ic_fc_ic_c_radio_button {
                border: 1px solid $color-prime;
                background-color: $color-prime;
              }
            }
            .cfmc_ic_fc_ic_c_icon {
              flex-shrink: 0;
              &.cfmc_ic_fc_ic_c_medium_icon {
                rect:nth-child(2) {
                  fill: $color-orange;
                }
              }
              &.cfmc_ic_fc_ic_c_high_icon {
                rect {
                  fill: $color-orange;
                }
              }
            }
            .cfmc_ic_fc_ic_c_text_container {
              flex-grow: 1;
              .cfmc_ic_fc_ic_c_tc_title {
                color: $color-grey-19;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 0;
              }
              .cfmc_ic_fc_ic_c_tc_desc {
                color: $color-grey-600;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
              }
            }
            .cfmc_ic_fc_ic_c_radio_button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              border: 1px solid $color-grey-17;
              background-color: $color-white;
              border-radius: 50%;
              flex-shrink: 0;
              &::before {
                content: '';
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background-color: $color-white;
                // position: absolute;
              }
            }
          }
        }
      }
    }
    .cfmc_ic_fc_tab_buttons_container {
      display: flex;
      align-items: center;
      padding: 12px 40px 0 40px;
      .cfmc_ic_fc_tbc_button {
        border-radius: 6px;
        border: 1px solid $color-grey-17;
        background-color: $color-grey-20;
        color: $color-dark-grey;
        padding: 8px 16px;
        height: 36px;
        &.cfmc_ic_fc_tbc_button_active {
          border: 1px solid $color-primary-10;
          background-color: $color-primary-3;
          color: $color-primary-11;
        }
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .cfmc_ic_fc_buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      padding: 26px 40px;
      border-top: 1px solid $color-border-grey;
      .cfmc_ic_fc_b_button {
        border-radius: 6px;
        border: 1px solid $color-black;
        padding: 10px 16px;
        height: 40px;
        &.cfmc_ic_fc_b_button_edit_mode {
          padding: 10px 24px;
        }
        &:first-child {
          background-color: $color-white;
          color: $color-black;
        }
        &:disabled {
          border-color: $color-grey-text;
        }
      }
    }
  }
}
