@import 'styles/titles.scss';
@import 'styles/colors.scss';

.new-pie-chart {
  &__label {
    text-align: center;
    color: $color-black;
    font-size: 24px;
    font-weight: 700;
    transform: translateY(5px);
  }

  &__transform {
    transform: translateY(-15px);
    fill: $color-grey-22;
    font-size: 16px;
    font-weight: 400;
  }

  &__list {
    width: 100%;
    gap: 10px 10px;
    display: grid;
    margin: 0 auto;
    position: relative;
    list-style-type: none;
    padding-top: 16px;
    border-top: 1px solid $color-grey-18;
    grid-template-columns: 1fr 1fr;
  }

  &__scrollable {
    max-height: 100px;
    overflow: auto;
  }

  &__legend {
    @include flex(row, center, flex-start);
    cursor: pointer;

    &__title {
      @include flex(row, center, space-between);
    }

    &__labels {
      @include body;
      color: $color-dark-text;
      letter-spacing: 0px;
    }

    &__list {
      width: 10px;
      height: 10px;
      min-width: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &__no-data-div {
      @include flex-body-column;
      gap: 6px;

      &__title {
        @include description-600;
        color: $color-grey-19;
        text-align: center;
      }

      &__description {
        @include description;
        color: $color-dark-grey;
        text-align: center;
      }
    }
  }
}

.new-pie-chart__list.legend_flex {
  grid-template-columns: 1fr 1fr 1fr;
}

.pie-chart-container.recharts-wrapper .recharts-legend-wrapper {
  left: 50% !important;
  transform: translateX(-50%);
}
