@import 'styles/colors.scss';

.dropdown_container_input {
  width: 100%;
  .dc_title {
    color: $color-grey-19;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
  }
  .dc_input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: $color-white;
    border: 1px solid $color-grey-17;
    gap: 16px;
    cursor: pointer;
    &.dc_input_container_open {
      border-color: $color-prime-bright;
    }

    .dc_ic_text {
      color: $color-dark-grey;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
      &.dc_ic_text_value {
        font-weight: 500;
        color: $color-grey-900;
      }
    }
    .dc_ic_icon {
      path {
        stroke: $color-dark-grey;
      }
    }
    .dc_ic_dropdown {
      position: absolute;
      left: 0;
      top: calc(100% + 4px);
      z-index: 1;
      border-radius: 8px;
      border: 1px solid $color-border-grey-2;
      background-color: $color-white;
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      width: 100%;
      padding: 4px 0;
      .dc_ic_d_option {
        padding: 11px 14px;
        color: $color-grey-900;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        margin-bottom: 0;
      }
    }
    &.dc_input_container_disabled {
      pointer-events: none;
      .dc_ic_icon {
        display: none;
      }
    }
  }
}
