@import 'styles/variables.scss';
@import 'styles/titles.scss';
@import 'styles/colors.scss';

.button {
  @include flex-body;
  @include button-text;
  font-family: 'Inter';
  border: none;
  outline: none;
  background-color: $color-primary-black;
  border-radius: 4px;
  padding: 6px 16px;
  height: 34px;
  gap: 8px;
  color: $color-white;
  user-select: none;
  cursor: pointer;

  &:disabled {
    background-color: $color-grey-text;
    border-color: $color-grey-text;
    cursor: not-allowed;

    .icon {
      :global path {
        fill: $color-white;
      }
    }
  }

  .icon {
    margin-bottom: auto;

    &.icon_loading {
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
