@import 'styles/colors.scss';

.search-dashboard {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .no-brand-placeholder {
    top: 55%;
    left: 55%;
    position: absolute;
    background-color: $color-white;
    transform: translate(-50%, -50%);
  }

  .fhc_sc_button {
    height: 40px;
    background-color: $color-unselected;
    border-radius: 6px;
    padding: 10px 16px;
    color: $color-dark-text;
  }

  .filters-section {
    display: flex;
    align-items: center;
    gap: 32px;
    padding-bottom: 32px;
    padding-inline: 24px;

    .power-insights-options {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #000000;
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        cursor: pointer;
      }
    }

    &.powerinsight_mood {
      padding-bottom: 8px;
      border-bottom: 1px solid $color-border-grey;
    }
  }

  .executive-summary-btn {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 9px 0px rgba(151, 71, 255, 0.4);
    // box-sizing: border-box;
    gap: 8px;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-dark-text;

    border: 1px solid linear-gradient(150.76deg, #0085ff 14.47%, #ff00e5 84.28%);
    border-image-slice: 1;
    // border: 1px solid transparent;
    // border-image-source: linear-gradient(150.76deg, #0085ff 14.47%, #ff00e5 84.28%);
    overflow: hidden;
  }

  .date-filter {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .brand-selected {
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid $color-border-grey-2;
    background-color: $color-grey-21;
    width: max-content;
    height: 44px;
    padding: 6px 24px;
    border-radius: 8px;
    box-sizing: border-box;
    min-width: 170px;
    cursor: pointer;

    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $color-grey-900;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  .cards-chart-wrapper {
    width: 100px;
    height: 50px;
  }
  .alert-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-grey-18;
    border-radius: 8px;
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: $color-dark-grey;
      }
    }
  }
}

.search-dashboard-stats {
  width: 100%;
  overflow-y: auto;
  padding-top: 24px;
  position: relative;
  height: calc(100vh - 60px);

  .ant-spin.ant-spin-spinning {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .dashboard-widgets {
    padding: 0px 24px;

    &__card {
      box-sizing: border-box;
      padding: 24px;
      border-radius: 12px;
      border: 1px solid #e2ebfa;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px $color-box-shadow;
      overflow: auto;

      &__sub-row {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        .ant-col-8,
        .dashboard-widgets__card {
          max-width: 500px;
          flex: 1;
          min-width: 250px;
          white-space: nowrap;
        }

        .ant-col-12 {
          max-width: 100%;
          flex: 1;
        }
      }
    }

    .snippet-section-column {
      overflow: hidden;
      height: calc(100vh - 137px);

      .snippet-section-loader {
        height: 100%;
        overflow: hidden;

        .ant-spin-container {
          height: 100%;
          overflow: hidden;

          .ant-row {
            height: 100%;
            overflow: hidden;
          }
        }
      }
    }

    .dashboard-snippet-section {
      border-left: 1px solid $color-border-grey;
      height: 100%;
      overflow: hidden;

      &__container {
        height: calc(100% - 120px);

        &__top-container {
          display: flex;
          flex-direction: column;
          padding: 24px 24px 16px 24px;
          border-bottom: 1px solid $color-border-grey;

          .filters-chips {
            margin: 0px;
            padding: 0px;
            font-size: 14px;
          }
        }

        #snippets_filters_header {
          border: none;
          padding: 0px;
          gap: 16px;
          flex-direction: column;

          .snippet-list-search {
            width: 100%;
          }

          .date-filter__text {
            display: none;
          }
        }

        &__header {
          display: flex;
          gap: 12px;

          &__title {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            color: #000000;
          }

          &__count {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #667085;
          }
        }
      }
    }

    &.powerinsight_dashboard {
      padding: 0px;
      height: calc(100vh - 137px);
    }

    .influencer-widgets {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .min-height-350 {
      min-height: 350px;
    }
  }
}

.details-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
    linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);
  border-width: 1px, 1px, 0px, 1px;
  border-style: solid;
  border-color: #16191d;
  border-radius: 6px;
}

.chart-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-container {
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
  height: 100vh;
}

.share-of-empty-state {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 32px;
  gap: 80px;

  .doghnut {
    svg {
      height: 135px;
      width: 135px;
    }
  }

  .skeleton-paragraph {
    &__small {
      width: 30px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }

    &__large {
      width: 60px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }
  }
}

.power-insights-components {
  padding: 24px 0px;
}
