@import 'styles/colors.scss';

.media-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-inline: 36px;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid $color-pastel-blue;
  .media-type {
    width: 110px;
    height: 36px;
    border-radius: 6px;
    border: 8px 12px;
    box-sizing: border-box;
    color: $color-dark-grey;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  .media-selected {
    background-color: $color-primary-6;
    color: $color-primary-300_1;
  }
  .media-disabled {
    cursor: not-allowed;
  }
}
