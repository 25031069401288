@import 'styles/colors.scss';

.riskItemWrapper {
  width: 135px;
  height: 56px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 8px;

  background-color: $color-unselected;
  border-radius: 4px;

  .riskItem {
    display: flex;
    gap: 8px;

    .riskIcon {
      display: flex;
      align-items: flex-start;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .riskData {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      .riskName {
        margin: 0;
        color: $color-dark-text;
      }

      .postsCount {
        color: $color-grey-text;
      }
    }
  }

  .bellIcon {
    display: flex;
    align-items: flex-start;

    img {
      width: 12px;
      height: 12px;
    }
  }
}

.noRisk {
  .riskIcon {
    svg {
      &.withFill {
        :global path {
          fill: $color-grey-text !important;
        }
      }

      &.withStroke {
        :global path {
          stroke: $color-grey-text !important;
        }
      }

      &.toxic {
        :global path:not(:last-of-type) {
          fill: unset !important;
        }
      }

      &.identityHate {
        :global path:not(:nth-last-of-type(2)) {
          fill: unset !important;
        }
      }
    }
  }

  .riskName {
    color: $color-grey-text !important;
  }

  .postsCount {
    color: $color-grey-text !important;
  }
}

.selected {
  background-color: $color-prime !important;

  .riskIcon {
    svg {
      &.withFill {
        :global path {
          fill: $color-white !important;
        }
      }

      &.withStroke {
        :global path {
          stroke: $color-white !important;
        }
      }

      &.toxic {
        :global path:not(:last-of-type) {
          fill: unset !important;
        }
      }

      &.identityHate {
        :global path:not(:nth-last-of-type(2)) {
          fill: unset !important;
        }
      }
    }
  }

  .riskName {
    color: $color-unselected !important;
  }

  .postsCount {
    color: $color-white !important;
  }
}
