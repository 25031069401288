@import 'styles/colors.scss';

.share_report_modal_footer_container {
  // background-color: gold;
  .cmc_cc_fc_button:last-child:disabled {
    background-color: $color-background-reports;
    border-color: $color-background-reports !important;
  }
}

.share_report_modal_container {
  .ant-spin-nested-loading {
    position: relative;

    .ant-spin {
      top: 0;
    }
  }

  margin-block: 24px;

  .srmc_text {
    color: $color-grey-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }

  .srmc_input {
    margin-block: 24px;
    height: 40px;
    padding: 10px 18px;
    width: 100%;

    .srmc_input_content_container {
      .srmc_input_cc_chunk {
        cursor: pointer;
      }
    }
  }

  .share_report_content_container {
    height: 208px;
    overflow-y: auto;
  }
}

.users_list_container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: $color-unselected;

    .ulc_content {
      .ulc_c_name_container {
        .ulc_c_remove_shared {
          color: $color-dark-grey;
          text-decoration-line: underline;
        }
      }
    }
  }

  .ulc_c_info {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  .disabled {
    color: $color-grey-17 !important;

    .value_text {
      color: $color-grey-17 !important;
    }

    svg {
      path {
        stroke: $color-grey-17;
      }
    }
  }

  .ulc_email_container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ulc_image_container {
    height: 32px;
    width: 32px;
    border: 50%;
    overflow: hidden;
    flex-shrink: 0;

    .ulc_image {
      height: 100%;
      width: 100%;
    }
  }

  .ulc_content {
    flex-grow: 1;

    .ulc_c_name_container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ulc_c_name {
        color: $color-dark-text;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
      }

      .ulc_c_info {
        display: flex;
        align-items: center;
        gap: 32px;
      }

      .ulc_c_remove {
        color: $color-dark-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;

        &.ulc_c_remove_shared {
          cursor: pointer;
          color: $color-dark-grey;
        }

        &.ulc_c_owner_share {
          pointer-events: none;
        }
      }

      .ulc_c_remove_email_share {
        color: $color-dark-grey;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        cursor: pointer;
      }

      .ulc_c_email_container {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .ulc_c_email {
      color: $color-grey-text;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
  }
}

.report_email_notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 5px solid $color-prime;
  box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 16px !important;

  &.ren_margin {
    margin-left: 70% !important;
    margin-bottom: -4%;
  }

  &.ren_error {
    border-left: 5px solid #e72f21;
  }

  .ren_description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }

  .ant-notification-notice-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-notification-notice-icon {
    path {
      fill: $color-prime;
    }
  }

  .ant-notification-notice-description {
    margin-left: 40px !important;
  }
}

.amc_switch {
  background-color: $color-grey-18;
  height: 24px;

  .ant-switch-handle {
    width: 20px;
    height: 20px;

    &::before {
      border-radius: 10px;
    }
  }

  &.ant-switch-checked {
    background-color: $color-prime;

    &:hover {
      background-color: $color-primary-300_1;
    }

    .ant-switch-handle {
      left: calc(100% - 20px - 2px);
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.share_report_modal_box_container {
  padding: 0 !important;
  width: 639px;

  .share_report_modal_container {
    margin: 0;

    .share_report_content_container {
      padding: 0 32px;
      margin: 24px 0;
    }
  }

  .cmc_cc_header_container {
    padding: 24px 32px 16px 32px;
    border-radius: 16px 16px 0px 0px;
  }

  .cmc_cc_main_content {
    padding: 0;

    .srmc_text {
      padding: 0 32px 16px 32px;
      border-bottom: 1px solid $color-border-grey;
    }

    .srmc_input {
      padding: 24px 32px;
      margin: 0;
      border: none;
      margin: 24px 0;
      position: relative;

      svg {
        position: absolute;
        right: 8%;
      }

      input {
        border-radius: 8px;
        border: 1px solid $color-grey-17;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 10px 14px;
      }

      > div {
        width: 90%;
        margin: 0 32px 24px 32px;
      }
    }

    .users_list_container {
      .c_name_container {
        color: $color-grey-600;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .amc_switch {
          margin-right: 16px !important;
        }
      }
    }

    .email-subscribe-info {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: 0 32px 24px 32px;

      .text {
        color: #667085;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .cmc_cc_footer_container {
    padding: 24px;
    border-radius: 0px 0px 16px 16px;
    border-top: 1px solid $color-border-grey-2;

    .cmc_cc_fc_button {
      display: flex;
      min-width: 64px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      border-radius: 6px;
      border-top: 1px solid #16191d;
      border-right: 1px solid #16191d;
      border-left: 1px solid #16191d;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
        linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
    }
  }
}

.mail-switch {
  &.ant-switch-checked {
    background-color: $color-prime;

    &:hover {
      background-color: $color-primary-300_1;
    }

    .ant-switch-handle {
      left: calc(100% - 20px - 2px);
    }
  }
}

.mail-switch-disabled {
  background-color: $color-grey-17 !important;
}
