@import 'styles/colors.scss';
@import 'styles/titles.scss';

.spotlight {
  padding: 24px 0;
  border-bottom: 1px solid $color-border-grey;

  &.dashboard-snippet {
    gap: 14px;
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    border-radius: 8px;
    min-width: 425px;
    position: relative;
    border: 1px solid $color-border-grey-2;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 0px $color-box-shadow;
  }

  &__description {
    @include body;
    color: $color-dark-text;
    padding-right: 10px;

    &.dashboard-snippet {
      margin-bottom: 10px;
    }
  }

  &__button {
    @include subtitle-16;
    color: $color-blue;
    cursor: pointer;
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: $color-grey-9;
  }

  &__creator {
    @include flex-body;
    cursor: pointer;

    &.dashboard-snippet {
      gap: 8px;

      .author-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: $color-black;
      }
    }
  }

  &__details {
    @include flex-body-centered;

    &--icon {
      border-radius: 24px;
      width: 24px;
      height: 24px;
      margin: auto 4px auto 4px;
    }
  }

  &__sentiment {
    margin: auto;
  }

  &__details__dropdown {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 5px;
  }

  &__tag-input-container {
    @include flex(row, center, flex-start);
    border: 1px solid $color-border-grey;

    &__left {
      @include flex(row, center, space-between);

      &__user-img {
        margin-right: 4px;
        height: 24px;
        width: 24px;
        border-radius: 24px;
        border: none;
      }

      &__title {
        @include info;
        color: $color-black;
      }

      &__breaker {
        @include title-500;
        color: $color-grey-text;
        margin-left: 4px;
        margin-right: 8px;
      }
    }

    &__right {
      @include flex-body;
      cursor: pointer;

      &__enter {
        @include footnote;
        color: $color-black;
        margin-left: 7px;
      }

      &__close {
        margin: 0 12px 0 8px;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  &__footer {
    @include flex(row, center, space-between);
    padding: 3px 0px;

    &__view-count {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__left {
      @include flex-body;

      &__app-icon {
        margin: 0 4px;
      }

      &__list {
        @include flex-body;
        @include footnote;
        color: #8694a4;
        margin: 0;
        padding: 0;
        gap: 6px;
      }

      &__tweet {
        display: flex;
      }

      &__tweet-icon,
      &__tweet-text {
        margin: auto 3px;
      }

      &__tooltip {
        .ant-tooltip-inner {
          min-height: max-content;
          padding: 15px 10px;
        }
      }

      &__translation-div {
        @include flex-body;
        padding: 2px 8px 2px 4px;
        background: $color-primary-4;
        border-radius: 3px;
        margin-left: 15px;

        &__text {
          @include footnote;
          color: $color-black;
        }

        svg {
          height: 10px;

          path {
            stroke: $color-prime;
          }
        }
      }
    }

    &__right {
      display: none;
      font-size: 12px;
      margin: 0;
      padding: 0;

      &__bookmark-btn,
      &__preview-btn {
        &,
        &:focus,
        &:active {
          @include flex-body;
          gap: 4px;
          outline: none !important;
          border: none !important;
          cursor: pointer;
          padding: 1px 8px;
          color: $color-prime;
          background: rgba(244, 202, 95, 0.2) !important;
          border-radius: 4px;

          font-size: 12px;
          line-height: 16px;

          span {
            color: #000000;
          }
        }
      }

      &__bookmark-btn {
        svg {
          height: 16px;
          width: 16px;
        }
      }

      &__like-dislike-btn {
        &,
        &:focus,
        &:active {
          @include flex-body;
          outline: none !important;
          border: none !important;
          cursor: pointer;
          color: $color-prime;
          background: rgba(244, 202, 95, 0) !important;
        }
      }

      &__breaker {
        @include info;
        color: $color-grey-text;
        margin-left: 7px;
        margin-right: 7px;
      }

      &__like-dislike {
        @include flex-body;
        background: rgba(244, 202, 95, 0.2);
        border-radius: 4px;

        &--active {
          transform: rotate(30deg);
        }

        span {
          background-color: transparent;
        }

        svg {
          path {
            fill: $color-prime;
          }
        }

        .snippet-item__footer__right__like-dislike_like_icon {
          path {
            stroke: $color-prime;
            fill: none;
          }
        }
      }

      &__like-dislike.hover {
        cursor: pointer;
      }

      & > svg {
        margin: 0 px;
        cursor: pointer;
      }
    }
  }

  &__add-watchlist {
    &__first-option {
      @include subtitle;
      color: $color-dark-text;
      padding: 8px 22px 0 22px;
    }

    &__options {
      padding: 10px 22px 0 22px;
    }
  }

  &__popup-class {
    .ant-select-item-option-selected {
      cursor: pointer;
      background-color: transparent !important;

      &:hover {
        cursor: pointer;
        background-color: transparent !important;
      }
    }
  }

  &__create-watchlist-placeholder {
    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          display: none;
        }
      }

      &:before {
        @include footnote;
        @include flex-body;
        color: $color-grey-text;
        padding: 4px 12px;
        content: 'Enter watchlist name';
      }
    }
  }

  &__create-watchlist-placeholder2 {
    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          display: none;
        }
      }
    }
  }

  &__notification {
    border-left: 3px solid $color-prime;
    max-height: 140px;
    padding-bottom: 10px;

    &__description {
      display: flex;
      padding: 7px 10px;
      align-items: flex-start;
      border-radius: 4px;
      background: $color-unselected;
      color: $color-dark-text;
      @include description;
      margin-bottom: 8px;
    }

    .ant-notification-notice-message {
      @include subtitle;
      margin-bottom: 4px;
      margin-left: 38px;
      color: $color-black;
    }

    .ant-notification-notice-description {
      margin-bottom: 4px;
      margin-left: 38px;
      max-height: 70px;
      overflow: auto;
    }
  }

  &__notification-margin {
    margin-left: 70% !important;
    margin-bottom: -4%;
  }

  &:hover {
    .snippet-item__footer__right {
      @include flex-body;
    }

    .snippet-item__footer__left__translation-div {
      display: none;
    }
  }

  .ant-input {
    min-height: inherit;
  }

  .ant-select-selector {
    min-height: 40px;
    max-height: max-content;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-select {
    .ant-select-open {
      .ant-select-focused {
        .ant-select-enabled {
          .ant-select-selection.ant-select-selection--single {
            .ant-select-selection__rendered {
              .ant-select-selection-selected-value {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
}
