@import 'styles/colors.scss';

.followed-issues-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.followed-issues {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.followed-issue-card {
  width: 100%;
  border-radius: 24px;
  min-height: 124px;
  padding: 16px;
  border: 1px solid $color-border-grey-2;
  box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  position: relative;
  cursor: pointer;

  .skeleton-image {
    width: 90px;
    height: 90px;
    border-radius: 12px;
  }
  .skeleton-title {
    height: 15px;
    overflow: hidden;
    width: 30%;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 8px;
  }
  .skeleton-description {
    width: 60%;
    height: 15px;
    overflow: hidden;
  }
  .skeleton-stats-item {
    width: auto;
    height: 15px;
    overflow: hidden;
    border-radius: 8px;
  }

  .img-wrapper {
    width: 90px;
    min-width: 90px;
    height: 90px;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
  .summary {
    flex: 1;
    &__title-section {
      display: flex;
      gap: 8px;
      align-items: center;
      svg {
        margin-top: 4px;
        cursor: pointer;
      }
    }
  }
  .stats {
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
    margin-right: 16px;
    min-width: 268px;

    .item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      h6 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: $color-grey-22;
        margin-bottom: 0px;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        color: $color-grey-23;
        margin-bottom: 0px;
      }
    }
  }
  .stats-edit-mode {
    min-width: unset;
    align-items: self-start;
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-text;
    margin-top: 16px;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-grey;
    line-height: 1.5;
  }
  .read-more {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-dark-text;
    cursor: pointer;
  }

  &__follow-btn.ant-btn.ant-btn-default {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 500;
    padding: 8px 12px;
    border-radius: 6px;
    color: $color-grey-19;
    min-width: 90px;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 8px;

    &:hover {
      border: none;
      outline: none;
      color: $color-grey-19;
    }
    background-color: $color-grey-18;

    svg {
      path {
        stroke: black;
      }
    }
  }

  &__unfollow-btn.ant-btn.ant-btn-default {
    background-color: $delete-label;
    color: $delete-label-text;

    &:hover {
      border: none;
      outline: none;
      background-color: $warning-color;
      color: $delete-label-text;
    }
  }

  &__loading-btn {
    right: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 500;
    padding: 8px 12px;
    position: absolute;
    border-radius: 6px;
    color: #079455;
    background-color: $color-white;

    svg {
      margin: 0px !important;
      path {
        stroke: #079455;
      }
    }
  }

  &__checkbox-btn {
    top: 16px;
    right: 16px;
    display: none;
    position: absolute;
  }

  &.selected {
    border: 1px solid $color-prime;
    background-color: $color-primary-3;
    box-shadow: 0px 0px 8px 0px $color-box-shadow;

    .followed-issue-card__checkbox-btn {
      display: flex;
    }
  }

  &:not(:is(.selected, .isOnlyView)):hover {
    background-color: $color-grey-20;

    .followed-issue-card__checkbox-btn {
      display: flex;
    }
  }
}

.try-again-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-dark-grey;
}

.try-again {
  margin-left: 4px;
  font-weight: 500;
  color: $color-black;
}

.browse-more-btn {
  font-weight: 500;
  display: flex;
  align-items: center;
  color: $color-grey-19;
  flex-direction: row-reverse;
  background-color: $color-grey-18;
  svg {
    margin: 0px !important;
    path {
      stroke: $color-grey-19;
    }
  }
}

.followed-issues-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #626d74;
    padding-top: 8px;
  }
  h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $color-primary-11;
    cursor: pointer;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #626d74;
  }
  &__message {
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: center;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85) !important;
  }
}
