@import 'styles/colors.scss';
@import 'styles/titles.scss';

.creator-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $color-white;
  padding: 16px 12px !important;
  gap: 16px;
  position: absolute !important;
  width: 250px;
  border: 1px solid $color-border-grey;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.16) !important;
  border-radius: 8px !important;
  z-index: 99;

  &__heading {
    @include flex-body;
    margin-bottom: 12px;
    justify-content: space-between;

    &__user {
      flex: 1;
      @include flex-body;
    }

    &__user-avatar {
      height: 35px;
      width: 35px;
      margin-right: 8px;
      border-radius: 20px;
    }

    &__user-title {
      @include info;
      display: flex;
      flex-direction: column;
      cursor: pointer !important;

      &__link {
        span {
          cursor: pointer;

          &:hover {
            background-color: $color-grey-7 !important;
            cursor: pointer !important;
          }
        }
      }

      &:hover {
        background-color: $color-grey-7 !important;
        cursor: pointer !important;
        font-weight: 500;
      }
    }

    &__watchlist-creator-btn {
      @include flex-body-centered;
      height: 30px;
      width: 100%;
      background-color: $color-black;
      padding: 6px 14px !important;
      margin-top: 12px;
      border-radius: 32px;
      border: 1px solid $color-black;
      line-height: 12px;
      font-size: 12px;
      font-weight: 600;
      color: $color-white;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $color-black !important;
        color: $color-white;
        border: 1px solid $color-black;
      }
    }
  }

  &__description {
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 8px;
  }

  &__following {
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 12px;

    span {
      color: $color-grey-text;

      b {
        color: $color-black;
      }
    }
  }

  &__communities-watchlists {
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 12px;

    span {
      color: $color-black;
    }

    &--no-margin {
      margin-bottom: 0;
    }
  }

  .ant-dropdown-menu-item :hover {
    padding: 0;
    cursor: default;
    background-color: white;
  }

  .ant-dropdown-menu-item {
    width: 100%;
    padding: 0;
    cursor: default;
    background-color: white;
  }
}
