@import 'styles/titles';
@import 'styles/colors';

.pagination {
  margin-left: 40% !important;

  &__flex {
    @include flex-body-centered;
  }

  &__container {
    @include flex-body-centered;
    padding: 0px 16px 0px 12px;
    gap: 4px;

    &--padding {
      padding: 0 14px;
    }

    svg {
      path {
        stroke: $color-grey-text;
        stroke-width: 1.5;
      }
    }
  }

  &__label {
    @include description;
    color: $color-dark-grey;
  }

  &__left-arrow {
    transform: rotate(180deg);
  }

  :last-child {
    margin-left: auto;
  }

  .ant-pagination-item,
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    @include flex-body-centered;
    background-color: #fff;
    border: none;
    margin-right: 0px;
    height: 36px;
    border: 1px solid $color-border-grey;
    border-right: none;
  }

  .ant-pagination-item {
    border-radius: 0px;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    @include flex-body-centered;
    margin-right: 0px;
    height: 36px;
  }

  .ant-pagination-prev {
    border-radius: 4px;
    border: 1px solid $color-border-grey;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-pagination-next {
    border-radius: 4px;
    border: 1px solid $color-border-grey;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ant-pagination-item-active {
    background-color: $color-grey-18;
    border-color: $color-grey-17 !important;
    .pagination__label {
      color: $color-grey-600 !important;
    }
  }

  .ant-select-selector {
    border-radius: 4px !important;
    border: 1px solid $color-border-grey !important;
    gap: 10px;
    box-shadow: none !important;
  }

  .ant-select-selection-item,
  .ant-select-item {
    @include description;
    color: $color-dark-grey;
  }

  .ant-pagination-options {
    @include flex-body-centered;

    &::before {
      @include description;
      content: 'Rows per page:';
      color: $color-dark-grey;
      padding: 0 8px;
    }
  }

  .ant-select {
    width: initial !important;
  }
}
