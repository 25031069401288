@import 'styles/colors';
@import 'styles/titles';

.tenant-modal {
  &__info-div {
    @include flex(column, flex-start, center);
    gap: 8px;
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid $color-border-grey;
    background: $color-background;

    &__url {
      @extend .tenant-modal__info-div;
      width: 100%;
      background: $color-white;
    }

    &__label {
      @include info-600;
      color: $color-grey-text;
    }

    .ant-input {
      border: none;
      padding: 0;
      background: $color-background;

      &:hover,
      &:focus {
        border: none;
        box-shadow: none !important;
      }
    }

    .ant-form-item-control-input {
      min-height: initial;
    }
  }

  &__notification {
    @include flex-body;
    border-left: 5px solid $color-success;
    box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.12);
    padding: 16px;

    &__margin {
      margin-left: 70% !important;
      margin-bottom: -4%;
    }

    &__description {
      @include subtitle;
      color: $color-black;
    }

    .ant-notification-notice-content {
      @include flex-body;
    }

    .ant-notification-notice-description {
      margin-left: 40px;
    }
  }

  .ant-modal-content {
    @include flex-body-column;
    gap: 24px;
    padding: 32px;
  }

  .ant-modal-header {
    @include flex-body;
    padding: 0;

    .ant-modal-title {
      text-align: start;
    }
  }

  .ant-modal-body {
    overflow: scroll;
  }

  .ant-modal-footer {
    padding: 0;
    @include flex(row, center, flex-end);
  }

  .ant-input,
  .ant-form-item-control-input-content {
    min-height: initial;
  }

  .ant-input-affix-wrapper {
    border-radius: 4px;
    border: none;
    background: $color-white;
    padding: 0;

    &:hover {
      border: none;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .ant-input-prefix {
    margin-right: 8px;
  }

  .ant-form-item,
  .ant-form-item-control {
    margin: 0px;
  }

  .ant-form-item {
    width: 100%;
  }

  .ant-form-item-control-input-content {
    padding: 0;
  }

  .ant-select-selector {
    border-radius: 4px !important;
    border: 1px solid $color-border-grey !important;
    padding: 10px !important;
    height: max-content !important;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .ant-checkbox,
  .ant-checkbox-input,
  :not(.ant-checkbox-checked) {
    &:hover,
    :focus,
    :active {
      .ant-checkbox-inner {
        border: 1px solid $color-grey-17;
      }
    }
  }

  .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid $color-grey-17;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-black;
    border-color: $color-black;
    border: 1px solid $color-black;
  }

  .ant-modal-close {
    top: 17px;
  }
}
