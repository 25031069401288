@import 'styles/colors.scss';
@import 'styles/titles.scss';

.feed-delete-modal {
  @include flex(column, center, center);
  gap: 16px;

  &__title {
    @include title;
    @include flex-body;
    color: $color-dark-text;
    text-align: center;
  }

  &__description {
    @include description;
    @include flex-body-centered;
    color: $color-grey-text;
  }

  &__close-btn {
    width: 100%;
    background-color: $color-black;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-white;
    margin-top: 24px;
  }

  &__confirmation-close-btn {
    width: 100%;
    background-color: $color-black;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-white;
    margin-top: 16px;
  }

  &__cancel-btn {
    width: 45%;
    background-color: transparent;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-black;
    margin-top: 24px;
    margin-right: 10px;
  }

  .feed-delete-modal__delete-btn.ant-btn {
    width: 45%;
    background-color: $color-red;
    border: 1px solid $color-red;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-white;
    margin-top: 24px;
    font-size: 14px;
    font-weight: 400;
    height: 38px;
    &:hover {
      background-color: $color-red;
      border: 1px solid $color-red;
      color: $color-white;
    }
    &.ant-btn-loading {
      background-color: $color-red;
      border: 1px solid $color-red;
      color: $color-white;
    }
  }

  &__margin {
    margin-bottom: 0;
  }

  button {
    cursor: pointer;
  }

  .ant-modal-footer {
    display: block;
    text-align: center;
    padding: 0;
  }

  .ant-modal-content {
    padding: 32px;
  }

  .delete-gif {
    img {
      width: 64px;
      height: 64px;
      margin: auto;
      display: block;
    }
  }
}
