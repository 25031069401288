@import 'styles/colors.scss';

.risk_flag_container {
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  flex-grow: 1;

  // background-color: gold;
  .rfc_text {
    align-self: flex-start;
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 0;
  }

  .rfc_flags_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 16px;
    // width: 312px;
    flex-shrink: 0;

    .rfc_fl_chunk {
      // cursor: pointer;
      padding: 8px;
      background-color: $color-primary-6;
      border-radius: 4px;
      width: calc(50% - 4px);

      &.rfc_fl_chunk_active {
        background-color: $color-prime;

        .rfc_flc_bottom {
          .rfc_flcb_text {
            color: $color-white;

            .rfc_flcb_t_part {
              font-size: 12px;
              color: $color-white;

              .rfc_flcb_tp_icon {
                path {
                  stroke: $color-white;
                }
              }
            }
          }
        }

        .rfc_flc_title {
          color: $color-white;
        }
      }

      &.rfc_fl_chunk_low_risk {
        background-color: $color-unselected;

        .rfc_flc_bottom {
          .rfc_flcb_text {
            .rfc_flcb_t_part {
              font-size: 12px;
              color: $color-indigo-700;

              .rfc_flcb_tp_icon {
                path {
                  stroke: $color-indigo-700;
                }
              }
            }
          }
        }

        .rfc_flc_title {
          color: $color-grey-text;
        }
      }

      &.rfc_fl_chunk_disabled {
        background-color: $color-unselected;

        .rfc_flc_bottom {
          .rfc_flcb_text {
            color: $color-grey-text;
          }

          .rfc_flch_icon {
            path {
              fill: $color-grey-text;
            }
          }
        }

        .rfc_flc_title {
          color: $color-grey-text;
        }
      }

      .rfc_flc_title {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
      }

      .rfc_flc_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .rfc_flcb_text {
          color: $color-dark-text;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 0;

          .rfc_flcb_t_part {
            font-size: 12px;
            color: $color-orange;
          }
        }
      }

      &.rfc_fl_chunk_more {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-unselected;
        align-self: stretch;

        .rfc_fl_cm_text {
          color: $color-grey-800;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }
  }
}
