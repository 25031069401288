@import 'styles/variables.scss';
@import 'styles/colors.scss';

.search_input_container {
  position: relative;
  display: flex;
  padding: 5px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  width: 260px;
  height: 34px;
  background-color: $color-white;
  border: 1px solid $color-border;

  .search_content_container {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    max-height: 174px;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid $color-border-grey;
    background-color: $color-white;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
    padding: 10px;
    z-index: 1;
  }

  .input {
    all: unset;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $color-black;
    flex-grow: 1;

    &::placeholder {
      color: $color-grey-text;
    }
  }

  .clear_icon {
    cursor: pointer;
  }
}
