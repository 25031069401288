@import 'styles/titles';
@import 'styles/colors';

.agreement-welcome-modal {
  &__title-container {
    @include flex-column-centered;
    padding: 32px 24px;
    gap: 8px;

    &__text {
      @include heading-600;
      color: $color-grey-900;
      text-align: center;
    }
  }

  &__description {
    @include description;
    color: $color-grey-600;
    text-align: center;
  }

  &__footer {
    @include flex-body;
    padding: 0 24px 24px 24px;
    width: 100%;
    gap: 16px;

    &__button {
      @include subtitle;
      width: 100px;
      border-radius: 6px;
      padding: 10px 16px;
      flex: 1;
      cursor: pointer;

      &--continue {
        @extend .agreement-welcome-modal__footer__button;
        background: $color-black;
        border: 1px solid $color-black;
        color: $color-white;
      }

      &--skip {
        @extend .agreement-welcome-modal__footer__button;
        color: $color-black;
        border: 1px solid $color-black;
        background: $color-white;
      }
    }
  }

  .ant-modal-content {
    @include flex-body-column;
    padding: 0px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 16px;
    border: 1px solid $color-border-grey-2;
  }

  .ant-modal-header {
    padding: 0px;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-modal-body {
    @include flex(column, center, flex-start);
  }

  .ant-modal-footer {
    @include flex(row, flex-end, flex-end);
    padding: 0px;
    border-radius: 0px 0px 8px 8px;
  }
}
