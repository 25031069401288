@import 'styles/titles.scss';
@import 'styles/colors.scss';
@import 'styles/variables.scss';

.pulse-dashboard {
  height: calc(100vh - 60px);
  background-color: $color-primary-5;

  &-tabs--container {
    gap: 16px;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    background-color: $color-white;
    border-bottom: 1px solid $color-border-grey-2;

    .ant-btn.ant-btn-default {
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 1rem;
        height: 1rem;
        path {
          stroke: $color-grey-19;
        }
      }
    }

    .add-tabs-btn {
      margin-left: 16px;
      &.isLimitExceeded .ant-btn.ant-btn-default {
        min-height: 54px;
        width: 54px;
      }
    }
  }

  &-tabs {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    &__tab {
      gap: 24px;
      display: flex;
      cursor: pointer;
      padding: 8px 24px;
      position: relative;
      align-items: center;
      align-self: stretch;
      background: transparent;
      border-right: 1px solid $color-border-grey-2;

      &__title {
        color: $color-grey-19;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        overflow: hidden;
        min-width: 50px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__count {
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 50px;
        min-width: 25px;
        min-height: 25px;
        background: $color-prime;

        &__text {
          color: $color-white;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .ant-btn.ant-btn-default {
        border: none;
        outline: none;
        background: transparent;
        svg {
          width: 10px;
          height: 10px;
        }
      }

      &.active,
      &:hover {
        background-color: #fcfaf5;
        .pulse-dashboard-tabs__tab__title {
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          line-height: 20px;
          color: $color-primary-11;
        }
      }

      &:active {
        font-weight: 600;
      }

      &__edit-icon.ant-btn.ant-btn-default.ant-btn-icon-only {
        right: 48px;
        visibility: hidden;
        position: absolute;
        svg {
          width: 13px;
          height: 13px;
        }
      }

      &:hover &__edit-icon.ant-btn.ant-btn-default.ant-btn-icon-only {
        visibility: visible;
      }
    }

    &__loader {
      ul li {
        height: 54px !important;
      }
    }
  }

  .ant-row {
    overflow: hidden;
    height: calc(100vh - 114px);
  }

  &__filters-container.ant-col-6 {
    gap: 40px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    height: calc(100vh - 100px);
    padding: 24px 24px 40px 24px;
    justify-content: space-between;

    .snippets-filters {
      gap: 24px;
      display: flex;
      flex-direction: column;

      &__dropdown-filter-container {
        gap: 16px;
        display: flex;
        padding: 16px;
        border-radius: 12px;
        flex-direction: column;
        align-items: flex-start;
        background: $color-white;
        border: 1px solid $color-border-grey-2;

        &__title {
          font-weight: 500;
          color: $color-grey-600;
        }

        &__select.ant-select {
          .ant-select-selector {
            border-radius: 8px;
            background: $color-white;
            border: 1px solid $color-grey-17;
            box-shadow: 0px 1px 2px 0px #1018280d;
          }
        }

        .date-selection {
          gap: 16px;
          width: 100%;
          display: flex;
          flex-direction: column;

          &__container {
            gap: 4px;
            width: 100%;
            display: flex;
            flex-direction: column;

            &__title {
              font-weight: 500;
              color: $color-grey-19;
            }

            &__value {
              gap: 4px;
              width: 100%;
              display: flex;
              padding: 4px 12px;
              border-radius: 8px;
              align-items: center;
              background: $color-white;
              border: 1px solid $color-grey-17;
              box-shadow: 0px 1px 2px 0px #1018280d;

              &__date {
                color: $color-grey-19;
              }
              &__time {
                color: $color-grey-22;
              }
            }
          }
        }

        .ant-tag.selected-content-tag {
          display: flex;
          align-items: center;
          padding: 4px 6px;
          margin-inline-end: 4;
          border-style: dotted;
          border-radius: 12px;

          .category {
            font-weight: 500;
            color: $color-grey-600;
          }
        }

        .category-values {
          margin-left: 24px;
          font-weight: 500;
          position: relative;
          color: $color-primary-1;
          text-wrap: wrap;
          &::before {
            content: '';
            position: absolute;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            height: 75%;
            width: 2px;
            background: $color-grey-17;
          }
        }

        .selected-content-tag-container {
          gap: 8px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .filter-action-buttons {
          gap: 16px;
          display: flex;
          align-items: center;

          .ant-btn-primary {
            border: none;
            outline: none;
            font-weight: 500;
            border-radius: 6px;
            color: $color-grey-19;
            background: $color-grey-18;
          }

          .ant-btn-text {
            border: none;
            outline: none;
            font-weight: 500;
            color: $color-black;
          }
        }

        .last-login-history-button {
          font-weight: 500;
          cursor: pointer;
          padding: 3px 6px;
          border-radius: 12px;
          color: $color-grey-19;
          display: inline-block;
        }
      }
    }

    .snippets-stats {
      gap: 16px;
      display: flex;
      padding: 16px;
      border-radius: 12px;
      flex-direction: column;

      &__container {
        gap: 16px;
        gap: 8px;
        display: flex;
        flex-direction: column;

        &__title {
          font-weight: 400;
          color: $color-grey-22;
        }

        &__value {
          font-weight: 500;
          color: $color-grey-23;
        }
      }
    }
  }

  &__snippets-container {
    position: relative;
    height: calc(100vh - 60px);
    background: $color-background-tan;

    .sl-with-filters-container__header,
    .sl-with-filters-container__filters-section {
      background-color: $color-white;
    }

    .c-snippets-list__list.dashboard_snippet_list:has(.brand-snippets-empty-state) {
      padding: 0px;
    }

    .pulse_snippets_loader--mask {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 99;
      position: absolute;
      background: rgba($color: #000000, $alpha: 0.1);
    }

    .pulse_snippets_loader {
      top: 50%;
      left: 50%;
      z-index: 99;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    .data-fetching__main-content {
      height: 100%;
      margin: 0px;
      padding: 0px 20px;
    }

    .brand-snippets-empty-state {
      background-color: $color-grey-20;

      &__description {
        max-width: 315px;
        text-align: center;
      }
    }

    .data-fetching {
      height: 100%;
      background: $color-background-tan;
    }

    .ant-btn.empty-feed-btn {
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $color-primary-11;
    }
  }

  &__searches-container.ant-col-6 {
    gap: 24px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding: 24px 24px 40px 24px;
    max-height: calc(100vh - 60px);

    .searches-container__metrics-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .pulse-actions {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        button {
          flex: 1;
          min-width: 150px;

          &:last-child {
            background-color: $color-white;
            svg {
              width: 0.8rem;
              height: 0.8rem;

              path {
                stroke: $color-primary-black;
              }
            }
          }
        }
      }

      &__card {
        gap: 16px;
        display: flex;
        padding: 16px 24px;
        border-radius: 12px;
        align-self: stretch;
        flex-direction: column;
        align-items: flex-start;
        background: $color-white;
        border: 1px solid $color-border-grey;
        box-shadow: 0px 0px 8px 0px $color-box-shadow;

        .mention-view-card__title {
          color: $color-grey-900;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .mention-view-card__status__counts {
          color: $color-grey-900;
          font-size: 24px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: -0.48px;
        }

        .mention-view-card__status__trend {
          display: none;
        }

        .chart-wrapper {
          display: none;
        }
      }
    }

    .searches {
      gap: 20px;
      display: flex;
      padding: 24px;
      min-height: 399px;
      border-radius: 12px;
      flex-direction: column;
      background-color: $color-white;
      border: 1px solid $color-border-grey-2;

      &__pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .disabled_pagination {
          opacity: 0.2;
          user-select: none;
          cursor: not-allowed;
          pointer-events: none;
        }

        &__text {
          font-weight: 500;
          color: $color-grey-600;
        }

        .pagination-btn {
          cursor: pointer;
        }
      }

      &__listing {
        gap: 16px;
        display: flex;
        overflow-y: auto;
        max-height: 330px;
        flex-direction: column;
        &__item {
          gap: 12px;
          display: flex;
          align-items: center;

          &__count {
            min-width: 24px;
            min-height: 24px;
            display: flex;
            border-radius: 50%;
            align-items: center;
            color: $color-grey-22;
            justify-content: center;
            background-color: $color-grey-18;
          }

          &__content {
            gap: 4px;
            display: flex;
            flex-direction: column;

            &__label {
              font-size: 500;
              color: $color-black;
            }

            &__value {
              font-size: 12px;
              font-size: 400;
              color: $color-dark-grey;
            }
          }
        }
      }
    }

    .watchlist {
      gap: 20px;
      display: flex;
      padding: 24px;
      min-height: 429px;
      border-radius: 12px;
      flex-direction: column;
      background-color: $color-white;
      border: 1px solid $color-border-grey-2;

      &__label {
        font-weight: 500;
        color: $color-grey-600;
      }

      &__content {
        overflow-y: auto;
        max-height: 340px;
        gap: 16px;
        display: flex;
        flex-direction: column;
        &__item {
          gap: 12px;
          display: flex;
          align-items: center;

          &__avatars {
            .ant-avatar-circle:nth-child(4) {
              color: $color-primary-4;
              background-color: $color-primary-7;
            }
          }

          &__stats {
            gap: 6px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__label {
              font-size: 12px;
              font-weight: 500;
              color: $color-black;
            }
            &__value {
              font-size: 12px;
              font-weight: 400;
              color: $color-dark-grey;
            }
          }
        }
      }
    }
  }
}

.warning-modal__content {
  display: flex;
  padding: 32px 24px 0px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;

  &__info {
    max-width: 321px;
    gap: 8px;
    display: flex;
    flex-direction: column;

    &__title {
      color: $color-grey-900;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    &__description {
      color: $color-grey-600;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.ant-modal.base-modal.tab-creation-modal {
  .ant-modal-footer {
    .tab-creation-confirm-btn {
      &:disabled {
        background: $color-disabled-grey;
      }
    }
  }
}

.tab-creation-content {
  display: flex;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  &__asterisk {
    color: $color-red;
  }

  &__tab-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-grey-19;
  }

  label {
    width: 100%;
    gap: 6px;
    display: flex;
    flex-direction: column;

    .ant-input {
      width: 100%;
      min-height: auto;
      font-size: 16px;
      font-weight: 400;
      color: $color-dark-text;
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid $color-grey-17;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
  }
}

.create-by-choice-modal.ant-modal.base-modal {
  width: 574px !important;
  .ant-modal-content {
    .ant-modal-header .ant-modal-title {
      .create-by-choice-modal__title {
        gap: 8px;
        display: flex;
        flex-direction: column;

        &__text {
          color: $color-black;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          font-style: normal;
          font-family: Roboto;
        }

        &__description {
          color: $color-dark-grey;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .ant-modal-body {
      .create-by-choice-modal__content {
        gap: 20px;
        display: flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 32px 32px 32px;
        border-radius: 0px 0px 16px 16px;

        .option {
          gap: 24px;
          width: 506px;
          padding: 20px;
          display: flex;
          cursor: pointer;
          border-radius: 8px;
          align-items: center;
          background: $color-grey-21;
          border: 1px solid $color-border-grey-2;

          &-info {
            gap: 8px;
            display: flex;
            flex-direction: column;

            &__title {
              font-size: 16px;
              font-weight: 700;
              font-style: normal;
              line-height: normal;
              font-family: Roboto;
              color: $color-border-black;
            }
            &__description {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              font-style: normal;
              font-family: Roboto;
              color: $color-dark-grey;
            }
          }
        }
      }
    }
  }
}

.pulse-date-selection-modal.ant-modal {
  width: 936px !important;
}

.pulse-date-selection-modal.ant-modal .ant-modal-content {
  padding: 24px;

  .ant-modal-body {
    display: flex;
    flex-direction: column;

    .snippets-filters__dropdown-filter-container {
      gap: 12px;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;

      &__optional {
        gap: 12px;
        display: flex;
        align-items: center;
        .option-text {
          font-weight: 500;
          color: $color-grey-22;
        }
      }

      &__title {
        font-weight: 500;
        color: $color-grey-600;
      }

      &__description {
        font-weight: 400;
        color: $color-dark-grey;
      }

      &__select.ant-select {
        .ant-select-selector {
          border-radius: 8px;
          background: $color-white;
          border: 1px solid $color-grey-17;
          box-shadow: 0px 1px 2px 0px #1018280d;
        }
      }

      .ant-tabs.ant-tabs-top {
        left: 0px;
        top: 100%;
        width: 100%;
        z-index: 9999;
        position: absolute;
        border-radius: 12px;
        background-color: $color-white;
        box-shadow: 0px 3px 8px 0px #00000026;

        .ant-tabs-nav-list {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .ant-tabs-tab {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-left: 12px;

            .ant-tabs-tab-btn {
              font-weight: 400;
              color: $color-grey-22;

              .custom-tab-label {
                gap: 8px;
                display: flex;
                align-items: center;

                &__counter {
                  height: 20px;
                  font-size: 12px;
                  min-width: 20px;
                  border-radius: 50%;
                  align-items: center;
                  color: $color-white;
                  display: inline-flex;
                  justify-content: center;
                  background: $color-grey-600;
                }
              }
            }
          }

          .ant-tabs-tab-active {
            width: 100%;

            .ant-tabs-tab-btn {
              font-weight: 500;
              color: $color-grey-600;

              .custom-tab-label {
                gap: 8px;
                display: flex;
                align-items: center;

                &__counter {
                  height: 20px;
                  font-size: 12px;
                  min-width: 20px;
                  border-radius: 50%;
                  align-items: center;
                  color: $color-white;
                  display: inline-flex;
                  justify-content: center;
                  background: $color-grey-600;
                }
              }
            }
          }

          .ant-tabs-ink-bar {
            background-color: $color-grey-600;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    padding-top: 40px;
    justify-content: flex-end;

    .pulse-content-selection-modal__footer {
      .ant-btn-primary {
        border: none;
        outline: none;
        font-weight: 500;
        border-radius: 6px;
        color: $color-white;
        background: $color-black;
      }
      .ant-btn-text {
        border: none;
        outline: none;
        font-weight: 500;
      }
    }
  }
}

.new-snippets-banner {
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 240px;
  height: 40px;
  padding: 8px 24px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 3px 0px 0px 0px;
  border: 3px solid $color-primary-8;
  box-shadow: 0px 3px 8px 0px #00000026;
  background-color: $color-prime;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: white;
  cursor: pointer;
}

.module-title-container {
  gap: 8px;
  display: flex;
  align-items: center;

  &__btn.ant-btn.ant-btn-default {
    padding: 0px;
    margin: 0px;
    width: 24px;
    border: none;
    outline: none;
    height: 24px;
    flex-shrink: 0;
    display: flex;
    cursor: text;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    font-style: normal;
    font-family: Roboto;
    color: $color-dark-text;
  }
}
