@import 'styles/colors.scss';
@import 'styles/titles.scss';

.tenant-platforms {
  @include flex(column, flex-start, flex-start);
  gap: 24px;

  &__header {
    @include flex(row, center, space-between);
    width: 100%;

    &__title {
      @include description;
      color: $color-grey-text;
      padding: 5px 0;
    }

    &__save-btn {
      @include flex-body-centered;
      @include button-text;
      padding: 7px 16px;
      gap: 2px;
      border: none;
      border-radius: 4px;
      color: $color-white;
      background-color: $color-black;
      cursor: pointer;

      &:disabled {
        @include subtitle;
        background: $color-grey-text;
        cursor: not-allowed;
        border: none;
      }
    }
  }

  &__buttons-container {
    @include flex-body;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__buttons {
    @include description;
    @include flex(row, center, flex-start);
    padding: 6px 20px 6px 12px;
    gap: 8px;
    border-radius: 4px;
    background: $color-unselected;
    color: $color-dark-grey;
    border: 1px solid $color-grey-scale-200;
    cursor: pointer;
    min-width: 128px;

    &--active {
      @extend .tenant-platforms__buttons;
      background: $color-black;
      color: $color-white;

      svg {
        path {
          fill: $color-white;
        }
      }

      .vk-icon {
        path {
          fill: $color-black;
        }
      }

      .threads-icon {
        path {
          fill: unset;
        }
      }

      .telegram-icon {
        path:nth-child(2) {
          fill: $color-black;
        }
      }
    }

    span {
      @include flex-body-centered;
    }
  }
}
