@import 'styles/colors.scss';
@import 'styles/titles.scss';

.loadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;

  padding-top: 10px;
  padding-bottom: 41px;

  border-radius: 8px;
  background: $color-primary-3;

  .waiting {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;

    .gifWrapper {
      width: 80px;
      height: 40px;

      img {
        height: 100%;
        text-align: center;
      }
    }

    .message {
      color: $color-black;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .message {
    text-align: center;
    color: $color-black;
    font-size: 14px;
    line-height: 20px;
  }
}

.overLay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background: #ffffff33;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
