@import 'styles/colors.scss';
@import 'styles/titles.scss';

.reportListForm {
  margin-top: 16px;
  min-height: 140px;

  .reportType {
    display: flex;
    width: 465px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    background-color: $color-background;
    border: 1px solid $color-border-grey;
    border-radius: 4px;

    .labelParent {
      display: flex;
      gap: 9px;

      .inputLabel {
        color: $color-grey-text;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 9px;
        margin: 0;
      }
    }

    .radioContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      .row {
        display: flex;
        align-items: flex-start;
        justify-content: left;
        gap: 6px;

        color: #8598ad;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        :global(.ant-radio) {
          top: unset;
        }

        :global {
          span.ant-radio + * {
            padding-right: unset;
            padding-left: unset;
          }

          .ant-radio-inner:after {
            background-color: black;
          }

          .ant-radio-checked .ant-radio-inner {
            border-color: black;
          }
        }

        .text {
          display: flex;
          align-items: center;
          gap: 2px;
        }
      }

      .dateRangeContainer {
        display: flex;
        padding: 0px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .inner {
          display: flex;
          width: 232px;
          height: 36px;
          padding: 10px 12px;
          align-items: center;

          border-radius: 6px;
          border: 1px solid var(--Stroke, #e2ebfa);
          background: #fff;

          .drInput {
            background-color: $color-background;
            color: $color-dark-grey;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            min-height: unset;
            outline: none;
            border: none;

            padding: 0;
          }

          .downArrow {
            stroke-width: 1.5px;
            stroke: var(--Dark-Text, $color-dark-text);
          }
        }

        .calendarDiv {
          position: relative;
          left: 340px;
          bottom: 19px;
        }
      }

      .selectContainer {
        display: flex;
        padding: 0px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        height: 36px;

        .select {
          display: flex;
          width: 232px;
          align-items: flex-end;

          border-radius: 6px;
          border: 1px solid var(--Stroke, #e2ebfa);
          background: #fff;

          .downArrowIcon {
            pointer-events: none;
            stroke-width: 1.5px;
            stroke: var(--Black, $color-black);
          }
        }
      }
    }
  }
}
