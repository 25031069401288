@import 'styles/colors.scss';

.dashboard-metrics {
  display: flex;
  gap: 24px;
  flex-direction: column;
  &__header {
    padding-bottom: 24px;
    display: flex;
    gap: 14px;
    flex-direction: column;
    border-bottom: 1px solid $color-border-grey-2;
    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      color: #16191d;
    }
    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #8598ad;
    }
  }
}
