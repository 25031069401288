@import 'styles/colors.scss';
@import 'styles/titles.scss';

.ids {
  width: 100%;
  height: calc(100vh - 68px);

  &-header {
    display: flex;
    padding: 12px 40px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-border-grey-2;

    &__left-menu {
      gap: 16px;
      display: flex;
      align-items: center;

      .power-insights-btn {
        svg path {
          stroke: $color-white;
        }
      }
    }

    &__right-menu {
      gap: 16px;
      display: flex;
      align-items: center;

      .ant-dropdown-trigger.date-filter__filter-icon.date-filter__filter-not-applied.date-filter {
        gap: 0px;
        margin: 0px;

        svg:first-child {
          margin-right: 8px;
        }

        .date-filter__text {
          margin-right: 65px;
        }
      }
    }
  }

  &-body {
    width: 100%;
    background-color: $color-primary-3;

    // left section
    &__ls {
      gap: 40px;
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      padding: 24px 24px 40px 24px;
      overflow-y: auto;
      height: calc(100vh - 135px);
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }

      &__card {
        gap: 16px;
        padding: 24px;
        display: flex;
        border-radius: 12px;
        flex-direction: column;
        border: 1px solid $color-grey-18;
        background: $color-white;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
      }
    }

    // center section
    &__cs {
      height: calc(100vh - 135px);
      border-left: 1px solid $color-border-grey-2;
      border-right: 1px solid $color-border-grey-2;

      .sl-with-filters-container-wrapper {
        background-color: $color-white;

        .empty-feed-btn {
          color: $color-primary-11;
        }
      }

      .pulse_snippets_loader--mask {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 99;
        position: absolute;
        background: rgba($color: #000000, $alpha: 0.1);
      }

      .pulse_snippets_loader {
        top: 50%;
        left: 50%;
        z-index: 99;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }

    //right section
    &__rs {
      gap: 24px;
      width: 100%;
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      padding: 24px 24px 40px 24px;
      height: calc(100vh - 135px);

      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }

      &--metrics-cards {
        gap: 16px;
        display: flex;
        flex-direction: column;
      }

      &__card {
        gap: 16px;
        padding: 16px 14px;

        .monitor-assets__graph__title {
          color: $color-grey-900;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .mention-view-card {
          margin: 0px;

          .mention-view-card__status__counts {
            color: $color-grey-900;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px;
            letter-spacing: -0.48px;
          }
        }

        .mention-view-card__empty-state {
          margin: 0px;

          .skeleton-paragraph__large {
            height: 44px;
            margin-bottom: 0px;
          }
        }
      }

      .bd__body__influential-voices--top-voices {
        width: 100%;
        height: 685px;
      }

      .bd__body__influential-voices__table--wrapper {
        gap: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .dashboard-widgets.powerinsight_dashboard {
    height: calc(100vh - 133px);
  }

  .dashboard-widgets .snippet-section-column {
    height: calc(100vh - 133px);
  }

  .PowerInsightTabsListVertical .fixed {
    height: calc(100vh - 133px);
  }
}
