@import 'styles/colors.scss';
@import 'styles/titles.scss';

:global {
  .ant-select-dropdown {
    z-index: 9999 !important;
  }
}

.platformTitleWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;

  .icon {
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
  }

  .titleText {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $color-dark-text;
  }
}

.createListBtn {
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &,
  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
  }
}

:global {
  .ant-select-dropdown {
    .ant-select-item {
      color: $color-black;

      .ant-select-item-option-content {
        display: flex;
        align-items: center;
      }

      .ant-select-item-option-state {
        height: 22px;
        display: flex;
        align-items: center;
      }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: $color-black;
      background-color: $color-primary-3;
    }
  }
}

.addSnippetToBookmarksListModalWrapper {
  :global {
    .ant-modal-content {
      border-radius: 4px;
      padding: 32px;

      .ant-modal-close {
        top: 38px;
        right: 34px;

        .ant-modal-close-x {
          height: 12px;
          width: 12px;
          line-height: 12px;

          svg {
            height: 12px;
            width: 12px;

            path {
              stroke-width: 2px;
              stroke: $color-grey-scale-600;
            }
          }
        }
      }
    }

    .ant-modal-header,
    .ant-modal-footer {
      padding: 0;
    }
  }

  .formModalBody {
    .bodyHeader {
      margin-top: 24px;

      .description {
        color: $color-grey-text;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    .selectBookmarksWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 24px;

      .labelText {
        margin: 0;
        display: flex;
        gap: 5px;

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;

        .selectedListsCount {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 50%;
          background: $color-prime;

          color: $color-white;
          font-family: Rubik;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
      }

      .createBookmarkInputWrapper {
        border: 1px solid $color-border-grey;
        padding: 7px 7px 7px 15px;

        :global {
          .ant-input-group {
            display: flex;
            align-items: center;
          }
        }

        .input {
          min-height: unset;
          border: 0;
          padding: 0;

          color: $color-black;
          font-family: Inter;
          font-size: 14px;
          line-height: 20px;

          &::placeholder {
            color: $color-grey-text;
          }
        }

        .actionBtnIcon {
          padding: 0;
          border: 0;
          width: 24px;
          height: 24px;

          &:disabled {
            background-color: unset;

            svg {
              path {
                stroke: $color-grey-text;
              }
            }
          }
        }
      }

      .selectDropdown {
        width: 100%;
        line-height: 16px;

        :global {
          .ant-select-selector {
            padding: 5px;
            background-color: $color-background;
            border-color: $color-border-grey;
            border-radius: 4px;
            box-shadow: unset !important;
            min-height: 40px;

            &::after {
              line-height: 16px;
            }

            .ant-select-selection-overflow {
              gap: 10px;
            }

            .ant-select-selection-item,
            .ant-select-selection-placeholder,
            .ant-select-selection-search-input {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
            }

            .ant-select-selection-item {
              margin: 0;
              height: unset;
              display: inline-flex;
              padding: 5px 10px;
              align-items: center;
              gap: 8px;

              border-radius: 4px;
              border: 1px solid $color-border-grey;
              background: $color-grey-3;

              font-size: 12px;
              line-height: 16px;
              color: $color-dark-grey;

              .ant-select-selection-item-content {
                margin-right: 0;
              }
            }

            .ant-select-selection-search-input,
            .ant-select-selection-search-mirror {
              height: unset;
              line-height: 16px;
            }

            .ant-select-selection-placeholder {
              color: $color-grey-text;
              right: 12px;
              left: 12px;
            }
          }

          .ant-select-arrow {
            right: 16px;
            transition: transform 0.3s ease-in-out;
          }
        }
      }

      :global {
        .ant-select-open {
          .ant-select-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .successModalBody {
    .gif {
      img {
        width: 64px;
        height: 64px;
        margin: auto;
        display: block;
      }
    }

    .mainContent {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .message {
        color: $color-dark-text;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }

      .subMessage {
        color: $color-grey-scale-600;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .openBookmarksLink {
      margin-top: 16px;
      padding: 7px 16px;
      text-align: center;
      line-height: 20px;

      .link {
        font-size: 14px;
        font-weight: 500;
        color: $color-prime;
      }
    }
  }

  .modalFooter {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    &.successModalFooter {
      width: 100%;
      margin-top: 16px !important;
    }

    button {
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 16px;
      border-radius: 4px;
      border: 0;

      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .cancelButton {
      color: $color-black;
      outline: 1px solid $color-black;

      &:hover {
        color: $color-black;
        background: unset;
        border-color: unset;
      }
    }

    .doneButton,
    .createButton {
      margin-left: 0 !important;
      background-color: $color-black;
      color: $color-white;

      &:hover {
        background: $color-black;
        color: $color-white;
        border-color: unset;
      }

      &:disabled {
        background: #bcc1cc;
        cursor: not-allowed;
      }
    }

    .doneButton {
      width: 100%;
    }
  }
}
