@import 'styles/titles.scss';
@import 'styles/colors.scss';
@import 'styles/variables.scss';

.content-selector {
  gap: 12px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;

  &__optional {
    gap: 12px;
    display: flex;
    align-items: center;
    .option-text {
      font-weight: 500;
      color: $color-grey-22;
    }
  }

  &__title {
    font-weight: 500;
    color: $color-grey-600;
  }

  &__description {
    font-weight: 400;
    color: $color-dark-grey;
  }

  &__tabs {
    left: 0px;
    top: 100%;
    width: 100%;
    position: absolute;
    z-index: 999;
  }

  .modal-selected-content-container {
    gap: 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    min-height: 40px;
    align-items: center;
    position: relative;
    border-radius: 8px;
    padding: 4px 24px 4px 12px;
    border: 1px solid $color-grey-17;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
    &::before {
      position: absolute;
      content: 'Select';
      top: 50%;
      color: #bfbfbf;
      transform: translateY(-50%);
    }

    &:has(.ant-tag.selected-content-tag)::before {
      display: none;
    }

    .drop-down-arrow {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }

    .ant-tag.selected-content-tag {
      display: flex;
      align-items: center;
      padding: 4px 6px;
      border-style: dotted;
      border-radius: 12px;

      .category {
        font-size: 14px;
        font-weight: 500;
        color: $color-grey-600;
        text-transform: capitalize;
      }

      .category-values {
        margin-left: 24px;
        font-weight: 500;
        position: relative;
        color: $color-primary-1;
        text-wrap: wrap;
        &::before {
          content: '';
          position: absolute;
          left: -12px;
          top: 50%;
          transform: translateY(-50%);
          height: 75%;
          width: 2px;
          background: $color-grey-17;
        }
      }
    }
  }

  &__select.ant-select {
    .ant-select-selector {
      border-radius: 8px;
      background: $color-white;
      border: 1px solid $color-grey-17;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
  }

  .ant-tabs.ant-tabs-top {
    border-radius: 12px;
    background-color: $color-white;
    box-shadow: 0px 3px 8px 0px #00000026;

    .ant-tabs-content-holder {
      margin-bottom: -10px !important;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 12px;

        .ant-tabs-tab-btn {
          font-weight: 400;
          color: $color-grey-22;

          .custom-tab-label {
            gap: 8px;
            display: flex;
            align-items: center;

            &__counter {
              height: 20px;
              font-size: 12px;
              min-width: 20px;
              border-radius: 50%;
              align-items: center;
              color: $color-white;
              display: inline-flex;
              justify-content: center;
              background: $color-grey-600;
            }
          }
        }
      }

      .ant-tabs-tab-active {
        width: 100%;

        .ant-tabs-tab-btn {
          font-weight: 500;
          color: $color-grey-600;

          .custom-tab-label {
            gap: 8px;
            display: flex;
            align-items: center;

            &__counter {
              height: 20px;
              font-size: 12px;
              min-width: 20px;
              border-radius: 50%;
              align-items: center;
              color: $color-white;
              display: inline-flex;
              justify-content: center;
              background: $color-grey-600;
            }
          }
        }
      }

      .ant-tabs-ink-bar {
        background-color: $color-grey-600;
      }
    }
  }
}
