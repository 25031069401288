@import 'styles/colors.scss';

.vlsc_spinner_wrapper {
  flex-grow: 1;

  .vlsc_spinner {
    max-height: 100% !important;
  }
}

.vectors_list_section_container {
  border-right: 1px solid $color-border-grey;

  .vlsc_header_container {
    border-bottom: 1px solid $color-border-grey-2;

    .vlsc_hc_search_container {
      padding: 16px 24px;

      .vlsc_hc_search {
        max-width: 563px;
        width: 100%;
        padding: 8px 16px;
        gap: 8px;
        background-color: $color-grey-20;
      }
    }

    .vlsc_hc_bottom_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $color-border-grey-2;
      padding: 16px 24px;
      gap: 16px;

      .vlsc_hc_bc_buttons {
        display: flex;
        align-items: center;

        .vlsc_hc_bc_button {
          border: 1px solid $color-grey-17;
          height: 36px;
          color: $color-dark-grey;
          padding: 8px 16px;
          background-color: $color-grey-20;

          &.vlsc_hc_bc_button_active {
            border-color: $color-primary-10;
            background-color: $color-primary-3;
            color: $color-primary-11;
          }

          &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          // &:nth-child(2) {
          //   border-left: none;
          //   border-right: none;
          //   border-radius: 0;
          // }
          &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
          }

          &:disabled {
            opacity: 0.5;
          }
        }
      }

      &.vlsc_hc_bottom_action_container {
        .vlsc_hc_bc_buttons {
          .vlsc_hc_bc_button {
            border: 1px solid $color-primary-10;
            height: 36px;
            color: $color-dark-grey;
            padding: 8px 16px;
            background-color: $color-primary-3;
            border-radius: 8px;
            font-size: 12px;

            &:first-child {
              border-color: $color-prime;
              background-color: $color-prime;
              color: $color-white;
              font-weight: 600;
              margin-right: 8px;

              svg {
                path {
                  stroke: $color-white;
                }
              }
            }

            &:nth-child(2) {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: none;
            }

            &:nth-child(3) {
              border-radius: 0;
            }

            &:nth-child(4) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-left: none;
            }

            &:nth-child(5) {
              border: none;
              background: none;
              color: $color-primary-11;
              padding: 8px;
              margin-inline: 18px;
              font-size: 14px;
            }

            &:last-child {
              border: none;
              background: none;
              color: $color-primary-11;
              padding: 8px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .vlsc_list_container {
    height: calc(100vh - 269px);
    overflow-y: auto;
  }

  .vlsc_pagination_container {
    // background-color: gold;
    padding-bottom: 16px;
    margin-top: 16px;

    .vlsc_pagination {
      margin: 0 !important;

      .ant-pagination-item,
      .ant-pagination-jump-next-custom-icon,
      .ant-pagination-jump-prev-custom-icon {
        height: 40px;
        width: 40px;
        border-color: $color-grey-17;

        .pagination__container {
          padding: 0;
        }

        .pagination__container {
          .pagination__label {
            font-family: Inter;
            font-weight: 500;
            color: $color-grey-800;
          }
        }

        &.ant-pagination-item-active {
          border-color: $color-prime;

          .pagination__container {
            .pagination__label {
              color: $color-white;
            }
          }
        }
      }

      .ant-pagination-next,
      .ant-pagination-prev {
        height: 40px;
        border-color: $color-grey-17;

        .pagination__container {
          gap: 8px;
          padding: 10px 16px;

          .pagination__label {
            display: none;
            font-family: Inter;
            font-weight: 500;
            color: $color-grey-800;
          }

          svg {
            path {
              stroke: $color-grey-19;
            }
          }
        }
      }

      :last-child {
        margin: 0;
      }
    }
  }
}
