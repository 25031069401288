@import 'styles/colors.scss';

.elw_containerClassName {
  .elw_box_container {
    min-height: 242px;
    width: 544px;
    padding: 24px;
    border-radius: 8px;

    .cmc_cc_header_container {
      .cmc_cc_hc_left_container {
        gap: 24px;

        .cmc_cc_hc_lc_heading {
          // font-family: Inter;
        }
      }
    }

    .cmc_cc_hc_close_icon {
      display: none;
    }

    .cmc_cc_footer_container {
      .cmc_cc_fc_button {
        font-size: 16px;
      }
    }
  }
}

.elw_body_container {
  display: flex;

  .elw_modal_body_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 8px 0 24px;
    color: $color-dark-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 72px;

    .elw_follow_text {
      margin-bottom: 8px;
    }

    .elw_mbc_clipboard_container {
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $color-border-grey;
      background-color: $color-background;

      .elw_mbc_cc_input {
        background: none;
        border: none;
        outline: none;
        flex-grow: 1;
        padding: 10px 12px;
        color: $color-grey-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .elw_mbc_cc_button {
        background: none;
        flex-shrink: 0;
        color: $color-grey-text;
        border-radius: 0;
        border-left: 1px solid $color-border-grey;
        padding: 10px 17px;

        svg {
          flex-shrink: 0;

          path {
            stroke: $color-grey-text;
          }
        }

        &.elw_mbc_cc_button_copied {
          padding: 10px 12px;
          gap: 4px;

          svg {
            path {
              stroke: none;
            }
          }
        }
      }
    }

    .elw_link_text {
      flex-grow: 1;
      margin-top: 20px;
      margin-bottom: 0;

      .elw_link {
        color: $link-color;
      }
    }
  }
}
