@import 'styles/colors.scss';
@import 'styles/titles.scss';

.ReportModalWrapper {
  :global {
    .ant-modal-content {
      background-color: $color-background;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      padding: 32px;
      position: relative;
      min-height: 700px;
      height: 675px;
      width: 529px;

      .ant-modal-close {
        top: 38px;
        right: 34px;

        .ant-modal-close-x {
          height: 12px;
          width: 12px;
          line-height: 12px;

          svg {
            height: 12px;
            width: 12px;

            path {
              stroke-width: 2px;
              stroke: $color-grey-scale-600;
            }
          }
        }
      }
    }

    .ant-modal-header,
    .ant-modal-footer {
      padding: 0;
    }

    .ant-spin-nested-loading {
      .ant-spin {
        max-height: 100%;
      }
    }
  }

  .formModalBody {
    width: 465px;

    .bodyHeader {
      margin-top: 24px;
      background-color: $color-background;

      .description {
        color: $color-grey-text;
        margin-bottom: unset;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .movable {
      background-color: $color-background;
      height: 510px;
      overflow: auto;

      .reportListForm {
        background-color: $color-background;
        margin-top: 16px;

        .floatingInputWithStaticLabel {
          &.mt16 {
            margin-top: 16px;
          }

          padding: 10px 16px;
          display: flex;
          gap: 2px;
          flex-direction: column;
          justify-content: center;
          background-color: $color-background;
          border: 1px solid $color-border-grey;
          border-radius: 4px;

          height: 61px;
          width: 465px;

          .briefNameInputLabel {
            color: $color-grey-text;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            display: flex;
            align-items: center;
            gap: 9px;
            width: 100%;
            line-height: 13px;
          }

          .briefNameinput {
            background-color: $color-background;
            border: 0;
            padding: 0;
            min-height: unset;

            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $color-black;

            height: 20px;
            width: 100%;
          }
        }

        .section {
          background-color: $color-background;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 12px;
          gap: 8px;

          &.sectionNotES {
            border: 1px solid $color-border-grey;
            border-radius: 4px;
            gap: 8px;
          }

          .sectionButton {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            display: flex;
            align-items: flex-start;
            background-color: $color-background;
            border: none;
            outline: none;
            color: black;
            color: $color-black;
            line-height: 20px;
            padding: 5px 0px;

            &.sectionButtonDisabled {
              color: $color-grey-text;
            }
          }

          .sectionButtonIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            margin-right: 11px;
            border-radius: 50%;
            background-color: $section-background;
          }

          .sectionButtonDelete {
            background-color: $color-red;
          }
        }
      }
    }
  }
}
