@import 'styles/colors.scss';
.gts_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-grey-1;
  gap: 20px;
  height: 100%;
  padding: 40px 80px;
  .gts_c_icon {
    g {
      path {
        fill: $color-dark-text;
        stroke: $color-dark-text;
      }
    }
  }
  .gts_c_desc {
    font-size: 14px;
    line-height: 20px;
    color: $color-dark-text;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
  }
  .gts_c_message {
    font-weight: 500;
    color: $color-dark-text;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0;
  }
}
