@import 'styles/colors.scss';
@import 'styles/titles.scss';

.executiveSummary {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .heading {
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: $color-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      user-select: none;

      display: flex;
      align-items: center;
      gap: 4px;
    }

    .feedbackIcons {
      display: flex;
      align-items: center;
      gap: 4px;

      .icon {
        width: 16px;
        height: 16px;
        cursor: pointer;

        &.iconDisabled {
          cursor: not-allowed;

          path {
            stroke: #bcc1cc;
          }
        }

        &.iconFilledDisabled {
          cursor: not-allowed;

          path {
            fill: #bcc1cc;
          }
        }
      }
    }

    .headingActions {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .loadingScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;
    align-self: stretch;

    padding-top: 10px;
    padding-bottom: 41px;

    border-radius: 8px;
    background: $color-primary-3;

    .waiting {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;

      .gifWrapper {
        width: 80px;
        height: 40px;

        img {
          height: 100%;
          text-align: center;
        }
      }

      .message {
        color: $color-black;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    .message {
      text-align: center;
      color: $color-black;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .errorScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    .message {
      @include title;
      color: $color-dark-text;
      margin-top: 8px;
    }

    .description {
      @include description;
      color: $color-dark-text;
      margin-top: 6px;
    }

    .retry {
      @include button-text;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      height: 34px;
      padding: 10px 16px;
      border-radius: 4px;
      background: $color-black;
      color: $color-white;
      cursor: pointer;
      margin-top: 16px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .summaryText {
    color: $color-dark-grey;
    font-size: 14px;
    line-height: 20px;

    ol > li {
      &:not(:first-child) {
        margin-top: 28px;
      }
    }

    .readMoreBtn {
      color: $color-black;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .copyBtn {
    height: 40px;
    background-color: $color-unselected;
    border-radius: 6px;
    padding: 10px 16px;
    color: $color-grey-900;

    svg {
      margin-right: 0px;

      path {
        stroke: $color-grey-900;
      }
    }
  }

  .noDataContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    background: $color-background-tan;

    .text {
      @include description;
      color: $color-dark-grey;
    }
  }
}
