@import 'styles/colors.scss';

.ant-pagination.custom-pagination {
  li {
    margin: 0px;
    border-radius: 0px;
    &:has(a) {
      border: 1px solid $color-border-grey;
    }
    button {
      border: 1px solid $color-border-grey;
    }

    &:hover a {
      &:hover {
        color: $color-grey-600;
      }
    }
  }

  li.ant-pagination-item-active {
    background-color: $color-grey-16;
    a {
      color: $color-grey-600;
      &:hover {
        color: $color-grey-600;
      }
    }
  }

  li:not(:first-child):has(a) {
    border-right: none;
  }

  .ant-pagination-prev button {
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    &:hover {
      border-color: $color-border-grey;
      svg {
        fill: $color-grey-600;
      }
    }
  }
  .ant-pagination-next button {
    border-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &:hover {
      border-color: $color-border-grey;
      svg {
        fill: $color-grey-600;
      }
    }
  }
}
