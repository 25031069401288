@import 'styles/colors.scss';
@import 'styles/titles.scss';

.review-content {
  .ant-tag.selected-content-tag {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border-style: dotted;
    border-radius: 12px;

    .category {
      font-size: 14px;
      font-weight: 500;
      color: $color-grey-600;
      text-transform: capitalize;
    }

    .category-values {
      margin-left: 24px;
      font-weight: 500;
      position: relative;
      color: $color-primary-1;
      text-wrap: wrap;
      &::before {
        content: '';
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        height: 75%;
        width: 2px;
        background: $color-grey-17;
      }
    }
  }

  &__display {
    width: 100%;
    background-color: $color-grey-21;
    border: 1px solid $color-border-grey-2;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;

    span {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $color-grey-600;
    }

    &__header {
      display: flex;
      flex-direction: row;
      height: 60px;
      align-items: center;
      justify-content: space-between;

      &__action {
        display: flex;
        align-items: center;
        gap: 16px;
        &__open {
          transform: rotate(180deg);
        }
      }

      &__title {
        box-sizing: border-box;
        height: 56px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: $color-black;
        }
      }
    }
  }

  &__summary {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    padding-top: 8px;
    padding-left: 16px;
    &__link {
      width: 32px;
      height: 35px;
      margin-bottom: 28px;
      border-left: 2px dashed $color-grey-17;
      border-bottom: 2px dashed $color-grey-17;
      border-bottom-left-radius: 12px;
    }
    &__text {
      padding: 16px;
      border-radius: 8px;
      background-color: $color-border-grey-2;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: $color-grey-600;
    }
  }
  &__filters-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__filters {
    padding: 16px;
    border-radius: 8px;
    background-color: $color-grey-14;
    &__title {
      margin-bottom: 4px;
    }
  }
}

.edit-form-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        color: $color-violet-700;
      }
    }
  }
  .review-form-actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: flex-end;
    flex-grow: 1;

    .continue-btn {
      background-color: $color-black;
      color: white;
      min-width: 128px;
      display: flex;
      justify-content: center;
    }
    .cancel-btn {
      background-color: $color-unselected;
      color: $color-dark-text;
      min-width: 128px;
      display: flex;
      justify-content: center;
    }
  }
}

.content-filters-list-item {
  @include flex-body;
  gap: 8px;
  border-bottom: 1px solid $color-border-grey-2;
  padding-bottom: 16px;

  &__container {
    @include flex-body-centered;
    width: max-content;
    max-width: 100%;
    padding: 4px 8px;
    gap: 4px;
    border-radius: 6px;
    cursor: pointer;
    border-radius: 16px;
    border: 1px dashed $color-grey-17;
  }

  &__info {
    @include flex-body;
    gap: 4px;

    &__title {
      @include info;
      color: $color-dark-grey;
      text-transform: capitalize;
    }
  }
  &__tags {
    @include flex-body;
    @include info;
    border-left: 1px solid $color-grey-17;
    padding-left: 4px;
    gap: 4px;
    color: $color-prime;
  }
}
