@import 'styles/colors.scss';
@import 'styles/titles.scss';

.tenant-details {
  @include flex(column, stretch, flex-start);
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 24px;

  &__navigation {
    padding-left: 0;
    margin: 0;
  }

  &__header {
    @include flex-body;
    gap: 20px;

    &__back {
      @include flex-body;
      gap: 8px;
      cursor: pointer;

      &_label {
        @include dp-selected;
        color: $color-black;
      }
    }

    &__info {
      @include flex-body;
      gap: 10px;
    }

    &__logo-div {
      @include flex-body;
      gap: 12px;
    }

    &__logo {
      @include flex-body-centered;
      border: 1px solid $color-grey-scale-300;
      width: 35px;
      height: 35px;
      border-radius: 50%;

      img {
        width: 100%;
        height: auto;
        max-height: 35px;
        border-radius: 50%;
      }
    }

    &__name {
      color: $color-dark-text;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    &__industry {
      @include description;
      color: $color-dark-grey;
    }

    &__domain {
      @include description;
      color: $hyper-link-color;
    }
  }

  &__content {
    &__header {
      @include flex(row, center, space-between);

      &__title {
        @include description;
        color: $color-grey-text;
        padding: 5px 0;
        flex: 1;
      }

      &__right {
        @include flex(row, center, flex-end);
        gap: 16px;
        flex: 2;

        &__view-div {
          @include flex-body;
          gap: 8px;

          &__tooltip {
            .ant-tooltip-inner {
              @include flex-body-centered;
              @include footnote;
              color: $color-white;
              box-shadow: none;
              background: $color-black;
              border-radius: 4px;
              padding: 8px;
              filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
            }

            .ant-tooltip-arrow {
              display: block;
            }

            .ant-tooltip-arrow-content {
              background-color: $color-black;
            }
          }

          &__card-icon {
            cursor: pointer;
            margin: 4px;
            width: 34px;
            height: 34px;
            padding: 6px;

            &--active {
              background-color: $color-prime;
              border-radius: 4px;

              path {
                fill: $color-white;
              }

              &:hover {
                path {
                  fill: $color-white !important;
                }
              }
            }

            &:hover {
              path {
                fill: $color-dark-grey;
              }
            }
          }

          &__list-icon {
            cursor: pointer;
            margin: 4px;
            width: 34px;
            height: 34px;
            padding: 6px;

            &--active {
              background-color: $color-prime;
              border-radius: 4px;

              path {
                stroke: $color-white;
              }

              &:hover {
                path {
                  stroke: $color-white !important;
                }
              }
            }

            &:hover {
              path {
                stroke: $color-dark-grey;
              }
            }
          }
        }

        &__add-btn {
          @include subtitle;
          border-radius: 4px;
          background: $color-black;
          color: $color-white;
          border: none;
          padding: 9px 16px;
          width: max-content;
          cursor: pointer;
        }

        .ant-input {
          min-height: initial;
          width: initial;

          &:hover {
            border: none;
          }
        }

        .ant-input-affix-wrapper {
          border-radius: 4px;
          border: 1px solid $color-border-grey;
          box-shadow: none;
          width: 320px;
          padding: 7px 16px 7px 12px;
          gap: 4px;

          &:hover,
          &:focus {
            border: 1px solid $color-border-grey;
            box-shadow: none;
          }

          svg {
            &:hover {
              box-shadow: none;
            }
          }
        }

        .ant-input:placeholder-shown {
          width: 100%;
        }
      }
    }
  }

  .ant-table-thead > tr > th {
    @include subtitle;
    padding: 8px 24px;
    background: $color-grey-5;
    color: $color-grey-text;
    text-transform: uppercase;
    height: 48px;
    border-bottom: none;

    &::before {
      height: 0 !important;
    }
  }

  .ant-table-tbody > tr > td {
    @include description;
    padding: 16px 24px;
    color: $color-dark-grey;
    border-bottom: none;
    height: 66px;
    border-bottom: 1px solid $color-border-grey;
  }

  .ant-table-row:hover .tenant-users__actions {
    display: flex;
  }
}
