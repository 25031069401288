@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

.new-mention-view-card {
  gap: 16px;
  margin-top: 16px;
  min-width: 221px;
  @include flexbox($justify: space-between);

  &__title {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-style: normal;
    font-family: Roboto;
    color: $color-grey-900;
    @include flexbox($justify: space-between);
  }
  &__status {
    display: flex;
    gap: 16px;
    flex-direction: column;
    &-stats {
      @include flexbox($gap: 16px);

      &__icon {
        padding: 16px;
        border-radius: 16px;
        @include flexbox($gap: 10px, $direction: column, $align: flex-start);

        &.impressions {
          background-color: #fce7f6;
        }
        &.engagement {
          background-color: #ebe9fe;
        }
        &.impression {
          background-color: $warning-50-color;
        }
      }

      &__count {
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
        color: $color-dark-text;
        letter-spacing: -0.02em;
      }
    }
    &__trend {
      display: flex;
      align-items: center;
      gap: 8px;
      &__detail {
        display: flex;
        align-items: center;
        gap: 2px;
        svg {
          transform: rotate(180deg);
          width: 20px;
          height: 20px;
          margin-left: -4px;
          path {
            stroke: $color-orange;
          }
        }
        &__percentage {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: $color-orange;
        }
      }
      .trending {
        svg {
          transform: rotate(0deg);
          path {
            stroke: #17b26a;
          }
        }
        .new-mention-view-card__status__trend__detail__percentage {
          color: #17b26a;
          @include subtitle;
          font-family: Roboto;
        }
      }
      &__duration {
        @include body;
        color: #667085;
      }
    }
  }
  &__empty-state {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
  }
  .skeleton-paragraph {
    &__small {
      width: 50px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }
    &__large {
      width: 100px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }
  }

  .chart-wrapper {
    width: 150px;
    height: 75px;
  }
}

.all_posts_container {
  display: flex;
  align-items: center;
  .apc_count {
    font-size: 16px;
    line-height: 20px;
  }
  .apc_trending_count {
    font-size: 12px;
    line-height: 20px;
    color: $color-orange;
  }
  .apc_low_icon {
    path {
      stroke: $color-indigo-700;
    }
  }
  &.all_posts_container_down {
    .apc_arrow_icon {
      transform: rotate(180deg);
      path {
        stroke: $color-indigo-700;
      }
    }
    .apc_trending_count {
      color: $color-indigo-700;
    }
  }
}
