@import 'styles/variables.scss';
@import 'styles/colors.scss';

.filtersSection {
  width: 100%;
  display: flex;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  padding: 8px 40px;

  .searchInputContainer {
    display: flex;
    flex-basis: 30%;
    align-items: center;
    position: relative;

    .closeIconSearch {
      right: 10px;
      z-index: 9999;
      position: absolute;
      cursor: pointer;
    }

    .searchInput {
      background: $color-grey-20;
      box-shadow: none;
      border-radius: 4px;
      font-size: 16px;
      height: 40px;
      border-color: $color-pastel-blue !important;

      &:hover,
      &:focus {
        border-color: $color-pastel-blue !important;
        box-shadow: none !important;
      }

      :global {
        .ant-input {
          min-height: 24px;
          font-weight: 400;
          line-height: 20px;
          background-color: $color-grey-20;
        }

        .ant-input-prefix {
          margin-right: 8px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
