@import 'styles/colors.scss';
@import 'styles/titles.scss';

.brands-assets {
  &__container {
    @include flex-body-column;
    gap: 32px;
  }

  &__header-div {
    @include flex-body-column;
    gap: 14px;
    padding-bottom: 24px;
    border-bottom: 1px solid $color-border-grey-2;

    &__title {
      @include title;
      line-height: 20px;
      color: $color-black;
    }

    &__description {
      @include description;
      color: $color-dark-grey;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
    grid-gap: 20px;
  }

  &__graph-container {
    @include flex-body-column;
    padding: 16px 24px;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid $color-border-grey-2;
    background: $color-white;
    overflow: auto;
    align-items: center;
    justify-content: center
  }

  &__graph {
    &__title {
      @include subtitle-16;
      color: #000;
      align-self: flex-start;
    }
  }
}
