@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

// form
//---------------------------------------------------------------------------------------
.ant-input {
  border: 1px solid $color-gray-1;
  border-radius: 4px;
}

.ant-form-item-label > label {
  color: $color-dark-1;
}

.ant-form-item-control {
  margin-bottom: 12px;
}

.ant-input-password,
.ant-form-item-control-input-content {
  padding-right: 11px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 56px;
}

.ant-input {
  min-height: 56px;
}

.ant-alert-error {
  margin-bottom: 24px;
}

.ant-form-item-label {
  padding-bottom: 8px !important;
}

.ant-form-item-label > label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

// spin
//---------------------------------------------------------------------------------------
.ant-spin-dot-item {
  background-color: $color-primary;
}

.ant-spin-blur {
  overflow: initial;
}

// divider
//---------------------------------------------------------------------------------------
.ant-divider-horizontal {
  border: 1px solid $color-gray-1;
}

// buttons
//---------------------------------------------------------------------------------------
.c-button--1.ant-btn,
.c-button--2.ant-btn,
.c-button--3.ant-btn {
  background: transparent;
  color: $color-dark-1;
  height: 50px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  display: inline-block;
}

.c-button--1.ant-btn {
  height: 50px;
  border: 2px solid $color-primary;
}

.c-button--2.ant-btn {
  height: 44px;
  border: 2px solid $color-primary;
}

.c-button--3.ant-btn {
  border: 2px solid $color-dark-1;
  background-color: $color-dark-1;
  height: 44px;
  color: $color-white;
}

// alerts
//---------------------------------------------------------------------------------------
.ant-alert {
  margin-bottom: 16px;
}

// selects
//---------------------------------------------------------------------------------------
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $color-blue-1;
  font-weight: 400;
}

// skeleton, used at the moment only for bubble chart
// needs updates if I want to use in multiple places
//---------------------------------------------------------------------------------------

.ant-skeleton-title {
  width: 100% !important;
  display: none;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100% !important;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  height: 230px;
}

.ant-skeleton-paragraph {
  margin: 0 !important;
}

// tooltip
//---------------------------------------------------------------------------------------

// .ant-tooltip-inner {
//   color: $color-dark-2;
//   box-shadow: none;
//   padding: 0;
//   background: $color-white;
//   border-radius: 8px;
//   font-size: 14px;
//   line-height: 1.5;
//   padding: 16px;
//   filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
// }

// .ant-tooltip-arrow {
//   display: none;
// }

// .pendulum-themed-tooltip {
//   max-width: unset;

//   .ant-tooltip-inner {
//     color: $color-white;
//     background: $color-black;

//     font-weight: 400;
//     font-size: 12px;
//     line-height: 16px;

//     padding: 8px;
//     border-radius: 8px;
//     box-shadow: none;
//     filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
//   }

//   .ant-tooltip-arrow {
//     display: initial;

//     .ant-tooltip-arrow-content::before {
//       background-color: $color-black;
//     }
//   }
// }

.ant-tooltip-arrow {
  display: block;

  .ant-tooltip-arrow-content {
    background-color: $color-black2;
  }
}

.ant-tooltip-inner {
  display: flex;
  padding: 8px 10px;
  gap: 8px;
  border-radius: 4px;
  background: $color-black2;
  color: $color-white;
  @include info;
}

// dropdown
//-------------------------------------------------------------------------

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  border-radius: 5px;
}

// modal
//---------------------------------------------------------------------------------------

.ant-modal-content {
  border-radius: 8px;
  box-shadow: none;
  padding: 70px 40px 40px 40px;
}

.ant-modal-header {
  border: 0;
  padding-top: 0;
  padding-bottom: 40px;
}

.ant-modal-title {
  font-size: 28px;
  text-align: center;
  font-weight: 300;
}

.ant-modal-body {
  padding: 0;
  font-size: 16px;
}

.ant-modal-footer {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.ant-modal-footer .ant-btn {
  border: 2px solid $color-primary-2;
  border-radius: 28px;
  background-color: transparent;
  color: $color-dark-1;
  font-size: 16px;
  height: 44px;
  padding-right: 25px;
  padding-left: 25px;
}

.ant-modal-footer .ant-btn-primary {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 2px solid #1d1c1a;
  border-radius: 100px;
  font-weight: 600;
  background: #1d1c1a;
  color: #fff;

  &:focus {
    background: #1d1c1a;
    border-color: #1d1c1a;
    color: #fff;
  }

  &:hover {
    background: #cca55a;
    border-color: #cca55a;
    color: #1d1c1a;
  }

  &:active {
    background: #b48938;
    border-color: #b48938;
    color: #1d1c1a;
  }
}

.ant-modal-footer .ant-btn-default {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 2px solid #cca55a;
  border-radius: 100px;
  font-weight: 600;
  background: #fff;
  color: #1d1c1a;

  &:focus {
    background: #fff;
    border-color: #cca55a;
    color: #1d1c1a;
  }

  &:hover {
    background: #f5edde;
    border-color: #cca55a;
    color: #1d1c1a;
  }

  &:active {
    background: #e0c99c;
    border-color: #cca55a;
    color: #1d1c1a;
  }
}

.ant-modal-footer .ant-btn-default.ant-btn-dangerous {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 2px solid #ec5b56;
  border-radius: 100px;
  font-weight: 600;
  background: #ec5b56;
  color: #ffffff;

  &:focus {
    background: #ec5b56 !important;
    border-color: #ec5b56 !important;
    color: #ffffff !important;
  }

  &:hover {
    background: #ff7269 !important;
    border-color: #ff7269 !important;
    color: #fafafa !important;
  }

  &:active {
    background: #ec5b56 !important;
    border-color: #ec5b56 !important;
    color: #fafafa !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #cca55a;
  border-radius: 2px;

  &:hover {
    background: #cca55a;
    opacity: 0.25;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #cca55a;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #cca55a;
  opacity: 0.25;
  .ant-input:hover {
    border-color: #cca55a;
    border-right-width: 1px;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #cca55a;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }
}
