@import 'styles/titles.scss';
@import 'styles/colors.scss';

.welcome-screen {
  // overflow: scroll;

  &__centered {
    // @include flex(column, center, center);
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  &__heading {
    @include flex-body;
    margin-top: 130px;

    &__title {
      @include main-title;
      white-space: pre-wrap;
      margin-bottom: 10px;
    }

    &--black {
      display: flex;
      @extend .welcome-screen__heading__title;
      color: $color-black;
    }

    &--prime {
      @extend .welcome-screen__heading__title;
      color: $color-prime;
    }

    &__for-text {
      @include subtitle-16;
      color: $color-grey-scale-600;
      margin-bottom: 10px;
    }
  }

  &__description {
    @include flex(column, center, center);
    @include title-300;
    color: $color-black;
    margin: 32px auto 0px auto;
  }

  &__img-content {
    @include flex-body;
    justify-content: space-around;
  }

  &__logo {
    max-width: 150px;
    max-height: 150px;
    object-fit: scale-down;
  }

  &__background {
    background-image: url('../../../assets/images/riskwand-background-image.jpg');
  }

  &__blind-text-div {
    @include flex(column, center, center);
  }

  &__blind-text {
    @include large-title;
    color: $color-black;
    margin-top: 24px;

    &__description {
      @include flex(column, center, center);
      @include title-300;
      color: $color-black;
      margin-top: 16px;
    }
  }

  &__social-icons-div-and-images {
    @include flex-body-centered;
    margin-top: 24px;
    gap: 48px;
  }

  &__social-icons-div {
    @include flex(row, flex-start, flex-start);
    flex: 1;
    flex-wrap: wrap;
    gap: 24px 40px;
    margin-left: 120px;

    max-width: 300px;

    &__container {
      @include flex(column, center, center);

      svg {
        width: 72px;
        height: 72px;
      }

      &__title {
        @include medium;
        text-align: center;
        color: $color-black;
        margin-top: 10px;
      }
    }
  }

  &__images {
    flex: 2;
    max-width: calc(100% - 420px);
    overflow: hidden;

    &__data-source-img {
      background: url('../../../assets/images/riskwand/welcome/no-blind-spots-bg.svg') repeat-x;
      height: 476px;
      width: 9620px;
      transform: translate3d(-1924px, 0, 0);
      animation: 30s linear infinite slide;
    }

    svg {
      animation: 60s linear infinite slide;
    }

    @keyframes slide {
      0% {
        transform: translate3d(-1924px, 0, 0);
      }

      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__automatedThreat {
    @include flex-body;
    background: rgba(255, 255, 255, 0.3);
    padding: 0px 80px;
    margin: 120px 0px;

    &__container {
      &__left {
        @include flex(column, center, center);

        &__title {
          font-weight: 700;
          font-size: 36px;
          line-height: 48px;
          color: $color-black;
          margin-bottom: 32px;
        }

        &__description {
          @include title-300;
          color: $color-grey-scale-900;
        }
      }

      &__right {
        @include flex-body;
      }
    }
  }

  &__follow-threats {
    @include flex(column, center, center);

    &__container {
      @include flex(row, center, center);
    }

    &__title {
      @include large-title;
      white-space: pre-wrap;
      margin-bottom: 12px;
    }

    &--black {
      display: flex;
      @extend .welcome-screen__follow-threats__title;
      color: $color-black;
    }

    &--red {
      @extend .welcome-screen__follow-threats__title;
      color: $color-pink;
    }
  }

  &__background3 {
    background: url('../../../assets/images/riskwand-background-image.jpg'),
      linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(229, 237, 249, 0.75) 100%);
  }

  &__launch {
    @include flex-body-centered;
    margin-top: 32px;

    &__btn {
      @include subtitle-16;
      background-color: $color-black;
      border: 1px solid $color-black;
      color: $color-white;
      padding: 16px 32px;
      border-radius: 6px;
      cursor: pointer;

      svg {
        margin-left: 12px;
      }
    }
  }

  &__floating-mouse-div {
    width: 100%;
    @include flex(column, center, center);
    @include description;
    border-radius: 64px;
    padding: 12px 16px;
    position: absolute;
    bottom: 16px;
    text-align: center;
    color: $color-black;
    user-select: none;
    z-index: 9998;

    &.hidden {
      bottom: -100% !important;
    }

    &__icon {
      animation: moveUp 2s alternate infinite;
    }

    &__text {
      @include description-16;
      color: $color-black;
      margin-top: 19px;
    }

    @keyframes moveUp {
      from {
        transform: translate3d(0, 30%, 0);
      }

      to {
        transform: none;
      }
    }
  }
}
