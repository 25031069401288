@import 'styles/variables.scss';

[data-popper-reference-hidden] {
  background-color: red;

  > div {
    background: $color-white !important;
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
}

.c-bubble-tip {
  display: flex;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);

  &__left {
    width: 250px;
    padding-right: 8px;
  }

  &__right {
    width: 60%;
    max-width: 380px;
    padding-left: 8px;
  }

  iframe {
    width: 100%;
    height: 150px;
  }

  &__title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  &__channel {
    display: flex;
    align-items: center;
  }

  &__channel-title {
    display: block;
    line-height: 1.2;
  }

  &__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
    overflow: hidden;
  }

  .c-video-frame {
    &__youtube {
      width: 100%;
      height: 100%;
    }
    &__bitchute {
      width: 100%;
      height: 100%;
    }
    &__rumble {
      display: flex;
      position: relative;
      width: 250px;
      height: 100%;
      background-color: #ddd;
      cursor: pointer;
      &-logo {
        display: block;
        max-width: 250px;
        width: auto;
        height: auto;
        margin: auto;

        &:hover {
          cursor: pointer;
        }
      }
      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        opacity: 0.9;
        cursor: pointer;
      }
    }

    &__podcast {
      display: flex;
      position: relative;
      width: 250px;
      height: 100%;
      background-color: #ddd;
      cursor: pointer;

      &-logo {
        display: block;
        max-width: 50px;
        width: auto;
        height: auto;
        margin: auto;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.node.deselected {
  opacity: 0.2 !important;
}

.arrow {
  width: 15px !important;
  height: 15px !important;

  &:before {
    background-color: $color-white !important;
    width: 15px !important;
    height: 15px !important;
  }
}
