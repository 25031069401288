@import 'styles/colors.scss';
@import 'styles/titles.scss';

.successModal {
  :global {
    .ant-modal-content {
      background: transparent;
    }
  }
}

.successModalBody {
  background-color: $color-background;
  display: flex;
  width: 387px;
  height: 180px;
  padding: 32px;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .gif {
    img {
      width: 40px;
      height: 40px;
    }
  }

  .message {
    color: $color-dark-text;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .subMessage {
    color: $color-grey-scale-600;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .deleteDone {
    margin-left: 0 !important;
    background-color: $color-black;
    color: $color-white;
    width: 320px;
    border-radius: 4px;
    border: 0;
  }
}
