@import 'styles/colors.scss';
@import 'styles/titles.scss';

.influencer-container {
  padding: 30px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $color-grey-20;
  height: calc(100vh - 60px);

  &__title {
    font-size: 40px;
    font-weight: 600;
    line-height: 65.35px;
    text-align: center;
    color: $color-dark-text;
  }

  &__input-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 700px;

    &__loader {
      gap: 8px;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 0px 12px;
      background-color: $color-white;
      border: 1px solid $color-border-grey;

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-style: normal;
        font-family: Roboto;
        color: $color-grey-19;
      }

      .loader {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $color-prime;
        border-left-color: transparent;
        animation: spin89345 1s linear infinite;
      }

      @keyframes spin89345 {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    .ant-form-item {
      margin: 0px;
      width: 100%;
      .ant-col.ant-form-item-control {
        margin: 0px;
        .ant-form-item-control-input-content {
          min-height: auto;
        }
      }
    }

    .ant-input-affix-wrapper {
      min-height: 50px;
      border-radius: 8px;
      border: 1px solid $color-grey-17;
      box-shadow: 0px 1px 2px 0px #1018280d;
      &:hover {
        border-color: $color-grey-17;
      }

      .ant-input {
        padding-left: 4px;
        min-height: auto;
        font-size: 16px;
        font-weight: 400;
        color: $color-dark-text;

        &::placeholder {
          color: $color-grey-22;
        }
      }
    }
  }

  &__input {
    height: 50px;
    min-width: 700px;
    border-radius: 12px;
  }

  &__card {
    margin-top: 60px;
    min-height: 350px;
    max-height: 454px;
    gap: 24px;
    display: flex;
    min-width: 352px;
    width: 100%;
    max-width: 600px;
    position: relative;
    padding: 24px;
    border-radius: 12px;
    flex-direction: column;
    background-color: $color-white;
    border: 1px solid $color-border-grey;
    box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.1490196078);

    .profile {
      gap: 20px;
      display: flex;
      align-items: center;

      &__image {
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__info {
        display: flex;
        flex: 1;
        flex-direction: column;

        &.singleCard {
          .channel-info__handle {
            span {
              display: inline-block;
              vertical-align: middle;
              overflow: hidden;
              max-width: 350px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        &__name {
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          color: $color-dark-text;
        }

        &__role {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #ffffff66;
        }
      }
    }

    .description {
      height: 96px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $color-dark-text;
      font-family: Inter Tight;
      overflow: hidden;
      max-width: 541px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      white-space: normal;
      text-overflow: ellipsis;
    }

    .channel-info {
      height: 80px;

      &__handle {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $color-grey-15;
        letter-spacing: -0.02em;
        font-family: Inter Tight;
        span {
          color: $color-dark-text;
          margin-left: 4px;
        }
      }
    }

    .similar-creators {
      gap: 10px;
      display: flex;
      flex-direction: column;

      &__title {
        font-size: 14px;
        font-weight: 400;
        color: $color-grey-15;
        line-height: 14.95px;
        letter-spacing: -0.02em;
      }

      &__platforms {
        gap: 6px;
        display: flex;
        align-items: center;
      }
    }
  }
}
