@import 'styles/colors.scss';
.grid_card_container {
  position: relative;
  padding: 22px 24px;
  border-radius: 8px;
  border: 1px solid $color-pastel-blue;
  background-color: $color-white;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.06);
  min-height: 316px;
  //   cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 15px 0px rgba(219, 175, 19, 0.2);
  }
  &.grid_card_container_trendline {
    &:hover {
      box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.06);
    }
  }
  .gcc_header {
    padding-bottom: 8px;
    border-bottom: 1px solid $color-border-grey;
    .gcc_h_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      .gcc_ht_text {
        color: $color-dark-text;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        width: 80%;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
      }
      .gcc_ht_notification_container {
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;
        margin-left: 38px;
        .gcc_ht_popup {
          right: 0;
          left: unset;
          top: calc(100% + 10px);
          z-index: 5;
        }
        .gcc_ht_icon:hover + .gcc_ht_popup {
          display: block;
        }
      }
    }
    .gcc_h_bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 10px 0;
      .gcc_hb_left_container {
        display: flex;
        align-items: center;
        gap: 16px;
        .gcc_hb_posts {
          display: flex;
          align-items: center;
          gap: 4px;

          .gcc_hb_p_text_container {
            display: flex;
            align-items: center;
            .gcc_hb_p_text {
              color: $color-dark-grey;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              margin-bottom: 0;
              text-align: center;
            }
            .gcc_hb_p_tc_fpc,
            .gcc_hb_p_tc_apc {
              gap: 4px;
              margin-left: 8px;
              .fpc_count,
              .apc_count {
                color: #14192e;
                font-weight: 500;
              }
              .fpc_trending_count,
              .apc_trending_count {
                font-weight: 500;
              }
            }
          }
        }
      }
      .gcc_hb_right_icon {
        cursor: pointer;
      }
    }
  }
  .elcc_f_rf_spinner_wrapper {
    flex: 1;
    .ant-spin-container {
      height: calc(100% - 15px);
    }
  }
  .gcc_footer {
    display: flex;
    // align-items: center;
    gap: 20px;
    margin-top: 15px;
    height: 100%;
    .elcc_f_risk_flag_container {
      padding: 0;
      .rfc_text {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }
      .rfc_flags_list {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 6px;
        margin-top: 6px;
        // width: 312px;
        // flex-shrink: 0;
        flex: 1;
        .rfc_fl_chunk {
          // cursor: pointer;
          padding: 6px;
          background-color: $color-primary-6;
          border-radius: 4px;
          width: calc(50% - 4px);

          &.rfc_fl_chunk_active {
            background-color: $color-prime;
            .rfc_flc_bottom {
              .rfc_flcb_text {
                color: $color-white;
                .rfc_flcb_t_part {
                  font-size: 12px;
                  color: $color-white;
                  .rfc_flcb_tp_icon {
                    path {
                      stroke: $color-white;
                    }
                  }
                }
              }
            }
            .rfc_flc_title {
              color: $color-white;
            }
          }

          &.rfc_fl_chunk_low_risk {
            background-color: $color-unselected;
            .rfc_flc_bottom {
              .rfc_flcb_text {
                .rfc_flcb_t_part {
                  font-size: 12px;
                  color: $color-indigo-700;
                  .rfc_flcb_tp_icon {
                    path {
                      stroke: $color-indigo-700;
                    }
                  }
                }
              }
            }
            .rfc_flc_title {
              color: $color-grey-text;
            }
          }

          &.rfc_fl_chunk_disabled {
            background-color: $color-unselected;
            .elcc_f_flc_bottom,
            .rfc_flc_bottom {
              .rfc_flcb_text {
                color: $color-grey-text;
              }
            }

            .rfc_flc_title {
              color: $color-grey-text;
            }
          }

          .rfc_flc_title {
            color: $color-dark-grey;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 4px;
          }

          .rfc_flc_bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .rfc_flcb_text {
              color: $color-dark-text;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 0;

              .rfc_flcb_t_part {
                font-size: 10px;
                color: $color-orange;
              }
            }
          }

          &.rfc_fl_chunk_more {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-unselected;
            align-self: stretch;

            .rfc_fl_cm_text {
              color: $color-dark-grey;
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .gcc_trendline_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    border-radius: 8px;
    background: $color-white;
    z-index: 1;

    .gcc_tc_spinner_wrapper {
      height: 100%;
      .gcc_tc_spinner {
        max-height: 100%;
      }
      .ant-spin-container {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
    .fcc_tc_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .gcc_tc_h_text {
        color: $color-dark-grey;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 0;
      }
      .gcc_tc_h_icon {
        cursor: pointer;
      }
    }
    .gcc_tc_title {
      width: 80%;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: $color-dark-text;
      margin-top: 8px;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .gcc_tc_area_chart_container {
      //   background-color: aquamarine;
      flex-grow: 1;
      width: 100%;
      // background-color: gold;
      padding-inline: 0;
      padding-bottom: 0;
    }
  }
}

.custom-tooltip {
  background: white;
}

.label {
  background: lightblue;
}
