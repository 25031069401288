@import 'styles/colors.scss';
@import 'styles/titles.scss';

.error-model-wrapper-container {
  z-index: 10000 !important;
}
.error-modal {
  &__container {
    @include flex(column, flex-start, flex-start);
    gap: 12px;
  }

  &__title {
    @include flex(row, flex-start, flex-start);
    gap: 16px;

    &__text {
      @include title-600;
      color: $color-dark-text;
    }
  }

  &__description {
    @include flex(column, flex-start, flex-start);
    gap: 12px;
    padding-left: 40px;

    &__text {
      @include description;
      color: $color-dark-text;
    }

    &--grey {
      @include description;
      color: $color-grey-text;
    }
  }

  &__footer {
    @include flex(row, flex-start, flex-end);
    gap: 8px;

    &__report {
      @include flex-body-centered;
      height: 36px;
      padding: 10px 16px;
      border-radius: 4px;
      background: $color-black !important;
      border: none;
      color: $color-white !important;
      cursor: pointer;

      &:hover {
        background: $color-black;
        border: none;
        color: $color-white;
      }
    }

    &__close {
      @include flex-body-centered;
      height: 36px;
      padding: 10px 16px;
      border-radius: 4px;
      background: $color-white;
      border: 1px solid $color-black;
      cursor: pointer;

      &:hover {
        background: $color-white;
        border: 1px solid $color-black;
        color: $color-black;
      }
    }
  }

  .ant-modal-content {
    padding: 24px !important;
  }

  .ant-modal-confirm-btns {
    @include flex(row, flex-start, flex-end);
    margin-top: 24px;
    padding: 0;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.error-notification {
  border-left: 3px solid #e72f21 !important;
  padding: 16px;
  margin-left: 100px;

  &__notification-margin {
    margin-left: 70% !important;
    margin-bottom: -4%;
  }

  &__container {
    @include flex(column, flex-start, flex-start);
    gap: 8px;
  }

  &__title {
    @include flex(row, flex-start, flex-start);
    gap: 16px;

    &__text {
      @include subtitle-600;
      color: $color-dark-text;
    }
  }

  &__description {
    @include flex(column, flex-start, flex-start);
    gap: 16px;

    &__text {
      @include description;
      color: $color-dark-text;
    }
  }

  &__copy-btn {
    @include subtitle;
    @include flex-body-centered;
    padding: 10px 12px;
    border-radius: 4px;
    background: $color-black;
    color: $color-white;
    border: none;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }
}
