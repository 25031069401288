@import 'styles/variables.scss';

.c-card {
  background-color: $color-white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: relative;

  &--0 {
    padding: 0;
  }

  &--1 {
    padding: 25px;
  }

  &--2 {
    padding: 40px;
  }

  &--3 {
    padding: 15px;
  }
}
