@import 'styles/colors.scss';
@import 'styles/titles.scss';

.secondary-navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 40px 0px 40px;
  background: $color-white;
  border-bottom: 1px solid $color-border-grey;

  &__container {
    max-width: 1550px;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .ant-menu {
    height: 100%;
  }

  .ant-menu-title-content a,
  .ant-menu-title-content {
    display: flex;
    align-items: center;
  }

  .ant-menu-title-content a {
    padding-right: 10px;
    padding-left: 10px;
  }

  .ant-menu-horizontal > .ant-menu-item a::before {
    display: none;
  }

  .ant-menu {
    width: 100%;
    position: relative;
  }

  .ant-menu-horizontal {
    background-color: $color-white;
    border: 0;
  }

  .ant-menu-horizontal > .ant-menu-item a {
    @include description;
    color: $color-grey-text;
  }

  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    a {
      @include tab-selected;
      color: $color-dark-text !important;
      border-bottom: 2px solid $color-dark-text;
      padding-bottom: 10px;
    }
  }

  .ant-menu-title-content {
    color: white !important;
    font-size: 14px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    display: none;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    &:first-child {
      padding: 8px 15px 0px 0px;
    }

    &:not(:first-child) {
      padding: 8px 15px 0px 15px;
    }
  }
}
