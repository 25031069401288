@import 'styles/colors.scss';

.dashboard {
  padding: 24px 36px;

  .dashboard-widgets {
    &__card {
      padding: 24px;
      border-radius: 12px;
      border: 1px solid #e2ebfa;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px $color-box-shadow;
      overflow: auto;

      &__sub-row {
        display: flex;
        gap: 0px 12px;

        .ant-col-12 {
          max-width: 100%;
          flex: 1;
        }
      }
    }

    .influencer-widgets {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .mention-view-widgets {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .min-height-350 {
      min-height: 350px;
    }
  }
}

.details-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
    linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);
  border-width: 1px, 1px, 0px, 1px;
  border-style: solid;
  border-color: #16191d;
  border-radius: 6px;
}

.chart-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
