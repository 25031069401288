@import 'styles/colors.scss';

.platformDistribution {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .heading {
    h4 {
      margin: 0;
      color: $color-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      user-select: none;

      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .platformsList {
    display: flex;
    gap: 80px;

    .listColumn {
      flex-grow: 1;
    }
  }
  .platformsListVertical {
    flex-direction: column;
    gap: 0px;
  }
}
