@import 'styles/colors.scss';

.flags_main_container_loader {
  max-height: none !important;
}

.flags_main_container {
  //   background-color: gold;
  .fmc_table_container {
    height: calc(100vh - 270px);
    overflow-y: auto;
    padding-inline: 40px;

    .fmc_table {
      thead {
        tr {
          th {
            span {
              color: $color-grey-600 !important;
              line-height: 18px !important;
              text-transform: none !important;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            .action_button_container {
              display: none;
            }
          }

          &:hover {
            td {
              .action_button_container {
                display: flex;
              }
            }
          }
        }
      }

      .fmc_th_checkbox_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 6px;
        border: 1px solid $color-grey-17;
        cursor: pointer;

        &.fmc_th_checkbox_container_selected {
          background-color: $color-prime;
          border-color: $color-prime;
        }

        .fmc_th_cc_line {
          height: 2px;
          width: 10px;
          border-radius: 10px;
          background-color: $color-white;
        }
      }

      .fmc_t_flag_name {
        color: $color-grey-900;
        font-weight: 500;
        line-height: 20px;
      }

      .fmc_t_impact_medium_icon {
        rect:nth-child(2) {
          fill: $color-orange;
        }
      }

      .fmc_t_impact_high_icon {
        rect {
          fill: $color-orange;
        }
      }

      .fmc_t_type_container {
        display: flex;
        align-items: flex-end;
        gap: 8px;

        .fmc_t_type_text {
          color: $color-grey-600;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .fmc_t_created_date {
        color: $color-grey-600;
        line-height: 20px;
      }

      .fmc_switch {
        background-color: $color-grey-18;
        height: 24px;

        .ant-switch-handle {
          width: 20px;
          height: 20px;

          &::before {
            border-radius: 10px;
          }
        }

        &.ant-switch-checked {
          background-color: $color-prime;

          &:hover {
            background-color: $color-primary-300_1;
          }

          .ant-switch-handle {
            left: calc(100% - 20px - 2px);
          }
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .fmc_pagination_container {
    // background-color: gold;
    padding-bottom: 16px;
    margin-top: 16px;

    .fmc_pagination {
      margin: 0 !important;

      .ant-pagination-item,
      .ant-pagination-jump-next-custom-icon,
      .ant-pagination-jump-prev-custom-icon {
        height: 40px;
        width: 40px;
        border-color: $color-grey-17;

        .pagination__container {
          padding: 0;
        }

        .pagination__container {
          .pagination__label {
            font-family: Inter;
            font-weight: 500;
            color: $color-grey-800;
          }
        }

        &.ant-pagination-item-active {
          border-color: $color-prime;

          .pagination__container {
            .pagination__label {
              color: $color-white;
            }
          }
        }
      }

      .ant-pagination-next,
      .ant-pagination-prev {
        height: 40px;
        border-color: $color-grey-17;

        .pagination__container {
          gap: 8px;
          padding: 10px 16px;

          .pagination__label {
            font-family: Inter;
            font-weight: 500;
            color: $color-grey-800;
          }

          svg {
            path {
              stroke: $color-grey-19;
            }
          }
        }
      }

      :last-child {
        margin: 0;
      }
    }
  }
}
