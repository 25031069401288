@import 'styles/colors.scss';
@import 'styles/titles.scss';

.recurrenc-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .recurrence-forms {
    padding-top: 14px;
    padding-left: 32px;
    display: flex;
    gap: 16px;
    .input {
      .label {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $color-grey-19;
        margin-bottom: 4px;
        background: unset;
      }
    }
  }

  .time-picker-trigger {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid $color-grey-17;
    border-radius: 8px;
    height: 40px;
  }
  .time-picker-trigger:not([disabled]):hover {
    color: rgba(0, 0, 0, 0.85) !important;
  }
  .time-picker-popover {
    .ant-popover-inner {
      border-radius: 8px;
      padding: 16px;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
  .weekDaySelector {
    width: 349px;

    .ant-select-selector {
      border-radius: 8px;
      .ant-select-selection-item {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.content-advance-filters {
  margin-left: 32px;
  display: flex;
  flex-direction: row;

  .linker {
    border-left: 2px dashed $color-grey-17;
  }

  .content-filters-linked {
    display: flex;
    align-items: center;
    .link {
      border-bottom: 2px dashed $color-grey-17;
      border-bottom-left-radius: 12px;
      width: 32px;
      height: 32px;
    }
    .content-select {
      position: relative;
      margin-top: 16px;
    }
  }
  //Chips filter

  .filters-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .content-filters-list-item {
      @include flex-body;
      gap: 8px;
      border-bottom: 1px solid $color-border-grey-2;
      padding-bottom: 16px;

      &__clearButton {
        @include info;
        color: $color-grey-22;
        cursor: pointer;
      }

      &__popover {
        .ant-popover-inner {
          border-radius: 8px;
        }

        .ant-popover-inner-content {
          width: 100%;
          max-height: 400px;
          overflow-y: auto;
          border-radius: 8px;
        }
      }

      &__container {
        @include flex-body-centered;
        width: max-content;
        padding: 4px 8px;
        gap: 4px;
        border-radius: 6px;
        cursor: pointer;
        border-radius: 16px;
        border: 1px dashed $color-grey-17;
      }

      &__info {
        @include flex-body;
        gap: 4px;

        &__title {
          @include info;
          color: $color-dark-grey;
          text-transform: capitalize;
        }
      }
      &__tags {
        @include flex-body;
        border-left: 1px solid $color-grey-17;
        padding-left: 4px;
        gap: 4px;

        span {
          @include info;
          color: $color-prime;
        }
      }
    }
  }
}
.content-select {
  width: 100%;
  padding: 16px 24px;
  background-color: $color-grey-20;
  border-radius: 12px;

  &__title-input {
    padding-bottom: 16px;
    &__error {
      color: $color-red;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__header {
    display: flex;
    gap: 8px;
    align-items: center;
    &__content {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0px;
        color: $color-grey-600;

        span {
          font-weight: 500;
          color: $color-grey-22;
          padding-right: 12px;
        }
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $color-dark-grey;
        margin-bottom: 16px;
      }
    }
  }
  .weekDaySelector {
    margin-top: 8px;
    .ant-select-selector {
      border-radius: 8px;
      .ant-select-selection-item {
        font-size: 16px;
        font-weight: 500;
        color: $color-grey-900;
      }
    }
  }
}

.advance-filter-btn {
  background-color: $color-grey-18;
  border-radius: 8px;
  color: $color-dark-text;
  margin-top: 16px;
  width: max-content;
}

.summarization-samples-list {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;

  .sample {
    padding: 24px;
    width: auto;
    border-radius: 12px;
    border: 1px solid $color-border-grey-2;
    box-shadow: 0px 0px 8px 0px $color-box-shadow;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
    .icon {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        border: 1px solid $color-border-grey-2;
        border-radius: 12px;
        padding: 2px 8px;
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $color-dark-grey;
      }
    }
    .title {
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-dark-grey;
    }
  }
}

.prompt-option {
  margin-top: 32px;
  height: 200px;
  border: 1px solid $color-grey-17;
  box-shadow: 0px 0px 8px 0px $color-box-shadow;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &__input {
    border: none !important;
    height: 100%;
    margin: 16px;
    resize: none;
    outline: none !important;
    overflow-y: auto;
  }
  .prompt-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid $color-grey-17;
    padding: 12px 8px;
    box-sizing: border-box;
    height: 64px;
    margin-top: auto;
    button {
      margin-top: 0px;
    }
  }
}

.review-form {
  background-color: $color-grey-20;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__spacing {
    padding: 24px 0px;
    margin-top: 32px;
  }

  &__step {
    background-color: white;
    border: 1px solid $color-border-grey-2;
    border-radius: 12px;
  }
  &__title {
    box-sizing: border-box;
    height: 56px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__action {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: $color-black;
    }
  }
  &__content {
    border-top: 1px solid $color-border-grey-2;
    padding: 16px;

    &__sections {
      border-top: 1px solid $color-border-grey-2;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.review-form-actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;

  .continue-btn {
    background-color: $color-black;
    color: white;
    min-width: 128px;
    display: flex;
    justify-content: center;
  }
  .cancel-btn {
    background-color: $color-unselected;
    color: $color-dark-text;
    width: 128px;
    display: flex;
    justify-content: center;
  }
}

.review-updated-section {
  .summary-section {
    border: 1px solid $color-border-grey-2;
    border-radius: 12px;
    padding: 24px;
    background-color: $color-grey-21;

    .dvsdc_section_desc_container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
      .sdc_chunk {
        position: relative;
        border: 1px solid $color-border-grey-2;
        border-radius: 12px;
        padding: 24px;
        background-color: white;

        .sdc_chunk_text {
          color: $color-grey-19;
          font-size: 16px;
          line-height: 28px;

          ol > li {
            &:not(:first-child) {
              margin-top: 28px;
            }
          }
          ol {
            margin-left: 24px;
          }
          ul {
            margin-left: 16px;
          }
        }
      }
    }
  }
  &__empty-state {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    gap: 16px;
    h5 {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: $color-grey-23;
      margin-bottom: 0px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-dark-grey;
      margin-bottom: 0px;
      width: 400px;
      text-align: center;
    }
  }
}

.review-updated-section .ant-tabs.ant-tabs-top {
  .ant-tabs-nav-list {
    width: max-content;
    .ant-tabs-tab {
      padding-left: 8px;
      padding-right: 8px;
      margin: 0;
      .ant-tabs-tab-btn {
        color: $color-dark-grey;
      }
    }

    .ant-tabs-tab-active {
      background: $color-primary-3;

      .ant-tabs-tab-btn {
        font-weight: 500;
        color: $color-primary-11;
      }
    }

    .ant-tabs-ink-bar {
      background-color: $color-primary-11;
    }
  }
}
