@import 'styles/variables.scss';
@import 'styles/colors.scss';

.c-bubble-chart-container {
  padding-bottom: 0px;
}

.c-bubble-chart {
  margin-top: 20px;

  &:has(.c-bubble-chart__title) {
    margin-top: 0px;

    .c-bubble-chart__heading {
      padding-bottom: 24px;
      border-bottom: 1px solid $color-border-grey-2;
    }
  }

  &__title {
    margin-bottom: 14px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }

  &__heading {
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      color: $color-secondary-black;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 20px;
      color: $color-primary-grey;
    }
  }

  &__platforms {
    display: flex;
    flex-wrap: wrap;
    gap: 6px 8px;
    margin-top: 20px;
  }

  &__bubbles {
    margin-top: 96px;
  }
}
