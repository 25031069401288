@import 'styles/colors.scss';
@import 'styles/titles.scss';

.toplevel-navigation {
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
  box-shadow: inset 0px -1px 0px #e2ebfa;

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__left {
      display: flex;
      align-items: center;
      gap: 4px;
      &__heading_container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        &--text {
          @include body;
          color: $color-grey-text;
          margin-bottom: 0;
          text-transform: capitalize;
        }
        &:first-child {
          .toplevel-navigation__logo-container__left__heading_container--text {
            @include subtitle-16;
            color: $color-dark-text;
          }
        }
      }
    }

    &__right {
      @include flex-body;

      &__container {
        @include flex-body;
        .toplevel-navigation__logo-container__right__container__vector_button {
          border: 1px solid $color-border-grey;
          background-color: $color-white;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          padding: 8px 14px;
          height: 36px;
          color: $color-dark-grey;
          white-space: nowrap;
          margin-right: 48px;
        }
        &__contact {
          @include flex-body;

          &__label {
            @include footnote;
            color: $color-grey-text;
            margin-right: 16px;
            cursor: pointer;
          }
        }

        &__tenant {
          margin-right: 16px;
          width: 100px;
          height: 32px;
          overflow: hidden;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      &__user {
        @include flex-body;
        gap: 12px;
        user-select: none;
        padding-left: 16px;
        border-left: 1px solid $color-border-grey;

        &__avatar-img {
          height: 32px;
          width: 32px;
          border-radius: 50%;
        }

        &__name-with-title {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          text-transform: capitalize;

          &__name {
            font-size: 14px;
            line-height: 20px;

            color: $color-dark-text;
          }

          &__title {
            font-size: 12px;
            line-height: 16px;

            color: $color-grey-text;
          }
        }
      }
    }
  }
}

.top-menu-item {
  display: flex;
  align-items: center;
  gap: 16px;

  &__toggler {
    background-color: $color-grey-18;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    h4 {
      margin: 0;
      color: $color-dark-text;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      max-width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h5 {
      margin: 0;
      color: $color-dark-grey;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
