@import 'styles/colors.scss';

.assets_categories_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 40px;
  border-bottom: 1px solid $color-border-grey;
  .acc_list {
    display: flex;
    align-items: center;
    gap: 4px;
    .acc_l_button {
      padding: 8px 12px;
      border-radius: 6px;
      height: 36px;
      background: none;
      font-weight: 600;
      color: $color-dark-grey;
      &.acc_l_button_active {
        background-color: $color-primary-6;
        color: $color-primary-300_1;
      }
    }
  }
}
