@import 'styles/titles.scss';
@import 'styles/colors.scss';

.custom-pie-chart {
  &__label {
    @include footnote;
    text-align: center;
    color: $color-black;
    transform: translateY(5px);
  }

  &__transform {
    @extend .custom-pie-chart__label;
    transform: translateY(-15px);
    fill: $color-grey-22;
  }

  &__list {
    list-style-type: none;
    padding-left: 0 !important;

    li {
      :hover {
        background-color: $color-primary-6;
      }
    }
  }

  &__scrollable {
    max-height: 180px;
    margin-top: 20px;
    overflow: auto;
  }

  &__legend {
    @include flex(row, center, space-between);
    margin-bottom: 12px;
    margin-right: 5px;
    cursor: pointer;

    &__title {
      @include flex(row, center, space-between);
    }

    &__labels {
      @include footnote;
      color: $color-grey-11;
    }

    &__margin {
      margin-right: 52px;
    }

    &__list {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &__no-data-div {
      @include flex-body-column;
      width: 50%;
      gap: 6px;
      float: right;

      &__title {
        @include description-600;
        color: $color-grey-19;
        text-align: center;
      }

      &__description {
        @include description;
        color: $color-dark-grey;
        text-align: center;
      }
    }
  }
}
