@import 'styles/colors.scss';
@import 'styles/titles.scss';

.cdvc_bt_text {
  color: $color-grey-text;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.cdvc_main_container {
  position: relative;
  display: flex;
}

.anticipatory_intelligence_container {
  display: flex;

  .cdvc_right_container {
    flex-grow: 1;
  }
}

.combine_details_view_container {
  height: calc(100vh - 60px);

  .cdvc_left_container {
    width: 50%;
    flex-shrink: 0;
    border-right: 1px solid $color-border-grey;
    padding-block: 19px;

    .cdvc_lc_inner_container {
      padding-inline: 40px;

      .cdvc_lc_btn {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        padding: 0;
        height: auto;
        background: none;
        color: $color-dark-grey;
        gap: 2px;
      }

      .cdvc_lc_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 100px;
        margin-block: 16px;

        .cdvc_lc_h_title_container {
          display: flex;
          align-items: center;
          gap: 12px;

          .cdvc_lc_h_t_c_text {
            color: $color-dark-text;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 0;
          }

          svg {
            height: 20px;
            width: 20px;
            cursor: pointer;

            path {
              stroke: $color-grey-text;
            }
          }
        }

        .cdvc_lc_h_module_text {
          padding: 4px 12px;
          color: $color-pink-700;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          border-radius: 4px;
          background-color: $color-light-pink;
          margin-bottom: 0;

          &.cdvc_lc_h_module_text_communities {
            background-color: $color-orange-50;
            color: $color-orange-700;
          }

          &.cdvc_lc_h_module_text_watchlists {
            background-color: $color-purple-50;
            color: $color-purple-700;
          }
        }
      }

      .cdvc_lc_action_buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 0px;

        .cdvc_lc_ab_button {
          flex-grow: 1;
          padding: 8px 14px;
          height: 36px;
          gap: 4px;
          justify-content: center;
          border-radius: 0;
          background-color: $color-white;
          border: 1px solid $color-border-grey;
          color: $color-grey-19;

          &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-right: 0;

            svg {
              path {
                stroke: $color-dark-grey;
              }
            }
          }

          &:nth-child(2) {
            border-right: 0;

            svg {
              path {
                stroke: $color-dark-grey;
              }
            }
          }

          &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-left: 0;
          }

          &:disabled {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .cdvc_width {
    width: 50%;
  }

  .cdvc_right_container {
    display: flex;
    flex-direction: column;
    // flex-shrink: 0;
    overflow: hidden;

    .cdvc_rc_header {
      padding: 59px 40px 16px;

      .cdvc_rc_h_title {
        color: $color-dark-text;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
      }

      .cdvc_rc_h_desc {
        color: $color-grey-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        margin-top: 4px;
      }

      .cdvc_rc_h_action_buttons {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
        flex-wrap: wrap;

        .cdvd_rc_h_ab_button {
          background-color: $color-unselected;
          padding: 8px 10px;
          font-weight: 400;
          color: $color-dark-text;

          &:nth-child(2),
          &:nth-child(3) {
            svg {
              path {
                stroke: $color-prime;
              }
            }
          }
        }
      }
    }

    .cdvc_rc_snippet_container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;

      .cdvc_rc_sc_spin_wrapper {
        overflow: hidden;
        flex-grow: 1;

        .ant-spin-container {
          height: 100%;
          overflow: hidden;
        }

        .cdvc_rc_sc_loader {
          max-height: none;
          height: 100%;
        }
      }
    }
  }

  .cdvc_narrative_form {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
  }

  .cdvc_narrative_info {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    height: 100%;
  }
}
