@import 'styles/titles';
@import 'styles/colors';

.TOS-Doc {
  overflow: scroll;

  &__container-1 {
    @include flex-body-column;
    gap: 32px;
  }

  &__container-2 {
    @include flex-body-column;
    gap: 16px;
  }

  &__title {
    @include dp-selected;
    color: $color-dark-text;
  }

  &__span-1 {
    @include description;
    color: $color-dark-text;
  }

  &__span-2 {
    @include dp-selected;
    color: $color-dark-text;
  }

  &__span-3 {
    @include dp-selected;
    color: $color-dark-text;
    font-style: italic;
  }

  &__heading-1 {
    @include dp-selected;
    color: $color-dark-text;
    text-transform: uppercase;
  }
}
