@import 'styles/colors.scss';
@import 'styles/titles.scss';

.power-insights-bar-chart {
  &__chart-container {
    margin-top: 30px;
  }

  &__see-more {
    @include flex(row, center, flex-start);
    margin-top: 6px;
    cursor: pointer;

    &__label {
      display: block;
      @include button-text;
      color: $color-black;
      margin-right: 4px;
    }
  }
}
