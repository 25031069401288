@import 'styles/colors.scss';

.topic-list-modal {
  width: 992px !important;
  top: 60px !important;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    padding: 16px 24px;
    align-content: center;
    justify-content: space-between;

    &-left,
    &-right {
      gap: 8px;
      display: flex;
      align-items: center;
    }

    &-btn {
      button {
        border: none;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 12px;
      }

      &--topic button {
        color: $color-dark-grey;
        background-color: transparent;
      }

      &--topic button.active {
        color: $color-primary-300_1;
        background-color: $color-primary-6;
      }

      &--suggestion button {
        gap: 2px;
        color: $color-dark-grey;
        background-color: transparent;
      }

      &--suggestion button.active {
        gap: 2px;
        color: $color-primary-300_1;
        background-color: $color-primary-6;
      }

      &--create button {
        gap: 6px;
        height: 40px;
        color: $color-grey-19;
        padding: 10px 12px;
        background-color: $color-grey-18;
        svg {
          margin: 0px !important;
          width: 10px;
          height: 10px;
          path {
            stroke-width: 3;
            stroke: $color-grey-19;
          }
        }
      }
    }

    &-search {
      .header-search-input.ant-input-affix-wrapper {
        min-height: auto;
        border-radius: 8px;
        border: 1px solid $color-grey-17;
        background-color: $color-grey-20;
        box-shadow: 0px 1px 2px 0px #1018280d;
        &:hover {
          border-color: $color-grey-17;
        }

        .ant-input {
          padding-left: 4px;
          min-height: auto;
          font-size: 16px;
          font-weight: 400;
          color: $color-dark-text;
          background-color: $color-grey-20;

          &::placeholder {
            color: $color-grey-22;
          }
        }

        &:hover,
        &:focus,
        &:active {
          border: 1px solid $color-grey-17;
        }

        &.ant-input-affix-wrapper-focused {
          border: 1px solid $color-grey-17;
        }
      }
    }
  }

  &__data {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    overflow-y: scroll;
    height: 612px;
    min-height: 612px;
    padding-bottom: 80px;

    &.suggestion_data {
      padding-bottom: 20px;
      display: grid;
      justify-content: center;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:has(.ant-pagination)) {
      justify-content: flex-end;
    }

    &__right-btn {
      gap: 18px;
      align-self: flex-end;
      align-items: center;
      display: inline-flex;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $color-dark-grey;
      }

      button.ant-switch-checked {
        background-color: $color-prime;
        &:hover {
          background-color: $color-primary-300_1;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 16px 24px;
  }

  &__drawer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__count {
      color: $color-black;
      font-weight: 500;
    }

    &__actions {
      gap: 8px;
      display: flex;
      align-items: center;

      button.ant-btn.ant-btn-default {
        gap: 6px;
        display: flex;
        border-radius: 6px;
        align-items: center;
        svg {
          margin: 0px !important;
        }
      }

      &__delete-btn.ant-btn.ant-btn-default {
        background: linear-gradient(180deg, #ff574a 0%, #e30800 100%),
          linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 21.25%);
        border: 1px solid $delete-button-color;
        color: $color-white;
        svg {
          width: 20px;
          height: 20px;
          path {
            stroke: $color-white;
            stroke-width: 2;
          }
        }
      }

      &__follow-btn.ant-btn.ant-btn-default {
        background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, $platform-brand-color-tiktok 100%),
          linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);
        border: $color-black;
        color: $color-white;

        svg {
          width: 12px;
          height: 12px;
        }
      }

      .ant-btn.ant-btn-default.ant-btn-loading {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
