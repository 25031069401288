@import 'styles/titles.scss';
@import 'styles/colors.scss';

.daily-summary {
  width: calc(100vw - 95px) !important;
  margin: 24px 0 0 80px !important;

  .ant-modal-content {
    height: calc(100vh - 150px) !important;
    box-shadow: 0px 0px 15px 0px rgba(151, 71, 255, 0.4) !important;
    border: 2px solid rgba(234, 236, 240, 1) !important;
    border-radius: 6px !important;
    padding-top: 32px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .version-changer {
    display: flex;
    align-items: center;
    gap: 12px;
    &:has(button) {
      margin-left: 32px;
    }

    h5 {
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $color-grey-23;
      min-width: 96px;
      text-align: center;
    }
    .icon-btn {
      background-color: $color-primary-7;
      color: $color-primary-300_1;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        path {
          stroke: $color-primary-300_1;
        }
      }

      &.icon-btn-last {
        background: $color-grey-18;
        color: $color-primary-11;
        svg {
          path {
            stroke: $color-primary-11;
          }
        }
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
    padding-top: 8px;
    border-bottom: 1px solid $color-border-grey-2;

    h4 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color: $color-dark-text;
      margin-bottom: 0;
      margin-left: 8px;
    }
    .collapse-option {
      margin-left: auto;
      margin-right: 8px;
      cursor: pointer;
    }
    .subscribe-option {
      cursor: pointer;
      margin-bottom: 0px;
      height: 20px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .body {
    display: flex;
    flex-wrap: nowrap;
    &__empty-state {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 10%;
      gap: 16px;
      h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: $color-grey-23;
        margin-bottom: 0px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $color-dark-grey;
        margin-bottom: 0px;
        width: 400px;
        text-align: center;
      }
    }
  }

  .summary-section {
    width: 50%;
    .summary-section-header {
      height: 52px;
      box-sizing: border-box;
      border-right: 1px solid $color-border-grey-2;
      border-bottom: 1px solid $color-border-grey-2;
      display: flex;
      align-items: center;
      padding: 16px 24px;

      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $platform-brand-color-tiktok;
        margin-bottom: 0px;
        padding-bottom: 0px;

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $color-dark-grey;
          margin-left: 8px;
        }
      }
    }

    .summary-list {
      height: calc(100vh - 300px);
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-right: 1px solid $color-border-grey-2;
      padding-top: 16px;
      padding-right: 8px;

      .summary-empty-state .ant-skeleton-paragraph {
        height: 150px;
        overflow: hidden;
      }
      .summary-sections-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        h4 {
          color: $color-grey-600;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .summary {
      border-radius: 12px;
      padding: 24px;
      border: 1px solid $color-border-grey-2;
      cursor: pointer;
    }
    .summary-selected {
      border: 1px solid $color-primary-8;
    }
  }
  .snippets-section {
    width: 50%;
    .snippets-section-header {
      height: 52px;
      box-sizing: border-box;
      border-bottom: 1px solid $color-border-grey-2;

      display: flex;
      align-items: center;
      padding: 16px 24px;

      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $platform-brand-color-tiktok;
        margin-bottom: 0px;
        padding-bottom: 0px;

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $color-dark-grey;
          margin-left: 8px;
        }
      }
    }
    .snippets-list {
      height: calc(100vh - 300px);
      background-color: $color-primary-3;
      padding: 24px;
      overflow: auto;
      gap: 8px;
      display: flex;
      flex-direction: column;
    }
  }
}

.subscription-status {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 12px;
  border-bottom: 1px solid $color-border-grey-2;

  .bell-icon {
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary-6;
    svg {
      path {
        stroke: $color-primary-11;
      }
    }
  }
  .status {
    max-width: 262px;
    margin-right: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: $platform-brand-color-tiktok;
    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
  }
  .fhc_sc_button {
    height: 40px;
    background-color: $color-unselected;
    border-radius: 6px;
    padding: 10px 16px;
    color: $color-grey-19;
  }
}

.subscription-options {
  padding: 16px 0px;
  padding-left: 8px;
  .ant-radio-wrapper {
    align-items: center;
    gap: 8px;
  }
  .label-radio {
    display: flex;
    flex-direction: column;
    gap: 4px;
    h4 {
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: $color-grey-19;
    }
    p {
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: $color-dark-grey;
      vertical-align: baseline;
    }
  }
  .weekly-selection {
    margin-top: 16px;

    .weekDaySelector {
      width: 349px;

      .ant-select-selector {
        border-radius: 8px;
        .ant-select-selection-item {
          font-size: 16px;
          font-weight: 500;
          color: $color-grey-900;
        }
      }
    }
  }
  .ant-select-dropdown {
    border-radius: 8px;
    box-shadow: 0px 8px 14px 0px #0000001a;
    border: 1px solid $color-border-grey-2;

    .rc-virtual-list {
      .ant-select-item-option-content {
        font-weight: 500;
        color: $color-grey-900 !important;
      }
    }
  }
}
