@import 'styles/colors.scss';
@import 'styles/titles.scss';

.steps-header {
  padding: 24px 0px;
  border-top: 1px solid $color-border-grey;
  margin-top: 32px;
  .head {
    display: flex;
    gap: 16px;
    h5 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 0px;
      color: $color-grey-text;
    }
    .title {
      color: $color-dark-text;
    }
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-grey;
  }
}

.recurrence-card {
  border: 1px solid $color-grey-17;
  width: 100%;
  padding: 16px 24px;
  border-radius: 12px;

  .header {
    display: flex;
    gap: 8px;
    align-items: center;
    &__content {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0px;
        color: $color-grey-19;
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $color-dark-grey;
        margin-bottom: 0px;
      }
    }
  }
}
.recurrence-card-selected {
  border: 1px solid $color-prime;
}

.steps-banner {
  padding: 0px 60px;
  margin-top: 40%;
  .icon {
    width: 48px;
    height: 48px;
    border: 1px solid $color-border-grey-2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #0c111d;
  }
  p {
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.review-tag {
  box-sizing: border-box;
  width: max-content;
  text-wrap: nowrap;
  height: 36px;
  padding: 8px 16px;
  border-radius: 16px;
  border: 1px solid $color-primary-700_1;
  background-color: $color-primary-3;
  color: $color-primary-400;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  svg {
    path {
      stroke: $color-black;
    }
  }
  span {
    color: $color-black;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.add-edit-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        color: $color-violet-700;
      }
    }
  }

  &__content {
    height: calc(100vh - 122px);
    overflow-y: auto;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &__review-form-actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
    // flex-grow: 1;
    .continue-btn {
      background-color: $color-black;
      color: white;
      min-width: 128px;
      display: flex;
      justify-content: center;
    }
    .cancel-btn {
      background-color: $color-unselected;
      color: $color-dark-text;
      min-width: 128px;
      display: flex;
      justify-content: center;
    }
  }
}
