@import 'styles/colors.scss';
@import 'styles/titles.scss';

.reportListForm {
  margin-top: 16px;
  .recurring {
    width: 465px;
    padding: 10px 16px;
    background-color: $color-background;
    border: 1px solid $color-border-grey;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;

    .centerItems {
      display: flex;
      gap: 9px;
      align-items: center;
    }

    .recurringcontainer {
      gap: unset;
      justify-content: space-between;

      .recurringheader {
        display: flex;
        gap: 9px;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        .recurringheaderActive {
          color: $color-black;
          margin: 0;
        }

        .recurringheaderInactive {
          color: $color-grey-text;
          margin: 0;
        }
      }
      :global {
        .ant-switch {
          width: 32px;
          height: 20px;
          min-width: unset;
        }

        .ant-switch-handle {
          width: 13px;
          height: 13px;

          top: 3.5px;
          left: 4px;
        }
        .ant-switch-checked {
          background-color: $color-prime;
          .ant-switch-handle {
            left: calc(100% - 17px);
          }
        }
      }
    }
  }
}
