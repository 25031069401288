@import 'styles/colors.scss';

.sm_containerClassName {
  .sm_box_container {
    min-height: 264px;
    min-width: 387px;
    width: 387px;
    padding: 32px;
    border-radius: 8px;
    .cmc_cc_header_container {
      justify-content: center;
      .cmc_cc_hc_left_container {
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        .cmc_cc_hc_lc_icon {
          flex-shrink: 0;
        }
        .cmc_cc_hc_lc_heading {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: $color-dark-text;
        }
      }
    }
    .cmc_cc_hc_close_icon {
      display: none;
    }
    .cmc_cc_footer_container {
      justify-content: center;

      .cmc_cc_fc_button {
        justify-content: center;
        flex-grow: 1;
        padding: 7px 16px;
        &:first-child {
          background-color: $color-black;
          color: $color-white;
        }
      }
    }
  }
}
.sm_body_container {
  display: flex;
  .sm_modal_body_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 8px 0 24px;
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    .sm_follow_text {
      margin-bottom: 0;
    }
  }
}
