.grid_view_section_container {
  .gvsc_card_container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .gvsc_cc_card {
      display: flex;
      flex-direction: column;
      padding: 20px;
      width: calc(33.33% - 11px);
    }
  }
}
