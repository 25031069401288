@import 'styles/titles.scss';
@import 'styles/colors.scss';

.floating-button {
  @include flex-body-centered;
  @include description;

  background: rgba(255, 255, 255, 0.5);
  border: 1px solid $color-grey-scale-300;
  border-radius: 64px;
  padding: 12px 16px;
  position: fixed;
  bottom: 16px;
  right: 130px;
  backdrop-filter: blur(8px);
  text-align: center;
  color: $color-black;
  z-index: 9999;
  cursor: pointer;
}
