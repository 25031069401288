@import 'styles/variables.scss';
@import 'styles/colors.scss';

.brands_table {
  margin-top: 24px;

  thead {
    height: 0px;
    opacity: 0;

    tr {
      th {
        padding: 0px !important;
      }
    }
  }

  tbody {
    tr {
      height: 72px;

      td {
        .action_button_container {
          display: none;
        }
      }
    }
  }

  .brand_name {
    color: $color-grey-900;
    font-weight: 500;
    line-height: 20px;
  }
}

.brand_info {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 24px;

  .image {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 0.48px solid $color-pastel-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
  }
}

.mentions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .apc_count {
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $color-dark-grey;
  }
}

.area_chart_container {
  width: 160px;
  height: 56px;
}

.am_dashboard_pagination_container {
  padding-bottom: 16px;
  margin-top: 16px;

  .amc_pagination {
    margin: 0 !important;

    .ant-pagination-item,
    .ant-pagination-jump-next-custom-icon,
    .ant-pagination-jump-prev-custom-icon {
      height: 40px;
      width: 40px;
      border-color: $color-grey-17;

      .pagination__container {
        padding: 0;
      }

      .pagination__container {
        .pagination__label {
          font-family: Inter;
          font-weight: 500;
          color: $color-grey-800;
        }
      }

      &.ant-pagination-item-active {
        border-color: $color-prime;

        .pagination__container {
          .pagination__label {
            color: $color-white;
          }
        }
      }
    }

    .ant-pagination-next,
    .ant-pagination-prev {
      height: 40px;
      border-color: $color-grey-17;

      .pagination__container {
        gap: 8px;
        padding: 10px 16px;

        .pagination__label {
          font-family: Inter;
          font-weight: 500;
          color: $color-grey-800;
        }

        svg {
          path {
            stroke: $color-grey-19;
          }
        }
      }
    }

    :last-child {
      margin: 0;
    }
  }
}
