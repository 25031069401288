@import 'styles/variables.scss';
@import 'styles/colors.scss';

.mention-view-card {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__status {
    display: flex;
    gap: 16px;
    flex-direction: column;
    &__counts {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: $color-grey-900;
    }
    &__trend {
      display: flex;
      align-items: center;
      gap: 8px;
      &__detail {
        display: flex;
        align-items: center;
        gap: 2px;
        svg {
          transform: rotate(180deg);
          width: 20px;
          height: 20px;
          margin-left: -4px;
          path {
            stroke: #f04438;
          }
        }
        &__percentage {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #b42318;
        }
      }
      .trending {
        svg {
          transform: rotate(0deg);
          path {
            stroke: #17b26a;
          }
        }
        .mention-view-card__status__trend__detail__percentage {
          color: #067647;
        }
      }
      .purple {
        svg {
          transform: rotate(0deg);
          path {
            stroke: $color-purple;
          }
        }
        .mention-view-card__status__trend__detail__percentage {
          color: $color-purple;
        }
      }
      &__duration {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $color-grey-600;
      }
    }
  }
  &__empty-state {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
  }
  .skeleton-paragraph {
    &__small {
      width: 50px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }
    &__large {
      width: 100px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }
  }

  .chart-wrapper {
    width: 150px;
    height: 75px;
  }
}

.all_posts_container {
  display: flex;
  align-items: center;
  .apc_count {
    font-size: 16px;
    line-height: 20px;
  }
  .apc_trending_count {
    font-size: 12px;
    line-height: 20px;
    color: $color-orange;
  }
  .apc_low_icon {
    path {
      stroke: $color-indigo-700;
    }
  }
  &.all_posts_container_down {
    .apc_arrow_icon {
      transform: rotate(180deg);
      path {
        stroke: $color-indigo-700;
      }
    }
    .apc_trending_count {
      color: $color-indigo-700;
    }
  }
}
