@import 'styles/colors.scss';
@import 'styles/titles.scss';

.contact-us {
  &__title {
    @include flex(row, flex-start, flex-start);
    @include title;
    color: $color-dark-text;
  }

  &__description {
    @include description;
    @include flex(row, flex-start, flex-start);
    color: $color-dark-text;
    margin-bottom: 8px;
  }

  &__footer {
    width: 100%;
    @include flex(row, flex-start, flex-end);

    &__cancel {
      background-color: transparent;
      border: 1px solid $color-black;
      border-radius: 4px;
      padding: 7px 16px;
      color: $color-black;
      margin-top: 24px;
      margin-right: 10px;
      cursor: pointer;
    }

    &__send {
      background-color: $color-black;
      border: 1px solid $color-black;
      border-radius: 4px;
      padding: 7px 16px;
      color: $color-white;
      margin-top: 24px;
      cursor: pointer;
    }
  }

  .ant-modal-content {
    padding: 32px;
  }

  .ant-modal-header {
    padding: 0 0 18px 0;
  }

  .ant-modal-close {
    top: 15px;
  }
}
