@import 'styles/colors.scss';
@import 'styles/titles.scss';

.risk-flags-header {
  @include flex(column, flex-start, flex-start);
  position: relative;
  width: 100%;

  &__header {
    @include flex(row, center, space-between);
    width: 100%;
    padding: 16px 30px;
    border-bottom: 1px solid $color-border-grey;

    &__filters {
      @include flex-body;
    }
  }

  &--inner {
    width: 100%;
    padding: 16px 30px;
  }

  &__list {
    @include flex-body;
    overflow: auto;
    scroll-behavior: smooth;
    min-width: 98%;

    &::-webkit-scrollbar {
      height: 0;
    }

    &__item {
      @include flex-body;
      padding: 7px 16px 7px 8px;
      gap: 8px;
      background-color: $color-unselected;
      border-radius: 4px;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;

      &__icon {
        @include flex-body-centered;

        path {
          fill: $color-prime;
        }
      }

      &__title {
        @include body;
        width: max-content;
        color: $color-dark-text;

        &--selected {
          color: $color-white;
        }
      }

      &--selected {
        background-color: $color-black;
        background-color: $color-prime;
        .risk-flags-header__list__item__icon {
          path {
            fill: $color-white;
          }
        }
      }
    }

    &__forward-btn {
      position: relative;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      border: none;
      outline: none;
      background-color: $color-unselected;
      color: $color-white;
      cursor: pointer;
      box-shadow: -10px 0px 14px rgba(0, 0, 0, 0.16);
    }
  }

  &__graph-div {
    @include flex-body-centered;
    background: $color-grey-scale-100;
    border-radius: 4px;
    margin-right: 16px;
    cursor: pointer;
    border: 1px solid $color-border-grey;

    &__title {
      @include description;
      color: $color-black;
      margin-right: 8px;

      &--active {
        color: $color-white;
      }
    }

    &__active-chart {
      @include flex-body-centered;
      background: $color-black;
      box-shadow: 6px 2px 10px rgba(0, 0, 0, 0.12);
      border-radius: 4px;

      svg {
        path {
          fill: $color-white !important;
        }
      }
    }

    svg {
      display: flex;
      margin: 8px;
      height: 18px;

      path {
        fill: $color-black;
      }
    }
  }
}
