@mixin main-title {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
}

@mixin large-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
}

@mixin medium-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

@mixin title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

@mixin title-18 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

@mixin small-bold-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

@mixin dp-selected {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

@mixin info-700 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

@mixin title-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

@mixin heading-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}

@mixin subtitle-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@mixin description-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

@mixin info-600 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 13px;
}

@mixin title-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}

@mixin warning {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

@mixin subtitle-16 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

@mixin subtitle-15 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
}

@mixin tab-selected {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin callout {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin heading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin info {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

@mixin tag {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

@mixin small {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
}

@mixin x-small {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
}

@mixin medium {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

@mixin description-16 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

@mixin body {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin simple {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

@mixin footnote {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin small-400 {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

@mixin title-300 {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
}

@mixin small-title-300 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

@mixin flex-body {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin flex-body-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin flex-body-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flex($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin flexbox($direction: row, $align: center, $justify: null, $gap: null) {
  display: flex;

  @if $direction != row {
    flex-direction: $direction;
  }

  @if $align != '' {
    align-items: $align;
  }

  @if $justify {
    justify-content: $justify;
  }

  @if $gap {
    gap: $gap;
  }
}
