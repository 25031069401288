@import 'styles/colors.scss';
@import 'styles/titles.scss';

.three_d_chart_container {
  //   background-color: gold;
  width: 100%;
  .tdcc_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 40px;
    border-bottom: 1px solid $color-border-grey;

    .tdcc_header_links_container {
      display: flex;
      align-items: center;
      background: $color-grey-scale-100;

      width: fit-content;
      cursor: pointer;
      border: 1px solid $color-border-grey;
      padding: 2px 6px;

      gap: 6px;

      .tdcc_header_lc_link {
        padding: 4px 8px;
        display: flex;
        align-items: center;
        gap: 6px;
        border-radius: 4px;

        .tdcc_header_lc_l_icon {
          path {
            fill: $color-black;
          }
          &.tdcc_header_lc_l_3d_icon {
            path {
              stroke: $color-black;
              fill: none;
            }
          }
        }

        .tdcc_header_lc_al_title {
          @include description;
          color: $color-black;
          margin-right: 8px;
        }
        &.tdcc_header_lc_link_active {
          @include flex-body-centered;
          background: $color-black;

          .tdcc_header_lc_l_icon {
            path {
              fill: $color-white;
            }
            &.tdcc_header_lc_l_3d_icon {
              path {
                stroke: $color-white;
                fill: none;
              }
            }
          }

          .tdcc_header_lc_al_title {
            color: $color-white;
          }
        }
      }
    }

    .date-filter__text {
      margin-top: 2px;
      font-size: 15px;
      font-weight: 600;
      color: $color-grey-19;
    }
  }

  .ant-dropdown-trigger {
    padding: 4px 15px;

    &.date-filter {
      border: 2px solid rgb(234, 236, 240);
      margin-right: 8px;
    }
  }

  .tdcc_3d_graph {
    position: relative;
    width: 100%;
    height: calc(100vh - 119px);
  }
}
.single-calendar-dropdown .ant-picker-range {
  display: none;
}

.single-calendar-dropdown .ant-picker-separator {
  display: none;
}
