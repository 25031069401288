@import 'styles/colors.scss';
.delete_report_modal_container {
  .delete_report_modal_footer_container {
    .cmc_cc_fc_button {
      border-color: $color-border-black;
      padding: 7px 16px;
      &:last-child {
        border-color: $delete-button-color;
        background-color: $delete-button-color;
      }
    }
  }
}

.delete_report_modal_body_container {
  .ant-spin-nested-loading {
    height: 100%;
  }
  height: 259px;
  overflow-y: auto;
  margin: 40px 0 32px 0;
  padding-right: 4px;
  .drmc_header {
    .drmc_shared_users_container {
      display: flex;
      align-items: center;
      gap: 8px;
      width: fit-content;
      cursor: pointer;
      padding-inline: 4px;
      .drmc_suc_heading {
        color: $color-dark-grey;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
      }
      .drmc_suc_shared_users_list {
        display: flex;
        align-items: center;
        gap: 8px;
        .drmc_suc_arrow_rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  .drmc_instruction {
    background-color: $delete-label;
    padding: 16px 24px;
    text-align: center;
    color: $delete-label-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-block: 32px;
  }
}

.shared_users_details_container {
  display: flex;
  .sudc_list_container {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    margin-top: 0;
    transition: max-height 0.45s linear, opacity 0.25s linear, margin-top 0.25s linear;
    &.sudc_list_container_show {
      margin-top: 24px;
      max-height: 100%;
      opacity: 1;
    }
    .sudc_list {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 6px;
      .sudc_list_image_container {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        .sudc_list_image {
          height: 100%;
          width: 100%;
        }
      }
      .sudc_list_content {
        .sudc_list_c_name {
          color: $color-dark-text;
          font-family: Rubik;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          margin: 0;
        }
        .sudc_list_c_email {
          color: $color-grey-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }
}
