@import 'styles/colors.scss';

.brand-discussions-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.followed-issues {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  min-width: min-content;

  &__controls {
    display: flex;
    justify-content: space-between;

    &:not(:has(.ant-pagination)) {
      align-self: flex-end;
    }

    .browse_more-icon {
      font-weight: 500;
      align-self: flex-end;
      display: inline-flex;
      align-items: center;
      color: $color-grey-19;
      flex-direction: row-reverse;
      background-color: $color-grey-18;
      svg {
        margin: 0px;
        path {
          stroke: $color-grey-19;
        }
      }
    }
  }
}
.brand-discussion-card {
  width: 100%;
  border-radius: 24px;
  min-height: 124px;
  padding: 16px;
  border: 1px solid $color-border-grey-2;
  box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  cursor: pointer;

  .skeleton-image {
    width: 90px;
    height: 90px;
    border-radius: 12px;
  }
  .skeleton-title {
    height: 15px;
    overflow: hidden;
    width: 30%;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 8px;
  }
  .skeleton-description {
    width: 60%;
    height: 15px;
    overflow: hidden;
  }
  .skeleton-stats-item {
    width: auto;
    height: 15px;
    overflow: hidden;
    border-radius: 8px;
  }

  .img-wrapper {
    width: 90px;
    min-width: 90px;
    height: 90px;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
  .summary {
    flex: 1;
  }
  .stats {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-right: 16px;

    .item {
      display: flex;
      flex-direction: column;
      border-right: 1px solid $color-border-grey-2;
      padding-right: 12px;
      gap: 8px;

      h6 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: $color-grey-22;
        margin-bottom: 0px;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        color: $color-grey-23;
        margin-bottom: 0px;
      }

      .sentiment {
        display: flex;
        gap: 8px;
        align-items: center;
        .positive {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #17b26a;
        }
        .negative {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          font-weight: 400;
          color: $delete-button-color;
        }
      }
    }
    .item:last-child {
      border-right: none !important;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-text;
    margin-top: 16px;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-grey;
    line-height: 1.5;
    min-width: 400px;
  }
  .read-more {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-dark-text;
    cursor: pointer;
  }
}

.try-again-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-dark-grey;
}

.try-again {
  margin-left: 4px;
  font-weight: 500;
  color: $color-black;
}
