@import 'styles/colors.scss';

.no-brand-placeholder {
  gap: 16px;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 60px);

  &__logo {
    svg {
      height: 125px;
    }
  }

  .no-brand-video {
    border-radius: 8px;

    video {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  &__info {
    gap: 8px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: $color-grey-600;
    }

    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      color: $color-dark-grey;
    }
  }

  &.isSkuLoading {
    gap: 24px;

    .no-brand-placeholder__info {
      gap: 12px;
      max-width: 380px;

      &__title {
        font-family: Roboto;
        color: $color-grey-23;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      &__description {
        color: $color-dark-grey;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &:has(.no-brand-video) {
    gap: 32px;

    .no-brand-placeholder__logo {
      display: none;
    }

    .no-brand-placeholder__info {
      gap: 16px;
      max-width: 380px;

      &__title {
        font-family: Roboto;
        color: $color-grey-23;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      &__description {
        color: $color-dark-grey;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .ant-btn-primary {
    height: auto;
    width: auto;
    margin: 0;
    gap: 8px;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 10px 16px;
    border-radius: 6px;
    color: $color-white;
    background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);

    svg {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%) !important;
      color: $color-white !important;
    }
  }
}
