@import 'styles/colors.scss';
@import 'styles/titles.scss';

.titleWrapper {
  background-color: $color-background;
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  margin-bottom: -10px;

  .titleicon {
    width: 24px;
    height: 24px;
  }

  .titleText {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: $color-dark-text;
  }
}
