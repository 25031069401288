@import 'styles/colors.scss';

.reports_main_view_container {
  padding: 24px 40px;
  height: calc(100vh - 106px);
  overflow: auto;

  :global {
    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin {
        max-height: 100%;
      }
    }
  }

  .rmvc_header {
    margin-bottom: 24px;
  }

  .report_name_container {
    display: flex;
    align-items: center;

    .report_name {
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
      // word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .report_new {
      background-color: $color-purple;
      padding: 2px 5px;
      border-radius: 4px;
      color: $color-white;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-left: 8px;
    }

    .rnc_generate_icon_container {
      position: relative;
      margin-left: 8px;

      &::before {
        display: none;
        width: max-content;
        position: absolute;
        border-radius: 4px;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        content: 'Generating Report';
        background-color: $color-black;
        padding: 8px;
        color: $color-white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      &::after {
        display: none;
        content: '';
        position: absolute;

        top: calc(100% - 8px);
        left: 50%;
        transform: translateX(-50%);

        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent $color-black transparent;
      }

      &:hover {
        &::before,
        &::after {
          display: block;
        }
      }

      .rnc_generate_icon {
        position: relative;
      }
    }
  }

  .report_type_container {
    display: flex;
    align-items: center;
    gap: 6px;

    .rtc_icon_container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .rtc_ic_icon {
        path {
          stroke: $color-prime;
        }
      }

      &::before {
        display: none;
        content: attr(data-tooltip);
        width: max-content;
        position: absolute;
        border-radius: 4px;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-black;
        padding: 8px;
        color: $color-white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        z-index: 1;
      }

      &::after {
        display: none;
        content: '';
        position: absolute;

        bottom: calc(100% - 2px);
        left: 50%;
        transform: translateX(-50%);

        border-width: 7px;
        border-style: solid;
        border-color: $color-black transparent transparent transparent;
      }

      &:hover {
        background-color: $color-white-hover;

        &::before,
        &::after {
          display: block;
        }
      }
    }
  }

  .rmvc_table {
    thead {
      tr {
        th {
          &:last-child {
            min-width: 150px;
          }
        }
      }
    }

    tbody {
      tr {
        &[data-row-disabled='true'] {
          background-color: $color-white;
          cursor: default;

          td {
            .report_name_container {
              .report_name {
                color: $color-dark-grey;
              }
            }
          }

          &:hover {
            background-color: $color-white;
          }
        }

        td {
          .rmvc_created_date,
          .rmvc_recurring {
            white-space: nowrap;
          }

          // &:last-of-type{
          //   padding: 10px !important;
          // }
          .action_button_container {
            display: none;
          }

          &:first-child {
            padding-block: 8px !important;
          }

          &:last-child {
            padding-block: 10px !important;
          }
        }

        &:hover {
          td {
            .action_button_container {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.emptyState {
  background-color: $color-primary-3;
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    background-color: white;
    width: 390px;
    height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px;
    border-radius: 16px;
    gap: 32px;
  }

  h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: $platform-brand-color-tiktok;
    margin-bottom: 0px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: $color-dark-grey;
    margin-bottom: 0px;
  }
}
