@import 'styles/colors.scss';
@import 'styles/titles.scss';

.export {
  background-color: $color-white;
  height: calc(100vh - 106px);
  padding: 24px 40px;

  &__wrapper {
    height: calc(100vh - 207px);
    overflow-y: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__header {
    &__sticky {
      top: 0;
      position: sticky;
      background-color: $color-white;
      z-index: 1;
      margin-bottom: 19px;
    }

    &__title {
      @include subtitle;
      color: $color-black;
    }
  }

  &__items-div {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 47px;
    }
  }

  &__item {
    @include flex(row, center, space-between);
    margin-bottom: 8px;
    padding: 10px 8px;

    &__name {
      @include description;
      color: $color-dark-grey;
    }

    &__date {
      @include description;
      color: $color-grey-text;
    }

    &--isSelected {
      background-color: $color-primary-3;
      cursor: pointer;
      border-radius: 4px;

      .export__item__name {
        color: $color-black;
      }
    }

    &:hover {
      background-color: $color-primary-3;
      cursor: pointer;
      border-radius: 4px;

      .export__item__name {
        color: $color-black;
      }
    }
  }

  &__info {
    @include flex-body-centered;
    background: $color-white;
    height: 100%;

    &__container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border: 1px solid $color-border-grey;
      border-radius: 8px;
      padding: 24px;
      min-width: 60%;
    }

    &__title {
      @include title;
    }

    &__description {
      @include description;
      color: $color-dark-grey;
    }

    &__filter-selection {
      @include flex(row, flex-start, flex-start);
      margin-top: 24px;

      &__filters {
        height: max-content;
        max-width: 260px;
        overflow: hidden;
        min-width: 150px;
        cursor: pointer;

        &__label {
          display: block;
          @include subtitle;
          color: #000;
          padding: 0;
          margin-bottom: 8px;
          margin-top: 16px;
        }

        .ant-dropdown-menu-item {
          padding: 0;
        }

        .ant-dropdown-menu {
          padding: 16px;
          padding-top: 0px;
          margin-top: 8px;
          border: 1px solid $color-border-grey;
          background: $color-white;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
        }
      }
    }

    &__filters {
      @include flex(row, center, space-between);
      padding: 16px;
      border-radius: 8px;
      border: 1px solid $color-border-grey;

      &__left-div {
        &__inner {
          @include flex-body-column;
          gap: 8px;

          &__export-text {
            @include description;
            color: $color-grey-text;
          }

          &__days-text {
            @include subtitle-600;
            color: $color-dark-text;
          }

          &__description-text {
            @include description;
            color: $color-dark-grey;
          }
        }
      }

      &__right-div {
        &__filters-btn {
          @include description;
          padding: 8px 16px;
          border-radius: 16px;
          background: $color-black;
          color: $color-white;
          border: none;
          cursor: pointer;
        }
      }
    }

    &__format {
      @include flex-body;
      gap: 16px;
      align-self: stretch;

      &__label {
        @include subtitle-16;
      }
    }

    &__dropdown {
      border: 1px solid $color-border-grey;
      border-radius: 8px;
      margin-top: 6px;

      &__icon {
        margin-left: 8px;

        path {
          stroke: $color-dark-grey;
          stroke-width: 1.66667;
        }
      }

      &__label {
        @include subtitle-16;
        color: $color-dark-text;
        overflow: hidden;
        width: fit-content;
      }

      .rc-virtual-list-holder-inner {
        :last-child {
          .ant-select-item-option-content {
            margin-bottom: 10px !important;
          }
        }
      }

      .ant-select-item-option {
        padding: 10px 14px 0px 14px !important;
      }

      .ant-select-item-option-content {
        margin-bottom: 16px !important;

        svg {
          margin-right: 8px;
        }
      }
    }

    &__download {
      @include flex-body-centered;
      @include description-600;
      padding: 13px 20px;
      gap: 6px;
      color: $color-white;
      border-radius: 4px;
      background: $color-black;
      border: none;
      cursor: pointer;

      &:disabled {
        @include description-600;
        background: $color-unselected;
        color: $color-grey-text;
        cursor: not-allowed;
      }
    }

    &__expired {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__title {
        @include warning;
        color: $color-grey-text;
      }

      &__type {
        @include flex(row, center, space-between);

        &__text {
          @include flex-body-centered;
          @include info;
          border-radius: 4px;
          background: $color-light-pink;
          padding: 4px 12px;
          color: #c11574;
          text-align: center;
        }
      }

      &__date {
        @include description;
        color: $color-grey-text;
      }

      &__creation-info {
        @include flex-body;
        gap: 8px;

        img {
          width: 40px;
          height: 40px;
        }
      }

      &__created-by {
        @include subtitle;
      }

      &__user-div {
        @include flex(column, flex-start, center);
      }

      &__user {
        @include description;

        &--designation {
          color: $color-grey-text;
        }
      }

      &__note {
        @include flex-body;
        gap: 8px;

        &__description {
          @include description;
          color: $color-dark-grey;
        }
      }
    }

    .ant-select-selector {
      border-radius: 8px !important;
      border: 1px solid $color-border-grey !important;
      background: $color-white !important;
      padding: 10px 14px !important;
      height: fit-content !important;
      min-width: 130px !important;
      width: fit-content !important;
      box-shadow: none !important;
    }

    .ant-select-selection-item {
      @include flex-body;
      padding: 0px !important;

      span {
        margin: 0;
        padding: 0px !important;
      }

      svg {
        margin-right: 8px;
      }

      &:hover {
        background-color: $color-white;
      }
    }
  }

  &__inProgress {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border: 1px solid $color-border-grey;
      border-radius: 8px;
      padding: 24px;
      max-width: 50%;
    }

    &__description {
      @include description;
      color: $color-dark-grey;
      text-align: center;
    }
  }
}

.export_report {
  @include flex-body;
  height: 100%;
  background: $color-primary-3;

  &__container {
    @include flex-body-column;
    gap: 24px;
    padding: 24px;
    background: $color-white;
    margin: auto;
  }

  &__title {
    @include title;
    color: #000;
  }

  &__description {
    @include description;
    color: $color-dark-grey;
  }

  &__info_container {
    @include flex(row, center, space-between);
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;

    &__selection_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      &__label {
        color: $color-grey-text;
        @include description;
      }

      &__text {
        @include subtitle-600;
        color: $color-dark-text;
      }
    }

    &__filters {
      @include flex-body-centered;
      border-radius: var(--Border-radius-xl, 16px);
      background: $color-black;
      padding: 8px 16px;
      gap: 8px;

      &__text {
        @include description;
        color: $color-white;
      }
    }
  }

  &__info_container2 {
    @include flex-body-column;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    gap: 16px;

    &__description {
      @include description;
      color: $color-grey-text;
    }

    &__div {
      @include flex-body-column;
      gap: 24px;
    }

    &__div2 {
      @include flex(row, flex-start, space-between);
      gap: 24px;

      &__label {
        @include subtitle;
        color: $color-grey-19;
      }
    }

    &__div3 {
      @include flex(row, center, flex-start);
      gap: 16px;

      &__label {
        @include subtitle;
        color: $color-grey-19;
      }
    }
  }

  &__select {
    .ant-select-selector {
      width: 146px !important;
      padding: 10px 14px !important;
      align-items: center;
      border-radius: 8px !important;
      border: 1px solid $color-grey-17 !important;
      background: $color-white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    }

    .ant-select-selection-item {
      @include description-16;
      overflow: hidden;
      color: $color-dark-grey !important;
    }
  }

  &__file_format_dropdown {
    padding: 10px 14px;
    border-radius: 4px;

    .ant-select-item-option-content {
      @include description;
      color: $color-black;
    }
  }

  &__download {
    @include flex-body-centered;
    @include description-600;
    padding: 13px 20px;
    gap: 6px;
    color: $color-white;
    border-radius: 4px;
    background: $color-black;
    border: none;
    cursor: pointer;

    &:disabled {
      @include description-600;
      background: $color-unselected;
      color: $color-grey-text;
      cursor: not-allowed;
    }
  }

  .ant-switch-checked {
    background-color: $color-prime;
  }

  .ant-select-dropdown {
    .ant-select-item {
      color: $color-black;

      .ant-select-item-option-content {
        @include description-16;
        display: flex;
        align-items: center;
        overflow: hidden;
        color: $color-grey-text;
        text-overflow: ellipsis;
      }

      .ant-select-item-option-state {
        height: 22px;
        display: flex;
        align-items: center;
      }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: $color-black;
      background-color: $color-primary-3;
    }
  }
}
