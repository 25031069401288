@import 'styles/variables.scss';
@import 'styles/colors.scss';

.flag-stats {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 32px;

  &__numbers {
    min-width: 120px;
  }
  &__chart {
    width: 160px;
    height: 56px;
    margin-bottom: 4px;
    margin-top: 4px;
    box-sizing: border-box;
  }
  &__status {
    display: flex;
    gap: 16px;
    flex-direction: column;
    &__counts {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: $color-grey-900;
    }
    &__trend {
      display: flex;
      align-items: center;
      gap: 8px;
      &__detail {
        display: flex;
        align-items: center;
        gap: 2px;
        svg {
          transform: rotate(180deg);
          width: 20px;
          height: 20px;
          path {
            stroke: #f04438;
          }
        }
        &__percentage {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #b42318;
        }
      }
      .trending {
        svg {
          transform: rotate(0deg);
          path {
            stroke: #17b26a;
          }
        }
        .mention-view-card__status__trend__detail__percentage {
          color: #067647;
        }
      }
      &__duration {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #475467;
      }
    }
  }
}

.all_posts_container {
  display: flex;
  align-items: center;
  .apc_count {
    font-size: 16px;
    line-height: 20px;
  }
  .apc_trending_count {
    font-size: 12px;
    line-height: 20px;
    color: $color-orange;
  }
  .apc_low_icon {
    path {
      stroke: $color-indigo-700;
    }
  }
  &.all_posts_container_down {
    .apc_arrow_icon {
      transform: rotate(180deg);
      path {
        stroke: $color-indigo-700;
      }
    }
    .apc_trending_count {
      color: $color-indigo-700;
    }
  }
}
