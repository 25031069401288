$sizes: (
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  37,
  39,
  40
);

@each $size in $sizes {
  .u-f-s-#{$size} {
    font-size: #{$size}px !important;
  }
}

$fontweights: (300, 400, 500, 600);

@each $weight in $fontweights {
  .u-f-w-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

.u-t-up {
  text-transform: uppercase;
}
