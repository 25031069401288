@import 'styles/colors.scss';

.flag_posts_container {
  display: flex;
  align-items: center;
  .fpc_count {
    font-size: 16px;
    line-height: 20px;
  }
  .fpc_trending_count {
    font-size: 12px;
    line-height: 20px;
    color: $color-orange;
  }
  .fpc_low_icon {
    path {
      stroke: $color-indigo-700;
    }
  }
  &.flag_posts_container_down {
    .fpc_arrow_icon {
      transform: rotate(180deg);
      path {
        stroke: $color-indigo-700;
      }
    }
    .fpc_trending_count {
      color: $color-indigo-700;
    }
  }
}
