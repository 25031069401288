@import 'styles/colors.scss';
@import 'styles/titles.scss';

.powerInsightsContainer {
  margin-top: 16px;
  background-color: $color-white;
  height: calc(100vh - 260px);
  overflow-y: scroll;
  position: relative;

  .tabsMenuWrapper {
    margin-bottom: 20px;
    // padding: 0 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $color-border-grey;

    .tabsMenu {
      max-width: calc(100% - 32px);
      overflow: hidden;

      .menuList {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow-x: auto;

        display: flex;
        gap: 16px;

        .listItem {
          padding: 10px 8px;
          user-select: none;
          cursor: pointer;
          white-space: nowrap;

          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $color-grey-text;

          &:hover {
            border-bottom: 1px solid $color-black;
          }

          &.listItemActive {
            @include tab-selected;
            color: $color-dark-text;
            border-bottom: 1px solid $color-black;
          }
        }
      }
    }

    .rightArrowIcon {
      height: 30px;
      width: 30px;
      cursor: pointer;
      user-select: none;
    }

    .leftArrowIcon {
      height: 30px;
      width: 30px;
      cursor: pointer;
      user-select: none;
      transform: rotate(180deg);
    }
  }
}
