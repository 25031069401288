@import 'styles/colors.scss';
@import 'styles/titles.scss';

.riskWand-table {
  overflow: scroll;

  &__table {
    &__topic_col {
      @include description;
      color: $color-black;
      text-transform: uppercase;
    }
  }

  &__expand-icon {
    cursor: pointer;
  }

  &__expand-row {
    .ant-table-row-expand-icon-cell {
      border: none !important;
    }
  }

  &__dropdown-text {
    @include description;
    color: $color-black;
    cursor: pointer;
  }

  .ant-table {
    padding: 0 30px !important;
  }

  .ant-table-thead > tr > th {
    @include heading;
    background-color: $color-unselected;
    color: $color-grey-text;
    text-transform: uppercase;
    padding: 14px 24px;
    border-bottom: none;
  }

  .ant-table-tbody > tr > td {
    @include description;
    border-bottom: 1px solid $color-border-grey;
    padding: 24px 24px;
    background-color: $color-white;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    background-color: transparent;
  }

  .ant-table-expanded-row > td {
    padding: 0px 30px 10px 33px !important;
    border-bottom: none !important;
  }

  .ant-table-cell-row-hover {
    background: $color-white !important;
  }
}
