@import 'styles/colors.scss';

.text_area_container {
  width: 100%;
  .tac_title {
    color: $color-grey-19;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
  }
  .tac_textarea {
    width: 100%;
    height: 154px;
    outline: none;
    border-radius: 8px;
    padding: 12px 14px;
    background-color: $color-white;
    border: 1px solid $color-grey-17;
    color: $color-dark-text;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    resize: none;
    &::placeholder {
      color: $color-dark-grey;
    }
  }
}
