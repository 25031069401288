@import 'styles/titles.scss';
@import 'styles/colors.scss';

.c-define-narrative-unique-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 104px;
  margin: 8px 0;
  box-sizing: border-box;
  overflow: scroll;

  &:hover {
    border: 1px solid $color-border-white;
    border-radius: 5px;
  }

  &__image {
    width: 130px;
    height: 102px;
    margin-right: 16px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  &__text-title {
    @include body;
    color: $color-black;
    margin-bottom: 4px;
  }

  &__text-website {
    @include simple;
    color: $color-grey-text;
    margin-bottom: 4px;
  }

  &__text-website-link {
    @include simple;
    color: #948f87;
    margin-bottom: 0;
  }
}

.c-define-advanced-modal__publish-menu {
  border: 0;
}
