@import 'styles/colors.scss';
@import 'styles/titles.scss';

.componentWrapper {
  :global {
    .recharts-responsive-container {
      margin-top: 56px;
      height: 350px !important;
    }
  }

  &:has(.wrapperTitle) {
    margin: 0px;
  }

  .wrapperHeaderText {
    gap: 14px;
    display: flex;
    flex-direction: column;

    &:has(.corporateUser) {
      padding-bottom: 24px;
      border-bottom: 1px solid $color-border-grey-2;
    }
  }

  .wrapperTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }

  .description {
    @include description;
    color: $color-dark-grey;
  }

  margin-top: 24px;

  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chartFilters {
      display: flex;
      gap: 32px;

      .pivot,
      .metric {
        display: flex;
        align-items: center;
        gap: 8px;

        > span {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;

          user-select: none;
          color: $color-grey-text;
        }

        .btnFilter {
          height: unset;
          display: flex;
          align-items: center;
          gap: 4px;

          padding: 2px 6px;

          font-size: 12px;
          font-weight: 400;
          line-height: 20px;

          color: $color-dark-text;
          background-color: transparent;

          filter: drop-shadow(6px 2px 10px rgba(0, 0, 0, 0.12));
          border-radius: 4px;
          border: 0;

          svg {
            width: 16px;
            height: 16px;
          }

          span {
            color: $color-dark-text;
          }

          &.active {
            background-color: $color-black;
            color: white;

            span {
              color: white;
            }
          }
        }

        :global {
          .ant-btn-group {
            gap: 4px;
            padding: 2px 4px;
            background: $color-grey-scale-100;
            border: 1px solid $color-border-grey;
            border-radius: 6px;
          }
        }
      }
    }

    .bellIcon {
      cursor: pointer;
    }
  }

  .tooltipLabelContainer {
    display: flex;
    padding: 5px;
    background-color: white;

    .labelText {
      margin: auto;
      font-size: 14px;
    }
  }

  .chartLegends {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 20px;
    user-select: none;
    margin-top: 32px;

    .legend {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;

      &.clickable {
        cursor: pointer;
      }

      .legendIcon {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      .legendLabel {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $color-grey-8;

        &.strikeThroughText {
          color: $color-dark-grey;
        }
      }

      .strikeThroughLine {
        position: absolute;
        right: -4px;
        left: -4px;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid $color-dark-grey;
      }
    }
  }
}
