@import 'styles/titles';
@import 'styles/colors';

.agreement-admin-modal {
  &__header {
    @include flex-body;
    gap: 24px;
    margin-top: 8px;

    &__title {
      @include title;
      color: $color-grey-900;
      text-align: left;
    }

    &__icon {
      @include flex-body-centered;
      padding: 12px;
      border-radius: 50%;
      border: 8px solid #fffaeb;
      background: #fef0c7;
    }
  }

  &__body {
    @include flex-body-column;
    gap: 24px;
  }

  &__description {
    @include description;
    color: $color-dark-text;
  }

  &__container {
    @include flex-body;
    gap: 16px;
  }

  &__info-container {
    @include flex(row, center, space-between);
    width: 100%;
  }

  &__admin-info {
    @include flex-body-column;
    gap: 6px;

    &--name {
      @include subtitle;
      color: $color-grey-23;
    }

    &--email {
      @include description;
      color: $color-dark-grey;
    }

    &--img {
      @include flex-body;
      min-height: 32px;
      min-width: 32px;
      border-radius: 50%;
      overflow: hidden;
      justify-content: center;
      margin-right: 8px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__copy-button {
    @include flex-body-centered;
    cursor: pointer;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 6px;
    background: $color-unselected;

    &--text {
      &,
      &:focus,
      &:active {
        @include subtitle;
        color: $color-black;
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
        cursor: pointer;
        padding: 0;
      }
    }
  }

  &__footer {
    &__button {
      @include subtitle;
      width: 100%;
      cursor: pointer;
      padding: 10px 16px;
      gap: 8px;
      border-radius: 6px;
      background: linear-gradient(180deg, #16191d 0%, rgba(22, 25, 29, 0.9) 100%);
      color: $color-white;
      border: none;
    }
  }

  .ant-modal-content {
    @include flex-body-column;
    padding: 24px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    gap: 24px;
  }

  .ant-modal-header {
    padding: 0px;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-modal-body {
    @include flex(column, center, flex-start);
  }

  .ant-modal-footer {
    @include flex-body-centered;
    padding: 0px;
    align-self: stretch;
  }
}
