@import 'styles/colors.scss';
.detail_view_history_container {
  //   background-color: gold;
  display: flex;
  gap: 8px;
  .dvhc_header {
    .dvhc_c_header_text {
      color: $color-dark-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}
