@import 'styles/titles.scss';
@import 'styles/colors.scss';

.threats-carousel {
  @include flex-body;
  padding: 0 104px 0 144px;
  height: max-content;
  width: 100%;

  &__container {
    &__left {
      @include flex(column, flex-start, flex-start);
      flex: 1;

      &__title {
        @include medium-title;
        color: $color-black;
        margin-bottom: 24px;
      }

      &__description {
        @include title-300;
        color: $color-black;
        margin-bottom: 50px;
      }
    }

    &__right {
      @include flex(column, center, center);
      flex: 1;

      &__margin {
        margin-bottom: 32px;
      }

      &__description {
        @include small-title-300;

        &--italic {
          @extend .threats-carousel__container__right__description;
          font-style: italic;
        }
      }
    }
  }
}
