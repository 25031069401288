@import 'styles/colors.scss';
@import 'styles/titles.scss';

.tenant-configuration-edit-modal {
  float: right;
  top: 0;
  padding: 0;

  &__title {
    width: 100%;
    @include title;
    text-align: start;
    color: $color-dark-text;
  }

  &__body {
    @include flex-body-column;
    gap: 32px;
    height: 100%;
  }

  &__editor {
    @include flex(column, stretch, space-between);
    background-color: #16181d;
    height: 85%;

    &__body {
      @include flex(row, stretch, flex-start);
      padding: 24px;
      padding-right: 0;
      gap: 32px;
      flex: 2;
      overflow: scroll;

      &__leftbar {
        @include flex(column, stretch, space-around);
        @include small-bold-title;
        color: rgba(255, 255, 255, 0.8);
      }

      &__key {
        @include small-bold-title;
        color: #81d4fa;
      }

      &__value {
        @include small-bold-title;
        color: rgba(255, 255, 255, 0.8);
      }

      &__edit-div {
        @include flex-body-column;
        gap: 12px;
        width: 100%;
        overflow: scroll;
      }

      &__error {
        @include description;
        margin-bottom: -20px;
        color: #ff4d4f;
        display: none;

        &--hide {
          display: block;
        }
      }

      &__code-div {
        width: 88%;
        height: 100%;

        code {
          border: none;
          outline: none;
          color: rgba(255, 255, 255, 0.8);

          pre {
            white-space: pre-wrap;
          }
        }
      }

      .ant-input {
        background-color: transparent;
        border: none;
        color: rgba(255, 255, 255, 0.8);

        &:focus {
          box-shadow: none;
          border: 1px solid $color-border-grey;
        }

        &:active {
          border: 1px solid $color-border-grey;
        }
      }
    }

    &__footer {
      @include flex(row, center, flex-end);
      @include tag;
      padding: 0 16px;
      background-color: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.4);
      gap: 16px;
      position: sticky;
    }
  }

  &__footer {
    @include flex-body-centered;
    gap: 10px;
  }

  &__cancel-btn {
    @include subtitle;
    background-color: transparent;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-black;
    cursor: pointer;
  }

  &__save-btn {
    @include subtitle;
    background-color: $color-black;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-white;
    cursor: pointer;

    &:disabled {
      @include subtitle;
      background: $color-grey-text;
      cursor: not-allowed;
      border: none;
    }
  }

  &__permissions {
    @include flex-body;
    gap: 16px;

    &__switch-div {
      @include flex-body;
      gap: 6px;

      &__label {
        @include subtitle;
        color: $color-dark-grey;
      }
    }

    &__switch {
      @include flex-body;
      gap: 24px;
    }

    &__label {
      @include subtitle-15;
      color: $color-dark-grey;
    }
  }

  .ant-switch {
    @include flex-body-centered;
    background-color: $color-disabled-grey;
    min-width: 32px;
    height: 20px;
  }

  .ant-switch-handle {
    width: 14px;
    height: 14px;
  }

  .ant-switch-checked {
    background-color: $color-prime !important;
  }

  .ant-switch-disabled {
    opacity: 1;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 15px);
  }

  .ant-modal-content {
    padding: 24px;
    gap: 32px;
  }
}
