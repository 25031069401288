@import 'styles/colors.scss';
.button_container {
  border: none;
  outline: none;
  padding: 6px 14px;
  background-color: $color-black;
  color: $color-white;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  &:disabled {
    background-color: $color-disabled-grey;
    cursor: default;
  }
}
