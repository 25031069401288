@import 'styles/colors.scss';
@import 'styles/titles.scss';

.tagrender {
  margin-right: 3;
  border-radius: 4px;
  color: black;
  :global(.anticon) {
    color: black;
  }
}
