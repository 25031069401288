@font-face {
  font-family: 'Hknova';
  src: url('./assets/fonts/HKNova-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Hknova';
  src: url('./assets/fonts/HKNova-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Hknova';
  src: url('./assets/fonts/HKNova-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Hknova';
  src: url('./assets/fonts/HKNova-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

body {
  font-family: 'Hknova', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  background-color: #f5f5f5;
}

.content-container {
  position: relative;
  max-height: 100vh;
  /* overflow: auto; */
  min-height: 100vh;
  background-color: #ffffff;
}

.content-wrapper {
  flex: 1;
  background-color: #ffffff;
  overflow: auto;
}

.left-margin {
  margin-left: 68px;
}

.d-flex {
  display: flex;
}
