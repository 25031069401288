@import 'styles/colors.scss';
@import 'styles/titles';

.loading-overlay {
  display: flex;
  width: 100%;
  padding: 40px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .header {
    color: var(--Black, $color-black);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    margin-bottom: unset;
  }

  .body {
    color: $color-dark-text;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    width: 486px;
    margin-bottom: unset;
  }
}
