@import 'styles/colors.scss';
@import 'styles/variables.scss';
.dropdown_container {
  position: relative;
  // background-color: $color-white;
  padding: 5px;
  width: fit-content;
  border-radius: 4px;
  user-select: none;
  .dc_value_container {
    display: flex;
    align-items: center;
    width: 105px;
    justify-content: space-between;
    gap: 8px;
    .value_text {
      color: $color-dark-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
    }
    .dropdown_icon {
      cursor: pointer;
      :global path {
        stroke: $color-grey-text;
      }
    }
  }
  .dc_content_container {
    position: absolute;
    top: 42px;
    right: 0;
    padding: 15px;
    width: 260px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    background-color: $color-white;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
    z-index: 1;
    .lc_l_header_container {
      display: flex;
      align-items: center;
      gap: 8px;
      .hc_text {
        color: $color-dark-grey;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
      }
    }
    .cc_desc {
      color: $color-dark-grey;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 16px;
      margin-top: 12px;
    }
    .cc_button_container {
      display: flex;
      justify-content: flex-end;
      .cc_button {
        border-radius: 32px;
      }
    }
  }
}
