@import 'styles/colors.scss';

.flags_header_container {
  .fhc_inner_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 40px;
    .fhc_search_container {
      display: flex;
      align-items: center;
      gap: 16px;
      .fhc_sc_input_container {
        gap: 8px;
        height: 40px;
      }
      .fhc_sc_button {
        height: 40px;
        background-color: $color-unselected;
        border-radius: 6px;
        padding: 10px 16px;
        color: $color-dark-grey;
      }
    }
    .fhc_ic_action_buttons {
      display: flex;
      align-items: center;
      .fhc_ic_ab_button {
        height: 36px;
        padding: 8px 10px;
        background-color: $color-primary-3;
        color: $color-dark-grey;
        border: 1px solid $color-primary-10;
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:nth-child(2) {
          border-radius: 0;
          border-right: 0;
          border-left: 0;
        }
        &:nth-child(3) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          .fhc_ic_ab_b_switch {
            background-color: $color-grey-18;
            min-width: 36px;
            height: 20px;

            .ant-switch-handle {
              width: 16px;
              height: 16px;
              &::before {
                border-radius: 10px;
              }
            }
            &.ant-switch-checked {
              background-color: $color-prime;
              &:hover {
                background-color: $color-primary-300_1;
              }
              .ant-switch-handle {
                left: calc(100% - 16px - 2px);
              }
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
        &:last-child {
          border: none;
          background: none;
          color: $color-primary-11;
          gap: 2px;
        }
      }
    }
  }
}
