@import 'styles/colors.scss';

.search_term_section_container {
  .stsc_copilot_text_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;

    .stsc_ctc_inner_container {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      user-select: none;

      .stsc_ctc_ic_text {
        color: $color-prime;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0;
      }

      .stsc_ctc_ic_info_icon {
        cursor: pointer;
      }
    }
  }

  .stsc_text_area_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    background-color: $color-background;
    padding: 10px 16px;
    height: 101px;

    .stsc_tac_heading {
      display: flex;
      align-items: center;
      gap: 2px;

      .stsc_tac_heading_text {
        color: $color-black;
        font-size: 12px;
        font-weight: 600;
        line-height: 13px;
        margin-bottom: 0;
      }

      .stsc_tac_ht_info_icon {
        align-self: baseline;
        cursor: pointer;
      }
    }

    .stsc_tac_ht_textarea {
      flex-grow: 1;

      .tac_textarea {
        height: 100%;
        border: none;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        color: $color-black;

        &::placeholder {
          color: $color-grey-text;
        }
      }
    }
  }

  .stsc_button {
    padding: 10px 39px;
    height: 40px;
    border-radius: 6px;
    background-color: $color-unselected;
    color: $color-dark-text;
    margin-block: 16px;

    &:disabled {
      color: $color-grey-text;

      svg {
        path {
          stroke: $color-grey-text;
        }
      }
    }
  }

  .stsc_test_button {
    padding: 10px 16px;
    height: 40px;
    border-radius: 6px;
    // background-color: $color-unselected;
    // color: $color-dark-text;
    margin-top: 16px;
  }
}

.ant-tooltip-zindex {
  z-index: 9999;
}
