@import 'styles/colors.scss';
@import 'styles/titles.scss';

.add-user {
  &__title {
    width: 100%;
    @include title;
    text-align: start;
    color: $color-dark-text;
  }

  &__body {
    @include flex-body-column;
    gap: 24px;

    &__container {
      @include flex-body-column;
      gap: 16px;
    }

    &__role {
      &__option {
        @include description;
        text-transform: capitalize;
        color: $color-dark-grey;
      }

      &__icon {
        path {
          stroke: $color-grey-text;
        }
      }

      &__dropdown {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

        .ant-select-item-option-content {
          span {
            color: $color-dark-text;
          }
        }
      }
    }

    &__notify-permission {
      @include description;
      color: $color-dark-grey;
    }

    .ant-form-item-explain-error {
      white-space: pre-line;
    }
  }

  &__footer {
    display: flex;
    gap: 8px;

    &__cancel {
      padding: 7px 16px;
      border-radius: 4px;
      border: 1px solid $color-black;
      background: $color-white;
      cursor: pointer;
    }

    &__add {
      padding: 7px 16px;
      border-radius: 4px;
      background: $color-black;
      border: none;
      color: $color-white;
      cursor: pointer;
    }
  }
}
