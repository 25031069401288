@import 'styles/colors.scss';
@import 'styles/titles.scss';

.card {
  @include flex(column, flex-start, flex-start);
  flex: 0 0 23%;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid $color-border-grey;
  background-color: $color-background;

  &--0 {
    padding: 16px;
  }

  &--1 {
    padding: 20px 24px;
  }

  &__body {
    @include flex(column, stretch, flex-start);
    width: 100%;
  }

  &__container {
    @include flex(row, stretch, flex-start);
    flex-wrap: wrap;

    &--0 {
      gap: 7px;
    }

    &--1 {
      gap: 20px;
    }
  }

  &__header {
    @include flex(row, flex-start, flex-end);
    width: 100%;

    &__title {
      @include subtitle-16;
      color: $color-dark-text;
      text-transform: capitalize;
      flex: 2;
    }

    &__icon {
      visibility: hidden;
      cursor: pointer;
    }
  }

  &:hover {
    .card__header__icon {
      visibility: visible;
    }
  }
}
