@import 'styles/colors.scss';

.detail_view_header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px 16px;
  border-bottom: 1px solid $color-border-grey;
  background-color: $color-white;
  .dvhc_left_side {
    display: flex;
    align-items: center;
    gap: 16px;
    .ls_back_icon {
      cursor: pointer;
    }
    .ls_report_heading {
      color: $color-black;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }
    .ls_report_icon {
      flex-shrink: 0;
      :global {
        g {
          path {
            fill: $color-prime;
          }
        }
      }
    }
    .ls_report_read_text {
      color: $color-dark-grey;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }
  }
  .dvhc_right_side {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    .rs_button {
      background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);
      border-width: 1px, 1px, 0px, 1px;
      border: 1px solid $color-primary-3;
      color: white;
      border-radius: 6px;
      svg {
        width: 12px;
        height: 12px;
        path {
          stroke: white;
        }
      }
    }
  }
}
