@import 'styles/variables.scss';

.c-workbench-frame {
  .hidden {
    display: none;
  }
  .c-workbench-alert {
    text-align: center;
  }
  #quicksight-workbench-container {
    .quicksight-embedding-iframe {
      width: 100%;
    }
  }
}
