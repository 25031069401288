@import 'styles/colors.scss';

.flags_categories_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 40px;
  border-bottom: 1px solid $color-border-grey;

  .fcc_list {
    display: flex;
    align-items: center;
    gap: 4px;

    .fcc_l_button {
      padding: 8px 12px;
      border-radius: 6px;
      height: 36px;
      background: none;
      font-weight: 600;
      color: $color-dark-grey;

      &.fcc_l_button_active {
        background-color: $color-primary-6;
        color: $color-primary-300_1;
      }
    }
  }

  .fcc_add_button {
    padding: 6px 16px;
    border-radius: 8px;
    height: 36px;
    background: none;
    font-weight: 600;
    background-color: $color-primary-3;
    color: $color-primary-11;
    gap: 4px;
    cursor: not-allowed !important;

    &:hover {
      background-color: $color-primary-7;
    }

    &:disabled {
      background-color: $color-primary-3;
      opacity: 0.7;
    }
  }
}
