@import 'styles/colors.scss';
@import 'styles/titles.scss';

.influencer-list-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.influencers-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 32px;
}

.influencer-table {
  margin-top: 16px;
  .header {
    height: 44px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: $color-border-grey-2;
    display: flex;
    align-items: center;
    background-color: $color-grey-20;
    padding: 12px 24px;
    box-sizing: border-box;
  }
  .item {
    border-bottom: 1px solid;
    border-color: $color-border-grey-2;
    height: 72px;
    padding: 12px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .info-wrapper {
    display: flex;
    gap: 16px;

    .avatar {
      min-width: 40px;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 20px;
    .name {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: $platform-brand-color-tiktok;
    }
    .count {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: $color-dark-grey;
    }
  }
  .watchlist {
    width: 250px;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-grey;
    max-height: 250px;
    overflow-x: auto;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: $color-dark-grey;
  }

  .skeleton-paragraph .ant-skeleton-paragraph {
    height: 20px;
    line-height: 20px;
    overflow: hidden;
  }

  .skeleton-paragraph-small .ant-skeleton-paragraph {
    height: 10px;
    line-height: 10px;
    overflow: hidden;
  }

  &__table {
    min-width: 900px;
    overflow-x: auto;
    &__checkbox {
      @include flex-body-centered;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      border: 1px solid $color-grey-17;
      cursor: pointer;

      &__selected {
        background-color: $color-prime;
        border-color: $color-prime;
      }
    }

    &__title {
      @include flex-body;
      gap: 4px;
    }

    &__sortIcon {
      transform: rotate(180deg);
    }

    &__selectedRow {
      background-color: $color-primary-3;
    }

    thead > tr > .ant-table-cell {
      @include info;
      padding: 12px 24px;
      color: $color-dark-grey;
      border: none;
      border-top: 1px solid $color-border-grey-2;
      border-bottom: 1px solid $color-border-grey-2;
      background: $color-grey-20;
      height: 44px;

      &::before {
        width: 0 !important;
      }
    }

    tbody > tr > .ant-table-cell {
      padding: 12px 24px;
      height: 72px;
    }

    tbody > tr > .ant-table-cell-row-hover {
      background: none !important;
    }
  }
}

.try-again-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-dark-grey;
}

.try-again {
  margin-left: 4px;
  font-weight: 500;
  color: $color-black;
  cursor: pointer;
}
