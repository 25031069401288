@import 'styles/colors.scss';

.vdsc_spinner_wrapper {
  max-width: 40%;
  min-width: 579px;
  flex-grow: 1;

  .vdsc_spinner {
    max-height: 100% !important;
  }

  .ant-spin-container {
    width: 100%;
  }
}

.vector_detail_section_container {
  width: 100%;
  height: calc(100dvh - 62px);
  overflow-y: auto;

  .vdsc_top_container {
    padding: 32px;

    .vdsc_type_text {
      width: fit-content;
      padding: 4px 12px;
      border-radius: 4px;
      background-color: $color-light-pink;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: $color-pink-700;
      text-align: center;

      &.vdsc_type_text_communities {
        background-color: $color-orange-50;
        color: $color-orange-700;
      }

      &.vdsc_type_text_watchlists {
        background-color: $color-purple-50;
        color: $color-purple-700;
      }
    }

    .vdsc_heading {
      color: $color-dark-text;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 16px;
      margin-bottom: 0;
    }

    .vdsc_tc_buttons_container {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .vdsc_tc_bc_button {
        flex-grow: 1;
        padding: 8px 14px;
        height: 36px;
        gap: 4px;
        justify-content: center;
        border-radius: 0;
        background-color: $color-white;
        border: 1px solid $color-border-grey;
        color: $color-grey-19;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-right: 0;

          svg {
            path {
              stroke: $color-dark-grey;
            }
          }
        }

        &:nth-child(2) {
          svg {
            path {
              stroke: $color-dark-grey;
            }
          }
        }

        &:nth-child(3) {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-left: 0;

          svg {
            path {
              stroke: $color-dark-grey;
            }
          }
        }

        &:last-child {
          background: linear-gradient(0deg, #16191d 0%, rgba(22, 25, 29, 0) 608.33%);
          padding: 10px 38px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          margin-left: 16px;
          color: $color-white;

          svg {
            path {
              stroke: $color-white;
            }
          }
        }

        &:disabled {
          opacity: 0.7;
        }

        &.vdsc_tc_bc_button_open {
          background: $color-primary-200_1;
          color: $color-grey-scale-800;
          text-transform: capitalize;
        }

        &.single_button {
          flex-grow: 0;
          margin-left: 0;
        }
      }
    }

    // ..........................
    .vdsc_tc_count_container {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 24px;
      background-color: $color-grey-20;

      .vdsc_tc_cc_chunk {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 13px 16px;
        // width: 50%;
        width: 100%;

        .vdsc_tc_cc_c_count {
          color: $color-dark-text;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          margin-bottom: 0;
        }

        .vdsc_tc_cc_c_text {
          color: $color-dark-grey;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
    }

    // .....................
    .vdsc_tc_desc_container {
      margin-top: 24px;

      .vdsc_tc_dc_heading {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
      }

      .vdsc_tc_dc_text {
        color: $color-dark-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    // .....................
    .vdsc_tc_lang_container {
      margin-top: 24px;

      .vdsc_tc_lc_heading {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
      }

      .vdsc_tc_lc_lists {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 8px;

        .vdsc_tc_lc_l_chunk_text {
          color: $color-grey-19;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          padding: 2px 6px;
          border-radius: 6px;
          border: 1px solid $color-border-grey-2;
          background-color: $color-grey-20;
          margin-bottom: 0;
        }
      }
    }

    // .......................
    .vdsc_tc_assets_container {
      margin-top: 24px;

      .vdsc_tc_ac_heading {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
      }

      .vdsc_tc_ac_lists {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        margin-top: 8px;

        .vdsc_tc_ac_l_chunk {
          // cursor: pointer;
          padding: 8px;
          background-color: $color-primary-6;
          border-radius: 4px;
          width: calc(25% - 4px);

          &.vdsc_tc_ac_l_chunk_low_risk {
            background-color: $color-unselected;

            .vdsc_tc_ac_lc_bottom {
              .vdsc_tc_ac_lcb_text {
                .vdsc_tc_ac_lcb_t_part {
                  font-size: 12px;
                  color: $color-indigo-700;

                  .vdsc_tc_ac_lcb_tp_icon {
                    path {
                      stroke: $color-indigo-700;
                    }
                  }
                }
              }
            }

            .vdsc_tc_ac_lc_title {
              color: $color-grey-text;
            }
          }

          &.vdsc_tc_ac_l_chunk_disabled {
            background-color: $color-unselected;

            .vdsc_tc_ac_lc_bottom {
              .vdsc_tc_ac_lcb_text {
                color: $color-grey-text;
              }

              .vdsc_tc_ac_lch_icon {
                path {
                  fill: $color-grey-text;
                }
              }
            }

            .vdsc_tc_ac_lc_title {
              color: $color-grey-text;
            }
          }

          .vdsc_tc_ac_lc_title {
            color: $color-dark-grey;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
          }

          .vdsc_tc_ac_lc_bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .vdsc_tc_ac_lcb_text {
              color: $color-dark-text;
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 0;

              .vdsc_tc_ac_lcb_t_part {
                font-size: 12px;
                color: $color-orange;
              }
            }
          }
        }
      }
    }

    // .........................
    .vdsc_tc_stats_container {
      margin-top: 24px;

      .vdsc_tc_sc_heading {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
      }

      .vdsc_tc_sc_area_chart_container {
        width: 515px;
        height: 188px;
        padding: 0;
        margin-top: 8px;

        .acsc_buttons_container {
          margin-bottom: 16px;
        }
      }
    }

    // .........................
    .vdsc_tc_snippets_container {
      margin-top: 24px;

      .vdsc_tc_sc_heading {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
      }
    }
  }
}
