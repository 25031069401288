@import 'styles/colors.scss';

.cfm_container {
  .cfm_box_container {
    min-width: 529px;
    min-height: 278px;
    width: 529px;
    padding: 24px 0px;
    border-radius: 16px;
    .cmc_cc_header_container {
      padding: 0px 32px 16px;
      border-bottom: 1px solid $color-pastel-blue;
      .cmc_cc_hc_left_container {
        gap: 16px;
        .cmc_cc_hc_lc_icon {
          display: none;
        }
        .cmc_cc_hc_lc_heading {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: $color-grey-900;
        }
      }
    }

    .cmc_cc_footer_container {
      padding: 16px 24px 0;
      border-top: 1px solid $color-border-grey-2;
      .cmc_cc_fc_button {
        border-radius: 6px;
      }
    }
  }
}

.cfm_body_container {
  display: flex;
  .cfm_modal_body_container {
    padding: 32px;
    flex: 1;
  }
}
