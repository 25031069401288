@import 'styles/colors.scss';

.folder_panel_container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $color-border-grey-2;
  background-color: $color-grey-20;
  height: 100%;
  width: 250px;
  .fpc_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-border-grey-2;
    padding: 11px 16px 11px 24px;
    .fpc_h_text {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0;
      color: $color-dark-text;
    }
    .fpc_h_icon {
      cursor: pointer;
    }
  }
  .fpc_list_container {
    margin-top: 16px;
    flex-grow: 1;
    overflow-y: auto;
  }
  .fpc_footer {
    padding: 24px;

    .fpc_f_button {
      height: 32px;
      padding: 10px 16px;
      background-color: $color-white;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      border: 1px solid $color-dark-grey;
      color: $color-dark-grey;
      gap: 6px;
      .fpc_f_b_icon {
        path {
          stroke: $color-dark-grey;
        }
      }
    }
  }
}

.fpc_lc_chunk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  padding: 12px 16px 12px 24px;
  border-top: 1px solid $color-grey-20;
  border-bottom: 1px solid $color-grey-20;
  user-select: none;
  cursor: pointer;
  &.fpc_lc_chunk_has_checkbox {
    padding-inline: 0;
    cursor: default;
    &:hover {
      background-color: $color-white;
      border-top: 1px solid $color-grey-20;
      border-bottom: 1px solid $color-grey-20;
      .fpc_lc_chunk_left {
        .fpc_lc_c_icon {
          path {
            stroke: $color-dark-grey;
          }
        }
        .fpc_lc_c_text {
          color: $color-dark-grey;
        }
      }
    }
  }
  .fpc_lc_chunk_left {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    .fpc_lc_c_icon {
      flex-shrink: 0;
    }
    .fpc_lc_cl_checkbox_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      border: 1px solid $color-grey-17;
      cursor: pointer;
      margin-right: 16px;
      flex-shrink: 0;
      &.fpc_lc_cl_checkbox_container_disabled {
        pointer-events: none;
      }
      &.fpc_lc_cl_checkbox_container_selected {
        background-color: $color-prime;
        border-color: $color-prime;
      }
      .lvsc_th_cc_line {
        height: 2px;
        width: 10px;
        border-radius: 10px;
        background-color: $color-white;
      }
    }
    .fpc_lc_c_text {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: $color-dark-grey;
      margin-bottom: 0;
    }
    .fpc_lc_c_input {
      outline: none;
      border-radius: 4px;
      border: 1px solid $color-grey-17;
      background-color: $color-grey-18;
      color: $color-dark-grey;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 2px 8px;
      width: 100%;
    }
  }
  &:hover {
    background-color: $color-grey-scale-300;
    border-top: 1px solid $color-grey-scale-300;
    border-bottom: 1px solid $color-grey-scale-300;
    .fpc_lc_chunk_left {
      .fpc_lc_c_icon {
        path {
          stroke: $color-black-hover;
        }
      }
      .fpc_lc_c_text {
        color: $color-black-hover;
      }
    }
  }
  &.fpc_lc_chunk_active {
    border-top: 1px solid $color-primary-10;
    border-bottom: 1px solid $color-primary-10;
    background-color: $color-primary-3;
    .fpc_lc_chunk_left {
      .fpc_lc_c_icon {
        path {
          stroke: $color-primary-11;
        }
      }
      .fpc_lc_c_text {
        color: $color-primary-11;
      }
    }
  }

  .fpc_lc_chunk_dropdown_icon {
    flex-shrink: 0;
    cursor: pointer;
  }
  .fpc_lc_chunk_dropdown_container {
    display: flex;
    flex-direction: column;
    gap: 1px;
    position: absolute;
    top: calc(100% - 8px);
    right: 11px;
    width: 135px;
    padding: 4px 6px;
    border-radius: 8px;
    z-index: 1;
    border: 1px solid $color-border-grey-2;
    background-color: $color-white;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    .fpc_lc_chunk_dc_button {
      padding: 11px 10px;
      height: 38px;
      background-color: $color-white;
      color: $color-grey-19;
      &:hover {
        background-color: $color-grey-scale-200;
      }
    }
  }
}
