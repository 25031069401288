@import 'styles/colors.scss';
@import 'styles/titles.scss';

.narrative-info-modal {
  @include flex-body-column;
  background-color: $color-white;
  padding: 24px 32px 24px 40px;
  height: 100%;
  width: 500px;
  gap: 32px;
  border-right: 1px solid $color-border-grey;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

  &__header {
    @include flex(row, center, space-between);

    svg {
      cursor: pointer;
    }
  }

  &__title {
    @include title;
    color: $color-dark-text;
  }

  &__body {
    @include flex-body-column;
    gap: 24px;

    &__inner-container {
      @include flex-body-column;
      gap: 8px;
    }

    &__sub-title {
      @include description;
      color: $color-grey-text;
    }

    &__info-text {
      @include description;
      color: $color-dark-text;
    }

    &__tags {
      &__container {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
      }

      &__tile {
        @include flex-body-centered;
        @include description;
        padding: 4px 10px;
        border-radius: 4px;
        user-select: none;
        color: $color-dark-text;

        &--color0 {
          @extend .narrative-info-modal__body__tags__tile;
          background: $color-pastel-blue;
        }

        &--color1 {
          @extend .narrative-info-modal__body__tags__tile;
          background: $color-pastel-green;
        }

        &--color2 {
          @extend .narrative-info-modal__body__tags__tile;
          background: $color-pastel-yellow;
        }

        &--color3 {
          @extend .narrative-info-modal__body__tags__tile;
          background: $color-pastel-pink;
        }

        &--color4 {
          @extend .narrative-info-modal__body__tags__tile;
          background: $color-pastel-coral;
        }
      }
    }
  }
}

.narrative-creator-info {
  @include flex-body;
  gap: 8px;

  &__container {
    @include flex-body-column;
  }

  &__name {
    @include description;
    color: $color-dark-text;
  }

  &__date {
    @include footnote;
    color: $color-grey-text;
  }

  &__avatar {
    object-fit: cover;
    width: 24px;
    height: 24px;
  }
}

.narrative-keyword-info {
  @include flex-body-column;
  width: 100%;
  gap: 0.2px;

  &__nav-container {
    @include flex-body;
    width: 100%;
    padding-left: 12px;

    svg {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &__languagesBar {
    @include flex-body;
    width: 100%;
    overflow: hidden;
    gap: 8px;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &__title {
    @include description;
    padding: 4px 8px;
    color: $color-grey-text;
    background-color: transparent;
    outline: none;
    border: none;

    &--active {
      @include subtitle;
      color: $color-dark-text !important;
      border-bottom: 1.2px solid $color-dark-text !important;
    }

    &,
    &:focus,
    &:active {
      background-color: transparent !important;
      outline: none !important;
      border: none;
      cursor: pointer;
      margin: 0;
      color: $color-grey-text;
      display: flex;
      align-items: center;
    }
  }

  &__text {
    &--ML {
      @include description;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 16px;
      background: rgba(168, 75, 211, 0.1);
      border: 1px solid rgba(168, 75, 211, 0.6);
      border-radius: 4px;
      color: $color-purple;
      min-height: 80px;
    }

    &--others {
      @include description;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 16px;
      gap: 8px;
      background: $color-background;
      border: 1px solid $color-border-grey;
      border-radius: 4px;
      color: $color-grey-8;
      min-height: 80px;
    }
  }
}

.narrative-saved-links {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 104px;
  box-sizing: border-box;
  overflow: scroll;
  border: 1px solid $color-border-white;
  border-radius: 5px;
  gap: 12px;

  &__image {
    width: 130px;
    height: 102px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    gap: 4px;

    p {
      margin: 0;
    }
  }

  &__text-title {
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }

  &__text-website {
    font-size: 14px;
    line-height: 16px;
    color: $color-grey-text;
  }

  &__text-website-link {
    font-size: 14px;
    line-height: 16px;
    color: #948f87;
  }
}
