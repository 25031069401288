@import 'styles/colors.scss';

.dvca_container {
  margin-top: 16px;
  user-select: none;
  background-color: white;
  border: 1px solid $color-border-grey-2;
  padding: 12px 24px;
  border-radius: 8px;
  .dvca_c_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .dvca_c_header_left_container {
      display: flex;
      align-items: center;
      gap: 12px;
      .dvca_c_header_text {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $color-grey-600;
        margin: 0;
      }
      .dvca_c_header_icons_container {
        display: flex;
        align-items: center;
        gap: 10px;
        .dvca_c_h_ic_icon_chunk {
          position: relative;
          display: flex;
          align-items: center;
          gap: 2px;
          .icon_chunk_text {
            color: $color-dark-grey;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            margin: 0;
          }

          &::before {
            display: none;
            content: attr(data-tooltip);
            width: max-content;
            position: absolute;
            border-radius: 4px;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            background-color: $color-black;
            padding: 8px;
            color: $color-white;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            z-index: 1;
          }
          &::after {
            display: none;
            content: '';
            position: absolute;
            top: calc(100% - 2px);
            left: 50%;
            transform: translateX(-50%);
            border-width: 6px;
            border-style: solid;
            border-color: transparent transparent $color-black transparent;
          }

          &:hover {
            .icon_chunk_text {
              color: $color-dark-text;
            }
            .icon_chunk_icon {
              path {
                fill: $color-prime;
              }
              ellipse {
                fill: $color-prime;
              }
            }
            &::before,
            &::after {
              display: block;
            }
          }
          &:nth-child(2):hover {
            .icon_chunk_icon {
              path {
                stroke: $color-prime;
              }
            }
          }
        }
      }
      &.dvca_c_header_left_container_expand {
        .dvca_c_header_text {
          color: $color-dark-text;
        }
        .dvca_c_header_icons_container {
          display: none;
        }
      }
    }
    .dvca_c_dropdown_icon_container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      &:hover {
        background-color: $color-grey-14;
      }
      .dvca_c_dropdown_icon {
        height: 16px;
        width: 16px;
        transition: transform 0.25s linear;
        &.dvca_c_rotate_dropdown_icon {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .dvca_content_main_container {
    display: flex;

    .dvca_content_container {
      width: 100%;
      display: flex;
      gap: 12px;
      padding-bottom: 0;
      margin-top: 0;
      max-height: 0;
      overflow-y: hidden;
      transition: all 0.25s linear;

      .filters {
        width: 60%;

        .filters_list {
          display: flex;
          gap: 4px;
          flex-wrap: wrap;
        }
        .filters_title {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: $color-grey-600;
          margin-bottom: 8px;
        }
      }
      .summary {
        width: 40%;
        padding-left: 16px;
        border-left: 1px solid $color-border-grey-2;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .heading {
          font-size: 12px;
          line-height: 18px;
          color: $color-grey-600;
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .text {
          color: $color-grey-19;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      &.dvca_content_container_collapse {
        padding-bottom: 16px;
        margin-top: 6px;
        max-height: 100%;
      }
      .dvca_cc_chunk {
        display: flex;
        flex-direction: column;
        width: 200px;

        &:nth-child(4) {
          .dvca_cc_c_links_container {
            flex-direction: row;
            gap: 4px;
          }
        }

        &:nth-child(5) {
          width: 100%;
          flex-grow: 1;
        }

        .dvca_cc_c_header {
          display: flex;
          align-items: center;
          gap: 8px;

          .dvca_cc_header_icon {
            path {
              fill: $color-prime;
            }
            ellipse {
              fill: $color-prime;
            }
          }
          .dvca_cc_c_header_text {
            color: rgba(20, 25, 46, 0.7);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin: 0;
          }
        }

        &:nth-child(2) {
          .dvca_cc_c_header {
            .dvca_cc_header_icon {
              path {
                stroke: $color-prime;
              }
            }
          }
        }
        .dvca_cc_c_links_container {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          .dvca_cc_c_lc_link {
            color: $color-dark-grey;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            text-decoration-line: underline;
            margin: 0;
            cursor: pointer;
            &.dvca_cc_c_lc_link_desc {
              line-height: 16px;
              text-decoration-line: none;
              cursor: default;
            }
            &.dvca_cc_c_lc_link_tag {
              text-decoration-line: none;
              padding: 4px 6px;
              color: $color-dark-text;
              border-radius: 4px;
              cursor: default;
              &.dvca_cc_c_lc_link_tag_russia {
                background-color: $color-pastel-pink;
              }
              &.dvca_cc_c_lc_link_tag_ukraine {
                background-color: $color-pastel-light-blue;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1775px) {
  .dvca_container {
    .dvca_content_main_container {
      .dvca_content_container {
        &.dvca_cc_edit_with_active_menu {
          // background-color: gold;
          .dvca_cc_chunk {
            width: 26%;
            // flex-grow: 1;

            &:nth-child(5) {
              // width: calc(60% + 32px);
              width: calc(64% - 8px);
              flex-grow: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1614px) {
  .dvca_container {
    .dvca_content_main_container {
      .dvca_content_container {
        &.dvca_cc_with_active_menu {
          // background-color: pink;
          .dvca_cc_chunk {
            width: 26%;

            &:nth-child(5) {
              width: calc(64% - 8px);
              flex-grow: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1495px) {
  .dvca_container {
    .dvca_content_main_container {
      .dvca_content_container {
        &.dvca_cc_edit_without_active_menu {
          // background-color: aquamarine;
          .dvca_cc_chunk {
            width: 26%;

            &:nth-child(5) {
              width: calc(64% - 8px);
              flex-grow: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1334px) {
  .dvca_container {
    .dvca_content_main_container {
      .dvca_content_container {
        &.dvca_cc_without_active_menu {
          // background-color: burlywood;
          .dvca_cc_chunk {
            width: 26%;

            &:nth-child(5) {
              width: calc(64% - 8px);
              flex-grow: 0;
            }
          }
        }
      }
    }
  }
}
