@import 'styles/titles.scss';
@import 'styles/colors.scss';

.categories {
  &__row {
    border-left: 1px solid $color-border-grey;
    padding-left: 35px;

    &_title-div {
      svg {
        path {
          stroke: $color-prime;
        }
      }
      &__title {
        @include description;
        text-transform: uppercase;
        color: $color-black;
        margin-left: 12px;
      }
    }
  }

  &__dropdown-text {
    @include description;
    color: $color-black;
    cursor: pointer;
  }

  &__attention-flag-div {
    @include flex-body;

    &__container {
      @include flex-body-centered;
      padding: 10px 16px 10px 8px;
      background: $color-unselected;
      border-radius: 4px;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        background: $color-prime;

        span {
          color: $color-white;
        }

        path {
          fill: $color-white;
        }
      }
    }

    &--selected {
      background-color: $color-prime;

      span {
        color: $color-white;
      }

      path {
        fill: $color-white !important;
      }
    }

    &__count {
      @include tag;
      @include flex-body;
      text-transform: uppercase;
      color: $color-black;
    }

    &__icon {
      @include flex-body;
      margin-right: 7px;
      height: 16px;

      path {
        fill: $color-prime;
      }
    }
  }
}
