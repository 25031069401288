@import 'styles/colors.scss';
@import 'styles/titles.scss';

.warning-modal {
  @include flex(column, center, center);

  &__body {
    @include flex(row, flex-start, flex-start);

    &__icon {
      @include flex-body-centered;
      border-radius: 50%;
      background-color: $warning-color;
      margin-right: 24px;
      padding: 12px;
      border: 10px solid $warning-color;
    }
  }

  &__main {
    @include flex(column, flex-start, flex-start);
  }

  &__title {
    @include title;
    color: $color-black;
  }

  &__description {
    @include description;
    color: $color-dark-grey;
    margin-top: 16px;
  }

  &__close-btn {
    @include subtitle-600;
    flex: 50%;
    background-color: $color-black;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 16px 24px;
    color: $color-white;
    gap: 6px;
    cursor: pointer;
  }

  &__footer {
    @include flex(row, center, flex-end);
    width: 100%;
    height: 100%;
    gap: 16px;
  }

  &__cancel-btn {
    @include subtitle-600;
    flex: 50%;
    background-color: $color-white;
    border: 1px solid #e33554;
    border-radius: 4px;
    padding: 16px 30px;
    color: #e33554;
    cursor: pointer;
  }

  .ant-modal-content {
    padding: 32px;
    gap: 24px;
  }

  .ant-modal-footer {
    padding: 0px;
    margin: 24px 0px 0 90px;
    gap: 16px;
  }

  .ant-modal {
    z-index: 1;
  }
}
