@import 'styles/colors.scss';
.detail_view_left_container {
  display: flex;
  flex-direction: column;
  //   background-color: gold;
  flex-grow: 1;
  .dvlc_history_text {
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-top: 12px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .dvlc_history {
    display: flex;
    flex-direction: column;
    gap: 26px;
    // background-color: gold;
    flex-grow: 1;
    margin-top: 32px;
    justify-content: flex-end;
    .dvlc_h_text {
      margin: 0;
      margin-top: 6px;
      color: $color-dark-grey;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .dvlc_h_share_users {
      margin-top: 6px;
    }
    .dvlc_h_share_container {
      cursor: pointer;
    }
  }
  .dvlc_last_modify_text {
    color: $color-dark-grey;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-top: 32px;
  }
}
