@import 'styles/variables.scss';

.c-users {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-table {
    overflow: auto;
  }

  .ant-table-thead > tr > th {
    background-color: $color-white;
    border-bottom: 2px solid $color-gray-1;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 20px 16px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #dddddd;
  }
}
