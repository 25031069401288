@import 'styles/colors.scss';
@import 'styles/titles.scss';

.welcomeScreenWrapper {
  position: relative;

  .swiperParent {
    :global {
      .swiper {
        max-height: calc(100vh - 70px);

        .swiper-slide {
          overflow: hidden;

          // background image on each slide
          background-image: url('../../../assets/images/riskwand/welcome/bg.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }

        .swiper-pagination-vertical.swiper-pagination-bullets {
          right: unset;
          left: 32px;
        }
      }
    }

    .swiperBulletActive {
      opacity: 1;
      background: #000;
    }

    .heroSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .automatedThreatSlide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      > div {
        width: 50%;

        .threatsTransitionGroup {
          position: relative;
          height: 400px;
          width: 540px;

          .threatTransition {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;

            &.threatEnter {
              opacity: 0;
            }

            &.threatEnterActive {
              opacity: 1;
              transition: opacity 1000ms ease-in;
            }

            &.threatExit {
              opacity: 1;
            }

            &.threatExitActive {
              opacity: 0;
              transition: opacity 1000ms ease-in;
            }
          }
        }
      }
    }

    .rangeSlide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  // .slide {
  //   height: calc(100vh - 70px);
  // }

  // .hero {
  //   .sectionHeading {
  //     @include flex-body;

  //     .title {
  //       @include main-title;
  //       white-space: pre-wrap;
  //       margin-bottom: 10px;
  //     }

  //     .textBlack {
  //       @extend .title;
  //       display: flex;
  //       color: $color-black;
  //     }

  //     .textPrime {
  //       @extend .title;
  //       color: $color-prime;
  //     }
  //   }

  //   .for {
  //     @include subtitle-16;
  //     color: $color-grey-scale-600;
  //     margin-bottom: 10px;
  //   }

  //   .brandLogo {
  //     max-width: 150px;
  //     max-height: 150px;
  //     object-fit: scale-down;
  //   }

  //   .description {
  //     @include flex(column, center, center);
  //     @include title-300;
  //     color: $color-black;
  //     margin: 44px auto 0px auto;
  //   }
  // }

  // .carouselWrapper {
  //   min-height: 100vh;

  //   .carouselSlide {
  //     min-height: 100vh;

  //     display: flex !important;
  //     flex-direction: column !important;
  //     align-items: center !important;
  //     justify-content: center !important;
  //   }
  // }

  // .carouselDots {
  //   width: auto;

  //   li,
  //   li > button {
  //     width: 8px !important;
  //     height: 8px !important;
  //     border-radius: 50px;
  //   }

  //   li > button {
  //     // background: #e1e1e1;
  //     background: blue;
  //   }

  //   :global {
  //     li.slick-active > button {
  //       background: #000;
  //     }
  //   }
  // }
}
