// TODO: Sort them based on font-size

.c-heading {
  &--1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 24px;
  }

  &--2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 300;
    margin-bottom: 24px;
  }

  &--3 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &--4 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  &--5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
