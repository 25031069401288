@import 'styles/colors.scss';
@import 'styles/titles';

.countries-map-container {
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:has(.c-bubble-chart__title) {
      gap: 14px;
      padding-bottom: 24px;
      border-bottom: 1px solid $color-border-grey-2;

      .c-bubble-chart__title {
        margin: 0px;
        line-height: 20px;
      }
    }
  }

  &:has(.countries-map-container__header > .c-bubble-chart__title) {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
}

.loading-overlay-height {
  height: 625px;
}

.map {
  &__world-chart {
    cursor: pointer;
    fill: $color-primary-7;

    &--selected {
      fill: $color-prime;
    }

    &:hover {
      fill: $color-prime;
    }
  }

  &__bubble {
    fill: $color-primary-8;
    r: 5.6;

    &--black {
      @extend .map__bubble;
      fill: $color-black;
    }
  }

  &__info-tooltip {
    &__content {
      @include flex(column, flex-start, flex-start);

      &__name {
        &__container {
          @include flex(row, center, center);
          margin-bottom: 10px;
        }

        @include subtitle;
        color: $color-black;
        margin-left: 8px;
      }

      &__data {
        @include info-700;
        color: $color-prime;

        &::before {
          @include info;
          content: 'Content:';
          color: $color-black;
          text-align: center;
          margin-right: 5px;
        }
      }
    }

    .ant-tooltip-inner {
      background: $color-white;
      padding: 12px;
      border-radius: 6px;
      height: 68px;
    }

    .ant-tooltip-arrow {
      display: initial;
    }

    .ant-tooltip-arrow-content::before {
      --antd-arrow-background-color: white !important;
    }
  }
}
