@import 'styles/colors.scss';
@import 'styles/titles.scss';

.narrative-evaluator-modal {
  float: right;
  top: 0;
  height: 100%;
  padding: 0;

  &__title {
    width: 100%;
    @include title;
    text-align: start;
    color: $color-dark-text;
  }

  &__footer {
    &--button {
      @include subtitle-16;
      @include flex-body-centered;
      align-self: stretch;
      width: 100%;
      flex: 1;
      padding: 10px 16px;
      border-radius: 6px;
      background: $color-black;
      color: $color-white;
      border: none;
      cursor: pointer;
      height: 40px;
    }
  }

  .ant-modal-content {
    height: 100%;
    padding: 24px 40px;
    display: flex;
    flex-direction: column;

    div:last-of-type {
      margin-top: auto;
    }
  }

  .ant-modal-header {
    padding: 0;
    display: flex;
  }
}
