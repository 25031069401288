@import 'styles/colors.scss';

.cnaw_container {
  height: calc(100dvh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px;
  .cnaw_c_content_container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .cnaw_c_content {
      width: 754px;
      display: flex;
      align-items: center;
      padding: 32px;
      border-radius: 16px;
      background-color: $color-primary-3;
      .cnaw_c_c_block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        &:last-child {
          padding-left: 48px;
        }

        &:not(:last-child) {
          border-right: 1px solid $color-border-grey;
        }

        &:first-child {
          padding-right: 48px;
        }
        .cnaw_c_c_b_heading {
          color: $color-border-black;
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin-top: 32px;
          margin-bottom: 8px;
        }
        .cnaw_c_c_b_desc {
          color: $color-dark-grey;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.024px;
          margin-bottom: 32px;
        }
        .cnaw_c_c_b_button {
          padding: 10px 16px;
          height: 40px;
          border-radius: 6px;
        }
      }

      .cnaw_c_c_block:only-child {
        padding: 0px 48px;
        width: 100%;
      }
    }
  }
  .cnaw_c_button {
    padding: 10px 16px;
    height: 40px;
    border-radius: 6px;
    background: none;
    color: $color-black;
    border: 1px solid $color-black;
  }
}

.cbc_container {
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;
  .cbc_c_link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0px;
    .cbc_c_l_close_icon {
      path {
        stroke: $color-prime;
      }
    }
  }
  .cbc_c_create_text {
    color: $color-dark-text;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 0;
  }
}
