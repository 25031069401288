@import 'styles/colors.scss';
.share_with_container {
  display: flex;
  align-items: center;
  justify-content: center;
  //   background-color: gold;
  width: fit-content;
  .swc_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    border-radius: 28px;
    border: 2px solid $color-white;
    &.swc_add_margin {
      &:nth-child(n + 2) {
        //   border-color: red;
        margin-left: -12px;
      }
    }
    .swc_ic_image {
      height: 100%;
      width: 100%;
    }
    &.swc_count_container {
      border-color: $color-grey-scale-100;
      background-color: $color-white;
      .swc_count {
        color: $color-grey-text;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
      }
    }
  }
}
