@import 'styles/colors.scss';

.topics_chart_container {
  // flex-grow: 1;
  //   padding-top: 5px;
  height: calc(100% - 50px);

  //   width: 100%;
  .recharts-responsive-container {
    .recharts-wrapper {
      .recharts-legend-wrapper {
        bottom: -16px !important;

        .tcc_legend {
          display: flex;
          align-items: center;
          gap: 30px;
          //   background-color: gold;
          list-style: none;
          margin: 0;

          .tcc_legend_list {
            display: flex;
            align-items: center;
            gap: 16px;

            .tcc_ll_square {
              height: 12px;
              width: 12px;
            }

            .tcc_ll_text {
              color: $color-grey-8;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              margin: 0;
            }

            &.tcc_legend_list_disabled {
              position: relative;

              &::before {
                content: '';
                position: absolute;
                top: 50%;
                width: calc(100% + 10px);
                left: 50%;
                transform: translate(-50%, -50%);
                border: 1px solid $color-dark-grey;
              }
            }
          }
        }
      }
    }
  }

  .topics_chat_axis_text {
    fill: $color-grey-text;
    font-family: 'Inter';
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    // width: 300;
  }
}
