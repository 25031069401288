@import 'styles/colors.scss';
@import 'styles/titles.scss';

.close-btn {
  background-color: $color-unselected !important;
  width: 160px;
  border-radius: 8px !important;
}

.topic-details {
  padding: 20px 32px;
  &__heading {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $color-grey-text;
  }
  &__keywords {
    border-radius: 4px;
    padding: 16px;
    background-color: $color-background;
    border: 1px solid $color-pastel-blue;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $color-dark-text;
  }
  &__created-by {
    display: flex;
    gap: 8px;
    flex-direction: row;
    h5 {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-dark-text;
      margin-bottom: 2px;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-dark-grey;
    }
  }
  &__editor-info {
    display: flex;
    align-items: center;
    gap: 96px;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-text;
  }
  &__box {
    margin-bottom: 24px;
  }
}

.topic-details-keyword-info {
  @include flex-body-column;
  width: 100%;
  gap: 0.2px;

  &__nav-container {
    @include flex-body;
    width: 100%;
    margin-bottom: 8px;

    svg {
      margin-left: 8px;
      cursor: pointer;
    }
  }

  &__languagesBar {
    @include flex-body;
    width: 100%;
    overflow: hidden;
    gap: 8px;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &__title {
    @include description;
    padding: 4px 8px;
    color: $color-grey-text;
    background-color: transparent;
    outline: none;
    border: none;

    &--active {
      @include subtitle;
      color: $color-primary-300_1 !important;
      background-color: $color-primary-6 !important;
    }

    &,
    &:focus,
    &:active {
      outline: none !important;
      border: none;
      cursor: pointer;
      margin: 0;
      color: $color-grey-text;
      display: flex;
      align-items: center;
    }
  }

  &__text {
    &--ML {
      @include description;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 16px;
      background: rgba(168, 75, 211, 0.1);
      border: 1px solid rgba(168, 75, 211, 0.6);
      border-radius: 4px;
      color: $color-purple;
      min-height: 80px;
    }

    &--others {
      @include description;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 16px;
      gap: 8px;
      background: $color-background;
      border: 1px solid $color-border-grey;
      border-radius: 4px;
      color: $color-grey-8;
      min-height: 80px;
    }
  }
}
