@import 'styles/colors.scss';

$platform-colors: (
  linkedin: #0a66c2,
  youtube: $color-red,
  twitter: $platform-brand-color-twitter,
  tiktok: $platform-brand-color-tiktok,
  podcast: $platform-brand-color-podcast,
  news: $platform-brand-color-news,
  bitchute: $platform-brand-color-bitchute,
  rumble: $platform-brand-color-rumble,
  reddit: $platform-brand-color-reddit,
  gab: $platform-brand-color-gab,
  'patriots.win': $platform-brand-color-patriots,
  truthsocial: $platform-brand-color-truthsocial,
  vk: #0077ff,
  instagram: #d300c5,
  threads: $platform-brand-color-tiktok,
  telegram: #0088cc,
  brighteon: #1b2e5a,
  bluesky: #0085ff,
  snapchat: $color-red,
);

.watchlist-builder {
  width: 100%;
  display: flex;
  height: calc(100% - 60px);
  background-color: $color-primary-3;

  .ant-form.ant-form-vertical {
    width: 100%;
    display: flex;
  }

  &__section {
    &--left,
    &--right {
      width: 25%;
    }

    &--center {
      width: 50%;
      display: flex;
      min-width: 500px;
      position: relative;
      flex-direction: column;
      background-color: $color-white;
      border-left: 1px solid $color-border-grey;
      border-right: 1px solid $color-border-grey;

      .ant-spin-nested-loading {
        height: 100%;
        overflow-y: auto;
        .ant-spin-container {
          height: 100%;
        }
        .ant-spin-spinning {
          max-height: 100%;
        }
      }

      &__navbar {
        gap: 32px;
        display: flex;
        padding: 24px 48px;
        border-bottom: 1px solid $color-border-grey;

        .step-counts,
        .step-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }

        .step-counts {
          color: $color-grey-text;
        }

        .step-title {
          color: $color-dark-text;
        }
      }

      &__header {
        display: flex;
        padding: 16px 48px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $color-border-grey;

        .creation-flow__loader {
          gap: 8px;
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          padding: 0px 12px;
          background-color: $color-white;
          border: 1px solid $color-border-grey;

          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            font-style: normal;
            font-family: Roboto;
            color: $color-grey-19;
          }

          .loader {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $color-prime;
            border-left-color: transparent;
            animation: spin89345 1s linear infinite;
          }

          @keyframes spin89345 {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }

        .ant-form-item {
          margin: 0px;
          width: 100%;
          .ant-col.ant-form-item-control {
            margin: 0px;
            .ant-form-item-control-input-content {
              min-height: auto;
            }
          }
        }

        .ant-input-affix-wrapper {
          min-height: auto;
          border-radius: 8px;
          border: 1px solid $color-grey-17;
          box-shadow: 0px 1px 2px 0px #1018280d;
          &:hover {
            border-color: $color-grey-17;
          }

          .ant-input {
            padding-left: 4px;
            min-height: auto;
            font-size: 16px;
            font-weight: 400;
            color: $color-dark-text;

            &::placeholder {
              color: $color-grey-22;
            }
          }
        }
      }

      &__form {
        flex: 1;
        overflow-y: auto;

        .add-details {
          gap: 24px;
          display: flex;
          padding: 32px 48px;
          flex-direction: column;

          .ant-form-item {
            margin: 0px;
            .ant-col.ant-form-item-control {
              margin: 0px;
              .ant-form-item-control-input-content {
                min-height: auto;
              }
            }
          }

          label {
            gap: 6px;
            display: flex;
            flex-direction: column;

            .asterisk {
              color: $color-red;
            }

            .input-label {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: $color-grey-19;
            }

            .ant-input {
              min-height: auto;
              font-size: 16px;
              font-weight: 400;
              color: $color-dark-text;
              padding: 8px 12px;
              border-radius: 8px;
              border: 1px solid $color-grey-17;
              box-shadow: 0px 1px 2px 0px #1018280d;
            }

            textarea.ant-input {
              padding: 12px 14px;
              min-height: 131px;
              resize: vertical;
              overflow: hidden;
            }
          }
        }

        .add-links {
          gap: 24px;
          display: flex;
          padding: 16px 48px;
          flex-direction: column;

          .ant-table-tbody > tr.ant-table-row-selected > td {
            background-color: $color-primary-3;
          }

          &__label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: $color-dark-grey;
          }

          &__search-input {
            gap: 8px;
            display: flex;
            flex-direction: column;
            .ant-input-affix-wrapper {
              min-height: auto;
              border-radius: 8px;
              border: 1px solid $color-grey-17;
              background-color: $color-grey-20;
              box-shadow: 0px 1px 2px 0px #1018280d;
              &:hover {
                border-color: $color-grey-17;
              }

              .ant-input {
                padding-left: 4px;
                min-height: auto;
                font-size: 16px;
                font-weight: 400;
                color: $color-dark-text;
                background-color: $color-grey-20;

                &::placeholder {
                  color: $color-grey-22;
                }
              }
            }

            &__applied-platform {
              gap: 8px;
              flex-wrap: wrap;
              display: inline-flex;

              &__invalid-links-counts {
                cursor: pointer;
                gap: 8px;
                display: inline-flex;
                padding: 4px 10px;
                border-radius: 4px;
                align-items: center;
                background-color: $warning-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: $delete-label-text;
              }

              &__platform {
                cursor: pointer;
                gap: 8px;
                display: inline-flex;
                padding: 4px 10px;
                border-radius: 4px;
                align-items: center;
                background-color: $color-grey-18;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: $color-grey-600;
              }

              .active {
                color: $color-white;
                background-color: $color-grey-23;
              }
            }
          }
        }

        // .preview {
        //   background-color: blue;
        // }
      }

      &__footer {
        gap: 32px;
        display: flex;
        padding: 24px 48px;
        justify-content: flex-end;
        border-top: 1px solid $color-border-grey;

        .cancel-btn {
          display: flex;
          min-width: 128px;
          padding: 10px 16px;
          justify-content: center;
          color: $color-dark-text;
          background-color: $color-unselected;
        }

        .continue-btn {
          display: flex;
          min-width: 128px;
          padding: 10px 16px;
          color: $color-white;
          justify-content: center;
          background-color: $color-black;

          &.delete_btn {
            background-color: $warning-color;
            color: $delete-label-text;
            svg path {
              stroke: $delete-label-text;
            }
          }

          &:disabled {
            background-color: $color-disabled-grey;
          }
        }
      }
    }

    &--right {
      overflow-y: auto;
      padding: 80px 40px 40px 40px;
      gap: 24px;
      display: flex;
      flex-direction: column;
      .label {
        color: #0c111d;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        background-color: transparent;
      }

      .platforms {
        gap: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .platform {
          gap: 8px;
          display: inline-flex;
          padding: 4px 10px;
          border-radius: 4px;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: $color-grey-600;
          border: 1px solid $color-grey-17;
          background-color: $color-grey-18;

          .icon {
            display: flex;
            align-items: center;
          }

          @mixin platform-styles($platform, $color) {
            &.#{$platform} {
              .icon {
                svg path {
                  fill: $color;
                }
              }

              &:hover {
                background-color: $color;

                .icon {
                  svg path {
                    fill: $color-white;
                  }
                }

                .title {
                  color: $color-white;
                }

                border: 1px solid $color;
              }
            }
          }

          @each $platform, $color in $platform-colors {
            @include platform-styles($platform, $color);
          }

          &.threads {
            .icon svg path {
              fill: transparent;
            }

            &:hover {
              .icon svg path {
                fill: transparent;
              }
              .icon svg path {
                stroke: $color-white;
              }
            }
          }

          &.vk {
            .icon svg path {
              stroke: $color-white;
            }

            &:hover {
              .icon svg rect {
                fill: $color-white;
              }
              .icon svg path {
                fill: map-get($platform-colors, vk);
              }
            }
          }

          &.snapchat {
            .icon svg g path {
              stroke: $color-white;
            }

            &:hover {
              .icon svg path:last-child {
                fill: $color-white;
              }
              .icon svg path:first-child {
                fill: map-get($platform-colors, snapchat);
              }
            }
          }

          &.telegram {
            .icon svg path:first-child {
              fill: map-get($platform-colors, telegram);
            }
            .icon svg path:last-child {
              fill: $color-white;
            }

            &:hover {
              .icon svg path:first-child {
                fill: $color-white;
              }
              .icon svg path:last-child {
                fill: map-get($platform-colors, telegram);
              }
            }
          }
        }
      }
    }
  }
}

.edit-url-modal {
  &--content {
    gap: 24px;
    display: flex;
    padding: 24px 32px;
    flex-direction: column;

    .previous-info {
      &--header {
        gap: 6px;
        display: flex;
        flex-direction: column;
      }

      &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $color-grey-19;
      }

      &__url {
        gap: 6px;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $delete-label-text;
        padding: 6px 20px;
        border-radius: 8px;
        align-items: center;
        background-color: $warning-color;
        svg {
          width: 15px;
          height: 15px;
          path {
            stroke: $delete-label-text;
          }
        }
      }

      gap: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__info {
    gap: 20px;
    display: flex;
    padding: 16px 24px;
    border-radius: 8px;
    align-items: center;
    border: 1px solid #fecdca;
    background-color: $delete-label;

    &__icon {
      width: 48px;
      height: 48px;
      min-width: 48px;
      border-radius: 50%;
      background-color: $warning-color;
      display: flex;
      align-items: center;
      justify-content: center;

      svg path {
        stroke: $delete-label-text;
      }
    }

    &__text {
      gap: 8px;
      display: flex;
      flex-direction: column;

      &__title {
        color: $delete-label-text;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.41px;
      }

      &__description {
        color: $color-grey-600;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;

        ul {
          margin-left: 24px;

          span {
            font-size: 14px;
            font-weight: 700;
            line-height: 16.41px;
            color: $color-grey-600;
          }
        }
      }
    }
  }

  &__input-field {
    label {
      gap: 6px;
      display: flex;
      flex-direction: column;

      .input-label {
        color: $color-grey-19;
        font-weight: 500;

        .asterisk {
          color: $delete-label-text;
        }
      }

      .error-message {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $delete-button-color;
      }

      .ant-form-item {
        margin: 0px;
        width: 100%;
        .ant-col.ant-form-item-control {
          margin: 0px;
          .ant-form-item-control-input-content {
            min-height: auto;

            input {
              min-height: auto;
              padding: 8px 12px;
              color: $color-dark-text;
              font-weight: 400;
              border-radius: 8px;
              border: 1px solid #fda29b;
              box-shadow: 0px 1px 2px 0px #1018280d;
            }
          }
        }
      }
    }
  }
}

.no-data-screen {
  gap: 24px;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -50%);

  &__text {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: $platform-brand-color-tiktok;
    }

    &__description {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: $color-dark-grey;
    }
  }
}

.platform-batch {
  gap: 8px;
  display: inline-flex;
  padding: 4px 10px;
  border-radius: 4px;
  align-items: center;
  background-color: $color-grey-18;
  border: 1px solid $color-grey-17;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $color-grey-600;

  &.invalid {
    color: $delete-label-text;
    background-color: $warning-color;
    border: 1px solid #fda29b;
    svg {
      path {
        stroke: $delete-label-text;
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
  }

  @mixin platform-styles($platform, $color) {
    &.#{$platform} {
      .icon {
        svg path {
          fill: $color;
        }
      }
    }
  }

  @each $platform, $color in $platform-colors {
    @include platform-styles($platform, $color);
  }

  &.threads {
    .icon svg path {
      fill: transparent;
    }
  }

  &.vk {
    .icon svg path {
      stroke: $color-white;
    }
  }

  &.telegram {
    .icon svg path:first-child {
      fill: map-get($platform-colors, telegram);
    }
    .icon svg path:last-child {
      fill: $color-white;
    }
  }

  &.snapchat {
    .icon svg g path {
      stroke: $color-white;
    }

    &:hover {
      .icon svg path:last-child {
        fill: $color-white;
      }
      .icon svg path:first-child {
        fill: map-get($platform-colors, snapchat);
      }
    }
  }
}

.pulse-date-selection-modal.watchlist-edit-url.ant-modal {
  width: 648px !important;

  .ant-modal-content {
    position: relative;
    padding: 0px;
    .ant-modal-header {
      padding: 24px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom: 1px solid $color-border-grey;
      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $color-black;
      }
    }

    .ant-modal-footer {
      padding: 24px;
      position: relative;
      border-top: 1px solid $color-border-grey;
    }
  }
}

.add-links__platform-table {
  .channel-info {
    gap: 12px;
    display: inline-flex;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    &__description {
      display: flex;
      flex-direction: column;

      &__name {
        color: $platform-brand-color-tiktok;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }

      &__url {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $color-dark-grey;
      }
    }
  }

  .creator-info-column {
    max-width: 400px;
    display: inline-block;
  }

  .create-url {
    gap: 6px;
    display: inline-flex;
    padding: 6px 20px;
    border-radius: 8px;
    align-items: center;
    background-color: $color-grey-18;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $color-grey-600;

    &--invalid {
      gap: 6px;
      opacity: 0;
      display: flex;
      visibility: hidden;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover .create-url--invalid {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in-out;
    }

    &__url {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icon {
      padding: 4px;
      border-radius: 6px;
      box-shadow: 0px 0px 2px 0px #00000026;
      border: 1px solid #fda29b;
      background-color: $warning-color;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .info-icon {
      display: flex;
      border: none;
      background-color: transparent;
      box-shadow: none;
    }

    &.invalid {
      color: $delete-label-text;
      background-color: $warning-color;
      svg {
        path {
          stroke: $delete-label-text;
        }
      }
    }
  }
}
