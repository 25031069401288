@import 'styles/colors.scss';

.area_chart_section_container {
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  padding: 16px;
  border-radius: 8px;
  background-color: $color-white;
  width: 451px;
  flex-shrink: 0;

  .acsc_buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 21px;

    .acsc_bc_left_section,
    .acsc_bc_right_section {
      display: flex;
      align-items: center;
      padding: 2px 4px;
      gap: 2px;
      border-radius: 6px;
      border-radius: 6px;
      border: 1px solid $color-border-grey;
      background-color: $color-grey-scale-100;

      .acsc_bc_s_button {
        height: 24px;
        padding: 2px 6px;
        border-radius: 3px;
        gap: 4px;
        background: none;
        color: $color-dark-text;
        font-size: 12px;

        &.acsc_bc_s_button_active {
          background-color: $color-black;
          color: $color-white;
        }
      }
    }
  }

  .acsc_area_chart_container {
    flex-grow: 1;

    //   background-color: gold;
    .acsc_acc_inner_container {
      height: 100%;
    }
  }

  .acsc_custom_legend_container {
    display: flex;
    align-items: center;
    gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    // background-color: gold;
    overflow-x: auto;
    padding-bottom: 4px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    .acsc_clc_li {
      .acsc_clc_l_chunk {
        display: flex;
        align-items: center;
        gap: 4px;

        .acsc_clc_lc_bar {
          width: 16px;
          height: 4px;
          border-radius: 10px;
        }

        .acsc_clc_lc_text {
          color: $color-dark-grey;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;
          margin-bottom: 0;
        }

        .acsc_clc_lc_icon {
          path {
            stroke: $color-grey-text;
          }
        }
      }
    }
  }
}
