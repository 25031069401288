@import 'styles/variables.scss';

.legend {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;

  padding: 4px 10px;
  cursor: pointer;
  border-radius: 3px;
  user-select: none;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  .icon {
    margin-right: 4px;

    &.vkIcon {
      path {
        fill: #a9ced9;
      }
    }

    &.linkedin {
      path {
        fill: $color-white;
      }
    }

    &.telegramIcon {
      path:nth-child(2) {
        fill: #c5ded5;
      }
    }
  }
}

.opaque {
  opacity: 0.4;
}
