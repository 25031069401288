@import 'styles/variables.scss';
@import 'styles/colors.scss';

.custom-select-container {
  position: relative;
  width: 100%;

  .ant-select-clear {
    height: 16px;
    width: 16px;
    font-size: 16px;
    margin-right: 24px;
    color: $color_dark_grey;
  }

  .select-wrapper {
    position: relative;
  }

  .ant-select-dropdown {
    position: absolute !important;
    top: 52px !important;
    bottom: auto !important;
  }

  .anticon-down {
    svg {
      transition: all 0.3s ease-in-out;
      fill: $color_dark_grey;
    }
  }

  .ant-select-open .anticon-down {
    svg {
      fill: $color_dark_grey;
      transition: all 0.3s ease-in-out;
      transform: rotate(-180deg);
    }
  }

  .ant-select.alert-custom-select {
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $color-border-grey-2;
      box-shadow: none;
    }

    .ant-select-arrow {
      height: 16px;
      width: 16px;
      font-size: 16px;
      color: $color_dark_grey;
    }

    .ant-select-selector {
      background-color: transparent;
      border: 1px solid $color-border-grey-2;
      outline: none;
      border-radius: 8px;
      padding: 10px 14px;
      .ant-select-selection-search {
        margin-inline-start: 0px;
      }
      .ant-select-selection-placeholder {
        padding-left: 5px;
      }
      .ant-select-selection-item {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-right: 8px;
      }
    }
  }

  .ant-select-selection-item-content {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-grey-22;
  }
}

.custom-select-options-container {
  padding: 12px;
  overflow-y: auto;
  box-sizing: border-box;
  .custom-option {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 12px 16px;

    &:hover {
      background-color: $color-hover;
    }
  }
  .ant-select-dropdown .ant-select-item-option-state {
    display: none;
  }

  .ant-select-selection-item {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-right: 8px;
  }

  .custom-option span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-grey-600;
  }

  .check-icon {
    margin-right: 8px;
    color: $color-prime;
  }
}
