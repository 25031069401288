@import 'styles/colors.scss';

.PowerInsightTabsListVertical {
  width: 240px;
  min-width: 240px;
  visibility: visible;

  .fixed {
    position: fixed;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    height: calc(100% - 200px);

    width: 240px;
    box-shadow: -4px 0px 10px 0px #0000000d inset;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  .height {
    height: calc(100% - 135px);
  }

  .tab-item {
    gap: 12px;
    height: 72px;
    display: flex;
    width: 240px;
    align-items: center;
    padding: 24px 24px 24px 40px;

    &:not(:last-child) {
      border-bottom: 1px solid $color-border-grey-2;
    }

    cursor: pointer;

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $color-dark-grey;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    &:hover {
      background-color: $color-grey-20;
    }

    .tab-item__right-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 15px;
        height: 15px;
      }

      &.info-icon svg {
        path {
          stroke: $color-grey-22;
        }
      }
    }

    .tab-item__left-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .tab-item-selected {
    background-color: $color-primary-3;
    position: relative;

    .title {
      font-weight: 600;
      color: $color-primary-300_1;
    }

    &:not(.fill) .tab-item__left-icon svg {
      path {
        stroke: $color-primary-300_1;
      }
    }

    &.fill .tab-item__left-icon {
      svg {
        path {
          stroke: null;
          fill: $color-primary-300_1;
        }
      }
    }

    &::after {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      width: 4px;
      position: absolute;
      background-color: $color-prime;
    }
  }
}

.hideBrandList {
  width: 0;
  min-width: 0;
  visibility: hidden;
}

.ant-tooltip-content {
  &:has(.ant-tooltip-inner > .hive-hover-card) {
    .ant-tooltip-arrow .ant-tooltip-arrow-content {
      &::before {
        background: $color-white;
      }
    }

    .ant-tooltip-inner {
      width: auto;
      padding: 0px;
      border: none;
      box-shadow: none;
      background-color: transparent;

      .hive-hover-card {
        display: flex;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        border-radius: 12px;
        border: 1px solid $color-border-grey-2;
        background: $color-white;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

        &--body {
          gap: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-bottom: 12px;
          border-bottom: 1px solid $color-border-grey-2;
        }

        &__title {
          color: $color-black;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        &__description {
          color: $color-dark-grey;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }

        &--footer {
          gap: 8px;
          display: flex;
          flex-direction: column;
        }

        .hive-hover-card-footer {
          &__text {
            color: $color-grey-17;
            font-size: 10px;
            font-weight: 400;
            font-style: normal;
            line-height: normal;
          }

          &__tags {
            gap: 8px;
            display: flex;

            &__tag {
              gap: 6px;
              display: flex;
              padding: 4px 10px;
              border-radius: 8px;
              align-items: center;
              justify-content: center;
              color: $color-dark-grey;
              text-align: center;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              &:first-child {
                background-color: $color-pastel-pink;
              }

              &:nth-child(2) {
                background-color: $color-pastel-blue;
              }

              &:last-child {
                background-color: $color-pastel-green;
              }
            }
          }
        }
      }
    }
  }
}
