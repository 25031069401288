@import 'styles/colors.scss';

.expand_list_chunk_container {
  position: relative;
  display: flex;
  gap: 16px;
  background-color: $color-grey-scale-100;
  box-shadow: 1px 0px 20px 0px rgba(0, 0, 0, 0.03) inset;
  padding: 22px;
  min-height: 288px;

  .elcc_flags_container {
    flex: 1;
    padding: 16px;
    background-color: $color-white;
    border-radius: 8px;

    .elcc_fc_header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .elcc_fc_h_left_container {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        .elcc_fc_h_lc_title {
          color: $color-dark-text;
          font-family: Inter;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          max-width: 340px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 0;
        }

        .elcc_fc_h_lc_info_icon:hover + .extra_info_popup_container {
          display: block;
        }
      }

      .elcc_fc_h_lc_button {
        background-color: $color-white;
        height: 40px;
        padding: 10px 16px;
        border-radius: 6px;
        gap: 4px;
        color: $color-black;

        &:hover {
          background-color: $color-primary-6;
        }
      }
    }

    .elcc_fc_inner_container {
      display: flex;
      // align-items: center;
      margin-top: 20px;

      .elcc_fc_ic_assets_flag_container {
        position: relative;
        padding-right: 0;
        padding-left: 16px;

        &::before {
          position: absolute;
          content: '';
          top: 5px;
          right: 100%;
          width: 1px;
          height: calc(100% - 10px);
          background-color: $color-border-grey-2;
        }

        .rfc_flags_list {
          .rfc_fl_chunk {
            width: calc(50% - 4px);
          }
        }
      }
    }
  }

  .elcc_area_chart_container {
    flex: 1;
  }

  .elcc_loading_state {
    position: absolute;
    top: 22px;
    left: 22px;
    height: calc(100% - 44px);
    width: calc(100% - 44px);
    padding: 16px;
  }
}
