@import 'styles/variables.scss';
@import 'styles/titles.scss';
@import 'styles/colors.scss';

.filtersDiv {
  @include flex(row, flex-start, flex-start);
  gap: 10px;
  padding-inline: 10px;
  align-items: center;
  padding-bottom: 16px;
}

.filtersContainer {
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 2px;
  }
}

.appliedFilters {
  display: flex;
  align-items: center;
  gap: 12px;

  user-select: none;
  margin-right: 16px;

  .filterLabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-grey;
    white-space: nowrap;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    padding: 4px 12px;
    border: 0;
    border-radius: 4px;

    background: $color-prime;
    color: $color-white;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 0px;

    :global {
      .ant-tag-close-icon {
        margin-left: 0;
        display: flex;
        align-items: center;
        color: $color-white;
      }
    }
  }
}

.resetContainer {
  @include flex(row, center, center);
  cursor: pointer;
  white-space: nowrap;
  gap: 3px;
}

.reset {
  @include footnote;
  color: $color-grey-text;
  white-space: nowrap;
}
