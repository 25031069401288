.vectors_container_loader {
  max-height: none !important;
}

.vectors_container {
  display: flex;
  width: 100%;
  height: calc(100dvh - 60px);
  //   background-color: gold;
}
