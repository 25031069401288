@import 'styles/colors.scss';
@import 'styles/titles.scss';

// Base button styles
%button-base {
  min-width: 64px;
  border-radius: 6px;
  @include button-text;
  @include flexbox($justify: center);
  transition: all 0.2s ease;
}

// Sizes
.antdcb {
  &.antdcb-small {
    height: 32px;
    padding: 6px 12px;
  }

  &.antdcb-medium {
    height: 40px;
    padding: 10px 16px;
  }

  &.antdcb-large {
    height: 48px;
    padding: 12px 20px;
  }
}

// Common button styles
.antdcb.ant-btn {
  @extend %button-base;

  // Icon positioning
  &.with-icon {
    .button-content {
      display: flex;
      align-items: center;

      &.icon-left {
        flex-direction: row;
        .button-icon {
          display: inline-flex;
          align-items: center;
          margin-right: 8px;
        }
      }

      &.icon-right {
        flex-direction: row-reverse;
        .button-icon {
          display: inline-flex;
          align-items: center;
          margin-left: 8px;
        }
      }
    }
  }

  // Full width option
  &.fullWidth {
    flex-grow: 1;
    width: 100%;
  }
}

// Primary button
.antdcb.ant-btn.ant-btn-primary {
  border: none;
  color: $color-white;
  @include flexbox($justify: center, $direction: row-reverse, $gap: 8px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
    linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);

  &:hover,
  &:active,
  &:focus {
    border: none;
    color: $color-white;
    border-color: $color-black;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
      linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
  }

  &:disabled {
    border-top: 1px solid $color-grey-text;
    border-right: 1px solid $color-grey-text;
    border-left: 1px solid $color-grey-text;
    background: $color-grey-text;
    cursor: not-allowed;
    pointer-events: none;
  }
}

// Default button
.antdcb.ant-btn.ant-btn-default {
  border: none;
  color: $color-grey-19;
  background: $color-grey-18;

  &:hover,
  &:active,
  &:focus {
    border: none;
    color: $color-grey-19;
    background: darken($color-grey-18, 5%);
  }
}

// Outlined button (dashed)
.antdcb.ant-btn.ant-btn-dashed {
  border: 1px solid $color-black;
  color: $color-black;
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $color-black;
    color: $color-black;
    background: rgba($color-black, 0.05);
  }
}

// Outlined-light button (ghost)
.antdcb.ant-btn.ant-btn-ghost {
  border: 1px solid $color-grey-17;
  color: $color-black;
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $color-grey-17;
    color: $color-black;
    background: rgba($color-black, 0.05);
  }
}

// Danger button
.antdcb.ant-btn.ant-btn-primary.ant-btn-dangerous {
  border: 1px solid $delete-label-text;
  color: $color-white;
  background: $delete-label-text;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $delete-label-text;
    color: $color-white;
    background: darken($delete-label-text, 5%);
  }
}
