@import 'styles/colors.scss';

.statistics {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .heading {
    h4 {
      margin: 0;
      color: $color-black;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      user-select: none;

      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .statCard {
      background-color: $color-primary-3;
      width: calc(33.33% - 6px);
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .statCount {
        color: $color-dark-text;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }

      .statName {
        color: #000;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        user-select: none;
      }
    }
  }
}
