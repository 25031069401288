@import 'styles/colors';
@import 'styles/titles';

.add-tenant {
  &__header {
    @include flex-body-column;

    &_title {
      @include title;
      text-align: left;
      color: $color-black;
      padding-bottom: 16px;
    }

    &__description {
      @include description;
      color: $color-grey-text;
    }
  }

  &__body {
    @include flex-body-column;
    gap: 16px;

    &__logo-div {
      @include flex(column, center, center);
      gap: 16px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid $color-border-grey;
      background: $color-background;
    }

    &__logo {
      @include flex-body-centered;
      width: 105px;
      height: 105px;
      border-radius: 104px;
      border: 1px solid $color-grey-scale-200;
      background: $color-unselected;
      overflow: hidden;
      padding: 5px;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__footer {
    display: flex;
    gap: 8px;

    &__cancel {
      padding: 7px 16px;
      border-radius: 4px;
      border: 1px solid $color-black;
      background: $color-white;
      cursor: pointer;
    }

    &__add {
      padding: 7px 16px;
      border-radius: 4px;
      background: $color-black;
      border: none;
      color: $color-white;
      cursor: pointer;
    }
  }
}
