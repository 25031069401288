@import 'styles/colors.scss';
@import 'styles/titles.scss';

.feed-export {
  &__title {
    @include flex(row, flex-start, flex-start);
    @include title;
    color: $color-black;
  }

  &__body {
    padding: 24px 32px;
  }

  &__description {
    @include description;
    @include flex(row, flex-start, flex-start);
    color: $color-dark-grey;
  }

  &__filters {
    @include flex(row, center, space-between);
    margin-top: 24px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;

    &__left-div {
      &__inner {
        @include flex-body-column;
        gap: 8px;

        &__export-text {
          @include description;
          color: $color-grey-text;
        }

        &__days-text {
          @include subtitle-600;
          color: $color-dark-text;
        }

        &__description-text {
          @include description;
          color: $color-dark-grey;
        }
      }
    }

    &__right-div {
      &__filters-btn {
        @include description;
        padding: 8px 16px;
        border-radius: 16px;
        background: $color-black;
        color: $color-white;
        border: none;
        cursor: pointer;
      }
    }
  }

  &__filter-selection {
    @include flex(row, flex-start, flex-start);
    margin-top: 24px;

    &__container {
      @include flex(column, flex-start, flex-start);
      margin-right: 16px;
      gap: 6px;

      &__label {
        @include subtitle;
        color: $color-grey-19;
      }
    }

    &__hidden {
      @include flex(column, flex-start, flex-start);
      margin-right: 16px;

      &__label {
        @include subtitle;
        margin-bottom: 6px;
        color: $color-grey-19;
      }
    }

    &__filters {
      height: max-content;
      max-width: 260px;
      overflow: hidden;
      min-width: 150px;
      cursor: pointer;

      &__label {
        display: block;
        @include subtitle;
        color: #000;
        padding: 0;
        margin-bottom: 8px;
        margin-top: 16px;
      }

      .ant-dropdown-menu-item {
        padding: 0;
      }

      .ant-dropdown-menu {
        padding: 16px;
        padding-top: 0px;
        margin-top: 8px;
        border: 1px solid $color-border-grey;
        background: $color-white;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
      }
    }

    &__order {
      &__label {
        @include subtitle-16;
        color: $color-dark-text;
        overflow: hidden;
        width: fit-content;
      }

      &__svg-icon {
        transform: rotate(180deg);
      }

      &__icon {
        margin-left: 8px;

        path {
          stroke: $color-dark-grey;
          stroke-width: 1.66667;
        }
      }
    }

    &__checked-icon {
      margin-bottom: 10px;
      margin-right: -5px;

      path {
        stroke: $color-prime;
      }
    }
  }

  &__checkbox {
    @include subtitle;
    @include flex-body;
    margin-top: 24px;
    gap: 16px;

    &__tooltip {
      margin-left: 5px;
    }

    .ant-checkbox,
    .ant-checkbox-input,
    :not(.ant-checkbox-checked) {
      &:hover,
      :focus,
      :active {
        .ant-checkbox-inner {
          border: 1px solid $color-grey-17;
        }
      }
    }

    .ant-checkbox-inner {
      border-radius: 4px;
      border: 1px solid $color-grey-17;
      width: 20px;
      height: 20px;
    }
  }

  &__dropdown {
    border: 1px solid $color-border-grey;
    border-radius: 8px;
    margin-top: 6px;

    .rc-virtual-list-holder-inner {
      :last-child {
        .ant-select-item-option-content {
          margin-bottom: 10px !important;
        }
      }
    }

    .ant-select-item-option {
      padding: 10px 14px 0px 14px !important;
    }

    .ant-select-item-option-content {
      margin-bottom: 16px !important;

      svg {
        margin-right: 8px;
      }
    }
  }

  &__exclusion {
    &__label {
      @include subtitle;
      color: $color-prime;

      &:hover {
        border-bottom: 1px solid $color-prime;
        line-height: 12px;
        font-weight: 600;
      }
    }
  }

  &__footer {
    width: 100%;
    @include flex(row, flex-start, flex-end);

    gap: 16px;

    &__cancel {
      background-color: transparent;
      border: 1px solid $color-black;
      border-radius: 4px;
      padding: 8px 40px;
      color: $color-black;
      cursor: pointer;
      width: 157px;
    }

    &__send {
      background-color: $color-black;
      border: 1px solid $color-black;
      border-radius: 4px;
      padding: 8px 40px;
      white-space: nowrap;
      color: $color-white;
      cursor: pointer;
      width: 157px;
    }
  }

  &__table {
    margin-top: 24px;

    &__creator {
      @include flex-body;

      &__name {
        @include flex(column, flex-start, flex-start);
        margin-left: 8px;

        &__1 {
          @include subtitle;
          color: #000;
        }

        &__username {
          @include footnote;
          color: $color-black;
          opacity: 0.5;
        }
      }

      &__platform {
        @include flex-body;
        width: fit-content;
        background-color: $color-unselected;
        padding: 4px 8px;
        border-radius: 4px;
        gap: 8px;

        &__label {
          @include footnote;
          color: $color-dark-grey;
        }
      }

      &__following {
        @include flex(column, flex-start, flex-start);
        gap: 4px;

        &__label {
          @include footnote;
          color: $color-grey-text;
        }

        b {
          @include info;
          color: $color-black;
          margin-right: 4px;
        }
      }
    }
  }

  &__calendar {
    @include flex(row, flex-start, space-between);
    padding: 16px;

    &__date {
      @include flex(row, center, flex-start);
      gap: 8px;
      min-width: 30px;

      &__container {
        padding: 10px 14px;
        border-radius: 8px;
        border: 1px solid $color-grey-17;
        background: $color-white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      &__button {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        &__cancel {
          @include description-600;
          border-radius: 8px;
          border: 1px solid $color-grey-17;
          background: $color-white;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: $color-grey-19;
          padding: 10px 14px;
          cursor: pointer;
        }

        &__apply {
          @include description-600;
          border-radius: 8px;
          border: none;
          background: $color-black;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: $color-white;
          padding: 10px 14px;
          cursor: pointer;
        }
      }

      span {
        @include description-16;
        color: $color-grey-900;
      }
    }
  }

  &__date-span {
    @include flex-body-centered;
    border: 1px solid $color-border-grey;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;

    svg {
      margin-left: 5px;
      margin-bottom: 1px;
    }
  }

  .ant-switch {
    background-color: $color-grey-18;
    border: 1px solid $color-grey-18;

    &:hover,
    :focus,
    :active {
      border: 1px solid $color-grey-18;
    }
  }

  .ant-switch-checked {
    background-color: $color-prime !important;
    border: 1px solid $color-prime;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-prime;
    border-color: $color-prime;
    border: 1px solid $color-prime;
  }

  .rc-virtual-list-holder-inner {
    &:first-child {
      font-size: 22px;
    }
  }

  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid $color-border-grey !important;
    background: $color-white !important;
    padding: 10px 14px !important;
    height: fit-content !important;
    min-width: 180px !important;
    width: fit-content !important;
  }

  .ant-select-selection-item {
    @include flex-body;
    padding: 0px !important;

    span {
      margin: 0;
      padding: 0px !important;
    }

    svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: $color-white;
    }
  }

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-header {
    padding: 24px 32px;
    border-bottom: 1px solid $color-border-grey;
  }

  .ant-modal-close {
    top: 15px;
  }

  .ant-modal-footer {
    padding: 24px 32px;
    border-top: 1px solid $color-border-grey;
  }

  .ant-table-thead > tr > th {
    @include subtitle;
    background-color: $color-white;
    color: $color-grey-text;
    padding: 4px 16px 4px 56px;
    border-bottom: 1px solid $color-border-grey;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: $color-white;
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: end;
  }

  .ant-table-container table > thead > tr:first-child th:not(:first-child) {
    padding: 4px 16px 4px 16px;
  }

  .ant-picker-ranges {
    min-width: 192px;
    border-right: 1px solid $color-border-grey;

    li {
      color: $color-grey-19;
      padding: 8px 16px;

      span {
        @include subtitle;
        color: $color-grey-19 !important;
        background: $color-white !important;
        border: none !important;
        padding: 0;
        margin: 0;
      }
    }
  }

  .ant-picker-cell-inner {
    display: flex !important;
    @include flex-body-centered;
    text-align: center;
    width: 40px;
    height: 40px !important;
    padding: 10px 8px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    @include subtitle;
    background: $color-black !important;
    border-radius: 20px !important;
    color: $color-white !important;

    &:hover {
      background: #f7faff;
    }
  }

  .ant-picker-cell-in-range {
    .ant-picker-cell-inner {
      background: #f7faff;
    }
  }

  .ant-picker-content {
    thead {
      th {
        @include dp-selected;
        text-align: center;
        height: 40px !important;
        padding: 10px 8px;
        color: $color-grey-19;
      }
    }

    tr {
      display: flex;
      gap: 16px;
    }
  }

  .ant-picker-cell-today {
    .ant-picker-cell-inner {
      @include dp-selected;
      background: #f7faff;
      border-radius: 20px !important;
    }
  }

  .ant-picker-cell-inner::before {
    background: none;
    border: none !important;
  }

  .ant-picker-body {
    padding: 0 !important;
  }

  .ant-picker-cell-range-hover,
  .ant-picker-cell-range-hover-end,
  .ant-picker-cell-range-hover-start {
    background: #f7faff !important;
  }

  .ant-picker-header {
    border: none;
  }

  .ant-picker-panels {
    width: 870px !important;
    justify-content: flex-end;
  }

  .ant-picker-panels,
  .ant-picker-panel {
    width: max-content;
    min-height: max-content !important;

    .ant-picker-date-panel {
      padding: 20px 24px;
      min-width: 340px;
    }

    :first-child {
      .ant-picker-date-panel {
        border-right: 1px solid $color-border-grey;
      }
    }
  }

  .ant-picker-cell-disabled::before {
    background-color: transparent;
  }

  .ant-picker-large {
    padding: 0px;
  }

  .ant-picker {
    visibility: hidden;
    // display: none;
    height: 0px !important;
    border: 1px solid $color-border-grey;
    border-radius: 8px;
  }

  .ant-picker-focused {
    box-shadow: none;
  }

  .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-inline-start: 11px;
    background: $color-black;
  }

  .ant-picker-ranges {
    position: absolute;
    top: 10px;
    padding: 12px 16px;
    margin-right: -10px;
    height: 96%;

    li {
      display: block;
    }
  }

  .ant-picker-footer-extra {
    margin-left: 192px;
    line-height: 0 !important;
  }

  .ant-picker-footer {
    line-height: 0 !important;
  }
}
