@import 'styles/titles';
@import 'styles/colors';

.tenant-list {
  @include flex-body-column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  &__link {
    @include description;
    color: $hyper-link-color;
  }

  &__logo {
    @include flex-body-centered;
    border: 1px solid $color-grey-scale-300;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    img {
      width: 100%;
      height: auto;
      max-height: 35px;
      border-radius: 50%;
    }
  }

  &__actions-div {
    padding: 5px;

    &:hover {
      background-color: $color-white-hover;
    }
  }

  &__icon {
    cursor: pointer;

    path {
      stroke: $color-grey-text;
      stroke-width: 1.5;
    }

    &:hover {
      background-color: $color-white-hover !important;
    }
  }

  &__margin {
    margin-right: 20px;
  }

  &__actions {
    display: none;
  }

  &__container {
    @include flex(row, center, space-between);

    &__left-div {
      flex: 1;

      &__title {
        @include description;
        color: $color-grey-text;
      }
    }

    &__right-div {
      @include flex(row, center, flex-end);
      gap: 12px;
      flex: 2;

      &__add-btn {
        @include subtitle;
        border-radius: 4px;
        background: $color-black;
        color: $color-white;
        border: none;
        padding: 7px 12px;
        width: max-content;
        cursor: pointer;
      }

      .ant-input {
        min-height: initial;
        width: initial;

        &:hover {
          border: none;
        }
      }

      .ant-input-affix-wrapper {
        width: initial;
        border-radius: 4px;
        border: 1px solid $color-border-grey;
        padding: 5px 12px;
        box-shadow: none;

        &:hover,
        &:focus {
          border: 1px solid $color-border-grey;
          box-shadow: none;
        }

        svg {
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }

  &__filters-div {
    display: flex;
    gap: 8px;

    &__label {
      @include description;
      color: $color-dark-text;
    }

    &__applied {
      @include flex-body-centered;
      height: 34px;
      padding: 10px 8px 10px 4px;
      gap: 4px;
      border-radius: 4px;
      background: $color-prime;
      cursor: pointer;

      span {
        @include footnote;
        color: $color-white;
      }

      svg {
        path {
          stroke: $color-white;
        }
      }
    }
  }

  &__dropdown {
    flex: 0.4;
    padding: 8px 6px;
    visibility: hidden;

    &__popup {
      left: 65% !important;
      padding: 8px 6px !important;
      border-radius: 4px;
      border: 1px solid $color-border-grey;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $color-grey-text;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: black;
        border-color: black;
      }

      .ant-checkbox-checked {
        &:hover {
          border-color: black;
        }

        &:hover {
          .ant-checkbox-inner {
            border-color: black;
          }
        }
      }

      .ant-checkbox-inner {
        &:focus,
        :hover,
        :active {
          border-color: black;
        }
      }

      .ant-checkbox {
        &:hover {
          .ant-checkbox-inner {
            border-color: black;
          }
        }
      }
    }

    &__icon {
      cursor: pointer;

      &:hover {
        background-color: $color-gray-hover;
      }
    }
  }

  &__tooltip {
    .ant-tooltip-inner {
      @include flex-body-centered;
      @include footnote;
      color: $color-white;
      box-shadow: none;
      background: $color-black;
      border-radius: 4px;
      padding: 4px;
      filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.2));
    }

    .ant-tooltip-arrow {
      display: block;
    }

    .ant-tooltip-arrow-content {
      background-color: $color-black;
    }
  }

  .ant-table-row:hover .tenant-list__actions {
    display: flex;
  }

  .ant-table-thead > tr > th {
    @include subtitle;
    height: 48px;
    padding: 8px 24px;
    background: $color-grey-5;
    border: none;
    color: $color-grey-text;
    text-transform: uppercase;

    &::before {
      height: 0 !important;
    }
  }

  .ant-table-tbody > tr > td {
    @include description;
    color: $color-dark-grey;
    padding: 17px 24px;
    cursor: pointer;
  }
}
