@import 'styles/colors.scss';

.influencer-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  &__header {
    display: flex;
    gap: 12px;
    align-items: center;
    .influencer-detail {
      &__name {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #000000;
      }
      &__creator-count {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $color-dark-grey;
      }
    }
  }
}
