@import 'styles/colors.scss';
.rdr_container {
  //   background-color: goldenrod;
  .rdr_c_header {
    display: flex;
    align-items: center;
    padding-inline: 8px;
    gap: 8px;
    margin-bottom: 12px;
    .rdr_c_header_text {
      color: $color-dark-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  .rdr_c_dropdown {
    background-color: $color-white;
    border-radius: 6px;
    padding: 10px 12px;
    border: 1px solid $color-border-grey;
    height: 34px;
    width: 209px;
    .rdr_c_dropdown_header {
      width: 100%;
      p {
        color: $color-dark-grey;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
      }
      svg {
        :global path {
          stroke: $color-black;
        }
      }
    }
    .rdr_c_dropdown_body {
      top: 110%;
      width: 100%;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
