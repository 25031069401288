@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

.alert-modal {
  .ant-modal-content {
    border-radius: 16px;
    padding: 0px;
    .ant-modal-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding: 16px 24px;
      border-bottom: 1px solid $color-border;
    }

    .ant-modal-body {
      padding: 24px;
      font-size: 14px;
      word-wrap: break-word;
    }

    .ant-modal-footer {
      padding: 10px 16px;
      text-align: right;
      background: transparent;
      border-top: 1px solid $color-border;
      border-radius: 0 0 2px 2px;
    }
  }

  .ant-modal-title {
    @include title;
    text-align: left;
    color: $color-primary-black;
  }
  .alert-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .amc_switch {
      background-color: $color-grey-18;
      height: 24px;

      .ant-switch-handle {
        width: 20px;
        height: 20px;
        &::before {
          border-radius: 10px;
        }
      }
      &.ant-switch-checked {
        background-color: $color-prime;
        &:hover {
          background-color: $color-primary-300_1;
        }
        .ant-switch-handle {
          left: calc(100% - 20px - 2px);
        }
      }
      &:focus {
        box-shadow: none;
      }
    }

    .alert-modal-body-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-info {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .alert-modal-title {
          @include heading;
          color: $color-secondary-black;
        }

        .alert-modal-toggle-button-title {
          @include body;
          color: $color-grey-22;
        }
      }
    }
    .alert-conditions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-bottom: 1px solid $color-border;
      padding-bottom: 10px;
      .alert-conditions-title {
        @include heading;
        color: $platform-brand-color-tiktok;
        &[aria-disabled='true'] {
          color: $color-grey-17;
        }
      }
      .alert-conditions {
        .ant-collapse {
          background-color: transparent;
          border: none;
          .ant-collapse-item {
            border: none;
            .ant-collapse-header {
              border: none;
              padding: 0px;
            }
            .ant-collapse-expand-icon {
              display: none;
            }
          }
          .ant-collapse-content {
            border: none;
            .ant-collapse-content-box {
              border: none;
            }
          }
        }

        .alert-condition {
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          &[aria-disabled='true'] {
            cursor: not-allowed;
            user-select: none;
          }

          .alert-condition-info {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .alert-condition-title {
              font-size: 14px;
              font-weight: 600;
              line-height: 22px;
              color: $color-grey-19;
              &[aria-disabled='true'] {
                color: $color-grey-17;
              }
            }
            .alert-modal-radio-button-title {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              color: $color_dark_grey;
              &[aria-disabled='true'] {
                color: $color-grey-17;
              }
            }
          }
        }
      }
    }

    .alert-modal-search-wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .search-label {
        @include heading;
        color: $color-grey-19;
        &[aria-disabled='true'] {
          color: $color-grey-17;
        }
      }
      .alert-modal-search {
        width: 100%;
        padding: 10px, 14px, 10px, 14px !important;
        height: 44px;
        line-height: 44px;
        input {
          height: 24px;
          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $color-dark-grey;
          }
        }
        svg {
          stroke: $color-grey-17;
        }

        input[disabled] {
          &::placeholder {
            color: $color-grey-17;
          }
        }
      }
    }
    .search-results {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0px 5px;
      max-height: 250px;
      overflow-y: auto;
      .profile-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user-profile {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }
      .owner-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
      .profile-wrapper-actions-button {
        border-radius: 6px;
        background: $color-secondary-grey;
        font-size: 14px;
        font-weight: 500;
        color: $color_dark_text;
        border: none;
      }
    }
  }
}

.watchlist-wrapper {
  border: 1px solid $color-border-grey-2;
  border-radius: 8px;
  max-height: 500px;
  overflow-y: auto;
  &:last-child {
    border-bottom: none !important;
  }
  .watchlist-item {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid $color-border-grey-2;

    &__name {
      color: $platform-brand-color-tiktok;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color-prime;
      border-color: $color-prime;
      border: 1px solid $color-prime;
    }

    .ant-checkbox {
      &:hover,
      &:active,
      &:focus,
      &:visited {
        .ant-checkbox-inner {
          border: 1px solid $color-prime;
        }
      }
    }
  }
}
