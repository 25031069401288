@import 'styles/colors.scss';
@import 'styles/titles.scss';

.languageRisksSection {
  .description {
    @include description;
    color: $color-grey-text;
    margin: 20px 0;
  }

  &:has(.languageRisksSectionTitle) {
    .description {
      margin-top: 0px;
      padding-bottom: 24px;
      border-bottom: 1px solid $color-border-grey-2;
    }
  }

  .languageRisksSectionTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 14px;
  }

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;
  }

  .languageRisksWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
  }
}
