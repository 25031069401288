.ant-radio-checked .ant-radio-inner {
  background-color: #cca55a !important ;
  border: none !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: white;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #cca55a !important;
}
