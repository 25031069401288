@import 'styles/colors.scss';
@import 'styles/titles.scss';

.feed-filters {
  display: flex;

  &__text {
    @include footnote;
    color: $color-dark-text;
    margin-left: 7px;
  }

  &__container {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    flex-basis: 70%;

    &__language-filters {
      display: flex;
      align-items: center;
    }

    &__other-filters {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }

  &__title {
    @include info-700;
    text-transform: uppercase;
    color: $color-dark-text;
    margin-bottom: 4px;
    padding: 0;

    &.disabled-item {
      cursor: not-allowed;

      &:hover {
        background-color: white !important;
        cursor: not-allowed !important;
      }
    }

    &--margin {
      @extend .feed-filters__title;
      margin-top: 18px;
    }

    &:hover {
      background-color: white !important;
      cursor: default !important;
    }
  }

  &__menu {
    li {
      &:nth-last-child(2) {
        margin-right: 14px;
      }
    }

    .ant-dropdown-menu-item:hover {
      background-color: $color-white;
    }
  }

  &__dropdown {
    height: max-content;
    max-width: 630px;
    overflow: hidden;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);

    overflow-y: auto;
    min-width: 150px;

    &::before {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      background-color: #dcdcdc;
      border-radius: 13px;
    }

    .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
      padding: 8px 8px 8px 16px;
    }
  }

  &__dropdown-item {
    @include description;
    margin: 2px 0;
    padding: 0;

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-dark-text;
    }

    &--selected {
      @include info-700;
      color: $color-dark-text;
    }

    &--inline {
      @extend .feed-filters__dropdown-item;
      display: inline-block !important;
    }

    &__mode-btn {
      padding: 4px 10px;
      background: $color-unselected;
      border: none;
      border-radius: 4px;
      color: $color-dark-grey;
      cursor: pointer;

      .vk-icon {
        path {
          fill: $color-white;
        }
      }

      .betaTag {
        width: 33px;
        height: 16px;

        rect {
          fill: $color-black;
        }
      }

      u .telegram-icon {
        path:nth-child(2) {
          fill: $color-white;
        }
      }

      &--active {
        color: $color-border-white;
        background: $color-black;

        path {
          fill: $color-white;
        }

        .betaTag {
          width: 33px;
          height: 16px;

          path:first-child {
            fill: $color-white;
          }

          path:last-child {
            fill: $color-black;
          }
        }

        .vk-icon {
          path {
            fill: $color-black;
          }
        }

        .threads-icon {
          path {
            fill: unset;
          }
        }

        .telegram-icon {
          path:nth-child(2) {
            fill: $color-black;
          }
        }

        .snapchat-icon {
          path:nth-child(2) {
            fill: $color-black;
          }
        }
      }

      &--margin {
        margin-right: 6px;
      }

      &__title {
        @include flex-body-centered;
        gap: 8px;

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    &__risk-slider {
      @include flex(column, center, center);
      padding: 10px;
      gap: 10px;
      background: $color-white;
      border: 1px solid $color-border-grey;

      span {
        margin: 0 10px;
      }

      .ant-dropdown-menu-title-content {
        @include flex-body-centered;
        width: 80%;
      }

      .ant-slider {
        width: 100%;
      }

      .ant-slider-track.ant-slider-track-1 {
        background-color: $color-black !important;
      }

      .ant-slider-handle {
        border-color: $color-black !important;
        background-color: $color-black;
      }

      .ant-slider-rail {
        width: 100%;
        background-color: $color-grey-scale-100;
      }
    }

    &.disabled-item {
      background-color: #d6d6d4 !important;
      cursor: not-allowed;
      color: #3b393455;
    }

    &:hover {
      color: $color-prime;
      background-color: $color-white;
    }

    .ant-radio-checked .ant-radio-inner {
      border: 1px solid $color-black;
    }

    .ant-radio-inner:after {
      background: $color-black;
    }
  }

  &__filter-icon {
    @include description;
    @include flex-body;
    padding: 10px 8px;
    gap: 4px;
    color: $color-black;
    background: $color-white;
    margin-right: 4px;
    border-radius: 6px;
    border: 1px solid $color-border-grey;

    svg {
      height: 20px;

      path {
        stroke: $color-grey-text;
      }
    }

    .feed-filters__text {
      color: $color-black !important;
    }
  }

  &__filter-not-applied {
    padding: 7px 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__filterby-icon {
    padding: 7px 8px;

    svg {
      width: 20px;
      height: 20px;
    }

    &__filter-opened {
      background-color: $color-unselected;

      path {
        stroke: $color-dark-text;
      }
    }
  }

  &__sentiment-span {
    @include flex-body-centered;
    gap: 8px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__custom-btn {
    &,
    &:focus,
    &:active {
      @include flex-body;
      @include footnote;
      background-color: transparent !important;
      outline: none !important;
      border: none !important;
      cursor: pointer;
      padding: 0;
      margin: 0;
      color: $color-prime;
    }

    &:hover {
      background-color: $color-white;
    }
  }

  &__date {
    background-color: $color-white;
    position: absolute;
    right: 180px;
    z-index: 1;
    margin-top: -8px;
    cursor: pointer;

    .react-datepicker__navigation *::before {
      border-color: $color-grey-text;
    }

    .react-datepicker__navigation-icon {
      top: 8px;
    }

    .react-datepicker__navigation--previous {
      left: initial;
      right: 30px;
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: $color-white;
        border-bottom: none;

        .react-datepicker__current-month {
          text-align: start;
          margin-left: 18px;
        }
      }

      .react-datepicker__month {
        @include subtitle;

        .react-datepicker__day--in-selecting-range {
          background-color: $color-grey-scale-50;
          color: $color-black;
        }

        .react-datepicker__day--outside-month {
          color: $color-grey-text;
        }

        .react-datepicker__day--selecting-range-start,
        .react-datepicker__day--selecting-range-end {
          color: $color-white !important;
          background-color: $color-black !important;
        }

        .react-datepicker__day--keyboard-selected {
          color: $color-black;
          background-color: $color-white;
        }

        .react-datepicker__day:hover {
          background-color: $color-grey-scale-50;
          color: $color-black;
        }

        .react-datepicker__week {
          .react-datepicker__day--in-range {
            background-color: $color-grey-scale-50;
            color: $color-black;
          }

          .react-datepicker__day--range-start {
            color: $color-white;
            background-color: $color-black;
          }

          .react-datepicker__day--range-end {
            color: $color-white;
            background-color: $color-black;
          }
        }
      }
    }
  }

  &__language-div {
    @include flex-body;
    margin-right: 4px;

    &__selected {
      svg {
        path {
          stroke: $color-black;
        }
      }
    }

    svg {
      margin-right: 10px;
    }

    .ant-switch {
      background-color: #bcc1cc;
    }

    .ant-switch-checked {
      background-color: $color-prime !important;
    }
  }

  &__reset-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 8px;
    gap: 4px;

    color: $color-grey-text;
    background-color: $color-white;
    border-radius: 4px;
    border: 0;
    cursor: pointer;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__language-dropdown {
    @include flex-body;
    justify-content: space-between;
    background: #f9f9f9;
    border: 1px solid $color-border-grey;
    border-radius: 3px;
    padding: 4px 10px;
    margin-right: 12px;
    width: 150px;

    &__title {
      @include description;
      margin-right: 8px;
      color: $color-black;
    }

    &__arrow {
      transform: rotate(180deg);
    }

    &__list {
      overflow-y: auto;
      height: 192px;
      width: 150px;
    }

    svg {
      height: 7px;
    }
  }

  &__language-dropdown--selected {
    background: $color-prime;

    &__title {
      color: $color-white;
    }

    svg {
      path {
        stroke: $color-white;
      }
    }
  }

  &__export-feed {
    @include flex-body;
    cursor: pointer;

    &__label {
      @include info;
      color: $color-grey-text;
      margin-left: 2px;
    }
  }

  &__actions {
    @include flex-body;
    cursor: pointer;
  }

  &__actions-button {
    padding: 10px 16px !important;
    border-radius: 6px !important;
    background-color: $color-grey-18 !important;
    color: $color-grey-19 !important;

    &__menu {
      display: flex;
      width: 196px;
      padding: 4px !important;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid $color-border-grey-2;
      background: $color-white;
      box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.1);

      &__item {
        padding: 0px !important;

        &:hover {
          background-color: transparent !important;
        }

        span {
          @include subtitle;
          color: $color-black;
          display: flex;
          padding: 8px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        }
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: #16191d;
        }
      }
    }

    svg {
      margin-bottom: 0 !important;

      path {
        stroke: $color-grey-19 !important;
        stroke-width: 2px;
      }
    }
  }

  .ant-radio-group {
    margin-bottom: 12px;
  }
}
