@import 'styles/colors.scss';
.detail_view_sections_container {
  //   background-color: gold;
  margin-top: 40px;

  .dvsc_header {
    display: flex;
    align-items: center;
    gap: 8px;
    .dvsc_c_header_text {
      color: $color-dark-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  .dvsc_content_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;
    .dvsc_cc_section_text {
      color: $color-dark-grey;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      cursor: pointer;
      &.dvsc_cc_section_text_active {
        color: $color-dark-text;
        font-weight: 500;
      }
    }
  }
}
