@import 'styles/titles.scss';
@import 'styles/colors.scss';
@import 'styles/variables.scss';

.date-time-filter-container {
  gap: 24px;
  display: flex;
  align-items: center;

  .time-inputs-container {
    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .enable-disable-switch {
      gap: 16px;
      display: flex;
      align-items: center;

      span {
        font-weight: 500;
        color: $color-grey-19;
      }

      .amc_switch {
        background-color: $color-grey-18;
        height: 24px;

        .ant-switch-handle {
          width: 20px;
          height: 20px;
          &::before {
            border-radius: 10px;
          }
        }
        &.ant-switch-checked {
          background-color: $color-prime;
          &:hover {
            background-color: $color-primary-300_1;
          }
          .ant-switch-handle {
            left: calc(100% - 20px - 2px);
          }
        }
        &:focus {
          box-shadow: none;
        }
      }
    }

    .input-label-container {
      gap: 8px;
      display: flex;
      flex-direction: column;

      label {
        font-weight: 500;
        color: $color-grey-19;
      }

      .input-component-container {
        gap: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .input-box {
          gap: 4px;

          display: flex;
          flex-direction: column;

          &__arrow-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          input {
            text-align: center;
            outline: none;
            width: 45px;
            height: 40px;
            border-radius: 8px;
            color: $color-grey-19;
            border: 1px solid $color-grey-17;
            box-shadow: 0px 1px 2px 0px #1018280d;
          }

          input[type='number']::-webkit-inner-spin-button,
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .am-pm-selection {
          width: 76px;
          height: 40px;
          border-radius: 8px;
          outline: none;
          border: 1px solid $color-grey-17;
          box-shadow: 0px 1px 2px 0px #1018280d;
          background-color: $color-white;
          padding: 4px 12px;
        }
      }
    }
  }
}

.input-component-container {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .input-box {
    gap: 4px;

    display: flex;
    flex-direction: column;

    &__arrow-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      text-align: center;
      outline: none;
      width: 45px;
      height: 40px;
      border-radius: 8px;
      color: $color-grey-19;
      border: 1px solid $color-grey-17;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .am-pm-selection {
    width: 76px;
    height: 40px;
    border-radius: 8px;
    outline: none;
    border: 1px solid $color-grey-17;
    box-shadow: 0px 1px 2px 0px #1018280d;
    background-color: $color-white;
    padding: 4px 12px;
  }
}
