@import 'styles/titles.scss';
@import 'styles/colors.scss';

.details-section {
  padding: 27px 40px;
  overflow: auto;
  height: 93vh;

  .summary-text {
    overflow-y: scroll;
    margin-bottom: 75px;
  }

  &__back-btn {
    @include flex-body;
    cursor: pointer;

    &--title {
      font-size: 14px;
      color: $color-prime;
      margin: 0 10px;
    }
  }

  &__close-icon {
    cursor: pointer;
  }

  &__header-div {
    @include flex(row, center, space-between);
    margin-bottom: 37px;
  }

  &__title {
    @include title;
    margin: 24px 0;
    color: $color-dark-text;
    flex-wrap: wrap;
    flex: 1;
  }

  &__summary {
    @include flex(row, center, space-between);

    &__title-div {
      @include flex-body;

      svg {
        margin-right: 12px;
      }
    }

    &__title {
      @include subtitle;
      color: $color-dark-text;
      margin-right: 7px;
    }

    &__icon {
      path {
        stroke: $color-prime;
      }
    }

    &__right {
      &__like-dislike-btn {
        &,
        &:focus,
        &:active {
          @include flex-body;
          outline: none !important;
          border: none !important;
          cursor: pointer;
          color: $color-prime;
          background: $color-white;
        }

        svg {
          margin-right: 8px;
          cursor: pointer;
        }
      }

      &__like-dislike {
        @include flex-body;
        padding: 0px 8px;
        border-radius: 4px;
        margin-right: 4px;

        &--active {
          transform: rotate(30deg);
        }

        span {
          background-color: transparent;
        }

        svg {
          path {
            fill: $color-prime;
          }
        }

        .ds_s_r_ld_icon {
          path {
            stroke: $color-prime;
            fill: none;
          }
        }
      }

      &__like-dislike.hover {
        cursor: pointer;
      }
    }
  }

  &__loading {
    @include flex(column, center, center);
    width: 100%;
    padding: 10px 0px 0px;
    height: 120px;
    background: $color-primary-3;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 40px;

    &__wait-text {
      @include small-bold-title;
      color: $color-black;
      margin-bottom: 2px;
      margin-top: 10px;
    }

    &__description {
      @include description;
      color: $color-black;
    }
  }

  &__description {
    @include body;
    margin-top: 16px;
    margin-bottom: 40px;
    color: $color-dark-grey;

    &--more-btn {
      @include subtitle;
      color: $color-black;
      cursor: pointer;
      white-space: pre-wrap;
    }
  }

  &__view-feed-btn {
    @include flex-body-centered;
    @include button-text;
    padding: 7px 16px;
    background: $color-black;
    border: 1px solid $color-black;
    border-radius: 4px;
    color: $color-white;
    margin-left: 30px;
    cursor: pointer;
  }

  &__power-insights-btn {
    height: 40px;
    background-color: $color-black;
    border-radius: 6px;
    padding: 10px 16px;
    color: $color-white;
  }

  &__topics {
    margin-top: 24px;
    margin-bottom: 16px;

    &__title {
      @include heading;
      color: $color-black;
    }

    &--margin {
      margin-bottom: 0px;
    }
  }

  &__bar-chart {
    &__tooltip {
      @include flex-body-centered;
      padding: 8px 10px;
      width: 78px;
      height: 32px;
      background: $color-black;
      border-radius: 4px;

      &__label {
        @include footnote;
        color: $color-white;
      }
    }
  }

  &__pie-chart {
    &__label {
      @include footnote;
      text-align: center;
      color: $color-black;
    }

    &__transform {
      @extend .details-section__pie-chart__label;
      transform: translateY(-10px);
    }

    &__list {
      list-style-type: none;
      padding-left: 0 !important;

      li {
        :hover {
          background-color: $color-primary-6;
        }
      }
    }

    &__legend {
      @include flex(row, center, space-between);
      margin-bottom: 12px;

      &__labels {
        @include footnote;
        color: $color-grey-11;
      }

      &__margin {
        margin-right: 52px;
      }

      &__list {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }

  .recharts-wrapper {
    text {
      cursor: pointer;

      &:hover {
        fill: $color-prime;
        text-decoration: underline;
      }
    }
  }

  .recharts-legend-wrapper {
    right: 18px !important;
  }
}
