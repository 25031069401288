@import 'styles/colors.scss';

.power-insights {
  &.power-insights_main_dashboard {
    padding: 0px;
    height: calc(100vh - 135px);
  }
  .action-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-grey-19;
    box-sizing: border-box;
    padding: 8px;

    svg {
      height: 16px;
      width: 16px;
      color: $color-dark-grey;
      stroke: $color-dark-grey;
    }
  }
}

.ant-modal.base-modal.creator-page-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: calc(100% - 70px) !important;
  margin-left: 70px !important;
  border-radius: 0px !important;
  padding: 0px !important;

  .ant-modal-content {
    padding: 0 !important;
    border-radius: 0;
  }

  .creator-page-modal__content {
    display: flex;
    height: calc(100vh - 58px);
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    justify-content: space-between;
    padding-bottom: 0px;

    .ant-spin-nested-loading:has(.creator-details) {
      overflow-y: auto;
      width: 100%;
    }

    &__snippets {
      width: 588px;
      height: 100%;
      min-width: 588px;
      position: relative;
      border-left: 1px solid $color-border-grey;
    }

    .ant-spin.ant-spin-spinning.creator-page-modal__snippets-loading {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
    }

    .creator-details {
      min-width: 684px;
      padding-top: 16px;
    }

    .creator-details__watchlists {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;

      &__grid {
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        justify-items: stretch;
      }

      &__title {
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__count {
        white-space: nowrap;
      }

      &__container {
        width: 100%;
      }
    }
  }
}

.rbc_c_create_text {
  color: $color-dark-text;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0;
}
.rbc_text {
  display: flex;
  align-items: center;
  gap: 8px;
}
.rbc_c_text {
  color: $color-grey-text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
