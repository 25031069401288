@import 'styles/colors.scss';
@import 'styles/titles.scss';

.creators-list {
  overflow: auto;

  &__table {
    &__checkbox {
      @include flex-body-centered;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      border: 1px solid $color-grey-17;
      cursor: pointer;

      &__selected {
        background-color: $color-prime;
        border-color: $color-prime;
      }
    }

    &__title {
      @include flex-body;
      gap: 4px;
    }

    &__sortIcon {
      transform: rotate(180deg);
    }

    &__selectedRow {
      background-color: $color-primary-3;
    }

    thead > tr > .ant-table-cell {
      @include info;
      padding: 8px 12px;
      color: $color-dark-grey;
      border: none;
      border-top: 1px solid $color-border-grey-2;
      border-bottom: 1px solid $color-border-grey-2;
      background: $color-grey-20;
      height: 44px;

      &::before {
        width: 0 !important;
      }
    }

    tbody > tr > .ant-table-cell {
      padding: 16px 12px;
      height: 72px;
      cursor: pointer;
    }

    tbody > tr > .ant-table-cell-row-hover {
      background: none !important;
    }

    .ant-table-column-sorter {
      display: none;
    }

    .ant-table-column-sort {
      background: none;
    }

    .ant-pagination-item-active {
      border: none;

      a {
        color: $color-white !important;
        border-radius: 50%;
        background-color: $color-prime;
      }
    }

    .ant-pagination-item {
      border: none;

      a {
        @include footnote;
        color: $color-grey-600;
        line-height: 30px;
      }

      &:hover,
      &:active,
      &:focus {
        a {
          @include footnote;
          color: $color-grey-600;
          line-height: 30px;
        }
      }
    }

    .ant-pagination-item-link {
      border: none !important;

      svg {
        fill: $color-grey-600;
        width: 16px;
        height: 16px;
      }
    }

    .ant-pagination-disabled {
      svg {
        fill: $color-grey-17;
      }
    }
  }

  &__count {
    @include description-16;
    color: $color-dark-grey;
  }
}

.power-insights-creator-info {
  @include flex-body;
  gap: 16px;

  &__container {
    @include flex-body;
    gap: 12px;
  }

  &__container2 {
    @include flex-body-column;
    gap: 4px;
    min-width: 120px;
  }

  &__container3 {
    @include flex-body;
    gap: 6px;
    width: 250px;
    line-break: anywhere;
  }

  &__name {
    @include info;
    color: #000;
  }

  &__link {
    @include footnote;
    color: $color-dark-grey;
  }

  &__avatar {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__platform {
    object-fit: fill;
  }

  &__view-icon {
    @include flex-body-centered;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: $color-primary-700_1;
    cursor: pointer;

    svg {
      fill: $color-prime;
      height: 16px;
      width: 16px;
    }
  }
}

.selection-overlay {
  @include flex-body;
  width: 392px;
  height: 48px;
  border-radius: 8px;
  background: $color-white;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

  position: fixed;
  bottom: 8%;
  left: 44%;
  z-index: 10000;

  &__count {
    @include flex-body-centered;
    @include title-18;
    height: 100%;
    width: 48px;
    border-radius: 6px 0px 0px 6px;
    background: $color-prime;
    color: $color-white;
    text-align: center;
  }

  &__creator-div {
    @include flex(row, center, space-between);
    padding: 2px 28px 2px 6px;
    width: 296px;
  }

  &__creator-text {
    @include subtitle;
    color: $color-black;
    text-align: center;
  }

  &__apply-filters {
    @include flex-body-centered;
    @include subtitle;
    border: none;
    background-color: transparent;
    color: $color-dark-grey;
    cursor: pointer;
  }

  &__close-icon {
    @include flex-body-centered;
    width: 48px;
    height: 100%;
    border-left: 1px solid $color-grey-17;
    cursor: pointer;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.table-skelton {
  .header {
    width: 100%;
    height: 42px;
  }

  .row {
    height: 77px;
    width: 100%;
    margin: 2px 0px;
    background-color: #f9fafb;
    animation: mymove 2s infinite;
    animation-delay: 100ms;
    animation-timing-function: ease-in-out;
  }

  .row-stats {
    height: 20px;
    width: 100%;
    margin-bottom: 16px;
    background-color: #f9fafb;
    animation: mymove 2s infinite;
    animation-delay: 100ms;
    animation-timing-function: ease-in-out;
  }

  .pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .pagination {
      margin-top: 16px;
      margin-bottom: 16px;
      height: 32px;
      width: 50%;
      background-color: #f9fafb;
      animation: mymove 2s infinite;
      animation-delay: 100ms;
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes mymove {
  from {
    background-color: #f9fafb;
  }

  to {
    background-color: $color-border-grey-2;
  }
}
