@import 'styles/colors.scss';
@import 'styles/titles.scss';

.dsrMain {
  position: relative;
  margin-top: 30px;
  z-index: 1;

  .dsrContainer {
    display: flex;
    padding: 10px 16px 10px 12px;
    gap: 8px;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      // background-color: gold;
      margin-bottom: 0;
    }

    &:hover {
      background-color: $delete-label;
      border-radius: 4px;

      .dsrTrash {
        cursor: pointer;

        path {
          stroke: red;
        }
      }
    }

    &.onFlagClick {
      padding: 8px 16px;
      background-color: $delete-label;
      border-radius: 4px;
    }

    .dsrBullet {
      width: 6px;
      height: 6px;
    }

    .dsrTitle {
      color: $color-dark-text;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 0;
      flex: 1;
    }

    .bold {
      font-weight: 600;
    }

    .dsrTrash {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-left: auto;
    }

    .deleteLink {
      margin-bottom: 0;
      color: $delete-label-text;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding-left: 70px;
      cursor: pointer;
    }

    .undoLink {
      margin-bottom: 0;
      color: $color-grey-text;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding-left: 170px;
      cursor: pointer;
    }

    .close {
      width: 10px;
      height: 10px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
