@import 'styles/variables.scss';

.c-analyze-page {
  padding: 32px 8px;
  width: 98%;
  margin-right: auto;
  margin-left: auto;
}

.c-page-heading {
  font-weight: 500;
  font-size: 30px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 44px;

  &__star {
    margin-right: 9px;
  }

  &__title {
    font-size: 30px;
    margin: 0 16px 16px 32px;
  }
}
