@import 'styles/colors.scss';
@import 'styles/titles.scss';

.clip-report-modal {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    &__title {
      color: $color-black;
      @include title;
    }

    &__description {
      color: $color-grey-text;
      @include description;
    }
  }

  &__content {
    @include flex-body-column;
    padding: 20px 32px;
    gap: 20px;
    align-self: stretch;
  }

  &__report_title {
    @include flex-body-column;
    gap: 6px;
    align-self: stretch;

    &__label {
      @include subtitle;
      color: $color-grey-19;

      &:after {
        content: ' *';
        color: $delete-label-text;
      }
    }

    &__input {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid $color-grey-17;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &:focus,
      &:hover,
      &:active {
        border: 1px solid $color-grey-17;
        outline: none;
      }
    }

    &__error {
      @include footnote;
      color: #ff4d4f;
    }
  }

  &__info_container {
    @include flex(row, center, space-between);
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;

    &__selection_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      &__label {
        color: $color-grey-text;
        @include description;
      }

      &__text {
        @include subtitle-600;
        color: $color-dark-text;
      }

      &__date {
        @include subtitle;
        color: $color-grey-19;
      }
    }

    &__filters {
      @include flex-body-centered;
      border-radius: var(--Border-radius-xl, 16px);
      background: $color-black;
      padding: 8px 16px;
      gap: 8px;

      &__text {
        @include description;
        color: $color-white;
      }
    }
  }

  &__info_container_2 {
    @include flex-body-column;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    gap: 16px;

    &__description {
      @include description;
      color: $color-grey-22;
    }

    &__div {
      @include flex(row, center, space-between);
      width: 100%;

      &__label {
        color: $color-dark-text;
        @include subtitle;
        flex: 1.1;
      }
    }

    &__div_2 {
      @include flex-body-column;
      gap: 8px;
      flex: 0.9;
    }

    &__div_3 {
      @include flex-body;
      gap: 8px;

      &__label {
        @include subtitle;
        color: $color-grey-19;
      }
    }

    &__select {
      flex: 0.9;

      .ant-select-selector {
        padding: 10px 14px !important;
        align-items: center;
        border-radius: 8px !important;
        border: 1px solid $color-grey-17 !important;
        background: $color-white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
      }

      .ant-select-selection-item {
        @include description-16;
        overflow: hidden;
        color: $color-dark-grey !important;
      }
    }
  }

  &_dropdown {
    padding: 10px 14px;
    border-radius: 4px;

    .ant-select-item-option-content {
      @include description;
      color: $color-black;
    }
  }

  &__radio {
    flex: 0.9;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    gap: 8px;

    &__button {
      @include flex-body-centered;
      min-width: 128px;
      padding: 10px 16px;
      gap: 8px;
      border-radius: 6px;
      @include subtitle;
      cursor: pointer;

      &:focus,
      &:hover,
      &:active {
        outline: none;
        box-shadow: none;
        transition: none;
      }
    }

    &__cancel {
      background: $color-unselected;
      border: none;
      color: $color-dark-text;
    }

    &__primary {
      border: 1px solid $color-black;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
        linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
      color: $color-white;
    }
  }

  .ant-modal-content {
    padding: 0;
    border-radius: 16px;
  }

  .ant-modal-header {
    padding: 24px 32px 16px 32px;
    border-bottom: 1px solid $color-border-grey;
  }

  .ant-modal-body {
    display: flex;
  }

  .ant-modal-footer {
    width: 100%;
    padding: 0;
    border-top: 1px solid $color-border-grey-2;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .ant-select-dropdown {
    .ant-select-item {
      color: $color-black;

      .ant-select-item-option-content {
        @include description-16;
        display: flex;
        align-items: center;
        overflow: hidden;
        color: $color-grey-text;
        text-overflow: ellipsis;
      }

      .ant-select-item-option-state {
        height: 22px;
        display: flex;
        align-items: center;
      }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: $color-black;
      background-color: $color-primary-3;
    }
  }

  .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid $color-grey-17;
    height: 16px;
    width: 16px;
  }

  .ant-checkbox {
    &:hover,
    &:active,
    &:focus,
    &:visited {
      .ant-checkbox-inner {
        border: 1px solid $color-prime;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-prime;
    border-color: $color-prime;
    border: 1px solid $color-prime;
  }
}
