@import 'styles/colors.scss';

.ant-drawer.search-list-drawer {
  .ant-drawer-content-wrapper {
    width: auto !important;
    left: 69px;
    .ant-drawer-content {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
  .ant-drawer-wrapper-body {
    background-color: $color-grey-21;
    box-shadow: -4px 0px 10px 0px #0000000d inset;
    .ant-drawer-header {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0px;
      .ant-drawer-header-title {
        width: 100%;
        justify-content: flex-end;
      }
    }
    .ant-drawer-body {
      padding: 0px;
    }
  }
}

.searchItemList {
  .fixed {
    background-color: $color-grey-21;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    padding: 12px;
    gap: 12px;
    width: 364px;
    .item-container {
      display: flex;
      align-items: center;
      width: 100%;
      .link {
        margin-bottom: 20px;
        height: 20px;
        width: 20px;
        border-bottom: 2px dotted $color-primary-2;
        border-bottom-left-radius: 8px;
        color: black;
      }
    }
    .list-section {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }
    .action-section {
      padding-top: 16px;
      padding-left: 16px;
      margin-top: auto;
      margin-bottom: 24px;
      width: 100%;
      border-top: 1px solid $color-grey-18;

      &__add {
        color: $color-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }
  .brand-item {
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    justify-content: space-between;

    .ant-avatar.ant-avatar-circle {
      min-width: 32px;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $color-grey-600;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .search-actions {
      margin-left: auto;
      visibility: hidden;
      .three-dot-icon-disabled {
        cursor: not-allowed;
      }
    }

    &:hover {
      .search-actions {
        visibility: visible;
      }
      background-color: $color-primary-6;
    }
  }

  .brand-item-selected {
    background-color: $color-primary-7;
    .search-actions {
      visibility: visible;
    }
    .title {
      font-weight: 600;
      color: $color-primary-11;
    }
  }
  .folder-container {
    width: 100%;

    .folder-item {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 8px;
      cursor: pointer;
      height: 48px;
      padding: 12px 16px;
      border-radius: 8px;
      box-sizing: border-box;
      .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        text-transform: capitalize;
        color: $color-grey-600;
      }
      .folder-actions {
        visibility: hidden;
        margin-left: auto;
        .three-dot-icon-disabled {
          cursor: not-allowed;
        }
      }

      &:hover {
        .folder-actions {
          visibility: visible;
        }
        background-color: $color-primary-6;
      }
    }
    .folder-item-open {
      .folder-actions {
        visibility: visible;
      }
      background-color: $color-primary-6;
      .title {
        color: $color-grey-900;
      }
    }
    .folder-items-list {
      border-left: 2px dotted $color-primary-2;
      margin-left: 28px;
    }
  }
}

.hideSearchItemList {
  width: 0;
  min-width: 0;
  visibility: hidden;
}

.add-new-brand {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $color-dark-grey;
  padding-left: 16px;
  margin-top: auto;
  margin-bottom: 16px;
}

.search-items-loading .ant-skeleton-paragraph {
  height: 20px;
  overflow: hidden;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
}

.search-items-empty {
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-left: 48px;
  font-size: 14px;
  color: $color-grey-23;
}

.search-items-load-more {
  color: $color-primary-300_1;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-left: 48px;
  cursor: pointer;
}

.action-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $color-grey-19;
  box-sizing: border-box;
  padding: 8px;

  svg {
    height: 16px;
    width: 16px;
    color: $color-dark-grey;
    stroke: $color-dark-grey;
  }
}

.fpc_lc_chunk_dropdown_icon {
  flex-shrink: 0;
  cursor: pointer;
}

.search-list-header {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--title {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__icon {
    background-color: $color-grey-18;
    width: 28px;
    height: 28px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h5 {
    color: $color-grey-600;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }

  h6 {
    color: $color-black;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px;
  }
}

.search-list-header.collapse {
  padding: 0px;
  cursor: pointer;
  width: max-content;
}
