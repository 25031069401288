@import 'styles/colors.scss';
@import 'styles/titles.scss';

.success-modal {
  @include flex(column, center, center);

  &__body {
    @include flex(column, center, center);
    gap: 24px;
  }

  &__title {
    @include title;
    color: $color-black;
  }

  &__description {
    @include description;
    color: $color-dark-grey;
    text-align: center;
  }

  &__close-btn {
    @include flex-body-centered;
    width: 100%;
    background-color: $color-black;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 13px 20px;
    color: $color-white;
    gap: 6px;
    cursor: pointer;
  }

  .ant-modal-content {
    padding: 24px;
    gap: 24px;
  }

  .ant-modal-footer {
    padding: 0px;
    margin: 24px 40px 0 40px;
  }
}
