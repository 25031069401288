@import 'styles/colors.scss';

.main_view_header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .heading {
    color: $color-dark-grey;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }

  .right_side {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}
