@import 'styles/colors.scss';
@import 'styles/titles.scss';

.tenants {
  @include flex-body-column;
  padding: 0px 40px;

  &__navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 0px 40px;
    margin: 0 -40px;
    background: $color-white;
    border-bottom: 1px solid $color-border-grey;

    &__container {
      max-width: 1550px;
      width: 100%;
      height: 100%;
      display: flex;
    }

    .ant-menu {
      height: 100%;
    }

    .ant-menu-title-content a,
    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }

    .ant-menu-title-content a {
      padding-right: 8px;
      padding-left: 8px;
    }

    .ant-menu-horizontal > .ant-menu-item a::before {
      display: none;
    }

    .ant-menu {
      width: 100%;
      position: relative;
    }

    .ant-menu-horizontal {
      background-color: $color-white;
      border: 0;
    }

    .ant-menu-horizontal > .ant-menu-item a {
      @include description;
      color: $color-grey-text;
    }

    .ant-menu-horizontal > .ant-menu-item-open,
    .ant-menu-horizontal > .ant-menu-submenu-open,
    .ant-menu-horizontal > .ant-menu-item-selected,
    .ant-menu-horizontal > .ant-menu-submenu-selected {
      a {
        @include description-600;
        color: $color-dark-text !important;
        border-bottom: 2px solid $color-dark-text;
        padding-bottom: 8px;
      }
    }

    .ant-menu-title-content {
      color: white !important;
      font-size: 14px;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
      display: none;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
      margin-right: 20px;

      &:first-child {
        padding: 8px 8px 0px 0px;
      }

      &:not(:first-child) {
        padding: 8px 8px 0px 8px;
      }
    }
  }
}
