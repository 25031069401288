@import 'styles/colors.scss';
@import 'styles/titles.scss';

.monitor-flags {
  &__container {
    @include flex-body-column;
    gap: 16px;
    height: 100%;
  }

  &__header-div {
    @include flex(row, center, space-between);
    gap: 8px;

    &:has(.dashboard_flags) {
      gap: 14px;
      padding-bottom: 24px;
      border-bottom: 1px solid $color-border-grey-2;
    }

    &__title-div {
      @include flex-body-column;
      gap: 8px;
    }

    &__title {
      @include subtitle;
      color: $color-black;

      &.dashboard_flags {
        font-size: 20px;
        font-weight: 700;
      }
    }

    &__description {
      @include description;
      line-height: 20px;
      color: $color-dark-grey;
    }

    &__type-div {
      @include flex-body-centered;
      cursor: pointer;

      &__bar-icon {
        @include flex-body-centered;
        border-radius: 0px 4px 4px 0px;
        border-top: 1px solid $color-grey-17;
        border-right: 1px solid $color-grey-17;
        border-bottom: 1px solid $color-grey-17;
        border-left: 1px solid $color-primary-11;
        background: $color-grey-20;
        padding: 6px;

        &--active {
          border: 1px solid $color-primary-11;

          svg {
            path {
              fill: $color-primary-11;
            }
          }
        }
      }

      &__sankey-icon {
        @include flex-body-centered;
        border-radius: 4px 0px 0px 4px;
        border-top: 1px solid $color-grey-17;
        border-bottom: 1px solid $color-grey-17;
        border-left: 1px solid $color-grey-17;
        background: $color-grey-20;
        padding: 6px;

        &--active {
          border: 1px solid $color-primary-11;
          border-right: none;

          svg {
            path {
              fill: $color-primary-11;
            }
          }
        }
      }
    }
  }

  &__body {
    @include flex-body-column;
    gap: 16px;
  }

  &__category-div {
    @include flex(row, center, space-between);
    padding: 20px 0px 24px 0px;

    &__border {
      @extend .monitor-flags__category-div;
      border-bottom: 1px solid $color-border-grey;
    }

    &__title {
      @include subtitle;
      color: $color-grey-19;
    }

    &__percentage {
      @include description;
      color: $color-grey-19;
    }

    &__info-div {
      @include flex-body;
      gap: 8px;
    }

    svg {
      cursor: pointer;
    }
  }

  &__empty-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__arrow {
    transform: rotate(90deg);
  }

  &__no-data {
    @include flex-column-centered;
    margin: 24px 0;
    width: 100%;
    gap: 12px;

    &__text {
      color: $color-black;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
