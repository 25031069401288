@import 'styles/colors.scss';

.mtfm_container {
  .mtfm_box_container {
    min-width: 529px;
    min-height: 608px;
    width: 529px;
    padding: 24px 0px;
    border-radius: 16px;
    .cmc_cc_header_container,
    .cmc_cc_footer_container {
      display: none;
    }
    .mtfm_body_container {
      display: flex;
      .mtfm_modal_body_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .mtfm_mbc_header {
          padding: 0px 32px 16px;
          border-bottom: 1px solid $color-pastel-blue;
          .mtfm_mbc_h_top_content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            .mtfm_mbc_htc_title {
              color: $color-dark-text;
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              margin: 0;
            }
            .mtfm_mbc_htc_close_icon {
              cursor: pointer;
              path {
                stroke: $color-grey-scale-600;
              }
            }
          }
          .mtfm_mbc_h_desc {
            color: $color-grey-text;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
            margin-top: 8px;
          }
        }
        .mtfm_modal_bc_content {
          flex-grow: 1;
          padding: 12px 32px;
          height: 488px;
          overflow-y: auto;
          .mtfm_modal_bc_c_chunk {
            padding-block: 20px;
            border-bottom: 1px solid $color-border-grey-2;
            .fpc_lc_chunk_left {
              gap: 16px;
            }
            &:last-child {
              border: none;
            }
          }
        }
        .mtfm_mbc_footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          padding: 16px 24px 0;
          border-top: 1px solid $color-border-grey-2;
          .mtfm_mbc_f_button {
            padding-block: 10px;
            border-radius: 6px;
            height: 40;
            border: 1px solid $color-unselected;
            background-color: $color-unselected;
            color: $color-black;
            svg {
              path {
                stroke: $color-black;
              }
            }
          }
          .mtfm_mbc_f_button_container {
            display: flex;
            align-items: center;
            gap: 8px;
            .mtfm_mbc_f_bc_button {
              border: 1px solid $color-black;
              padding-block: 10px;
              border-radius: 6px;
              height: 40;
              &:disabled {
                border-color: $color-grey-text;
              }
              &:first-child {
                background: none;
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }
}
