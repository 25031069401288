@import 'styles/colors.scss';
@import 'styles/titles.scss';

.calendar-div {
  display: flex;

  &__calendar {
    background-color: $color-white;
    position: absolute;
    right: 0px;
    top: 18px;
    z-index: 1;
    margin-top: -8px;
    cursor: pointer;

    .react-datepicker__navigation *::before {
      border-color: $color-grey-text;
    }

    .react-datepicker__navigation-icon {
      top: 8px;
    }

    .react-datepicker__navigation--previous {
      left: initial;
      right: 30px;
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: $color-white;
        border-bottom: none;

        .react-datepicker__current-month {
          text-align: start;
          margin-left: 18px;
        }
      }

      .react-datepicker__month {
        @include subtitle;

        .react-datepicker__day--in-selecting-range {
          background-color: $color-grey-scale-50;
          color: $color-black;
        }

        .react-datepicker__day--outside-month {
          color: $color-grey-text;
        }

        .react-datepicker__day--selecting-range-start,
        .react-datepicker__day--selecting-range-end {
          color: $color-white !important;
          background-color: $color-black !important;
        }

        .react-datepicker__day--keyboard-selected {
          color: $color-black;
          background-color: $color-white;
        }

        .react-datepicker__day:hover {
          background-color: $color-grey-scale-50;
          color: $color-black;
        }

        .react-datepicker__week {
          .react-datepicker__day--in-range {
            background-color: $color-grey-scale-50;
            color: $color-black;
          }

          .react-datepicker__day--range-start {
            color: $color-white;
            background-color: $color-black;
          }

          .react-datepicker__day--range-end {
            color: $color-white;
            background-color: $color-black;
          }
        }
      }
    }
  }
}
