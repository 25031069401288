@import 'styles/colors.scss';
@import 'styles/titles.scss';
@import 'styles/variables.scss';

.create-narrative {
  background-color: $color-white;
  padding: 32px 40px 0 40px;
  height: 100%;

  &.create-narrative_container {
    padding: 24px 40px 0 40px;
    height: calc(100dvh - 62px);
    width: 500px;
    border-right: 1px solid $color-border-grey;
  }

  &__header {
    @include flex-body;
    gap: 8px;
    margin-bottom: 16px;

    &__back-btn {
      display: flex;
      align-items: center;
      gap: 16px;

      &--container {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        &--icon {
          path {
            fill: $color-prime;
          }

          line {
            stroke: $color-prime;
          }
        }

        &--text {
          font-size: 16px;
          font-weight: 400;
          color: $color-dark-grey;
          margin-bottom: 0;
          line-height: 16px;
        }
      }
    }

    &__title {
      @include title;
      @include flex-body;
      color: $color-dark-text;
      margin: 0 8px;
    }

    span {
      @include flex-body;
    }

    svg {
      cursor: pointer;
    }
  }

  &__main-body {
    height: calc(100vh - 195px);
    overflow-y: auto;
    padding: 0 32px;
    margin: 0 -32px;
    position: relative;

    &--edit {
      height: calc(100vh - 228px);
    }
  }

  &__edit-warning {
    @include flex(column, center, center);
    padding: 32px 0px;
    background: $color-primary-5;
    padding: 32px 56px;
    margin-bottom: 16px;

    &__text {
      @include description;
      text-align: center;
      color: $color-black;
      margin-top: 9px;
    }

    svg {
      path {
        stroke: $color-prime;
        fill: $color-prime;
      }
    }
  }

  &__filter-applied {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: $color-background;
    border: 1px solid $color-border-grey;
    padding: 10px 16px;
    border-radius: 4px;

    &__header {
      @include flex-body;
      justify-content: space-between;
      cursor: pointer;

      &--rotate-icon {
        @extend .create-narrative__filter-applied__header;

        svg {
          transform: rotate(180deg);
        }
      }

      &--title {
        @include flex-body;
        gap: 8px;

        h2 {
          @include tab-selected;
          color: $color-black;
          margin-bottom: 0;
        }

        span {
          @include info;
          @include flex-body;
          width: 20px;
          height: 20px;
          justify-content: center;
          border-radius: 50%;
          background-color: $color-black;
          color: $color-white;
          padding: 2px 6px;
        }
      }
    }

    &__body {
      margin-top: 16px;
      padding-bottom: 16px;
    }
  }

  &__copilot-switch-btn {
    &__wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;
    }

    border: 0;
    padding: 0;
    height: 20px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-prime;

    &:focus,
    &:hover,
    &:active,
    &:visited {
      color: $color-prime;
    }

    span {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &__sparkle-icon {
      path {
        fill: $color-prime;
      }
    }

    &__info-icon {
      width: 16px;
      height: 16px;

      path {
        stroke: $color-prime;
      }
    }
  }

  &__copilot-action-btn {
    &__wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;
    }

    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border: 0;
    border-radius: 4px;
    padding: 10px 8px;

    color: $color-black;
    background-color: $color-unselected;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &:focus,
    &:hover,
    &:active,
    &:visited {
      color: $color-black;
      background-color: $color-unselected;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    &:disabled {
      color: $color-grey-text;
      background-color: $color-unselected;
    }

    &.disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-grey-text;

      .btn-prefix {
        fill: $color-grey-text;
      }

      &:focus,
      &:hover,
      &:active,
      &:visited,
      &:disabled {
        color: $color-grey-text;
        background-color: $color-unselected;
      }
    }
  }

  &__input-wrapper {
    @include flex-body-column;

    background-color: $color-background;
    border: 1px solid $color-border-grey;
    border-radius: 4px;

    padding: 10px 16px;
    margin-bottom: 16px;

    &__label {
      user-select: none;

      .boolean-search-label-div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .feedback-icons {
          display: flex;
          align-items: flex-start;
          gap: 4px;

          &__icon {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
      }
    }

    .ant-form-item-control {
      margin-bottom: 0 !important;

      .ant-input,
      .ant-input-status-error {
        margin-bottom: 0 !important;
        padding: 0;
        border: 0;

        line-height: 20px;
        color: $color-black;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $color-grey-text;
          opacity: 1;
          /* Firefox */
        }

        &:focus {
          box-shadow: unset !important;
        }
      }
    }
  }

  &__form--span {
    @include flex-body;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: $color-black;
    gap: 4px;
    margin-bottom: 8px;

    &.greyed {
      color: $color-grey-text;
    }

    &__no-margin {
      margin-bottom: 0;
    }

    &__prefix-icon {
      height: 12px;
      width: 12px;

      path {
        fill: $color-black;
      }
    }

    &__info-icon {
      height: 12px;
      width: 12px;
      cursor: pointer;

      path {
        stroke: $color-prime;
      }
    }

    &--no-margin {
      @extend .create-narrative__form--span;
      margin-bottom: 0;
    }
  }

  &__form-twitter-switch {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    background: $color-background;
    border: 1px solid $color-border-grey;
    border-radius: 4px;
    padding: 6px 16px;

    &__container {
      @include flex-body;
      justify-content: space-between;
    }

    &__twitter {
      @include flex-body;

      h2 {
        @include tab-selected;
        color: $color-black;
        margin-bottom: 0;
      }
    }

    &__checkbox {
      display: flex;
      flex-direction: column;

      &--span {
        @include body;
      }
    }

    .margin {
      margin-top: 16px;
    }

    svg {
      margin-right: 5px;
    }

    .ant-form-item-control {
      margin-bottom: 0px;
    }
  }

  &__search-btn {
    &--result-found {
      @include button-text;
      @include flex-body;
      background-color: $color-grey-text;
      color: $color-white;
      padding: 12px;
      width: 100%;
      justify-content: center;
      border-radius: 6px;
      border: none;
      cursor: pointer;
      margin-top: 16px;
    }

    &--primary {
      background-color: $color-black;
      color: $color-white;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__copilot-tooltip {
    ul {
      padding: 0px;
      margin-left: 16px;
    }
  }

  &__boolean-search-tooltip {
    list-style-type: none;
    padding: 0;

    .here-button {
      color: $color-white;
      background-color: transparent;

      padding: 0;
      border: 0;
      border-bottom: 1px dotted $color-white;

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      cursor: pointer;
    }
  }

  &__select-wrapper {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    label {
      @include info;
      display: block;
      color: $color-black;
      margin-bottom: 8px;

      span {
        color: $color-grey-text;
      }

      .cn_sw_info_icon {
        height: 12px;
        width: 12px;
        cursor: pointer;
        margin-left: 4px;
        margin-bottom: -2px;

        path {
          stroke: $color-prime;
        }
      }
    }
  }

  &__boolean-search-example {
    li {
      list-style-type: disc;
    }

    ul {
      li {
        list-style-type: circle;
      }
    }
  }

  &__result-found {
    background-color: $color-grey-scale-50;
    border-bottom: 1px solid $color-grey-scale-200;
    text-align: center;
    padding: 32px 51px 20px;

    &__container {
      margin-top: 26px;
    }

    strong {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $color-dark-text;
      margin-bottom: 6px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $color-grey-text;
      margin-bottom: 0;
    }

    &--warning {
      border-bottom: none;
      padding: 20px 46px 32px;

      img {
        margin-bottom: 8px;
      }

      p {
        color: $color-black;
      }
    }
  }

  &__footer {
    padding: 30px 32px;
    background-color: $color-white;
    border-top: 1px solid $color-border-grey;
    box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.06);
    margin: 0 -32px -32px;
    display: flex;
    justify-content: flex-end;

    &__save-btn {
      @include button-text;
      background-color: $color-black;
      color: $color-white;
      border: 1px solid $color-black;
      padding: 10px 16px;
      border-radius: 6px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: #8598ad;
        border: 1px solid #8598ad;
      }
    }
  }

  &__notification {
    &__margin {
      margin-left: 70% !important;
      margin-bottom: -4%;
    }

    &__title {
      @include callout;
      color: $color-black;
    }

    &__description {
      @include description;
      color: $color-grey-text;
    }
  }

  &__language-switch-container {
    @include flex-body;
    justify-content: flex-end;
    margin: 8px 0;

    &__edit-icon {
      margin: 0 8px 0 12px !important;
    }

    &__black-icon {
      path {
        stroke: $color-black;
      }
    }

    svg {
      margin-right: 4px;
    }

    .ant-switch {
      background-color: $color-grey-text;
    }

    .ant-switch-checked {
      background-color: $color-prime !important;
    }
  }

  &__languages-nav-container {
    @include flex-body;
    width: 78%;

    &--left {
      transform: rotate(180deg);
    }

    &--right {
      margin-left: 8px;
    }

    svg {
      cursor: pointer;
    }
  }

  &__languages-nav {
    width: 100%;
    margin-top: 8px;
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .ant-menu-horizontal {
      line-height: normal;
    }
  }

  &__language-count {
    background-color: $color-white;
    border-radius: 50%;
    color: $color-black;
    margin-left: 8px;
    height: 20px;
    width: 20px;
  }

  &__selected-language {
    @include flex(row, center, space-between);
  }

  &__unselected-language {
    @include flex(row, center, flex-end);
  }

  &__select-container {
    @include flex(column, flex-start, space-between);
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    // height: 544px;

    &__description-div {
      @include flex-body-column;
    }

    &__description {
      @include description;
      color: $color-grey-text;
    }

    &__warning {
      @include flex-body-centered;
      @include description;
      background-color: $color-primary-9;
      color: $color-orange;
      padding: 16px;

      svg {
        margin-right: 16px;

        path {
          stroke: $color-orange;
          fill: $color-orange;
        }
      }
    }

    &__search-div {
      @include flex(row, center, space-between);
      width: 100%;
      margin-top: 16px;
    }

    &__dropdown {
      // width: 23% !important;
      // min-width: 23% !important;
      box-shadow: none !important;
      position: absolute;
      z-index: 9999;
      top: 44px !important;

      .rc-virtual-list-scrollbar-thumb {
        border-radius: 13px !important;
        background: $color-grey-13 !important;
        width: 60% !important;
      }

      .ant-select-item {
        padding-left: 0;
      }

      .ant-select-item-option,
      .ant-select-item-option-active,
      .ant-select-item-option-selected {
        background-color: transparent !important;
      }

      .ant-select-item-option,
      .ant-select-item-option-state,
      .ant-select-item-option-selected {
        order: 1 !important;
      }

      .ant-select-item,
      .ant-select-item-option,
      .ant-select-item-option-content {
        order: 2;
        flex-grow: 1;
        color: $color-dark-grey;
      }

      .ant-select-item-option-content {
        margin-left: 28px;
      }

      .ant-select-item-option-selected {
        .ant-select-item-option-content {
          margin-left: 4px;
          color: $color-black;
        }
      }
    }

    &__options {
      display: flex;
    }

    &__buttons {
      @include flex(row, center, flex-end);
      width: 100%;
      margin-top: calc(256px + 22px);

      &__cancel {
        @include button-text;
        @include flex-body-centered;
        height: 34px;
        background-color: $color-white;
        border: 1px solid $color-black;
        color: $color-black;
        padding: 10px 16px;
        border-radius: 4px;
        cursor: pointer;
      }

      &__add {
        @include button-text;
        @include flex-body-centered;
        width: max-content;
        height: 34px;
        background-color: $color-black;
        border: 1px solid $color-black;
        color: $color-white;
        padding: 10px 16px;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 7px;
      }
    }

    svg {
      cursor: pointer;
    }

    .ant-select-selector {
      @include flex-body;
      padding: 5px 12px;
      gap: 8px;
      background: $color-grey-4;
      border: 1px solid $color-border-grey !important;
      border-radius: 4px !important;
      color: $color-black;

      &:hover {
        border: 1px solid $color-border-grey;
      }

      .ant-select-selection-overflow {
        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            display: none;
          }
        }
      }

      .ant-select-selection-placeholder {
        @include flex-body;

        svg {
          margin-right: 4px;
        }
      }

      .custom-multi-select .ant-select-selection-item {
        display: none;
      }
    }
  }

  .no-margin {
    margin-bottom: 0 !important;
  }

  textarea {
    resize: none;
  }

  .ant-input {
    background: $color-background;
    border: 1px solid $color-border-grey;
    border-radius: 4px;
    padding: 8px 16px;
    min-height: initial;
    margin-bottom: 12px;

    &::placeholder {
      color: $color-border-grey;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-radio-group {
    margin-bottom: 12px;
  }

  .ant-switch {
    background-color: $color-grey-text;
  }

  .ant-switch-checked {
    background-color: $color-black;
  }

  .ant-form-item-control-input-content {
    padding-right: 0px;
    min-height: 0px;
  }

  .ant-form-item {
    padding: 0;
    margin-bottom: 0;
  }

  .ant-select {
    &.filter-applied-select {
      @include footnote;

      .ant-select-selector {
        background-color: $color-background;
        border-radius: 4px;
        border: 1px solid $color-border-grey;

        &:hover {
          border: 1px solid $color-border-grey;
        }

        .ant-select-selection-overflow {
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              @include footnote;
              @include flex-body;
              color: $color-selector-text;
              background-color: $color-selector-bg;
              border: 1px solid $color-border-grey;
              justify-content: center;
              border-radius: 4px;
              gap: 8px;
            }
          }
        }
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $color-border-grey;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }

  .ant-notification {
    &.ant-notification-bottomLeft {
      left: 60px !important;
    }
  }

  .ant-checkbox-wrapper {
    margin-bottom: 14px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #8598ad;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black;
    border-color: black;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 2px solid #8598ad;
    border-radius: 6px;

    &:focus,
    :hover,
    :active {
      border-color: black;
    }

    &::after {
      width: 8px;
      height: 12px;
    }
  }

  .ant-checkbox {
    font-size: 20px;

    &:hover {
      .ant-checkbox-inner {
        border-color: black;
      }
    }
  }

  .ant-menu {
    height: 100%;
  }

  .ant-menu-title-content span,
  .ant-menu-title-content {
    display: flex;
    align-items: center;
  }

  .ant-menu-title-content span {
    padding: 0 8px 8px 8px;
    user-select: none;
  }

  .ant-menu-horizontal > .ant-menu-item span::before {
    display: none;
  }

  .ant-menu {
    position: relative;
    white-space: nowrap;
  }

  .ant-menu-horizontal {
    background-color: $color-white;
    border: 0;
  }

  .ant-menu-horizontal > .ant-menu-item span {
    @include description;
    color: $color-grey-text;

    &:hover::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 2px solid $color-dark-text;
    }
  }

  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    span {
      @include tab-selected;
      position: relative;
      color: $color-dark-text !important;

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 2px solid $color-dark-text;
      }
    }
  }

  .ant-menu-title-content {
    color: white !important;
    font-size: 14px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    display: none;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0;
    margin: 4px 8px 0px 0px;
  }

  .ant-select-selection-search-input {
    margin-left: 20px !important;
  }

  .twitter-expansion {
    margin-bottom: 16px;
  }

  .twitter-expansion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .twitter-icon {
      width: 16px;
      height: 16px;
    }
  }
}
