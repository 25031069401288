@import 'styles/titles.scss';
@import 'styles/colors.scss';
@import 'styles/variables.scss';

.pulse-content-selection-modal.ant-modal {
  width: 660px !important;
}

.optional-elements {
  gap: 25px;
  display: flex;
  padding-left: 40px;
  position: relative;
  flex-direction: column;

  &__linkage {
    top: -39px;
    left: 20px;
    height: 80%;
    position: absolute;
    border-left: 2px dotted $color-grey-17;
  }
}

.pulse-content-selection-modal.ant-modal .ant-modal-content {
  padding: 24px;

  .ant-modal-body {
    gap: 25px;
    display: flex;
    flex-direction: column;

    .snippets-filters__dropdown-filter-container {
      gap: 12px;
      padding: 20px;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      background-color: $color-grey-20;

      &.optional-filter-container {
        margin-left: 20px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -40px;
          top: -16px;
          bottom: 49.5%;
          border-bottom-left-radius: 20%;
          width: 40px;
          border-left: 2px dotted $color-grey-17;
          border-bottom: 2px dotted $color-grey-17;
        }
      }

      &__optional {
        gap: 12px;
        display: flex;
        align-items: center;
        .option-text {
          font-weight: 500;
          color: $color-grey-22;
        }
      }

      &__title {
        font-weight: 500;
        color: $color-grey-600;
      }

      &__description {
        font-weight: 400;
        color: $color-dark-grey;
      }

      .modal-selected-content-container {
        gap: 12px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        min-height: 40px;
        align-items: center;
        position: relative;
        border-radius: 8px;
        padding: 4px 24px 4px 12px;
        border: 1px solid $color-grey-17;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);
        &::before {
          position: absolute;
          content: 'Select content';
          top: 50%;
          color: #bfbfbf;
          transform: translateY(-50%);
        }

        &:has(.ant-tag.selected-content-tag)::before {
          display: none;
        }

        .drop-down-arrow {
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
        }

        .ant-tag.selected-content-tag {
          display: flex;
          align-items: center;
          padding: 4px 6px;
          border-style: dotted;
          border-radius: 12px;

          .category {
            font-size: 14px;
            font-weight: 500;
            color: $color-grey-600;
            text-transform: capitalize;
          }

          .category-values {
            margin-left: 24px;
            font-weight: 500;
            position: relative;
            color: $color-primary-1;
            text-wrap: wrap;
            &::before {
              content: '';
              position: absolute;
              left: -12px;
              top: 50%;
              transform: translateY(-50%);
              height: 75%;
              width: 2px;
              background: $color-grey-17;
            }
          }
        }
      }

      &__select.ant-select {
        .ant-select-selector {
          border-radius: 8px;
          background: $color-white;
          border: 1px solid $color-grey-17;
          box-shadow: 0px 1px 2px 0px #1018280d;
        }
      }

      .ant-tabs.ant-tabs-top {
        left: 0px;
        top: 100%;
        width: 100%;
        z-index: 9999;
        position: absolute;
        border-radius: 12px;
        background-color: $color-white;
        box-shadow: 0px 3px 8px 0px #00000026;

        .ant-tabs-nav-list {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .ant-tabs-tab {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-left: 12px;

            .ant-tabs-tab-btn {
              font-weight: 400;
              color: $color-grey-22;

              .custom-tab-label {
                gap: 8px;
                display: flex;
                align-items: center;

                &__counter {
                  height: 20px;
                  font-size: 12px;
                  min-width: 20px;
                  border-radius: 50%;
                  align-items: center;
                  color: $color-white;
                  display: inline-flex;
                  justify-content: center;
                  background: $color-grey-600;
                }
              }
            }
          }

          .ant-tabs-tab-active {
            width: 100%;

            .ant-tabs-tab-btn {
              font-weight: 500;
              color: $color-grey-600;

              .custom-tab-label {
                gap: 8px;
                display: flex;
                align-items: center;

                &__counter {
                  height: 20px;
                  font-size: 12px;
                  min-width: 20px;
                  border-radius: 50%;
                  align-items: center;
                  color: $color-white;
                  display: inline-flex;
                  justify-content: center;
                  background: $color-grey-600;
                }
              }
            }
          }

          .ant-tabs-ink-bar {
            background-color: $color-grey-600;
          }
        }
      }
    }

    .content-filters-linked {
      padding-left: 20px;
      position: relative;
      .content-select {
        padding: 20px;
        background-color: $color-grey-20;
      }

      .filters-list {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;

        .ant-popover-inner {
          .ant-popover-inner-content {
            max-height: 300px;
            overflow-y: auto;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: -20px;
        top: -16px;
        bottom: 50%;
        border-bottom-left-radius: 20%;
        width: 40px;
        border-left: 2px dotted $color-grey-17;
        border-bottom: 2px dotted $color-grey-17;
      }
    }
  }

  .ant-modal-footer {
    justify-content: flex-end;

    .pulse-content-selection-modal__footer {
      gap: 24px;
      display: flex;
      align-items: center;
      .ant-btn-primary {
        border: none;
        outline: none;
        font-weight: 500;
        border-radius: 6px;
        color: $color-white;
        background: $color-black;
      }
      .ant-btn-text {
        border: none;
        outline: none;
        font-weight: 500;
      }
    }
  }
}
