@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

// TODO: use the same css for sign in and css reset
// use a single scss file
.c-sign-in {
  height: 100vh;
  display: flex;
  align-items: center;

  &__logo {
    position: absolute;
    top: -100px;
    right: 0;
    bottom: auto;
    left: 0;
    display: block;
    margin: auto;
    width: 185px;
    height: 32px;
  }

  .c-heading {
    text-align: center;
    margin-bottom: 24px;
  }

  &__reset {
    text-align: center;
    color: $color-gray-3;
    font-size: 16px;
    font-weight: 300;
    margin-top: 24px;
    margin-bottom: 0;
  }

  .c-card {
    max-width: 550px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .ant-input-password-icon svg {
    width: 23px;
    height: 23px;
  }

  .ant-btn {
    width: 150px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .toplevel-navigation {
    position: absolute;
    // top: 40px;
    text-align: right;
    width: 100%;
    margin: 0;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 40px;
    box-shadow: inset 0px -1px 0px #e2ebfa;

    &__logo-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__right {
        @include flex-body;

        &__container {
          @include flex-body;

          &__contact {
            @include flex-body;

            &__label {
              @include footnote;
              color: $color-grey-text;
              margin-right: 16px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
