@import 'styles/colors.scss';

.create_detail_view_container {
  position: relative;
  .cdvc_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 40px;
    border-bottom: 1px solid $color-border-grey;
    background-color: $color-white;
    gap: 16px;
    .cdvc_h_left {
      display: flex;
      align-items: center;
      gap: 16px;
      .cdvc_h_l_button {
        border-radius: 6px;
        border: 1px solid $color-black;
        color: $color-black;
        padding: 10px 16px;
        height: 40px;
        background: none;
      }
      .cdvc_h_l_info_container {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 10px 16px;
        border-radius: 6px;
        border: 1px solid rgba(254, 240, 199, 0.6);
        background-color: $warning-50-color;
        .cdvc_h_l_ic_text {
          color: $warning-700-color;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
    }
    .cdvc_h_l_button {
      border-radius: 6px;
      padding: 10px 16px;
      height: 40px;
    }
  }
  .cdvc_detail_view_container {
    height: calc(100vh - 130px);
    overflow-y: auto;
  }
  .cdvc_narrative_form {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
  }
}

.cbc_container {
  display: flex;
  align-items: center;
  gap: 24px;
  .cbc_c_link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    .cbc_c_l_close_icon {
      path {
        stroke: $color-prime;
      }
    }
  }
  .cbc_c_create_text {
    color: $color-dark-text;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 0;
  }
}
