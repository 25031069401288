@import 'styles/colors.scss';
.detail_panel_feeds_container {
  //   background-color: gold;
  width: 100%;
  padding-block: 24px;
  border-bottom: 1px solid $color-border-grey;
  background-color: $color-white;
  .dpfc_desc {
    color: $color-dark-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 14px;
    word-break: break-word;
    .dpfc_desc_more_text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $color-blue;
      cursor: pointer;
    }
  }
  .dpfc_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    .dpfc_f_left_section {
      display: flex;
      align-items: center;
      gap: 6px;
      .dpfc_f_ls_divider {
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: $color-grey-15;
      }
      .dpfc_author_details {
        .dpfc_ad_content {
          display: flex;
          align-items: center;
          gap: 6px;
          .dpfc_ad_c_image_container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
            .dpfc_ad_c_image {
              height: 100%;
              width: 100%;
            }
          }
          .dpfc_ad_c_author_name {
            color: $color-grey-15;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin: 0;
          }
        }
      }
      .dpfc_f_ls_views,
      .dpfc_f_ls_date {
        color: $color-grey-15;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
      }
      .dpfc_f_ls_risk_container {
        display: flex;
        align-items: center;
        gap: 2px;
        .dpfc_rc_text {
          color: $color-grey-15;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          margin: 0;
        }
      }
      .dpfc_f_ls_sentiments_container {
        display: flex;
        align-items: center;
        justify-content: center;
        .dpfc_f_ls_sc_icon {
          circle {
            fill: $color-white;
          }
          path {
            fill: $color-grey-text;
          }
        }
      }
    }
    .dfpc_f_right_section {
      //   display: flex;
      display: none;
      align-items: center;
      gap: 8px;
      .dfpc_f_rs_icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        border-radius: 2px;
        background-color: $color-primary-4;
        cursor: pointer;
      }
      .dfpc_f_rs_like_dislike_icon_container {
        display: flex;
        align-items: center;
        background-color: $color-primary-4;
        border-radius: 2px;
        padding-block: 2px;
        .dpfc_ld_icon_container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-inline: 2px;
          cursor: pointer;
          //   width: 16px;
          //   height: 16px;
          &:first-child {
            border-right: 1px solid $color-disabled-grey;
          }
        }
      }
    }
  }
  &:hover {
    .dpfc_footer {
      .dfpc_f_right_section {
        display: flex;
      }
    }
  }
}
