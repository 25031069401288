@import 'styles/colors.scss';

.input_container {
  width: 100%;
  .ic_title {
    color: $color-grey-19;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
  }
  .ic_input {
    width: 100%;
    outline: none;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: $color-white;
    border: 1px solid $color-grey-17;
    color: $color-dark-text;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &::placeholder {
      color: $color-dark-grey;
    }
  }
}
