@import 'styles/colors.scss';
@import 'styles/titles.scss';

.clip-report-success-modal {
  @include flex(column, center, center);

  &__body {
    @include flex(column, center, center);
    gap: 16px;
  }

  &__icon {
    @include flex-body-centered;
    width: 54px;
    height: 54px;
    padding: 13.5px;
    border-radius: 27px;
    background: $success-100;

    svg {
      width: 27px;
      height: 27px;
    }
  }

  &__info_container {
    @include flex(column, center, center);
    gap: 8px;
  }

  &__title {
    @include title;
    color: $color-dark-text;
    text-align: center;
  }

  &__description {
    @include description;
    color: $color-dark-grey;
    text-align: center;
  }

  &__footer {
    width: 100%;
    display: flex;
    gap: 8px;

    &__button {
      flex: 1;
      @include subtitle;
      min-width: 128px;
      padding: 10px 16px;
      gap: 16px;
      border-radius: 6px;
      cursor: pointer;

      &:focus,
      &:hover,
      &:active {
        outline: none;
        box-shadow: none;
        transition: none;
      }
    }

    &__close {
      background: $color-unselected;
      border: none;
      color: $color-dark-text;
    }

    &__primary {
      border: 1px solid $color-black;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
        linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
      color: $color-white;
    }
  }

  .ant-modal-content {
    @include flex-body-column;
    padding: 32px;
    gap: 24px;
    border-radius: 8px;
    background: $color-white;
  }

  .ant-modal-footer {
    @include flex-body;
    width: 100%;
    padding: 0;
  }
}
