@import 'styles/colors.scss';

.list_view_section_container {
  .lvsc_table {
    thead {
      background-color: $color-grey-20;
      border-top: 1px solid $color-border-grey-2;
      border-bottom: 1px solid $color-border-grey-2;
      tr {
        th {
          padding: 12px !important;
          span {
            text-transform: none !important;
          }
        }
      }
    }
    .lvsc_th_checkbox_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      border: 1px solid $color-grey-17;
      cursor: pointer;
      &.lvsc_th_checkbox_container_selected {
        background-color: $color-prime;
        border-color: $color-prime;
      }
      .lvsc_th_cc_line {
        height: 2px;
        width: 10px;
        border-radius: 10px;
        background-color: $color-white;
      }
    }
    .lvsc_name_container {
      display: flex;
      align-items: center;
      gap: 12px;
      .lvsc_nc_text {
        line-height: 20px;
        color: $color-dark-text;
      }
      .lvsc_mc_type_text {
        padding: 4px 12px;
        border-radius: 4px;
        background-color: $color-light-pink;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $color-pink-700;
        text-align: center;
        &.lvsc_mc_type_text_communities {
          background-color: $color-orange-50;
          color: $color-orange-700;
        }
        &.lvsc_mc_type_text_watchlists {
          background-color: $color-purple-50;
          color: $color-purple-700;
        }
      }
    }

    .lvsc_bell_icon {
      cursor: pointer;
      path {
        stroke: $color-dark-grey;
      }
    }
  }
}
