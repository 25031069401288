@import 'styles/variables.scss';
@import 'styles/titles.scss';
@import 'styles/colors.scss';

.define-advanced-modal {
  &__header {
    @include title;
    text-align: left;
  }

  &__wrapper {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:last-child {
      height: 250px;
      overflow: scroll;
    }

    label {
      @include heading;
      display: block;
      color: $color-black;
      margin-bottom: 8px;

      span {
        color: $color-grey-text;
      }
    }

    .ant-input {
      height: initial;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 16px;
      gap: 8px;
      background: $color-background;
      border: 1px solid $color-border-grey;
      border-radius: 4px;
    }
  }

  &__publish {
    .ant-modal-footer .ant-btn-primary {
      @include button-text;
      width: 100%;
      background: $color-black;
      border-color: $color-black;
      color: $color-white;
      border-radius: 8px;
    }

    .ant-modal-header {
      padding: 0px 0px 20px 0px;
    }

    .ant-modal-content {
      padding: 32px;
    }

    .ant-modal-footer {
      padding: 0;
    }
  }

  &__publish-button {
    background-color: $color-primary-4 !important;
  }

  &__publish-menu {
    padding: 0px;
    padding-bottom: 10px;

    .ant-row.ant-form-item.c-define__item.c-define__item--1 {
      display: flex;
      flex-direction: column;
    }

    .ant-col.ant-form-item-label {
      label {
        float: left;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
