@import 'styles/titles';
@import 'styles/colors';

.agreement {
  z-index: 10000;

  &__title {
    @include flex(row, flex-start, flex-start);
    @include title;
    color: $color-black;
    border-bottom: 1px solid $color-border-grey;
    padding: 20px 32px;
  }

  &__main-div {
    @include flex-body-column;
    height: 93%;
    gap: 24px;
  }

  &__content-div {
    @include flex-body-column;
    gap: 16px;
    height: 100%;
  }

  &__description {
    @include description;
    color: $color-dark-grey;
  }

  &__content {
    flex: 2;
    padding: 16px;
    background: $color-grey-20;
    overflow: scroll;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
  }

  &__checkbox {
    @include flex-body;
    gap: 12px;

    &__title {
      @include subtitle;
      color: $color-grey-600;
    }
  }

  &__footer {
    @include flex(row, center, flex-end);
    padding: 20px 24px;
    width: 100%;
    border-top: 1px solid $color-border-grey;
    gap: 16px;

    &__button {
      @include subtitle;
      width: 100px;
      border-radius: 6px;
      padding: 10px 16px;
      cursor: pointer;

      &--disable {
        @extend .agreement__footer__button;
        background: $color-disabled-grey;
        border: 1px solid $color-disabled-grey;
        color: $color-white;
      }

      &--active {
        @extend .agreement__footer__button;
        background: $color-black;
        border: 1px solid $color-black;
        color: $color-white;
      }

      &--decline {
        @extend .agreement__footer__button;
        color: $color-black;
        border: 1px solid $color-black;
        background: $color-white;
      }
    }
  }

  .ant-modal-content {
    @include flex-body-column;
    padding: 0px;
    height: 900px;
  }

  .ant-modal-header {
    padding: 0px;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-modal-body {
    @include flex(column, center, flex-start);
    flex: 2;
    padding: 20px 40px;
    height: 70%;
  }

  .ant-modal-footer {
    display: initial;
    padding: 0px;
    border-radius: 0px 0px 8px 8px;
  }

  .ant-checkbox-inner {
    border-radius: 6px;
    border: 1px solid $color-grey-17;
    height: 20px;
    width: 20px;
  }

  .ant-checkbox {
    &:hover,
    &:active,
    &:focus,
    &:visited {
      .ant-checkbox-inner {
        border: 1px solid $color-prime;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-prime;
    border-color: $color-prime;
    border: 1px solid $color-prime;
  }
}
