@import 'styles/variables.scss';
@import 'styles/titles.scss';
@import 'styles/colors.scss';

.insightContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 16px;
  padding: 32px;
  width: 440px;
  display: inline-flex;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $color-border-grey-2;
  box-shadow: 0px 1px 2px 0px #00000005;
  .heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $color-secondary-black;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-grey;
    text-align: center;
    .info {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $color-dark-grey;
    }
  }
}

.snippetsContainer {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  gap: 30.03px;
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
  .message {
    gap: 6px;
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    color: $color-grey-19;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: $color-dark-grey;
    text-align: center;
  }
}
