.c-reset-pass-stage-3 {
  &__checkmark {
    &-container {
      display: flex;
      margin: 20px auto;
      width: 112px;
      height: 112px;
      background-color: rgba($color: #15cb27, $alpha: 0.1);
      border-radius: 12px;
    }

    &-check {
      margin: auto;
      width: 36px;
      height: 36px;
      fill: rgba($color: #15cb27, $alpha: 1);
    }
  }

  &__succes-info {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }
}
