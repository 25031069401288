.c-reset-pass-stage-2 {
  &__password-field {
    &-check {
      width: 18px !important;
      height: 18px !important;
      fill: #15cb27;
      margin-right: 12px;
      margin-bottom: 2px;
    }

    &--valid {
      border-color: #15cb27;
    }
  }

  &__label-pwd-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #232222;
    margin: -15px 0;
  }
}
