@import 'styles/variables.scss';
// bee chart
.bars rect {
  fill: $color-primary-3 !important;
}

.group {
  display: none;
}

// .scroll-container::-webkit-scrollbar-track {
//   border: 2px solid $color-primary-3;
//   background-color: #f5f5f5;
// }
// .scroll-container::-webkit-scrollbar {
//   width: 6px;
//   background-color: #f5f5f5;
// }
// .scroll-container::-webkit-scrollbar-thumb {
//   background-color: $color-primary-3;
// }
// scroll
::-webkit-scrollbar {
  width: 5px;
  height: 0.1px;
  border-radius: 16px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e3e7f0;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-primary;
}

::-webkit-scrollbar-thumb:active {
  background: #e3e7f0;
}

::-webkit-scrollbar-thumb:active {
  background: #e3e7f0;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
