@import 'styles/colors.scss';
@import 'styles/titles.scss';

.bar-chart-header {
  position: relative;
  @include flex(row, center, space-between);

  &:has(.bar-chart-header__info__title) {
    @include flex(row, flex-start, space-between);
  }

  &__info {
    gap: 14px;
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid $color-border-grey-2;
    }

    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__description {
    @include description;
    color: $color-grey-text;
    display: block;
    max-width: 550px;
  }

  &__input {
    width: 240px;
    background: #ffffff;
    border: 1px solid $color-border-grey;
    box-shadow: none;
    border-radius: 4px;
    padding: 6px 16px 6px 12px;
    margin-right: 16px;
  }

  &__filter {
    @include flex(row, center, space-between);

    &__title {
      @include info-700;
      text-transform: uppercase;
      color: $color-dark-text;
      margin-bottom: 4px;
      padding: 0;

      &.disabled-item {
        cursor: not-allowed;

        &:hover {
          background-color: white !important;
          cursor: not-allowed !important;
        }
      }

      &--margin {
        @extend .bar-chart-header__filter__title;
        margin-top: 18px;
      }

      &:hover {
        background-color: white !important;
        cursor: default !important;
      }
    }

    &__dropdown {
      height: max-content;
      max-width: 630px;
      overflow: hidden;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);

      overflow-y: auto;
      min-width: 150px;

      &::before {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #dcdcdc;
        border-radius: 13px;
      }

      .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
        padding: 8px 8px 8px 16px;
      }
    }

    &__dropdown-item {
      @include description;
      margin: 2px 0;
      padding: 0;

      &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-dark-text;
      }

      &__mode-btn {
        padding: 4px 10px;
        background: $color-unselected;
        border: none;
        border-radius: 4px;
        color: $color-dark-grey;
        cursor: pointer;

        &--active {
          color: $color-border-white;
          background: $color-black;

          path {
            fill: $color-white;
          }
        }

        &--margin {
          margin-right: 6px;
        }

        &__title {
          @include flex-body-centered;
          gap: 8px;

          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }

      &--inline {
        @extend .bar-chart-header__filter__dropdown-item;
        display: inline-block;
      }

      &__menu {
        li {
          &:nth-last-child(2) {
            margin-right: 14px;
          }
        }

        .ant-dropdown-menu-item:hover {
          background-color: $color-white;
        }
      }

      &.disabled-item {
        background-color: #d6d6d4 !important;
        cursor: not-allowed;
        color: #3b393455;
      }

      &:hover {
        color: $color-prime;
        background-color: $color-white;
      }

      .ant-radio-checked .ant-radio-inner {
        border: 1px solid $color-black;
      }

      .ant-radio-inner:after {
        background: $color-black;
      }
    }

    &__icon {
      height: 18;
      width: 18;
      cursor: pointer;
    }

    .ant-radio-group {
      margin-bottom: 12px;
    }

    .ant-input {
      min-height: 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $color-dark-text;
    }

    .ant-input-prefix {
      margin-right: 8px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    :focus {
      box-shadow: none;
      border: 1px solid $color-border-grey;
    }

    .ant-input-affix-wrapper-focused {
      border: 1px solid $color-border-grey;
      box-shadow: none;
    }

    .ant-input:hover,
    :focus {
      box-shadow: none !important;
      border: none !important;
    }
  }
}
