@import 'styles/colors.scss';
// @import 'styles/variables.scss';
.dropdown_container {
  position: relative;
  //   background-color: $color-white;
  padding: 5px;
  width: fit-content;
  border-radius: 4px;
  user-select: none;
  &.dropdown_container_disabled {
    pointer-events: none;
  }
  .dc_value_container {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    .value_text {
      color: $color-dark-text;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
    .dropdown_icon {
      transition: transform 0.25s linear;
      path {
        stroke: $color-grey-text;
      }
      &.dropdown_icon_rotate {
        transform: rotate(-180deg);
      }
    }
  }
  .dc_content_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px;
    gap: 4px;
    min-width: 136px;
    border-radius: 4px;
    border: 1px solid $color-border-grey;
    background-color: $color-white;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    z-index: 1;
    .list_container {
      list-style: none;
      margin: 0;
      padding: 0;
      .lc_list {
        .lc_l_header_container {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          .hc_text {
            color: $color-dark-text;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
          }
        }
        .lc_l_options_container {
          display: flex;
          align-items: center;
          height: 32px;
          cursor: pointer;
          &:hover {
            background-color: $color-unselected;
          }

          .oc_check_icon {
            path {
              stroke: $color-white;
            }
            &.oc_check_icon_active {
              path {
                stroke: $color-dark-text;
              }
            }
          }
          .oc_text {
            color: $color-dark-text;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            white-space: nowrap;
            margin: 0;
          }

          &.lc_l_options_container_remove,
          &.lc_l_options_container_active {
            &:hover {
              background: none;
            }
          }
        }
      }
      .lc_l_divider {
        border: none;
        border-top: 1px solid $color-border-grey;
        // background: $color-border-grey;
        margin-block: 4px;
      }
    }
  }
}
