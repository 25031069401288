@import 'styles/colors.scss';
@import 'styles/titles.scss';

.tenant-configuration {
  @include flex-body-column;
  gap: 24px;

  &__body {
    @include flex(column, stretch, flex-start);
    gap: 20px;
  }

  &__settings-div {
    @include flex(row, stretch, flex-start);
    gap: 20px;
    flex-wrap: wrap;

    &__container {
      @include flex(column, flex-start, space-around);
      flex: 0 0 23%;
      padding: 20px 24px;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid $color-border-grey;

      &:hover {
        .tenant-configuration__settings-div__edit-icon {
          visibility: visible;
        }
      }
    }

    &__label-div {
      @include flex(row, flex-start, flex-end);
      width: 100%;
    }

    &__edit-icon {
      visibility: hidden;
      cursor: pointer;
    }

    &__label {
      @include subtitle-16;
      color: $color-dark-text;
      text-transform: capitalize;
      flex: 2;
    }

    &__description {
      @include description;
      color: $color-dark-grey;
    }

    &__permissions {
      @include flex-body-column;
      gap: 16px;

      &__switch-div {
        @include flex-body;
        gap: 6px;
      }

      &__switch {
        @include flex-body;
        gap: 20px;
      }

      &__label {
        @include subtitle;
        color: $color-dark-grey;
      }
    }
  }

  &__table-col {
    &__container {
      @include flex-body-column;
      gap: 10px;
    }
  }

  .ant-switch {
    @include flex-body-centered;
    background-color: $color-disabled-grey;
    min-width: 32px;
    height: 20px;
  }

  .ant-switch-handle {
    width: 14px;
    height: 14px;
  }

  .ant-switch-checked {
    background-color: $color-prime !important;
  }

  .ant-switch-disabled {
    opacity: 1;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 15px);
  }
}
