@import 'styles/colors.scss';

.collection_section_container {
  flex-shrink: 0;
  width: 250px;
  height: 100%;
  background-color: $color-grey-20;
  border-right: 1px solid $color-border-grey;

  .csc_heading {
    padding: 19px 16px 19px 32px;
    color: $color-dark-text;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    border-bottom: 1px solid $color-border-grey;
    margin-bottom: 0;
  }

  .csc_spinner_wrapper {
    .csc_spinner {
      max-height: 100% !important;
    }
  }

  .csc_collections {
    height: calc(100dvh - 116px);
    overflow-y: auto;

    .csc_c_chunk {
      .csc_c_c_heading_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 12px 16px 12px 24px;
        cursor: pointer;

        .csc_c_c_hc_header {
          display: flex;
          align-items: center;
          gap: 8px;

          .csc_c_c_hc_h_collection_name {
            color: $color-dark-grey;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
          }
        }
      }

      .csc_c_c_children_container {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.5s ease-out;

        &.csc_c_c_children_container_active {
          grid-template-rows: 1fr;

          .csc_c_c_cc_lists {
            .csc_c_c_cc_l_chunk_container {
              .csc_c_c_cc_l_chunk {
                .csc_c_c_cc_h_right_icon_rotate {
                  transform: rotate(90deg);

                  path {
                    fill: $color-black;
                  }
                }
              }
            }
          }
        }

        .csc_c_c_cc_lists {
          overflow: hidden;

          .csc_c_c_cc_l_chunk {
            display: flex;
            align-items: center;
            padding: 12px 16px 12px 72px;
            gap: 8px;
            cursor: pointer;

            .csc_c_c_cc_h_collection_name {
              color: $color-dark-grey;
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              margin-bottom: 0;
            }

            &.csc_c_c_cc_l_chunk_active {
              background-color: $color-primary-6;

              .csc_c_c_cc_h_collection_name {
                color: $color-black;
              }
            }

            &.csc_c_c_cc_l_chunk_hightlight {
              background: none;

              .csc_c_c_cc_h_collection_name {
                color: $color-black;
              }
            }
          }
        }
      }

      &:hover {
        .csc_c_c_heading_container {
          background-color: $color-grey-scale-200;
        }
      }

      &.csc_c_chunk_active {
        .csc_c_c_heading_container {
          border-left: 4px solid $color-border-black;

          .csc_c_c_hc_header {
            .csc_c_c_hc_h_right_icon {
              transform: rotate(90deg);

              path {
                fill: $color-dark-text;
              }
            }

            .csc_c_c_hc_h_collection_icon {
              path {
                stroke: $color-dark-text;
              }
            }

            .csc_c_c_hc_h_collection_fill_icon {
              path {
                fill: $color-dark-text;
              }
            }

            .csc_c_c_hc_h_collection_fill_icon_2 {
              path {
                fill: $color-dark-text;
                stroke: $color-dark-text;
              }
            }

            .csc_c_c_hc_h_collection_name {
              color: $color-dark-text;
              font-weight: 700;
            }
          }
        }

        &:hover {
          .csc_c_c_heading_container {
            background: none;
          }
        }
      }

      &.csc_c_chunk_highlight {
        background-color: $color-primary-6;

        .csc_c_c_heading_container {
          .csc_c_c_hc_header {
            .csc_c_c_hc_h_collection_icon {
              path {
                stroke: $color-dark-text;
              }
            }

            .csc_c_c_hc_h_collection_fill_icon {
              path {
                fill: $color-dark-text;
              }
            }

            .csc_c_c_hc_h_collection_fill_icon_2 {
              path {
                fill: $color-dark-text;
                stroke: $color-dark-text;
              }
            }

            .csc_c_c_hc_h_collection_name {
              color: $color-dark-text;
            }
          }
        }

        &:hover {
          .csc_c_c_heading_container {
            background: none;
          }
        }
      }
    }
  }
}
