@import 'styles/colors.scss';
@import 'styles/titles.scss';

.platformTitleWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;

  .icon {
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
  }

  .titleText {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $color-dark-text;
  }
}

.createBookmarkModalWrapper {
  :global {
    .ant-modal-content {
      border-radius: 4px;
      padding: 32px;

      .ant-modal-close {
        top: 38px;
        right: 34px;

        .ant-modal-close-x {
          height: 12px;
          width: 12px;
          line-height: 12px;

          svg {
            height: 12px;
            width: 12px;

            path {
              stroke-width: 2px;
              stroke: $color-grey-scale-600;
            }
          }
        }
      }
    }

    .ant-modal-header,
    .ant-modal-footer {
      padding: 0;
    }
  }

  .formModalBody {
    .bodyHeader {
      margin-top: 24px;

      .description {
        color: $color-grey-text;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .bookmarkListForm {
      margin-top: 24px;

      .floatingInputWithStaticLabel {
        &.mt16 {
          margin-top: 16px;
        }

        padding: 10px 16px;
        background-color: $color-background;
        border: 1px solid $color-border-grey;
        border-radius: 4px;

        display: flex;
        flex-direction: column;
        gap: 8px;

        .inputLabel {
          user-select: none;
          color: $color-grey-text;

          font-weight: 600;
          font-size: 12px;
          line-height: 13px;
        }

        .input {
          border: 0;
          padding: 0;
          min-height: unset;

          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $color-black;

          &:focus {
            box-shadow: unset;
          }
        }
      }
    }
  }

  .successModalBody {
    .gif {
      img {
        width: 64px;
        height: 64px;
        margin: auto;
        display: block;
      }
    }

    .message {
      color: $color-dark-text;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 16px;
    }

    .subMessage {
      color: $color-grey-scale-600;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
    }
  }

  .modalFooter {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    &.successModalFooter {
      width: 100%;
    }

    button {
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 16px;
      border-radius: 4px;
      border: 0;

      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .cancelButton {
      color: $color-black;
      outline: 1px solid $color-black;

      &:hover {
        color: $color-black;
        background: unset;
        border-color: unset;
      }
    }

    .doneButton,
    .createButton {
      margin-left: 0 !important;
      background-color: $color-black;
      color: $color-white;

      &:hover {
        background: $color-black;
        color: $color-white;
        border-color: unset;
      }

      &:disabled {
        background: #bcc1cc;
        cursor: not-allowed;
      }
    }

    .doneButton {
      width: 100%;
    }
  }
}
