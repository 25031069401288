@import 'styles/colors.scss';

.create_flag_modal_step1_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  padding: 0 40px 24px 40px;
  height: calc(100vh - 258px);
  overflow-y: auto;
  flex-grow: 1;
  .cfmsc_impact_container {
    .cfmsc_ic_text {
      color: $color-grey-19;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .cfmsc_ic_chunk_container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .cfmsc_ic_chunk {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid $color-border-grey-2;
        background-color: $color-white;
        cursor: pointer;

        &.cfmsc_ic_chunk_active {
          border: 1px solid $color-prime;
          background-color: $color-primary-6;
          .cfmsc_ic_c_radio_button {
            border: 1px solid $color-prime;
            background-color: $color-prime;
          }
        }
        .cfmsc_ic_c_icon {
          flex-shrink: 0;
          &.cfmsc_ic_c_medium_icon {
            rect:nth-child(2) {
              fill: $color-orange;
            }
          }
          &.cfmsc_ic_c_high_icon {
            rect {
              fill: $color-orange;
            }
          }
        }
        .cfmsc_ic_c_text_container {
          flex-grow: 1;
          .cfmsc_ic_c_tc_title {
            color: $color-grey-19;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 0;
          }
          .cfmsc_ic_c_tc_desc {
            color: $color-grey-600;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
          }
        }
        .cfmsc_ic_c_radio_button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          border: 1px solid $color-grey-17;
          background-color: $color-white;
          border-radius: 50%;
          flex-shrink: 0;
          &::before {
            content: '';
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: $color-white;
            // position: absolute;
          }
        }
      }
    }
  }
}
