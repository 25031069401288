@import 'styles/colors.scss';

.data-fetching__main-content.brand-snippets-empty-state {
  background-color: $color-white;
  border: none;
  margin: none;

  .brand-snippets-empty-state__description {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  h3 {
    color: $color-grey-600;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: $color-dark-grey;
  }
}
