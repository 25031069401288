@import 'styles/colors.scss';

.c-monitor-heat-map {
  width: 100%;
  height: 200px;
  margin-top: 20px;

  &:has(.c-monitor-heat-map__title) {
    margin-top: 0px;

    .section-description {
      padding-bottom: 24px;
      border-bottom: 1px solid $color-border-grey-2;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  .section-header {
    margin-bottom: 20px;

    .section-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-grey-text;
    }
  }

  g {
    cursor: pointer;
  }

  &__row {
    display: flex;
  }

  &__item {
    height: 100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin: 4px;
    overflow: hidden;
    word-wrap: break-word;
  }

  &__item--name {
    margin: 0 auto auto 0;
  }

  &__item--impresions {
    margin: auto auto 0 0;
  }

  &.large-heat-map {
    height: 300px !important;
  }
}
