@import 'styles/titles.scss';
@import 'styles/colors.scss';

.filters-chips {
  @include flex(row, flex-start, space-between);
  gap: 10px;
  padding-inline: 40px;
  align-items: center;

  &__container {
    display: flex;
    max-width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 2px;
    }
  }

  &__applied {
    display: flex;
    align-items: center;
    gap: 12px;

    user-select: none;
    margin-right: 16px;

    &__label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-dark-grey;
      white-space: nowrap;
    }

    &__tag {
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 8px;

      padding: 4px 12px !important;
      border: 0 !important;
      border-radius: 4px !important;

      background: $color-prime !important;
      color: $color-white !important;

      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      margin-right: 0px !important;

      .ant-tag-close-icon {
        margin-left: 0;
        display: flex;
        align-items: center;
        color: $color-white !important;
      }
    }
  }

  &_popover {
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    width: 235px;
    padding: 16px 24px !important;
    background: $color-white;
    margin-top: 20px !important;

    &_title {
      @include tag;
      color: $color-dark-grey;
    }

    &__default {
      @include flex-body-column;
      gap: 8px;
    }

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      box-shadow: none;
    }

    .ant-popover-inner-content {
      padding: 0;
      width: 100%;
    }
  }

  &__resetContainer {
    @include flex(row, center, center);
    cursor: pointer;
    white-space: nowrap;
    gap: 3px;
  }

  &__reset {
    @include footnote;
    color: $color-grey-text;
    white-space: nowrap;
  }
}

.filters-chips-margin {
  margin-bottom: 8px;
  margin-top: 8px;
}

.filters-chips_creator_info {
  @include flex-body-column;
  gap: 12px;

  &__container {
    @include flex-body;
    gap: 16px;
  }

  &__container2 {
    @include flex-body;
    line-break: anywhere;
    gap: 12px;
  }

  &__avatar {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &__container3 {
    @include flex-body-column;
    gap: 4px;
  }

  &__name {
    @include info;
    color: #000;
  }

  &__link {
    @include footnote;
    color: $color-dark-grey;
  }

  &__platform {
    object-fit: cover;
    width: 15px;
    height: 15px;
  }
}
