@import 'styles/colors.scss';
.detail_panel_right_section_container {
  // background-color: gold;
  display: flex;
  flex-direction: column;
  .dprsc_header {
    padding-right: 40px;
  }
  .dprsc_tab_container {
    margin-top: 16px;
    .dprsc_tab_header {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      padding-right: 40px;
      justify-content: space-between;
      gap: 2px;
      .dprsc_th_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        // flex-grow: 1;
        font-weight: 400;
        line-height: 22px;
        border-radius: 0;
        background-color: $color-unselected;
        color: $color-black;
        &.dprsc_th_button_active {
          background-color: $color-black;
          color: $color-white;
          font-weight: 700;
        }
      }
    }
  }
  .dprsc_search_filters_panel {
    padding-right: 40px;
    .dprsc_sfp_inner_container {
      padding: 10px 8px 10px 4px;
      border-top: 1px solid $color-border-grey;
      border-bottom: 1px solid $color-border-grey;
      .dprsc_sfp_search_input {
        border: none;
        padding: 0;
        gap: 8px;
        width: 100%;
        height: auto;
        input {
          font-size: 12px;
        }
      }
    }
  }
  .dprsc_tab_content_container {
    display: flex;
    width: 100%;
    height: calc(100vh - 294px);
    overflow-y: auto;
    padding-right: 40px;
    overflow-anchor: none;
    .dprsc_tab_cc_feeds_container {
      height: 100%;
      .dprsc_tab_cc_feeds_empty_container {
        height: 100%;
        justify-content: center;
        padding: 40px;
      }
    }
  }
}

.detail-panel-snippet-section {
  border-left: 1px solid $color-border-grey;
  height: 100%;
  overflow: hidden;
  width: 580px;
  flex-shrink: 0;

  &__container {
    height: calc(100vh - 140px);

    &__top-container {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 16px 24px;
      border-bottom: 1px solid $color-border-grey;

      .snippet-list-search {
        flex-basis: 100%;
      }

      .filters-chips {
        margin: 0px;
        padding: 0px;
        font-size: 14px;
      }
    }

    #snippets_filters_header {
      border: none;
      padding: 0px;
      gap: 16px;
      flex-direction: column;

      .snippet-list-search {
        width: 100%;
      }

      .date-filter__text {
        display: none;
      }
    }

    &__header {
      display: flex;
      gap: 12px;

      &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: #000000;
      }

      &__count {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #667085;
      }
    }
  }
  &__snippet-list {
    height: calc(100% - 170px);
  }
  &__snippet-list-sub {
    height: calc(100% - 80px);
  }
}
