@import 'styles/colors.scss';

.rbc_container {
  display: flex;
  align-items: center;
  gap: 24px;
  .rbc_c_link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $color-dark-grey;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0px;
    .rbc_c_l_close_icon {
      path {
        stroke: $color-prime;
      }
    }
  }
  .rbc_c_create_text {
    color: $color-dark-text;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 0;
  }
  .rbc_text {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .rbc_c_text {
    color: $color-grey-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.create-report {
  height: calc(100vh - 60px);

  .content {
    box-sizing: border-box;
    height: calc(100% - 200px);
    overflow-y: auto;
    padding-bottom: 24px;

    &__editing {
      height: calc(100% - 90px);
    }
  }
  .steps {
    margin-left: auto;
    margin-right: auto;
    max-width: 850px;
    padding-top: 32px;
    box-sizing: border-box;
    .ant-steps-item-process .ant-steps-item-icon {
      background-color: $color-prime;
      border-color: $color-prime;
      color: $color-grey-600;
      box-shadow: 0px 0px 0px 4px #dbaf133d;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: $color-prime;
      color: $color-prime;
      border-color: $color-prime !important;
    }

    .ant-steps-item-wait .ant-steps-item-icon {
      background-color: transparent;
      color: $color-grey-600;
      border: 1.5px solid $color-border-grey-2 !important;
    }

    .ant-steps-item-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-grey-19 !important;
    }

    .ant-steps-item-process .ant-steps-item-title {
      color: $color-prime !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: white;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: $color-prime;
    }
    .ant-steps-item-tail::after {
      height: 2px;
    }
  }
  .actions-row {
    border-top: 1px solid $color-border-grey;
    padding: 24px 0px;
  }
  .actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;

    .continue-btn {
      background-color: $color-black;
      color: white;
      min-width: 128px;
      display: flex;
      justify-content: center;
    }
    .cancel-btn {
      background-color: $color-unselected;
      color: $color-dark-text;
      min-width: 128px;
      display: flex;
      justify-content: center;
    }
  }
}
