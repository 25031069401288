@import 'styles/colors.scss';
@import 'styles/titles.scss';

.monitor-assets {
  &__container {
    @include flex-body-column;
    gap: 32px;
  }

  &__header-div {
    @include flex-body-column;
    gap: 8px;

    &:has(.dashboard_assets) {
      gap: 14px;
    }

    &__title {
      @include subtitle;
      color: $color-black;
      &.dashboard_assets {
        font-size: 20px;
        font-weight: 700;
      }
    }

    &__description {
      @include description;
      color: $color-dark-grey;
    }
  }

  &__body {
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
    grid-gap: 20px;
  }

  &__graph-container {
    @include flex-body-column;
    padding: 16px 24px;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid $color-border-grey-2;
    background: $color-white;
    overflow: auto;
  }

  &__graph {
    &__title {
      @include subtitle-16;
      color: #000;
    }
  }
}