@import 'styles/colors.scss';
@import 'styles/titles.scss';

.delete-tenant-modal {
  @include flex(column, center, center);

  &__title {
    width: 100%;
    @include title;
    text-align: center;
  }

  &__description {
    @include description;
    color: $color-grey-text;
    text-align: center;
  }

  &__footer {
    @include flex-body-centered;
  }

  &__cancel-btn {
    width: 45%;
    background-color: transparent;
    border: 1px solid $color-black;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-black;
    margin-right: 10px;
  }

  &__delete-btn {
    width: 45%;
    background-color: $color-red;
    border: 1px solid $color-red;
    border-radius: 4px;
    padding: 7px 16px;
    color: $color-white;
  }

  button {
    cursor: pointer;
  }

  .ant-modal-footer {
    display: block;
    padding: 0;
  }

  .ant-modal-header {
    @include flex-body-centered;
    padding: 0;
  }
}
