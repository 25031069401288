@import 'styles/variables.scss';

.c-tabs {
  &--no-rigth-filter {
    .ant-tabs-nav {
      width: 0%;
    }
  }

  .ant-tabs-nav-wrap {
    position: relative;
    z-index: 2;
  }

  & .ant-tabs-tab {
    padding-bottom: 6px;
  }

  .ant-tabs-tab-btn {
    color: $color-gray-3;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-dark-1;
    text-shadow: none;
  }

  .ant-tabs-ink-bar {
    background-color: $color-dark-1;
  }

  .ant-tabs-ink-bar {
    height: 4px !important;
    bottom: -14px !important;
    z-index: 2;
  }

  & > .ant-tabs-nav .ant-tabs-nav-wrap,
  & > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    z-index: 99;
    position: relative;
  }

  & > .ant-tabs-nav .ant-tabs-nav-wrap,
  & > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: initial;
  }
}
