@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

.c-left-navigation {
  z-index: 100;
  background-color: $color-dark-3;
  padding: 20px 16px 40px 16px;
  display: flex;
  flex-direction: column;
  transition: all 0.25s linear;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  gap: 60px;
  width: 224px;

  &__logo {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &--logoText {
        margin-left: 6px;
        height: 25px;
        width: auto;
      }

      &--logoSonar {
        width: auto;
      }
      &--logo {
        object-fit: contain;
        width: 100%;
      }
    }

    &-box {
      width: 32px;
      &,
      &:hover {
        display: flex;
        flex-wrap: nowrap;
        cursor: pointer;
        text-decoration: none;
      }
    }
    &-full-box {
      width: 138px;
    }

    &-push-btn {
      color: #fff;
      cursor: pointer;
      margin-left: 15px;
      margin-top: 0px;
      display: flex;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__links-container {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: max-content;
    width: 100%;
    gap: 8px;
    overflow-y: auto;

    &__link-item {
      padding: 0;
      margin: 0;
      display: flex;
      width: 100%;

      &__link {
        padding: 8px;
        width: 100%;
        gap: 12px;

        & {
          transition: all 0.25s;
        }

        &:link,
        &:visited,
        &:hover,
        &:active {
          color: $color-nav-link-1;
          display: flex;
          align-items: center;
        }

        svg {
          max-width: 20px;
          min-width: 20px;
          max-height: 20px;
          min-height: 20px;
        }

        span {
          @include heading;
          color: $color-white;
          white-space: nowrap;
          text-transform: capitalize;
        }

        &--link-active {
          border-radius: 6px;
          background: $color-primary-12;

          span {
            color: $color-primary-13;
          }

          > .c-left-navigation__links-container__link-item__fill-white {
            svg,
            path {
              fill: $color-primary-13 !important;
            }
          }
          > .c-left-navigation__links-container__link-item__stroke-white {
            svg,
            path {
              stroke: $color-primary-13 !important;
            }
          }
        }
      }

      &__fill-white:hover {
        svg,
        path {
          fill: $color-white;
        }
      }

      &__stroke-white:hover {
        svg,
        path {
          stroke: $color-white;
        }
      }

      &:hover {
        background: rgba(219, 175, 19, 0.2);
        border-radius: 8px;
      }
    }

    &__signout {
      &,
      &:focus,
      &:active {
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
        cursor: pointer;
        margin: 0;
        color: #8598ad;
        display: flex;
        align-items: center;
      }
    }
  }

  &__tooltip {
    left: 61px !important;
    &.Collapse {
      left: 217px !important;
    }

    .ant-tooltip-arrow {
      display: block;

      .ant-tooltip-arrow-content {
        background-color: $color-black2;
      }
    }

    .ant-tooltip-inner {
      display: flex;
      padding: 8px 10px;
      gap: 8px;
      border-radius: 4px;
      background: $color-black2;
      color: $color-white;
      @include info;
    }
  }
}

.collapsed-nav {
  padding: 20px 16px 40px 16px;
  position: fixed;
  width: 68px;

  .c-left-navigation__logo {
    &-container {
      flex-direction: column;
      gap: 24px;

      &--logoText {
        display: none;
      }
    }

    &-push-btn {
      margin-left: 0px;

      svg {
        transform: rotate(0deg);
      }
    }
  }

  .c-left-navigation__links-container {
    align-items: center;
    width: 100%;
    min-width: 100%;
    gap: 8px;
    @media (max-height: 620px) {
      min-width: min-content;
    }
  }

  .c-left-navigation__links-container__link-item__link {
    span {
      display: none;
    }
  }
}

.c-left-navigation-bottom-section {
  margin-top: auto;
}
