@import 'styles/colors.scss';

.dcm_containerClassName {
  .dcm_box_container {
    min-height: 232px;
    min-width: 441px;
    width: 441px;
    padding: 32px 24px 24px;
    border-radius: 8px;
    .cmc_cc_header_container {
      .cmc_cc_hc_left_container {
        gap: 16px;
        .cmc_cc_hc_lc_icon {
          flex-shrink: 0;
        }
        .cmc_cc_hc_lc_heading {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          color: $color-grey-900;
        }
      }
    }
    .cmc_cc_hc_close_icon {
      display: none;
    }
    .cmc_cc_footer_container {
      justify-content: center;
      padding-inline: 20px;
      gap: 12px;
    }
  }
}
.dcm_body_container {
  display: flex;
  .dcm_modal_body_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 8px 0 32px;
    color: $color-grey-600;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 72px;
    .dcm_follow_text {
      margin-bottom: 0;
    }
  }
}
