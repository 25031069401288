@import 'styles/colors.scss';

.detail_view_section_detail_container {
  scroll-margin-top: 24px;
  margin-top: 24px;
  border: 1px solid $color-border-grey-2;
  border-radius: 12px;
  background-color: $color-grey-21;
  padding: 24px;

  .dvsdc_buttons {
    display: none;
  }

  &:hover {
    .dvsdc_buttons {
      display: flex;
    }
    box-shadow: 0px 0px 8px 0px $color-box-shadow;
  }
  .dvsdc_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    height: 40px;
    .dvsdc_header_text {
      color: $color-grey-19;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }
  .dvsdc_section_desc_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    .sdc_chunk {
      position: relative;
      border: 1px solid $color-border-grey-2;
      border-radius: 12px;
      padding: 24px;
      background-color: white;

      &:hover {
        .summary_actions {
          display: block;
        }
        box-shadow: 0px 0px 8px 0px $color-box-shadow;
      }
      .sdc_chunk_text {
        color: $color-grey-19;
        font-size: 16px;
        line-height: 28px;

        ol > li {
          &:not(:first-child) {
            margin-top: 28px;
          }
        }
        ol {
          margin-left: 24px;
        }
        ul {
          margin-left: 16px;
        }
      }
      .summary_actions {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
    &.dvsdc_section_desc_container_selected {
      .sdc_chunk {
        border: 1px solid $color-prime;
      }
    }
    &.dvsdc_section_desc_container_editable {
      .sdc_chunk {
        .sdc_chunk_text {
          outline: none;
        }
        .sdc_chunk_edit_icon_container {
          display: none;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 8px;
          cursor: pointer;
          padding: 8px;
          &:hover {
            background-color: $color-grey-18;
            border-radius: 6px;
            box-shadow: 0px 0px 10px 0px #34405433;
          }
        }
        .sdc_chunk_buttons_container {
          display: flex;
          justify-content: flex-end;
          .sdc_chunk_buttons_inner_container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            width: 100%;
            max-height: 0;
            margin: 0;
            overflow: hidden;
            opacity: 0;
            transition-property: max-height, margin, opacity;
            transition-duration: 0.1s;
            transition-timing-function: linear;
            .sdc_c_bc_cancel_button {
              border: 1px solid $color-black;
              background: none;
              color: $color-black;
            }
            .sdc_c_bc_save_button {
              border: 1px solid $color-black;
              &:disabled {
                border: 1px solid $color-disabled-grey;
                background-color: $color-disabled-grey;
                cursor: default;
              }
            }
          }
        }
        &:hover {
          .sdc_chunk_edit_icon_container {
            display: flex;
          }
        }
        &.sdc_chunk_selected {
          background-color: $color-pastel-blue;
        }
        &.sdc_chunk_active_edit {
          border-color: $color-dark-grey;
          background-color: $color-white;
          cursor: unset;
          &:hover {
            .sdc_chunk_edit_icon_container {
              display: none;
            }
          }
          .sdc_chunk_buttons_container {
            .sdc_chunk_buttons_inner_container {
              max-height: 100%;
              margin: 16px 0 8px 0;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.detail_view_section_detail_container_selected {
    border: 1px solid $color-prime;
    background-color: transparent;
  }
}

.action_icon_container {
  padding: 8px;
  &:hover {
    background-color: $color-grey-18;
    border-radius: 6px;
    box-shadow: 0px 0px 10px 0px #34405433;
  }
}

.delete_icon_style {
  path {
    stroke: #b42318;
  }
}
