@import 'styles/colors.scss';

.create_flag_modal_step2_container {
  flex-grow: 1;
  padding: 24px 40px;
  .ant-spin-nested-loading {
    height: 100%;
    .cfms2c_loading {
      max-height: 100% !important;
    }
  }
  .cfms2c_content_container {
    margin-top: 24px;
    .cfms2c_cc_edit_content_container {
      .cfms2c_cc_ecc_item_text {
        color: $color-grey-19;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0;
      }
      .cfms2c_cc_ecc_items_container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 8px;
        margin-bottom: 16px;
        max-height: calc(100vh - 500px);
        overflow-y: auto;
        .cfms2c_cc_ecc_ic_chunk {
          display: flex;
          align-items: center;
          padding: 16px 24px 16px 16px;
          border-radius: 8px;
          border: 1px solid $color-grey-17;
          background-color: $color-white;
          gap: 12px;
          .cfms2c_cc_ecc_ic_c_checkbox_container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            border: 1px solid $color-grey-17;
            cursor: pointer;
            &.cfms2c_cc_ecc_ic_c_checkbox_container_selected {
              background-color: $color-prime;
              border-color: $color-prime;
            }
          }
          .cfms2c_cc_ecc_ic_c_text {
            color: $color-dark-text;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
          }
        }
      }
      .cfms2c_cc_ecc_button {
        height: 40px;
        padding: 8px 14px;
        border-radius: 8px;
        border: 1px solid $color-border-grey;
        background-color: $color-white;
        color: $color-dark-grey;
      }
    }
    .cfms2c_cc_title {
      color: $color-grey-19;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 6px;
    }
    .cfms2c_cc_input_container {
      padding: 8px 16px;
      width: 100%;
      height: 40px;
      gap: 8px;
      border-radius: 8px;
    }
    .cfms2c_cc_lists {
      margin-top: 12px;
      border-radius: 8px;
      border: 1px solid $color-border-grey-2;
      background-color: $color-white;
      height: calc(100vh - 475px);
      overflow-y: auto;
      .cfms2c_cc_l_option {
        display: flex;
        align-items: center;
        gap: 12px;
        border-bottom: 1px solid $color-border-grey-2;
        padding: 16px 24px;
        .cfms2c_cc_checkbox_container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 6px;
          border: 1px solid $color-grey-17;
          cursor: pointer;
          &.cfms2c_cc_checkbox_container_selected {
            background-color: $color-prime;
            border-color: $color-prime;
          }
        }
        .cfms2c_cc_lp_text {
          color: $color-dark-text;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 0;
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}
