@import 'styles/titles.scss';
@import 'styles/colors.scss';

.snippets-discovery {
  margin-left: 70px !important;

  .ant-modal-content {
    padding: 0 !important;
    border-radius: 0;
  }

  .content {
    height: 100vh;
    overflow: hidden;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-border-grey-2;
    padding: 24px 32px 16px 32px;

    h4 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: $color-black;
      margin-bottom: 0;
      margin-left: 8px;
    }

    .collapse-option {
      margin-right: 8px;
      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .body {
    display: flex;
    flex-wrap: nowrap;
  }

  .summary-section {
    width: 58%;
    min-width: 400px;
    border-right: 1px solid $color-border-grey-2;

    &__details {
      padding-left: 40px;
      padding-right: 24px;
    }

    .flag-actions {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 8px;
    }

    .summary {
      overflow: auto;
      height: 400px;
      width: 80%;
    }

    .summary-image {
      width: 100%;
      height: 250px;
      position: relative;
      background-color: rgba($color: $color-black, $alpha: 0.1);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__content {
        position: absolute;
        bottom: 8px;
        left: 40px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      h4 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        color: white;
      }
      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: white;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        svg {
          path {
            stroke: white;
          }
        }
      }
    }

    .stats {
      width: 42%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 24px;
      padding-bottom: 24px;

      .item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h6 {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: $color-grey-22;
          margin-bottom: 0px;
        }

        p {
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          color: $color-grey-23;
          margin-bottom: 0px;
        }
      }
    }

    .summary {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-dark-grey;
    }
  }

  .snippets-section {
    width: 50%;
    &__snippets {
      height: calc(100vh - 66px);
    }
  }
}

.skeleton-stats-item {
  width: auto;
  height: 15px;
  overflow: hidden;
  border-radius: 8px;
}

.summary-empty-state .ant-skeleton-paragraph {
  height: 170px;
  overflow: hidden;
  border-radius: 4px;
  width: 80%;
}

.summary-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  justify-content: center;

  .summary-error-text {
    @include description;
    color: $color-dark-text;
    margin-top: 6px;
  }

  .summary-retry-error {
    @include button-text;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 34px;
    padding: 10px 16px;
    border-radius: 4px;
    background: $color-black;
    color: $color-white;
    cursor: pointer;
    margin-top: 16px;
  }
}
