@import 'styles/colors.scss';

.pulse-date-selection-modal.brand-creation-modal.ant-modal {
  width: 1350px !important;
  position: relative;
  overflow: hidden;
  top: 60px !important;

  .ant-form-item {
    margin: 0px;

    .ant-col.ant-form-item-control {
      margin: 0px;

      .ant-form-item-control-input-content {
        padding: 0px;
        min-height: auto;
      }
    }
  }

  .ant-modal-content {
    position: relative;
    padding: 0px;

    .ant-modal-header {
      padding: 24px 32px 16px 32px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom: 1px solid $color-border-grey;

      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $color-black;
      }
    }

    .ant-modal-footer {
      padding: 16px 24px;
      position: relative;
      border-top: 1px solid $color-border-grey;

      .footer {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__edit-btn {
          display: flex;
          min-width: 64px;
          padding: 10px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          background: #f6f6f7;
          color: var(--Dark-Text, #14192e);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .continue-btn {
        gap: 8px;
        align-items: center;
        display: inline-flex;

        &.delete-btn {
          background-color: $warning-color;
          color: $delete-label-text;

          svg path {
            stroke: $delete-label-text;
          }
        }

        &:disabled {
          background-color: $color-disabled-grey;
        }
      }
    }

    .brand-creation-modal__content {
      display: flex;
      height: 676px;
      min-height: 676px;

      &--left-section {
        overflow-y: scroll;
        gap: 20px;
        width: 600px;
        display: flex;
        min-width: 600px;
        padding: 20px 32px;
        flex-direction: column;
        border-right: 1px solid $color-border-grey;

        .creation-flow {
          gap: 16px;
          display: flex;
          flex-direction: column;

          &__urls-links {
            gap: 8px;
            display: flex;
            align-items: flex-end;

            label {
              width: 100%;
            }

            .creation-flow__loader {
              gap: 8px;
              width: 100%;
              height: 40px;
              display: flex;
              align-items: center;
              border-radius: 8px;
              padding: 0px 12px;
              background-color: $color-white;
              border: 1px solid $color-border-grey;

              span {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                font-style: normal;
                font-family: Roboto;
                color: $color-grey-19;
              }

              .loader {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid $color-prime;
                border-left-color: transparent;
                animation: spin89345 1s linear infinite;
              }

              @keyframes spin89345 {
                0% {
                  transform: rotate(0deg);
                }

                100% {
                  transform: rotate(360deg);
                }
              }
            }
          }

          &__logo-url {
            gap: 8px;
            display: flex;
            position: relative;
            flex-direction: column;

            .input-label {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              position: relative;
              color: $color-grey-19;
            }

            .ant-input-affix-wrapper {
              min-height: auto;
              border-radius: 8px;
              border: 1px solid $color-border-grey;
              box-shadow: 0px 1px 2px 0px #1018280d;

              &:hover {
                border-color: $color-border-grey;
              }

              .ant-input {
                padding-left: 4px;
                min-height: auto;
                font-size: 16px;
                font-weight: 400;
                color: $color-dark-text;

                &::placeholder {
                  color: $color-grey-22;
                }
              }
            }

            .input-supporting-text {
              font-weight: 400;
              font-size: 14px;
              color: $color-dark-grey;
            }
          }

          &__logo-container {
            gap: 16px;
            display: inline-flex;
            flex-direction: column;

            &__title {
              font-size: 14px;
              font-weight: 500;
              color: $color-grey-19;
            }

            &__div {
              gap: 32px;
              display: inline-flex;
              align-items: center;

              &__logo {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid $color-border-grey;
              }

              img {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 50%;
              }
            }

            button {
              border: none;
              outline: none;
              font-weight: 500;
              border-radius: 6px;
              color: $color-grey-19;
              background-color: $color-grey-18;

              &::after {
                all: unset;
              }
            }
          }

          &__brand-name {
            gap: 6px;
            display: flex;
            flex-direction: column;

            .input-label {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              position: relative;
              color: $color-grey-19;

              .asterisk {
                color: $color-red;
              }
            }

            .ant-input {
              min-height: auto;
              font-size: 16px;
              font-weight: 400;
              color: $color-dark-text;
              padding: 6px 12px;
              border-radius: 8px;
              border: 1px solid $color-border-grey;
              box-shadow: 0px 1px 2px 0px #1018280d;
            }
          }

          .hide,
          &__hide {
            display: none;
          }

          &__heading {
            gap: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: flex-end;

            &__text {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: $color-dark-text;
            }

            &__spark-icon {
              svg {
                width: 16px;
                height: 16px;

                path {
                  fill: $color-prime;
                }
              }
            }

            &__info-icon {
              svg path {
                stroke: $color-prime;
              }
            }

            div {
              display: flex;
              align-items: center;
            }
          }

          &__search {
            position: relative;

            &__header {
              gap: 4px;
              top: 10px;
              z-index: 2;
              left: 16px;
              display: flex;
              position: absolute;
              align-items: center;
              margin-top: -9px;
              background-color: white;
              height: 30px;
              width: 96%;

              &__text {
                font-size: 12px;
                font-weight: 600;
                line-height: 13px;
                color: $color-black;
              }

              &__info-icon {
                svg {
                  width: 16px;
                  height: 16px;

                  path {
                    stroke: $color-prime;
                  }
                }
              }

              div {
                display: flex;
                align-content: center;
              }
            }

            textarea {
              resize: none;
              outline: none;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              min-height: 110px;
              color: $color-black;
              padding: 32px 16px 10px 16px;
              border: 1px solid $color-border-grey;

              &::placeholder {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: $color-grey-text;
              }

              &:active,
              &:hover,
              &:focus {
                box-shadow: none;
                border-color: $color-border-grey !important;
              }
            }
          }

          &__btn {
            gap: 8px;
            display: flex;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 10px 8px;
            align-items: center;
            justify-content: center;
            color: $color-white;
            background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
              linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);

            svg path {
              stroke: $color-white;
            }

            &.preview-btn {
              color: $color-dark-text;
              background: $color-unselected;
            }

            &:disabled {
              color: $color-grey-text;
              background: $color-unselected;

              svg path {
                stroke: $color-grey-text;
              }
            }
          }

          .add-links__platform-table.add-brand-screen {
            .channel-info__description__url {
              max-width: 280px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .create-url.invalid {
              max-width: 360px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &__info-section {
            overflow-y: scroll;
            gap: 32px;
            display: flex;
            flex-direction: column;

            .title {
              color: $color-black;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              padding-bottom: 24px;
              border-bottom: 1px solid var(--Gray-200, #eaecf0);
            }

            .stats-box {
              display: flex;
              flex-direction: row;
              gap: 16px;

              &__container {
                display: flex;
                padding: 20px;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                border-radius: 12px;
                border: 1px solid $color-border-grey;
                box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
                flex: 1;
              }

              &__metric-title {
                color: $color-dark-grey;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }

              &__metric-value {
                color: $color-grey-19;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px;
                letter-spacing: -0.6px;
              }
            }

            .quality {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 24px;
              align-self: stretch;

              &__title-container {
                display: flex;
                align-items: center;
                gap: 8px;

                &__text {
                  color: $color-grey-19;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                }

                &__tag {
                  display: flex;
                  padding: 2px 6px;
                  align-items: center;
                  color: $color-green;
                  text-align: center;
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px;
                  border-radius: 4px;
                  background: $success-100;
                }

                svg {
                  width: 20px;
                  height: 20px;
                  cursor: pointer;
                }
              }

              &__bar {
                width: 100%;
                display: flex;
                position: relative;

                &__text {
                  position: absolute;
                  color: $color-white;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 16px;
                  top: 20%;
                }
              }

              .ant-progress-bg {
                background-color: #17b26a;
                border-radius: 8px;
              }

              .ant-progress-inner {
                border-radius: 8px;
              }
            }

            .summary {
              gap: 16px;

              &__text {
                color: $color-dark-grey;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                ol > li {
                  &:not(:first-child) {
                    margin-top: 28px;
                  }
                }
              }

              &__toggle-text {
                color: $color-black;
                font-size: 14px;
                font-style: normal;
                line-height: 20px;
                font-weight: 500;
                cursor: pointer;
              }

              svg {
                path {
                  stroke: $color-prime;
                  stroke-width: 1.4px;
                }
              }
            }
          }
        }

        &__follow-flow {
          display: flex;
          flex-direction: column;
          gap: 4px;

          &__edit-boolean-div {
            display: flex;
            height: 40px;
            min-width: 64px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            background: $color-grey-18;
            cursor: pointer;

            span {
              color: $color-grey-19;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }

            .rotate {
              transform: rotate(180deg);
            }

            svg {
              height: 20px;

              path {
                stroke: $color-grey-19;
              }
            }
          }
        }
      }

      .right-section {
        width: 100%;
        position: relative;

        &--spinner.ant-spin.ant-spin-spinning {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 9999;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
