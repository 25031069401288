@import 'styles/colors.scss';

.change-owner-modal {
  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    &__title {
      color: #16191D;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }

    &__description {
      color: #667085;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  &___modal_container {
    display: flex;
    width: 100%;
    gap: 24px;
    flex-direction: column;

    &__container {
      display: flex;
      flex-direction: column;
      gap: 6px
    }

    .search-text {
      color: #344054;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .ant-spin-nested-loading {
      position: relative;

      .ant-spin {
        top: 0;
      }
    }

    .srmc_text {
      color: $color-grey-text;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .srmc_input {
      height: 40px;
      padding: 10px 18px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #D0D5DD;
      background: #FFF;

      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      .srmc_input_content_container {
        .srmc_input_cc_chunk {
          cursor: pointer;
        }
      }
    }

    .share_report_content_container {
      height: 120px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    display: flex;
    padding: 24px 32px 16px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
    border-bottom: 1px solid #E2EBFA;
    background: #FFF;
  }

  .ant-modal-body {
    width: 100%;
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  .ant-modal-footer {
    display: flex;
    padding: 24px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    border-radius: 0px 0px 16px 16px;
    border-top: 1px solid #EAECF0;
    background: #FFF;

    .ant-btn {
      display: flex;
      min-width: 64px;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      border: 1px solid #16191D;

      span {
        border: none;
      }
    }

    .ant-btn-default {

      &:hover,
      &:active,
      &:focus {
        border-radius: 6px;
        border: 1px solid #16191D;
        background-color: #fff;
        color: #16191D
      }
    }

    .ant-btn-primary {
      border-radius: 6px;
      border-top: 1px solid #16191D;
      border-right: 1px solid #16191D;
      border-left: 1px solid #16191D;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 2.5%, rgba(0, 0, 0, 0.00) 15.49%), linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);

      &:hover,
      &:active,
      &:focus {
        color: white;
        border-radius: 6px;
        border-top: 1px solid #16191D;
        border-right: 1px solid #16191D;
        border-left: 1px solid #16191D;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 2.5%, rgba(0, 0, 0, 0.00) 15.49%), linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
      }
    }
  }

  .ant-spin-nested-loading {
    width: 100%;
  }
}