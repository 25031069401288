@import 'styles/colors.scss';

.ctcm_containerClassName {
  .ctcm_box_container {
    min-height: auto;
    min-width: 512px;
    width: 512px;
    padding: 24px;
    border-radius: 6px;
    .cmc_cc_header_container {
      .cmc_cc_hc_left_container {
        gap: 24px;
      }
    }
    .cmc_cc_hc_close_icon {
      display: none;
    }
  }
}
.ctcm_body_container {
  display: flex;
  .ctcm_modal_body_container {
    margin: 8px 0 16px;
    padding-left: 72px;
    .ctcm_link_text {
      color: $color-dark-text;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}
