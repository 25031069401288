.c-sign-in-link {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin-top: 24px;
  margin-bottom: 0;

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: #948f87;
  }
}
