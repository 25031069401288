@import 'styles/colors.scss';

.vectors_list_chunk_container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 26px 24px;
  border-bottom: 1px solid $color-border-grey-2;
  background-color: $color-white;
  cursor: pointer;
  &.vectors_list_chunk_container_active {
    background-color: $color-primary-3;
    border-color: $color-primary-2;
  }
  &.vectors_list_chunk_container_highlighted {
    background-color: $color-primary-3;
  }
  //   &:last-child {
  //     border: none;
  //   }
  .vlcc_text {
    color: $color-dark-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
  .vlcc_cube_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: $color-primary-700_1;
    &::before {
      display: none;
      content: attr(data-tooltip);
      width: max-content;
      position: absolute;
      border-radius: 4px;
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-black;
      padding: 8px;
      color: $color-white;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      z-index: 1;
    }
    &::after {
      display: none;
      content: '';
      position: absolute;

      bottom: calc(100% - 2px);
      left: 50%;
      transform: translateX(-50%);

      border-width: 7px;
      border-style: solid;
      border-color: $color-black transparent transparent transparent;
    }
    &:hover {
      &::before,
      &::after {
        display: block;
      }
    }
  }
  &:first-child {
    .vlcc_cube_icon_container {
      &::before {
        top: calc(100% + 10px);
        height: 32px;
      }
      &::after {
        top: calc(100% - 2px);
        border-color: transparent transparent $color-black transparent;
      }
    }
  }
}
