.influencer-list {
  max-height: 600px;
  overflow: auto;
  padding: 8px 0px;
}

.influencers-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 32px;
}
