$color-prime: #cca55a;
$color-prime-bright: #dbaf13;
$color-dark-text: #14192e;
$color-dark-text-2: #1e1e1e;
$color-black: #16191d;
$color-border-black: #000;
$color-border-grey: #e2ebfa;
$color-border-grey-2: #eaecf0;
$color-border-white: #f5f4f3;
$color-white: #ffffff;
$color-prime-bright: #dbaf13;
$color-grey-text: #8598ad;
$color-highlight: #fffbf4;
$color-background: #fcfcfc;
$color-background-tan: #f4f3ef;
$color-foreground-tan: #605958;
$color-unselected: #f6f6f7;
$color-selected: #fffbf4;
$color-black-hover: #25282c;
$color-white-hover: #f1f1f2;
$color-gray-hover: #fbfbfc;
$color-dark-grey: #667085;
$color-disabled-grey: #bcc1cc;
$color-black2: #131316;

$color-grey-1: #fafafa;
$color-grey-2: #566477;
$color-grey-3: #eff3f9;
$color-grey-4: #fbfdff;
$color-grey-5: #f8fafc;
$color-grey-6: #fff0d3;
$color-grey-7: #f0f0f1;
$color-grey-8: #3b3934;
$color-grey-9: #c9c7c3;
$color-grey-10: #fdfdff;
$color-grey-11: #242930;
$color-grey-12: #e7eaf0;
$color-grey-13: #dcdcdc;
$color-grey-14: #f6f6f6;
$color-grey-15: #8694a4;
$color-grey-16: #ebf2ff;
$color-grey-17: #d0d5dd;
$color-grey-18: #f2f4f7;
$color-grey-19: #344054;
$color-grey-20: #f9fafb;
$color-grey-21: #fcfcfd;
$color-grey-22: #98a2b3;
$color-grey-23: #182230;
$color-grey-600: #475467;
$color-grey-800: #1d2939;
$color-grey-900: #101828;
$color-grey-scale-50: #f7faff;
$color-grey-scale-100: #f2f5fb;
$color-grey-scale-200: #ebeef4;
$color-grey-scale-300: #dde0e6;
$color-grey-scale-400: #babdc2;
$color-grey-scale-500: #9c9ea3;
$color-grey-scale-600: #73757a;
$color-grey-scale-700: #5f6166;
$color-grey-scale-800: #404246;
$color-grey-scale-900: #1f2125;

$color-pastel-blue: #e2ebfa;
$color-pastel-yellow: #fef4d3;
$color-pastel-pink: #f1e1e9;
$color-pastel-coral: #f5dfcc;
$color-pastel-green: #e7f3e0;
$color-pastel-light-blue: #d6e4ee;

$color-primary-1: #cba459;
$color-primary-2: #ebdbbd;
$color-primary-3: #fcfaf5;
$color-primary-4: #fdf4df;
$color-primary-5: #fff9ed;
$color-primary-6: #faf6ef;
$color-primary-7: #f5edde;
$color-primary-8: #e0c99c;
$color-primary-9: #fffcf5;
$color-primary-10: #e6d2ad;
$color-primary-11: #a38448;
$color-primary-12: #3d311b;
$color-primary-13: #dbc08c;
$color-primary-50: #f5efe1;
$color-primary-100: #e8d7b5;
$color-primary-200: #dabd86;
$color-primary-200_1: #d6b77b;
$color-primary-300: #cca45a;
$color-primary-300_1: #8f733f;
$color-primary-400: #c3933f;
$color-primary-500: #bd8325;
$color-primary-600: #b97a21;
$color-primary-700: #b36d1b;
$color-primary-700_1: #f0e4ce;
$color-primary-800: #ac6014;
$color-primary-900: #a34b08;

$color-purple: #a84bd3;
$color-purple-50: #f4f3ff;
$color-purple-700: #5925dc;
$color-blue-link: #0000c5;
$color-blue: #4595ff;
$color-red: #ff0000;
$color-pink: #dc7777;
$color-brown: #ad9a85;
$color-orange: #dc6803;
$color-orange-50: #fff6ed;
$color-orange-700: #c4320a;
$color-light-pink: #fdf2fa;
$color-success: #18b368;
$color-pink-700: #c11574;
$color-violet: #7839ee;
$color-green: #067647;

$platform-brand-color-twitter: #00acee;
$platform-brand-color-youtube: #c51313;
$platform-brand-color-tiktok: #000000;
$platform-brand-color-podcast: #bc3fe0;
$platform-brand-color-news: #040487;
$platform-brand-color-bitchute: #ef4138;
$platform-brand-color-rumble: #75cd1d;
$platform-brand-color-reddit: #ff4500;
$platform-brand-color-4chan: #8a2dc5;
$platform-brand-color-gab: #62c886;
$platform-brand-color-patriots: #8a2dc5;
$platform-brand-color-truthsocial: #5448ee;
$color-hover: #fafafb;
$color-background-reports: #bcc1cc;
$section-background: #f1f1f2;
$delete-label: #fef3f2;
$delete-label-text: #d92d20;
$delete-button-color: #f04438;
$link-color: #348bff;

$success-100: #dcfae6;
$warning-color: #fee4e2;
$warning-50-color: #fffaeb;
$warning-700-color: #b54708;
$hyper-link-color: #1b55c5;
$color-indigo-500: #6172f3;
$color-indigo-700: #3538cd;

$color-violet-700: #6927da;

$elections-races-red: #c71920;
$elections-races-blue: #0f265e;
$elections-races-purple: #6927da;

$color-box-shadow: #8694a426;
