@import 'styles/colors.scss';
@import 'styles/titles.scss';

.removeSnippetFromBookmarkListsModalWrapper {
  :global {
    .ant-modal-content {
      border-radius: 4px;
      padding: 32px;
    }

    .ant-modal-header,
    .ant-modal-footer {
      padding: 0;
    }

    .ant-modal-footer {
      margin-top: 24px;
    }
  }

  .deleteModalBody {
    display: flex;
    flex-direction: column;
    gap: 24px;

    text-align: center;

    .heading,
    .description {
      margin-bottom: 0;
    }

    .heading {
      color: $color-dark-text;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }

    .description {
      color: $color-dark-grey;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .modalFooter {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      padding: 7px 16px;
      border-radius: 4px;
      border: 0;

      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .cancelButton {
      color: $color-black;
      outline: 1px solid $color-black;

      &:hover {
        color: $color-black;
        background: unset;
        border-color: unset;
      }
    }

    .removeButton {
      margin-left: 0 !important;
      background-color: #ea3535;
      color: $color-white;

      &:hover {
        background: #ea3535;
        color: $color-white;
        border-color: unset;
      }

      &:disabled {
        background: #bcc1cc;
        cursor: not-allowed;
      }
    }
  }
}
