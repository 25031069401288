@import 'styles/colors.scss';
@import 'styles/titles.scss';

.card-view {
  @include flex(column, stretch, flex-start);
  gap: 20px;

  &__body {
    @include flex(column, stretch, flex-start);
    width: 100%;
  }

  &__container {
    @include flex(row, stretch, flex-start);
    gap: 20px;
    flex-wrap: wrap;
  }
}
