@import 'styles/colors.scss';
@import 'styles/titles.scss';

.power-insights-creators {
  @include flex-body-column;
  gap: 16px;

  &--header {
    gap: 14px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    line-height: 20px;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: -2px;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-dark-grey;
  }

  &__search {
    &__container {
      padding: 8px 16px;
      gap: 8px;
      background: $color-grey-20;
      flex: 1;
      height: auto;
      width: 100%;
    }

    &__icon {
      width: 24px;
      height: 24px;

      path {
        fill: $color-dark-grey;
      }
    }
  }

  &__count-div {
    @include flex-body;
    gap: 8px;

    &__title {
      @include description;
      color: $color-dark-grey;
    }

    &__text {
      @include subtitle-16;
      color: $color-dark-text;
    }
  }

  &__top-creators-div {
    @include flex-body;
    width: max-content;
    padding: 2px 12px;
    gap: 4px;
    border-radius: 28px;
    background: $success-100;

    &__text {
      @include description-600;
      color: $color-green;
      line-height: 20px;
    }
  }
  &__stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.creators-filter-button {
  @include flex-body-centered;
  min-width: 64px;
  padding: 10px 16px;
  gap: 8px;
  border-radius: 6px;
  background: $color-unselected;

  &__icon {
    path {
      stroke: $color-dark-text;
    }
  }

  &__title {
    @include subtitle;
    color: $color-dark-text;
  }

  &__overlay {
    position: relative;
  }
}
