@import 'styles/colors.scss';

.suggested-issues-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.issue-card {
  min-width: 32%;
  max-width: 400px;
  border-radius: 24px;
  padding: 16px;
  border: 1px solid $color-border-grey-2;
  box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
  display: flex;
  flex-direction: column;

  .follow-btn {
    border: none;
    display: flex;
    margin-top: auto;
    align-items: center;
    border-radius: 6px;
    align-self: flex-start;
    color: $color-grey-19;
    background-color: $color-grey-18;

    &:hover {
      border: none;
      outline: none;
      color: $color-grey-19;
      background-color: $color-grey-18;
    }
  }

  .skeleton-image {
    min-height: 250px;
    width: 100% !important;
    max-height: 316px;
    border-radius: 12px;
  }

  .skeleton-title {
    height: 20px;
    overflow: hidden;
    width: 50%;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  div.img-wrapper {
    min-height: 250px;
    overflow: hidden;
    max-height: 316px;
    position: relative;
    border-radius: 24px;
    background-color: $color-grey-18;
  }

  img.img {
    width: 100%;
    object-fit: cover;
    border-radius: 24px;
  }

  .stats {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    bottom: -5px;
    width: 100%;

    .item {
      display: flex;
      flex-direction: column;

      h6 {
        color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.02em;
      }

      p {
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-text;
    margin-top: 16px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-dark-grey;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }
}

.issues-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  padding: 8px 8px;
}

.carousel-wrapper {
  position: relative;
  min-width: 900px;

  .ant-carousel .slick-dots {
    position: absolute;
    bottom: -10px;
  }

  .ant-carousel .slick-dots li button {
    background-color: $color-grey-17;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: $color-dark-grey;
  }
}

.follow-modal {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__search-btn {
    display: flex;
    min-width: 64px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid $color-black;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
      linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
    height: 40px;
  }
}
