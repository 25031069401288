@import 'styles/variables.scss';

.custom-button {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 2px solid;
  border-radius: 100px;
  font-weight: 600;
  display: flex;

  &-disabled--primary {
    background: #e4e3e1 !important;
    border-color: #e4e3e1 !important;
    color: #aeaba5 !important;
  }

  &--primary,
  &--primary:focus {
    background: #1d1c1a;
    border-color: #1d1c1a;
    color: #fff;
  }

  &--primary:hover {
    background: #cca55a;
    border-color: #cca55a;
    color: #1d1c1a;
  }

  &--primary:active {
    background: #b48938;
    border-color: #b48938;
    color: #1d1c1a;
  }

  &-disabled--secondary {
    background: transparent !important;
    border-color: #f5edde !important;
    color: #aeaba5 !important;
  }

  &--secondary,
  &--secondary:focus {
    background: #fff;
    border-color: #cca55a;
    color: #1d1c1a;
  }

  &--secondary:hover {
    background: #f5edde;
    border-color: #cca55a;
    color: #1d1c1a;
  }

  &--secondary:active {
    background: #e0c99c;
    border-color: #cca55a;
    color: #1d1c1a;
  }

  &-disabled--tertiary {
    background: transparent !important;
    border-color: transparent !important;
    color: #b5d5ff !important;
  }

  &--tertiary,
  &--tertiary:focus {
    background-color: transparent;
    border-color: transparent;
    color: #4595ff;
  }

  &--tertiary:hover {
    background: #daeaff;
    border-color: #daeaff;
    color: #4595ff;
  }

  &--tertiary:active {
    background: #b5d5ff;
    border-color: #b5d5ff;
    color: #4595ff;
  }

  &-disabled--tertiary-grayed {
    background: transparent !important;
    border-color: transparent !important;
    color: #e4e3e1 !important;
  }

  &--tertiary-grayed,
  &--tertiary-grayed:focus {
    background-color: transparent;
    border-color: transparent;
    color: #77736a;
  }

  &--tertiary-grayed:hover {
    background: #e4e3e1;
    border-color: #e4e3e1;
    color: #77736a;
  }

  &--tertiary-grayed:active {
    background: #c9c7c3;
    border-color: #c9c7c3;
    color: #77736a;
  }

  &-disabled--danger {
    background: #e4e3e1 !important;
    border-color: #e4e3e1 !important;
    color: #aeaba5 !important;
  }

  &--danger,
  &--danger:focus {
    background: #ec5b56;
    border-color: #ec5b56;
    color: #ffffff;
  }

  &--danger:hover {
    background: #ff7269;
    border-color: #ff7269;
    color: #fafafa;
  }

  &--danger:active {
    background: #ec5b56;
    border-color: #ec5b56;
    color: #fafafa;
  }

  &--large {
    min-width: 160px;
    min-height: 48px;
    font-size: 16px;
    line-height: 18px;
    margin-right: 30px;
  }

  &--small {
    min-width: 110px;
    min-height: 32px;
    font-size: 14px;
    line-height: 14px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  &__content-children {
    margin: auto;
    display: flex;
  }
}
