@import 'styles/colors.scss';
@import 'styles/titles.scss';

.narrative-info {
  @include flex(column, flex-start, flex-start);
  padding: 16px 12px 10px 12px !important;
  background: $color-white;
  gap: 16px;
  position: absolute;
  width: max-content;
  border: 1px solid $color-border-grey;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 99;

  &__header {
    @include flex(row, center, space-between);
    margin-bottom: 16px;

    &__container {
      @include flex(column, flex-start, flex-start);
    }

    &__heading {
      @include flex(row, flex-start, flex-start);
      display: inline-flex;
      @include info;
      color: $color_black;

      &__large-font {
        @extend .narrative-info__header__heading;
        font-size: 16px;
        white-space: pre-wrap;
      }

      &__margin {
        @extend .narrative-info__header__heading;
        padding-right: 20px;
        margin-right: 11px;
      }
    }

    &__details-btn {
      @include flex-body;
      @include small;
      padding: 6px 12px;
      background: $color-black;
      border-radius: 32px;
      text-align: center;
      color: $color-white;
      border: 1px solid $color-black;
      cursor: pointer;

      &:hover {
        padding: 6px 12px !important;
        background-color: $color-black !important;
        cursor: pointer !important;
      }

      svg {
        margin-left: 8px;
      }
    }
  }

  &__stream-text {
    @include small-400;
    color: $color_black;
    opacity: 0.5;
  }

  &__summary {
    @include flex(row, flex-start, flex-start);
    margin-top: 16px;
    margin-bottom: 8px;
    color: $color_dark_text;

    &__label {
      @include info;
      max-width: 70px;
      width: max-content;
      margin-right: 5px;
    }

    &__description {
      display: flex;
      @include info;
      font-weight: 400;
      flex-wrap: wrap;
      position: relative;
    }
  }

  &__details {
    @include flex(row, center, flex-start);
    color: $color_dark_text;
    margin-bottom: 8px;

    &__label {
      @include info;
      margin-right: 5px;
    }

    &__description {
      @include info;
      display: flex;
      flex-wrap: wrap;

      &--blue {
        @include info;
        display: flex;
        flex-wrap: wrap;
        color: $color-prime;
      }
    }

    &__margin {
      margin-top: 16px;
    }
  }

  .ant-dropdown-menu-item :hover {
    padding: 0;
    cursor: default;
    background-color: white;
  }

  .ant-dropdown-menu-item {
    width: 100%;
    padding: 0;
    cursor: default;
    background-color: white;
  }
}
