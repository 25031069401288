.c-add-user-form {
  width: 434px;
  margin: 16px auto 16px auto;

  &__item {
    margin-bottom: 0px;
  }

  &__field-name {
    margin: 14px 0;
    font-size: 16px;
    font-weight: 500;
  }

  &__input {
    border: 1px solid #a8a8a8 !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    min-height: 40px !important;
    height: 40px !important;

    input {
      min-height: 38px !important;
      height: 38px !important;
    }
  }
  .ant-select-selection-item {
    margin: auto;
  }
}

.c-add-user-form .ant-checkbox-checked .ant-checkbox-inner {
  border-color: rgb(191, 147, 71);
  background-color: rgb(191, 147, 71);
}
