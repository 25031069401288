@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

.c-preview-modal {
  display: flex;
  height: max-content;
  width: 553px !important;
  overflow: scroll;
  overflow-y: auto;
  overflow: scroll;

  &__container {
    @include flex-body-column;
    gap: 24px;
  }

  &__container-2 {
    @include flex(row, flex-start, flex-start);
    gap: 24px;

    .c-preview-modal__content {
      max-width: 450px;
    }
  }

  &__content {
    @include flex-body-column;
    gap: 24px;
    flex: 1;
  }

  &__image_content {
    width: 1400px !important;
  }

  &__iframe {
    margin: auto;
    width: 450px;
    aspect-ratio: 16/9;

    &__image-width {
      width: 450px;
    }
  }

  &__default_img {
    width: 450px;
    max-height: 450px;
    object-fit: fill;

    &__image-width {
      width: 450px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__right-div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    &__snippet-source {
      display: flex;
      padding: 6px 8px;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-radius: 4px;
      background: $color-unselected;

      span {
        color: $color-dark-grey;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__text {
      font-weight: 700;
      font-size: 20px;
      color: #16191d;
      line-height: 24px;
      display: -webkit-box;
      max-width: 280px;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__open-source-btn {
    white-space: nowrap;

    &,
    &:focus,
    &:active,
    &:hover {
      outline: none !important;
      border: none !important;
      cursor: pointer;
      padding: 5px 6px;
      color: $color-black;
      display: flex;
      align-items: center;
      background: rgba(244, 202, 95, 0.2) !important;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__video-content {
    @include flex-body-column;
    gap: 8px;
  }

  &--description-text {
    font-size: 14px;
    line-height: 20px;
  }

  &__description {
    color: $platform-brand-color-tiktok;
    margin: 0;

    &--more {
      font-weight: 500;
      font-size: 16px;
      color: $color-blue;
      cursor: pointer;
    }
  }

  &__news-cover-img {
    max-width: 450px;
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #c9c7c3;
    margin: 0 !important;
    white-space: nowrap;
  }

  &__channel-title {
    display: -webkit-box;
    max-width: 140px;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__details {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    gap: 2px;

    svg {
      path {
        stroke: $color-prime;
      }
    }
  }

  &__play-at {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    width: max-content;
    background: #fffbf4;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #14192e;

    &--primary {
      color: $color-primary-yellow;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__sentiment {
    margin: auto;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      &__list {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #8694a4;
        margin: 0;
        padding: 0;
        gap: 8px;
      }

      &__tooltip {
        .ant-tooltip-inner {
          min-height: max-content;
          padding: 15px 10px;
        }
      }

      &__user-img {
        border-radius: 50%;
        height: 24px;
        width: 24px;
      }

      &__date {
        color: #16191d;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        white-space: nowrap;
      }

      svg {
        border-radius: 24px;
        width: 20px;
        height: 20px;
        margin: auto 4px auto 4px;
      }
    }
  }

  &__audio {
    margin: auto;
    width: 750px;
  }

  &__transcript {
    @include flex-body-column;
    gap: 10px;
    padding: 0 16px;
    width: 620px;

    &__container {
      @include flex-body-column;
      gap: 2px;
    }

    &__info-container {
      @include flex(row, flex-start, flex-start);
      gap: 16px;
    }

    &__title-div {
      @include flex-body;
      padding: 10px 16px;
      gap: 8px;
      align-self: stretch;
      border-radius: 6px;
      background: $color-grey-18;

      &__label {
        @include subtitle;
        color: $color-grey-19;
      }

      &__rotate {
        transform: rotate(270deg);
      }

      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {
          stroke: $color-grey-19;
        }
      }
    }

    &__content {
      @include flex-body-column;
      padding: 24px;
      gap: 16px;
      align-self: stretch;
      border-radius: 8px;
      background: $color-grey-20;
      overflow: scroll;
      height: 300px;
    }

    &__time {
      @include flex-body-centered;
      @include description;
      padding: 4px 10px;
      border-radius: 4px;
      background: $color-border-grey-2;
      color: $color-dark-grey;
    }

    &__text {
      @include description;
      color: $color-dark-text;

      &__bold {
        font-weight: 500;
      }
    }
  }

  .ant-modal-content {
    padding: 24px;
  }

  .ant-modal-body {
    @include flex-body-column;
    gap: 24px;
  }

  .ant-modal-close {
    top: 15px;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    padding: 0px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-radius: 2px 2px 0 0;
  }

  audio::-webkit-media-controls-panel {
    background-color: white;
  }

  audio::-webkit-media-controls-play-button {
    background-color: #16191d;
    opacity: 1;
    border-radius: 156px;
  }

  audio::-webkit-media-controls-mute-button {
    display: none !important;
  }

  audio::-webkit-media-controls-volume-slider {
    display: none !important;
  }

  audio::-webkit-media-controls-volume-control-container {
    display: none;
  }

  audio::-internal-media-controls-overflow-button {
    display: none;
  }

  audio::-webkit-media-controls-current-time-display {
    display: none;
  }

  audio::-webkit-media-controls-time-remaining-display {
    display: none;
  }

  audio::-internal-media-controls-overflow-button {
    display: none;
  }

  .ant-spin-container {
    display: flex;
  }
}
