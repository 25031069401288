@import 'styles/titles.scss';
@import 'styles/colors.scss';

.chord-graph {
  &__container {
    @include flex(column, flex-start, flex-start);
    position: relative;
    width: 100%;
  }

  &__header {
    @include flex(row, center, space-between);
    width: 100%;
    padding: 16px 30px;
    border-bottom: 1px solid $color-border-grey;

    &__filters {
      @include flex-body;
    }
  }

  &__content-div {
    margin: 0 40px 0 40px;
  }

  &__description {
    @include flex-body;
    @include subtitle;
    color: $color-grey-text;
    margin: 20px 0 30px 0;
  }

  &__chart {
    &__labels {
      font-size: 2.1px;
      text-transform: uppercase;
      fill: $color-black;
      cursor: pointer;

      &__opacity {
        @extend .chord-graph__chart__labels;
        opacity: 0.5;
      }

      &--highlighted {
        @extend .chord-graph__chart__labels;
        fill: $color-black;
        font-weight: 500;
        opacity: 1;
      }
    }

    &__title {
      font-size: 2.1px;
      font-weight: 500;
      text-transform: uppercase;
      fill: $color-black;
      cursor: pointer;

      &__background {
        fill: $color-grey-12;

        &__opacity {
          @extend .chord-graph__chart__title__background;
          opacity: 0.5;
        }
      }

      &__opacity {
        @extend .chord-graph__chart__title;
        opacity: 0.5;
      }

      &--highlighted {
        @extend .chord-graph__chart__title;
        fill: $color-white;
      }
    }

    &__rectangle {
      fill: $color-grey-12;

      &--highlighted {
        fill: $color-prime;
      }
    }

    &__arcs {
      &__midRange,
      &__races {
        stroke: $color-brown;
      }

      &__closeRange,
      &__company {
        stroke: $color-grey-text;
      }

      &__races {
        &--blue {
          stroke: $elections-races-blue;
        }

        &--red {
          stroke: $elections-races-red;
        }

        &--purple {
          stroke: $elections-races-purple;
        }
      }

      &__general {
        stroke: $color-grey-12;
      }

      &__opacity {
        stroke: $color-grey-12;
        opacity: 0.9;
      }

      &--highlighted {
        stroke: $color-prime;
      }
    }
  }

  &__graph-div {
    @include flex-body-centered;
    background: $color-grey-scale-100;
    border-radius: 4px;
    margin-right: 16px;
    cursor: pointer;
    border: 1px solid $color-border-grey;

    &__title {
      @include description;
      color: $color-black;
      margin-right: 8px;

      &--active {
        color: $color-white;
      }
    }

    &__active-chart {
      @include flex-body-centered;
      background: $color-black;
      box-shadow: 6px 2px 10px rgba(0, 0, 0, 0.12);
      border-radius: 4px;

      svg {
        path {
          fill: $color-white !important;
        }
      }
    }

    svg {
      display: flex;
      margin: 8px;
      height: 18px;

      path {
        fill: $color-black;
      }
    }
  }

  &__election-graph-div {
    @include flex-body-centered;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
    border: 1px solid $color-border-grey-2;
    overflow: hidden;

    &___tab {
      @include flex-body-centered;
      background: $color-grey-21;
      padding: 8px 14px;
      gap: 4px;
      border-left: 1px solid$color-border-grey-2;

      &__title {
        @include description-600;
        color: $color-grey-19;
        margin: 4px 4px 4px 0;

        &--active {
          color: $color-white;
        }
      }

      &--active {
        border-left: 1px solid $color-border-grey-2;
        background: $elections-races-blue;

        svg {
          path {
            fill: #ff151f !important;
          }
        }
      }

      &:first-child {
        border-left: none;
      }

      svg {
        display: flex;
        margin: 4px;
        height: 18px;

        path {
          fill: $color-black;
        }
      }
    }
  }

  &__election-logo {
    top: -150px;
    position: relative;
    float: right;
  }

  &__links {
    stroke: $color-grey-12;
    stroke-width: 0.2;
    fill: none;

    &--highlighted {
      stroke: $color-prime;
      stroke-width: 0.4;
      fill: none;
      cursor: pointer;
    }

    &--red {
      stroke: $elections-races-red;
      stroke-width: 0.4;
      fill: none;
      cursor: pointer;
    }

    &--blue {
      stroke: $elections-races-blue;
      stroke-width: 0.4;
      fill: none;
      cursor: pointer;
    }

    &--purple {
      stroke: $elections-races-purple;
      stroke-width: 0.4;
      fill: none;
      cursor: pointer;
    }
  }

  &__selected-node {
    font-weight: 500;
    opacity: 1;
  }

  &__selected-title-node {
    font-weight: 500;
    fill: $color-white;
    opacity: 1;
  }

  &__elections {
    &__title {
      font-size: 2.1px;
      font-weight: 500;
      text-transform: uppercase;
      fill: $color-white;
      cursor: pointer;
    }

    &__races {
      &--red {
        @extend .chord-graph__chart__title;
        fill: $elections-races-red;
      }

      &--blue {
        @extend .chord-graph__chart__title;
        fill: $elections-races-blue;
      }

      &--purple {
        @extend .chord-graph__chart__title;
        fill: $elections-races-purple;
      }
    }
  }
}
