@import 'styles/colors.scss';
@import 'styles/titles.scss';

.tenant-users {
  @include flex-body-column;
  gap: 24px;

  &__body {
    &__card {
      @include flex-body-column;
      gap: 16px;

      &__icon {
        width: 34px;
        height: 34px;
        padding: 9px;
        visibility: hidden;
        cursor: pointer;

        &--visible {
          visibility: visible !important;
        }

        &:hover {
          background-color: $color-white-hover;
        }
      }

      &__menu {
        border-radius: 6px;
        border: 1px solid $color-grey-scale-200;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

        .ant-dropdown-menu {
          @include flex-body-column;
          padding: 8px 6px;
          gap: 4px;

          .ant-dropdown-menu-item {
            padding: 8px;

            span {
              @include description;
              color: $color-dark-text;
            }
          }
        }
      }
    }

    &__info {
      @include flex-body-column;
      gap: 16px;
      width: 100%;

      &__email {
        @include flex-body-column;
        gap: 2px;

        &--label {
          @include footnote;
          color: $color-dark-grey;
        }

        &--text {
          @include description;
          color: $color-dark-grey;
        }
      }

      &__role {
        @include flex-body-column;
        gap: 4px;

        &__option {
          @include description;
          text-transform: capitalize;
          color: $color-dark-grey;
        }

        &__icon {
          path {
            stroke: $color-grey-text;
          }
        }

        &__dropdown {
          border-radius: 4px;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

          .ant-select-item-option-content {
            span {
              color: $color-dark-text;
            }
          }
        }

        .ant-select {
          width: 100% !important;
        }
      }
    }
  }

  &__actions {
    display: none;

    &__icon {
      cursor: pointer;

      &:hover {
        background-color: $color-gray-hover;
      }
    }
  }

  &__actions-div {
    padding: 5px;
    display: flex;

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      background-color: $color-white-hover;
    }
  }

  &__margin {
    margin-right: 20px;
  }

  &__icon {
    cursor: pointer;

    path {
      stroke: $color-grey-text;
      stroke-width: 1.5;
    }

    &:hover {
      background-color: $color-white-hover !important;
    }
  }

  .card {
    &:hover {
      .tenant-users__body__card__icon {
        visibility: visible;
      }
    }
  }

  .ant-select {
    width: 80%;
  }

  .ant-select-selector {
    border-radius: 4px !important;
    border: 1px solid $color-grey-scale-200 !important;
    background-color: $color-unselected !important;
    padding: 4px 12px !important;
    height: max-content !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 2px solid $color-grey-scale-200 !important;
    box-shadow: none;
  }
}
