@import 'styles/variables.scss';
@import 'styles/colors.scss';

.radio_group {
  display: flex;
  flex-wrap: nowrap;
  .ant-radio-button-wrapper {
    border-radius: 4px;
    background-color: $color-grey-20;
    border-color: $color-grey-17 !important;
    color: $color-grey-22 !important;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    &::before {
      background-color: $color-grey-17 !important;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: $color-primary-3 !important;
      border-color: $color-primary-10 !important;
      border-right-color: $color-primary-10 !important;
      border-left-color: $color-primary-10 !important;
      color: $color-primary-11 !important;
      &::before {
        background-color: $color-primary-10 !important;
      }
    }
  }
}
