@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

.data-fetching {
  background-color: white;
  height: 100%;

  &__title-icon {
    display: flex;
    margin-bottom: 10px;
    gap: 8px;

    padding-left: 40px;

    img {
      margin: 40px 0px;
    }

    h2 {
      margin: 40px 0px;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: $color-black;
      margin-bottom: 0;
    }
  }

  &__main-content {
    height: calc(100vh - 400px);
    background-color: $color-grey-1;
    border: 1px solid $color-secondary-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0px 40px;
    text-align: center;

    h3 {
      font-weight: 500;
      color: $color-dark-text;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 22px;
    }

    p {
      @include heading;
      color: $color-dark-text;
      margin-bottom: 0;
      font-weight: 400;
    }
  }
}
