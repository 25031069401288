@import 'styles/colors.scss';

.brandListVertical {
  width: 337px;
  min-width: 337px;
  visibility: visible;

  &__no-active-brand-placeholder {
    top: 38%;
    left: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    padding: 0px 24px;
    color: $color-dark-grey;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  .fixed {
    background-color: $color-grey-21;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    padding: 0px 24px 24px 24px;
    gap: 12px;
    width: 100%;
    box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.05) inset;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    &:has(.brandListVertical__no-active-brand-placeholder) {
      background-color: $color-primary-3;
    }
  }

  .brand-item {
    height: 48px;
    padding: 8px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .ant-avatar.ant-avatar-circle {
      min-width: 32px;
    }

    &__search-actions {
      margin-left: auto;
      .three-dot-icon-disabled {
        cursor: not-allowed;
      }
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $color-dark-grey;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  .brand-item-selected {
    background-color: $color-primary-7;
    .title {
      font-weight: 600;
      color: $color-primary-300_1;
    }
  }
}

.hideBrandList {
  width: 0;
  min-width: 0;
  visibility: hidden;
}

.lower-section {
  margin-top: auto;

  .add-new-brand {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-black;
    padding-left: 16px;
    border-top: 1px solid $color-grey-18;
    padding-top: 16px;
  }
  .add-new-brand-disabled {
    color: $color-disabled-grey;
    cursor: unset;
  }

  .deactivated-brands {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-dark-grey;
    cursor: pointer;
  }
}

.deactivated-brands-modal {
  &__content {
    padding: 20px 32px;
    .status {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: $color-dark-grey;
    }
    .brand-list {
      border: 1px solid $color-border-grey-2;
      border-radius: 8px;
      max-height: 400px;
      overflow-y: auto;
      &__brand {
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid $color-border-grey-2;
        padding: 16px;
        h5 {
          margin-bottom: 0px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: $platform-brand-color-tiktok;
        }
        .ant-checkbox-inner {
          border-color: rgb(191, 147, 71) !important;
          border-radius: 4px;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          border-color: rgb(191, 147, 71);
          background-color: rgb(191, 147, 71);
        }
      }
    }
  }
  &__footer {
    gap: 8px;
    display: flex;

    &__cancel-btn {
      display: flex;
      min-width: 128px;
      padding: 10px 16px;
      justify-content: center;
      color: $color-dark-text;
      background-color: $color-unselected;
    }

    &__continue-btn {
      display: flex;
      min-width: 128px;
      padding: 10px 16px;
      color: $color-white;
      justify-content: center;
      background-color: $color-black;

      &:disabled {
        background-color: $color-disabled-grey;
      }
    }
  }
}

.deactivated-brands-modal.ant-modal {
  // width: 648px !important;

  .ant-modal-content {
    position: relative;
    padding: 0px;

    .ant-modal-close {
      top: 12px;
    }
    .ant-modal-header {
      padding: 24px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom: 1px solid $color-border-grey;
      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $color-black;
      }
    }

    .ant-modal-footer {
      padding: 16px;
      border-top: 1px solid $color-border-grey;
      justify-content: flex-end;
    }
  }
}

.brand-list-header {
  padding: 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--title {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__icon {
    background-color: $color-grey-18;
    width: 28px;
    height: 28px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h5 {
    color: $color-grey-600;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  h6 {
    color: $color-black;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px;
  }
}

.brand-list-header.collapse {
  padding: 0px;
  cursor: pointer;
  width: max-content;
}

.ant-drawer.ant-drawer-left.brand-list-drawer.ant-drawer-open {
  .ant-drawer-mask {
    background: rgba(0, 0, 0, 0.5);
  }
}
