@import 'styles/colors.scss';

.assets_header_container {
  .ahc_inner_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 40px;
    .ahc_search_container {
      display: flex;
      align-items: center;
      gap: 16px;
      .ahc_sc_input_container {
        gap: 8px;
        height: 40px;
      }
      .ahc_sc_button {
        height: 40px;
        background-color: $color-unselected;
        border-radius: 6px;
        padding: 10px 16px;
        color: $color-dark-grey;
      }
      .ahc_corportate_comm_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $color-grey-900;
        margin-bottom: 0px !important;
      }
      .ahc_sc_exec_button {
        color: #14192e;
      }
    }
    .ahc_ic_action_buttons {
      display: flex;
      align-items: center;
      .ahc_ic_ab_button {
        height: 36px;
        padding: 8px 10px;
        background-color: $color-primary-3;
        color: $color-dark-grey;
        border: 1px solid $color-primary-10;
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:nth-child(2) {
          border-radius: 0;
          border-right: 0;
          border-left: 0;
        }
        &:nth-child(3) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          .ahc_ic_ab_b_switch {
            background-color: $color-grey-18;
            min-width: 36px;
            height: 20px;

            .ant-switch-handle {
              width: 16px;
              height: 16px;
              &::before {
                border-radius: 10px;
              }
            }
            &.ant-switch-checked {
              background-color: $color-prime;
              &:hover {
                background-color: $color-primary-300_1;
              }
              .ant-switch-handle {
                left: calc(100% - 16px - 2px);
              }
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
        &:last-child {
          border: none;
          background: none;
          color: $color-primary-11;
          gap: 2px;
        }
      }
    }
    .ahc_filter_container {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.radio_group_view {
  .ant-radio-button-wrapper {
    border-radius: 4px;
    background-color: $color-grey-20;
    border-color: $color-grey-17;
    color: $color-grey-22;
    &::before {
      background-color: $color-grey-17;
    }
    padding: 0px 8px;

    svg {
      path {
        fill: $color-grey-22;
      }
    }

    &.ant-radio-button-wrapper-checked {
      background-color: $color-primary-3;
      border-top-color: $color-primary-10 !important;
      border-bottom-color: $color-primary-10 !important;
      border-right-color: $color-primary-10 !important;
      border-left-color: $color-primary-10 !important;
      color: $color-primary-11;
      &::before {
        background-color: $color-primary-10;
      }
      svg {
        path {
          fill: $color-primary-11;
        }
      }

      &:hover,
      &:active,
      &:focus {
        border-top-color: $color-primary-10 !important;
        border-bottom-color: $color-primary-10 !important;
        border-right-color: $color-primary-10 !important;
        border-left-color: $color-primary-10 !important;
        color: $color-primary-11 !important;
      }

      &:hover::before {
        background-color: $color-primary-10;
      }
    }
  }
  .radio_group_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
