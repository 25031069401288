@import 'styles/colors.scss';

.toaster_container {
  position: absolute;
  bottom: 56px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999999;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: fit-content;
  border-radius: 12px;
  border: 1px solid $color-grey-17;
  background-color: $color-white;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  .tc_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: $success-100;
    border-radius: 50%;
  }
  .tc_text {
    color: $color-dark-text;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0;
  }
}
