@import 'styles/colors.scss';
@import 'styles/titles.scss';

.sectionInner {
  background-color: $color-background;

  .sectionIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    margin-right: 11px;
    border-radius: 50%;
    background-color: $section-background;
  }

  .sectionTitleContainer {
    padding: 10px 16px;
    background-color: $color-background;
    border: 1px solid $color-border-grey;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    height: 61px;
    width: 100%;

    &.sectionTitleContainerES {
      margin-bottom: 12px;
    }

    .sectionLabel {
      color: $color-grey-text;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px;
      display: flex;
      align-items: center;
    }

    .sectionInput {
      background-color: $color-background;
      border: 0;
      padding: 0;
      min-height: unset;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
    }
  }

  .subsection {
    background-color: $color-background;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &.subsectionES {
      margin-bottom: 12px;
    }

    .panel {
      width: 100%;

      &.panelES {
        border: 1px solid $color-border-grey;
        border-radius: 4px;
        margin-bottom: 12px;
        padding: 5px 8px;
      }

      :global(.ant-collapse-header) {
        padding-inline: 0px;
      }

      :global(.ant-collapse-content-box) {
        padding: 0px;
      }
    }

    .subheader {
      display: flex;
      line-height: 17px;
      color: $color-dark-text;
      width: 100%;
      margin: 0;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .subheaderIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      margin-right: 11px;
      border-radius: 50%;
      background-color: $color-prime;
      color: $color-white;
      margin-left: 13px;

      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    .subselect {
      background-color: $color-background;
      border: 1px solid $color-border-grey;
      border-radius: 4px;
      width: 100%;

      .downArrowIcon {
        pointer-events: none;
        flex-shrink: 0;
      }
    }
  }

  .summarizationContainer {
    padding: 10px 16px;
    margin-top: 10px;
    background-color: $color-background;
    border: 1px solid $color-border-grey;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 3px;

    .inner {
      display: flex;
      gap: 2px;

      .summarizationLabel {
        color: $color-grey-text;
        font-size: 12px;
        font-style: normal;
        display: flex;
        align-items: center;
        gap: 9px;
        font-weight: 600;
        line-height: 13px;
      }
    }

    .summarizationTextArea {
      background-color: $color-background;
      border: 0;
      padding: 0;
      font-size: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      min-height: 80px;
      resize: none;
    }
  }
}
