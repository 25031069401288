@import 'styles/colors.scss';
@import 'styles/titles.scss';

.date-filter {
  height: 40px;
  flex-shrink: 0;
  cursor: pointer;
  padding: 10px 14px;
  align-self: stretch;
  align-items: center;
  @include flexbox($gap: 8px);

  &__text {
    @include footnote;
    font-size: 14px;
    font-family: Roboto;
    color: $color-dark-grey;
  }

  &__title {
    @include info-700;
    text-transform: uppercase;
    color: $color-dark-text;
    margin-bottom: 4px;
    padding: 0;

    &.disabled-item {
      cursor: not-allowed;

      &:hover {
        background-color: $color-white !important;
        cursor: not-allowed !important;
      }
    }

    &--margin {
      @extend .date-filter__title;
      margin-top: 18px;
    }

    &:hover {
      background-color: $color-white !important;
      cursor: default !important;
    }
  }

  &__custom-btn {
    &,
    &:focus,
    &:active {
      @include flex-body;
      @include footnote;
      background-color: transparent !important;
      outline: none !important;
      border: none !important;
      cursor: pointer;
      padding: 0;
      margin: 0;
      color: $color-prime;
    }

    &:hover {
      background-color: $color-white;
    }
  }

  &__filter-icon {
    @include description;
    // @include flex-body;
    padding: 10px 8px;
    gap: 4px;
    color: $color-black;
    background: $color-white;
    margin-right: 4px;
    border-radius: 6px;
    border: 1px solid $color-border-grey;

    svg {
      height: 20px;

      path {
        stroke: $color-grey-text;
      }
    }

    .feed-filters__text {
      color: $color-black !important;
    }
  }

  &__filter-not-applied {
    padding: 7px 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__dropdown {
    height: max-content;
    max-width: 630px;
    overflow: hidden;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);

    overflow-y: auto;
    min-width: 150px;

    &::before {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      background-color: #dcdcdc;
      border-radius: 13px;
    }

    .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
      padding: 8px 8px 8px 16px;
    }
  }

  &__dropdown-item {
    @include description;
    margin: 2px 0;
    padding: 0;

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-dark-text;
    }

    &--selected {
      @include info-700;
      color: $color-dark-text;
    }

    &--inline {
      @extend .date-filter__dropdown-item;
      display: inline-block !important;
    }

    &__mode-btn {
      padding: 4px 10px;
      background: $color-unselected;
      border: none;
      border-radius: 4px;
      color: $color-dark-grey;
      cursor: pointer;

      .vk-icon {
        path {
          fill: $color-white;
        }
      }

      .telegram-icon {
        path:nth-child(2) {
          fill: $color-white;
        }
      }

      &--active {
        color: $color-border-white;
        background: $color-black;

        path {
          fill: $color-white;
        }

        .vk-icon {
          path {
            fill: $color-black;
          }
        }

        .threads-icon {
          path {
            fill: unset;
          }
        }

        .telegram-icon {
          path:nth-child(2) {
            fill: $color-black;
          }
        }
      }

      &--margin {
        margin-right: 6px;
      }

      &__title {
        @include flex-body-centered;
        gap: 8px;

        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    &__risk-slider {
      @include flex(column, center, center);
      padding: 10px;
      gap: 10px;
      background: $color-white;
      border: 1px solid $color-border-grey;

      span {
        margin: 0 10px;
      }

      .ant-dropdown-menu-title-content {
        @include flex-body-centered;
        width: 80%;
      }

      .ant-slider {
        width: 100%;
      }

      .ant-slider-track.ant-slider-track-1 {
        background-color: $color-black;
      }

      .ant-slider-handle {
        border-color: $color-black !important;
        background-color: $color-black;
      }

      .ant-slider-rail {
        width: 100%;
        background-color: $color-grey-scale-100;
      }
    }

    &.disabled-item {
      background-color: #d6d6d4 !important;
      cursor: not-allowed;
      color: #3b393455;
    }

    &:hover {
      color: $color-prime;
      background-color: $color-white;
    }

    .ant-radio-checked .ant-radio-inner {
      border: 1px solid $color-black;
    }

    .ant-radio-inner:after {
      background: $color-black;
    }
  }

  &__calendar {
    @include flex(row, flex-start, space-between);

    &__date {
      @include flex(row, center, flex-start);
      gap: 12px;
      min-width: 30px;

      &__container {
        padding: 10px 12px;
        background: $color-white;
        border-radius: 6px;
        border: 1px solid $color-border-grey;
      }

      &__button {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        &__cancel {
          @include subtitle;
          border-radius: 6px;
          border: 1px solid $color-black;
          background: $color-white;
          color: $color-black;
          padding: 10px 16px;
          cursor: pointer;
          width: 100px;
        }

        &__apply {
          @include subtitle;
          border-radius: 6px;
          border: none;
          background: $color-black;
          color: $color-white;
          padding: 10px 16px;
          cursor: pointer;
          width: 100px;
        }
      }

      path {
        stroke: $color-dark-grey;
      }

      span {
        @include description;
        color: $color-grey-text;
      }
    }

    &__picker {
      border: 1px solid $color-border-grey !important;
      border-radius: 6px;
      box-shadow: none;

      input {
        @include description;
        color: $color-black;
        font-size: 14px !important;
      }

      .ant-picker-active-bar {
        background-color: $color-black;
        opacity: 0.8 !important;
      }
    }
  }

  &__calendar-dropdown {
    width: 663px;
    border-radius: 8px;
    background: $color-white;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: auto;
    z-index: 9999;
    right: 100px;

    &__list-view {
      right: 45px;
    }

    &__container {
      .rdrDateDisplayWrapper {
        background-color: $color-white;
        min-height: 64px;
        border-bottom: 1px solid $color-border-grey-2;

        .rdrDateDisplay {
          margin: 12px 24px !important;
          justify-content: flex-end;
          color: $color-border-grey;
        }
      }

      .rdrDateDisplayItem {
        width: 112px;
        height: 40px;
        flex: none;
        box-shadow: none;
        border-radius: 6px;
        border: 1px solid $color-border-grey;
        background: $color-border-grey;
        text-align: center;

        input {
          height: 40px;
        }
      }

      .rdrDefinedRangesWrapper {
        display: none;
      }

      .calendar-dropdown .rdrCalendarWrapper {
        width: 100%;
      }

      .rdrNextPrevButton {
        background: none;
      }

      .rdrDayPassive {
        pointer-events: none;

        .rdrDayNumber span {
          color: transparent !important;
        }
      }

      .rdrDayDisabled {
        .rdrDayNumber span {
          @include subtitle;
        }
      }

      .rdrDayWeekend {
        .rdrInRange {
          border-radius: 0;
        }
      }

      .rdrDay {
        height: 4em;
      }

      .rdrDay,
      .rdrDayHovered {
        .rdrDayStartPreview {
          border: none;
        }

        .rdrDayEndPreview {
          border: none;
        }

        .rdrDayInPreview {
          border: none;
        }
      }

      .rdrDayToday {
        .rdrDayNumber {
          span::after {
            background: $color-black;
          }
        }
      }

      .rdrDay:not(.rdrDayPassive, .rdrDayDisabled) {
        .rdrDayNumber span {
          @include subtitle;
          color: $color-black !important;
        }

        .rdrStartEdge {
          border-radius: 4px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background: $color-black !important;

          ~ .rdrDayNumber {
            span {
              color: $color-white !important;
            }
          }
        }

        .rdrEndEdge:not(.rdrStartEdge) {
          border-radius: 4px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          background: $color-black !important;

          ~ .rdrDayNumber {
            color: $color-black !important;

            span {
              color: $color-white !important;
            }
          }
        }

        .rdrInRange {
          color: $color-black !important;
          background-color: $color-grey-scale-50;
        }
      }

      .rdrWeekDays {
        height: 4em;
        align-items: center;

        .rdrWeekDay {
          @include description;
          text-transform: uppercase;
        }
      }

      .rdrMonthPicker {
        border-radius: 6px;
        border: 1px solid $color-grey-17;
        background: $color-white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        select:hover {
          background-color: transparent;
        }
      }

      .rdrYearPicker {
        border-radius: 6px;
        border: 1px solid $color-grey-17;
        background: $color-white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        select:hover {
          background-color: transparent;
        }
      }

      .rdrMonthName {
        @include flex-body-centered;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 2px;
        color: $color-black;
      }
    }

    &__footer {
      @include flex(row, center, flex-end);
      padding: 16px 24px;
      gap: 16px;
      border-top: 1px solid $color-border-grey-2;
    }
  }
}
