@import 'styles/colors.scss';
.irdp_container {
  :global {
    .ant-spin-nested-loading {
      .ant-spin {
        max-height: 100%;
      }
    }
  }

  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  .irdp_c_grid_container {
    display: flex;
    flex-grow: 1;
    .gc_left_column {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 294px;
      border-right: 1px solid $color-border-grey;
      padding: 32px 24px 20px 40px;
    }
    .gc_left_column_view_history {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 294px;
      border-right: 1px solid $color-border-grey;
    }
    .gc_center_column {
      overflow-y: auto;
      flex-grow: 1;
      height: calc(100vh - 140px);
      padding: 40px 128px 30px 80px;
    }
    .gc_right_column {
      flex-shrink: 0;
      width: 588px;
      padding: 16px 0 0 16px;
      border-left: 1px solid $color-grey-17;
      height: calc(100% - 190px);
    }
  }
}
