@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

.custom_modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.35);
  z-index: 10000;
  .cmc_content_container {
    display: flex;
    flex-direction: column;
    min-width: 530px;
    min-height: 290px;
    background-color: $color-white;
    border-radius: 16px;
    padding: 32px;
    .cmc_cc_header_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cmc_cc_hc_left_container {
        display: flex;
        align-items: center;
        gap: 10px;
        .cmc_cc_hc_lc_heading {
          color: $color-dark-text;
          @include title;
          margin: 0;
        }
        .cmc_cc_hc_lc_desc {
          color: $color-grey-text;
          @include body;
          margin: 0;
          margin-top: 8px;
        }
      }
      .cmc_cc_hc_close_icon {
        cursor: pointer;
        path {
          stroke: $color-grey-scale-600;
        }
      }
    }
    .cmc_cc_main_content {
      //   background-color: gold;
      flex-grow: 1;
    }
    .cmc_cc_footer_container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
