@import 'styles/colors.scss';

.mmp_container {
  border-bottom: 1px solid $color-border-grey;

  .mmp_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px;
    border-bottom: 1px solid $color-border-grey;

    .mmp_hc_search {
      padding: 8px 16px;
      gap: 8px;
      background-color: $color-grey-20;
    }

    .mmp_hc_button {
      padding: 8px 12px;
      height: 36px;
      gap: 4px;
      font-size: 12px;

      svg {
        path {
          stroke: $color-white;
        }
      }
    }
  }

  .mmp_content_container {
    display: flex;
    height: calc(100vh - 118px);

    &.mmp_content_container_add_padding {
      padding-left: 40px;
    }

    .mmp_cc_left_side_container {
      flex-shrink: 0;
      min-width: 250px;
    }

    .mmp_cc_main_container {
      flex-grow: 1;

      .mmp_cc_mc_sub_header_container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 16px;
        padding-right: 40px;
        z-index: 2;

        .mmp_shc_left_section {
          display: flex;
          align-items: center;

          .mmp_cc_mc_shc_ls_button {
            background-color: $color-white;
            border: 1px solid $color-border-grey;
            height: 36px;
            padding: 10px 14px;
            color: $color-dark-grey;
          }

          .mmp_shc_ls_buttons {
            display: flex;
            align-items: center;
            margin-left: 16px;

            .mmp_cc_mc_shc_ls_button {
              padding: 8px 16px;
              border-color: $color-grey-17;
              background-color: $color-grey-20;

              &.mmp_cc_mc_shc_ls_button_active {
                border-color: $color-primary-10;
                background-color: $color-primary-3;
                color: $color-primary-11;
              }

              &:not(:last-child) {
                border-right: none;
                border-radius: 0px;
              }

              &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }

              &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }

              &:only-child {
                border-radius: 4px;
              }
            }

            &.mmp_shc_ls_action_buttons {
              margin-left: 21px;

              .mmp_cc_mc_shc_ls_button {
                border-color: $color-primary-10;
                background-color: $color-primary-3;
                padding: 8px 12px;

                &.mmp_cc_mc_shc_ls_button_disabled {
                  border-color: $color-grey-17;
                  background-color: $color-grey-20;
                  cursor: not-allowed;
                  opacity: 0.7;
                }

                &:not(:last-child) {
                  border-right: none;
                  border-radius: 0px;
                }

                &:first-child {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }

                &:last-child {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }

                &:only-child {
                  border-radius: 4px;
                }

                &:last-child {
                  border: none;
                  border-left: 1px solid $color-primary-10;
                  background: none;
                  color: $color-primary-11;
                }
              }
            }
          }
        }

        .mmp_shc_right_section {
          display: flex;
          align-items: center;
          gap: 16px;

          .react-datepicker__tab-loop {
            position: absolute;
            top: 0;
            right: -16px;
          }

          .mmp_shc_rs_buttons {
            display: flex;
            align-items: center;

            .mmp_cc_mc_shc_rs_button {
              height: 36px;
              padding: 6px;
              border: 1px solid $color-grey-17;
              background-color: $color-grey-20;

              &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                .mmp_button_icon {
                  path {
                    fill: $color-dark-grey;
                  }
                }
              }

              &:last-child {
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                .mmp_button_icon {
                  path {
                    stroke: $color-dark-grey;
                  }
                }
              }

              &.mmp_cc_mc_shc_rs_button_active {
                border-color: $color-primary-10;
                background-color: $color-primary-3;

                &:first-child {
                  .mmp_button_icon {
                    path {
                      fill: $color-primary-11;
                    }
                  }
                }

                &:last-child {
                  .mmp_button_icon {
                    path {
                      stroke: $color-primary-11;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .mmp_cc_mc_pagination_container {
        // background-color: gold;
        padding-bottom: 16px;
        margin-top: 16px;

        .mmp_cc_mc_pagination {
          margin: 0 !important;

          .ant-pagination-item,
          .ant-pagination-jump-next-custom-icon,
          .ant-pagination-jump-prev-custom-icon {
            height: 40px;
            width: 40px;
            border-color: $color-grey-17;

            .pagination__container {
              padding: 0;
            }

            .pagination__container {
              .pagination__label {
                font-family: Inter;
                font-weight: 500;
                color: $color-grey-800;
              }
            }

            &.ant-pagination-item-active {
              border-color: $color-prime;

              .pagination__container {
                .pagination__label {
                  color: $color-white;
                }
              }
            }
          }

          .ant-pagination-next,
          .ant-pagination-prev {
            height: 40px;
            border-color: $color-grey-17;

            .pagination__container {
              gap: 8px;
              padding: 10px 16px;

              .pagination__label {
                font-family: Inter;
                font-weight: 500;
                color: $color-grey-800;
              }

              svg {
                path {
                  stroke: $color-grey-19;
                }
              }
            }
          }

          :last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

.mmp_spinner_wrapper {
  height: 100% !important;

  .mmp_spinner {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
  }
}

#custom_modal {
  .watchlist-success-modal {
    .cmc_cc_fc_button:first-child {
      display: flex;
      border: none;
      min-width: 128px;
      padding: 10px 16px;
      justify-content: center;
      color: $color-dark-text;
      background-color: $color-unselected;
    }

    .cmc_cc_fc_button:last-child {
      display: flex;
      border: none;
      min-width: 128px;
      padding: 10px 16px;
      color: $color-white;
      justify-content: center;
      background-color: $color-black;
    }
  }
}
