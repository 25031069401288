@import 'styles/colors.scss';

.c-executive-requires-attention {
  width: 150px;
  display: flex;

  &__icon--active,
  &__icon--inactive {
    margin: auto auto;
    width: 16px;
    height: 16px;
  }

  &__icon--active {
    path {
      stroke: $color-prime;
    }
  }

  &__icon--inactive {
    path {
      stroke: #e4e3e1;

      &:nth-of-type(2) {
        fill: #e4e3e1;
      }
    }
  }
}
