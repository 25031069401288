@import 'styles/colors.scss';

.ant-modal.base-modal {
  .ant-modal-content {
    padding: 0px;
    margin: 0px;

    .ant-modal-close {
      padding: 16px 24px;

      .ant-modal-close-x {
        width: auto;
        height: auto;
        line-height: normal;
      }
    }

    .ant-modal-header {
      margin: 0px;
      padding: 0px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      .ant-modal-title {
        text-align: left;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        padding: 16px 24px;
        color: $color-grey-900;
      }
    }

    .ant-modal-footer {
      gap: 8px;
      margin: 0px;
      display: flex;
      padding: 16px 24px;
      align-items: center;
      justify-content: flex-end;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .ant-btn-primary {
        margin: 0px;
        border: none;
        outline: none;
        font-weight: 500;
        padding: 10px 16px;
        border-radius: 6px;
        color: $color-white;
        background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
          linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);
      }
      .ant-btn-text {
        border: none;
        outline: none;
        font-weight: 500;
        padding: 10px 16px;
      }
    }
  }

  &.isHeaderBordered {
    .ant-modal-header {
      border-bottom: 1px solid $color-border-grey;
    }
  }

  &.isFooterBordered {
    .ant-modal-footer {
      border-top: 1px solid $color-border-grey;
    }
  }
}
