@import 'styles/colors.scss';
@import 'styles/titles.scss';

.slide-carousel-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;

  .carousel-content {
    width: 100%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    .data-fetching__main-content.brand-snippets-empty-state {
      height: auto;
    }

    .carousel-slide {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      transition: transform 0.3s ease;

      &.hidden {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
      }

      &.active {
        position: relative;
        z-index: 1;
        transform: translateX(0);
        opacity: 1;
      }

      &.slide-in-right {
        transform: translateX(0);
        animation: slideInRight 0.3s ease forwards;
      }

      &.slide-in-left {
        transform: translateX(0);
        animation: slideInLeft 0.3s ease forwards;
      }

      &.slide-out-left {
        position: absolute;
        z-index: 0;
        animation: slideOutLeft 0.3s ease forwards;
      }

      &.slide-out-right {
        position: absolute;
        z-index: 0;
        animation: slideOutRight 0.3s ease forwards;
      }
    }
  }

  .carousel-controls {
    height: 36px;
    margin-top: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    box-sizing: border-box;

    .carousel-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      .carousel-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-grey-17;
        border: none;
        padding: 0;
        cursor: pointer;
        transition: all 0.3s ease;
        box-sizing: border-box;

        &:hover {
          background-color: $color-grey-17;
        }

        &.active {
          background-color: $color-dark-grey;
          transform: scale(1.2);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .carousel-arrow {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      padding: 0.5rem;
      color: $color-grey-19;
      transition: all 0.3s ease;
      z-index: 1;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.7;
        transform: scale(1.1);
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
        transform: none;
      }

      &:focus {
        outline: none;
      }

      &.prev {
        transform: rotate(180deg);
        svg path {
          stroke: $color-grey-19;
        }
      }

      &.next {
        svg path {
          stroke: $color-grey-19;
        }
      }
    }
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.slide-carousel-container.fullscreen {
  height: 100vh;

  .carousel-content {
    height: 100%;
  }
}

.slide-carousel-container.dark-theme {
  .carousel-dot {
    background-color: $color-grey-17;

    &:hover {
      background-color: $color-grey-17;
    }

    &.active {
      background-color: $color-white;
    }
  }

  .carousel-arrow {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }
}

@media (max-width: 768px) {
  .slide-carousel-container {
    .carousel-controls {
      .carousel-arrow {
        font-size: 1.2rem;
        padding: 0.3rem;
      }

      .carousel-dots {
        .carousel-dot {
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}
