@import 'styles/colors.scss';

.checkbox_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid $color-grey-17;
  cursor: pointer;
  &.checkbox_container_selected {
    background-color: $color-prime;
    border-color: $color-prime;
    .ccs_line {
      height: 2px;
      width: 10px;
      border-radius: 10px;
      background-color: $color-white;
    }
  }
}
