@import 'styles/variables.scss';
@import 'styles/colors.scss';

.table_wapper {
  width: 100%;
  border-collapse: collapse;
  border: none;

  .table_header {
    background-color: $color-secondary-grey;

    .table_header_cell {
      text-align: left;

      .table_header_cell_heading {
        width: 100%;
        color: $color-grey-text;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;
      }

      &:first-child {
        border-top-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
      }

      .table_header_cell_chunk {
        padding: 16px;
      }
    }
  }

  .table_body {
    .table_row_item {
      cursor: pointer;
      border-bottom: 1px solid $color-border-grey;

      &.table_row_item_disabled {
        cursor: not-allowed !important;
      }

      &:hover {
        background-color: $color-hover;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      .table_row_cell {
        padding: 16px;
        color: $color-dark-grey;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        .table_row_expand_icon {
          transition: all 0.1s linear;

          &.table_row_expand_icon_expanded {
            transform: rotate(90deg);
          }
        }
      }

      &.table_row_item_expand {
        .table_row_cell {
          padding: 0;
        }
      }
    }

    .table_row_selected {
      background-color: $color-primary-3;
      border-bottom: 1px solid $color-prime !important;

      &:hover {
        background-color: $color-primary-3;
      }
    }
  }
}
