@import 'styles/titles.scss';
@import 'styles/colors.scss';

.summary-wrapper {
  padding-top: 24px;
}
.executive-summary-body {
  max-width: 530px;
  // overflow-y: auto;
  max-height: 500px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.brand {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.brand-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $color-grey-900;
}

.brand-image {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 0.48px solid $color-pastel-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
}
