@import 'styles/colors.scss';
.report_details_panel_container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 106px);
  :global {
    .ant-spin-nested-loading {
      .ant-spin {
        max-height: 100%;
      }
    }
  }
  .rdpc_grid_container {
    display: flex;
    flex-grow: 1;
    .rdpc_gc_left_column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      border-right: 1px solid $color-border-grey;
      padding: 16px 0px 20px 40px;
      .rdpc_l_header_container {
        padding: 0 17px 21px 0;
        border-bottom: 1px solid $color-border-grey;
      }

      .rdpc_l_text_container {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 24px 0;
        padding-right: 40px;
        .rdpc_l_text {
          margin: 0;
          color: $color-dark-grey;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .rdpc_l_cc_section_container {
        padding-right: 40px;
        height: calc(100vh - 290px);
        overflow: auto;
      }
    }
    .rdpc_right_column {
      flex-shrink: 0;
      flex-basis: 574px;
      padding: 16px 0 0 16px;
    }
  }
}
