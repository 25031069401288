@import 'styles/colors.scss';
@import 'styles/titles.scss';

.navigation-settings {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;

  .ant-menu {
    background-color: transparent !important;
  }

  .ant-menu-item a::before {
    display: none;
  }

  .ant-menu-item::after {
    border-right: none !important;
  }

  .ant-menu-item {
    padding: 0 !important;

    a {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      @include description-16;
      color: $color-dark-text;

      svg {
        stroke: $color-dark-grey;

        g {
          stroke: $color-dark-grey;
        }

        path {
          stroke: $color-dark-grey;
        }
      }
    }
  }

  .ant-menu-item a:hover {
    text-decoration: underline;
    color: $color-prime;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $color-primary-6;
    border-radius: 12px;
    padding: 0 !important;

    svg {
      path {
        stroke: $color-prime;
      }
    }

    a {
      color: $color-prime;
    }
  }

  .ant-menu-inline {
    border: none;
  }

  .ant-menu-submenu-title {
    display: flex !important;
    padding: 12px 16px !important;
    align-items: center !important;
    gap: 8px;
    align-self: stretch;
    @include description-16;
    color: $color-dark-text;
    transition: none !important;

    &:hover {
      span {
        text-decoration: underline;
        color: $color-prime;
      }

      .ant-menu-submenu-arrow {
        color: $color-prime !important;
      }
    }

    svg {
      stroke: $color-dark-grey;

      g {
        stroke: $color-dark-grey;
      }

      path {
        stroke: $color-dark-grey;
      }
    }
  }

  .ant-menu-submenu:hover {
    .ant-menu-submenu-arrow {
      color: $color-prime !important;
    }
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: #f5edde;
  }

  .ant-menu-sub:not(.ant-menu-horizontal) .ant-menu-item-selected {
    border-radius: 12px;
    background: #f5edde;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    padding: 8px 16px !important;
    align-items: center;
    gap: 8px;

    a {
      color: #a38448;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .ant-menu-sub {
    .ant-menu-item {
      color: $color-dark-grey;
      @include description-16;

      &:hover {
        a {
          text-decoration: underline;
          color: $color-prime;
        }
      }
    }
  }

  .ant-menu-sub {
    padding-left: 20px !important;
  }

  .ant-menu-submenu {
    .ant-menu-item {
      display: flex;
      padding: 8px 16px !important;
      align-items: center !important;
      gap: 8px;
      align-self: stretch;
      margin: 0 !important;
      margin-bottom: 8px !important;
    }
  }
}
