@import 'styles/colors.scss';
.action_button_container {
  display: flex;
  align-items: center;
  gap: 6px;
  &.abc_icon_container_gap {
    gap: 20px;
  }
  .abc_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // background-color: gold;
    &.abc_icon_container_tooltip {
      position: relative;
      height: 34px;
      width: 34px;
      border-radius: 4px;
      &::before {
        display: none;
        content: attr(data-tooltip);
        width: max-content;
        position: absolute;
        border-radius: 4px;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-black;
        padding: 12px 8px;
        color: $color-white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        z-index: 1;
      }
      &::after {
        display: none;
        content: '';
        position: absolute;

        top: calc(100% - 2px);
        left: 50%;
        transform: translateX(-50%);

        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent $color-black transparent;
        // border-color: transparent transparent pink transparent;
      }
      &:hover {
        background-color: $color-white-hover;
        &::before,
        &::after {
          display: block;
        }
      }
    }
    &.abc_icon_container_disabled {
      border-radius: 4px;
      opacity: 0.5;
      cursor: default;
    }
  }
}
