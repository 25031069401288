@import 'styles/colors';
@import 'styles/titles';

$platform-colors: (
  linkedin: #0a66c2,
  youtube: $color-red,
  twitter: $platform-brand-color-twitter,
  tiktok: $platform-brand-color-tiktok,
  podcast: $platform-brand-color-podcast,
  news: $platform-brand-color-news,
  bitchute: $platform-brand-color-bitchute,
  rumble: $platform-brand-color-rumble,
  reddit: $platform-brand-color-reddit,
  gab: $platform-brand-color-gab,
  'patriots.win': $platform-brand-color-patriots,
  truthsocial: $platform-brand-color-truthsocial,
  vk: #0077ff,
  instagram: #d300c5,
  threads: $platform-brand-color-tiktok,
  telegram: #0088cc,
  brighteon: #1b2e5a,
  bluesky: #0085ff,
  snapchat: $color-red,
);

.creator-details {
  @include flex-body-column;
  padding: 0 24px;
  gap: 24px;

  &__back {
    @include flex-body;
    @include description-16;
    gap: 2px;
    color: $color-dark-grey;
    outline: none !important;
    border: none !important;
    cursor: pointer;
    background: transparent;

    &:focus,
    &:active,
    &:hover {
      outline: none !important;
      border: none !important;
      cursor: pointer;
      background: transparent;
    }
  }

  &__info-container {
    @include flex-body;
    flex-wrap: wrap;
    gap: 39px;

    &__avatar {
      object-fit: cover;
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }

    &__container-0 {
      @include flex-body-column;
      gap: 16px;
      width: 60%;
    }

    &__container-1 {
      @include flex-body-column;
      gap: 15px;
    }

    &__container-2 {
      @include flex-body-column;
      gap: 4px;
    }

    &__title {
      @include title;
      color: #000;
    }

    &__url {
      @include description;
      color: $color-dark-grey;
      word-break: break-word;
    }

    &__container-3 {
      @include flex-body;
      gap: 24px;
      flex-wrap: wrap;
    }

    &__container-4 {
      @include flex-body;
      gap: 8px;
    }

    &__following-container {
      &__title {
        @include info;
        color: $color-dark-grey;
        line-height: 18px;
      }

      &__count {
        @include heading;
        color: #000;
        line-height: 18px;
      }
    }

    &__social_ids {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 8px;
      flex-wrap: wrap;

      &__container {
        @include flex-body;
        flex-wrap: wrap;
        min-width: 215px;
        min-height: 30px;
        padding: 6px 16px;
        gap: 6px;
        border-radius: 6px;
        background: $color-grey-18;
      }
      &__platform {
        object-fit: fill;
      }

      &__text {
        @include info;
        color: $color-grey-19;
        word-break: break-word;
        line-height: 18px;
      }
    }

    &__platform {
      background-color: $color-grey-18;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 6px;
      width: max-content;
      min-width: 32px;
      height: 32px;
      cursor: pointer;
      span {
        margin: 0px;
        display: flex;
      }
      &__copy-icon {
        display: none !important;
      }
      &:hover &__copy-icon {
        border: 1px solid $color-grey-17;
        border-radius: 6px;
        height: 24px;
        width: 24px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        svg {
          path {
            stroke: $color-grey-text;
          }
        }
      }

      @mixin platform-styles($platform, $color) {
        &.#{$platform} {
          .icon {
            svg path {
              fill: $color;
            }
          }
        }
      }

      @each $platform, $color in $platform-colors {
        @include platform-styles($platform, $color);
      }

      &.threads {
        .icon svg path {
          fill: transparent;
        }
      }

      &.vk {
        .icon svg path {
          stroke: $color-white;
        }
      }

      &.snapchat {
        .icon svg g path {
          stroke: $color-white;
        }
      }

      &.telegram {
        .icon svg path:first-child {
          fill: map-get($platform-colors, telegram);
        }
        .icon svg path:last-child {
          fill: $color-white;
        }
      }
    }
  }

  &__watchlists {
    @include flex-body;
    flex-wrap: wrap;
    gap: 8px;

    &__heading {
      @include info;
      color: $color-dark-grey;
      line-height: 18px;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }

    &__container {
      @include flex-body;
      height: 40px;
      padding: 6px 24px;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid $color-border-grey-2;
      background: $color-grey-21;
    }

    &__info {
      @include flex-body;
      gap: 8px;
    }

    &__title {
      @include info;
      color: #000;
      line-height: 18px;
    }

    &__count {
      @include footnote;
      color: $color-dark-grey;
      line-height: 18px;
    }

    &__logo-container {
      @include flex-body;
    }

    &__logo-wrapper {
      display: flex;
      position: relative;
    }

    &__logo {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-left: -12px;
      z-index: 1;
      border: 1.5px solid #fff;
      background: #d9d9d9;

      :first-child {
        margin-left: 0;
      }
    }

    &__remaining-count {
      @include flex-body-centered;
      border-radius: 12px;
      border: 1.5px solid #fff;
      background: $color-primary-7;
      color: $color-prime;
      font-family: Inter;
      font-size: 9.6px;
      font-style: normal;
      font-weight: 400;
      line-height: 10.8px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-left: -12px;
      z-index: 1;
    }

    &__add-to-watchlist {
      height: 40px;
      background-color: $color-black;
      border-radius: 6px;
      padding: 10px 16px;
      color: $color-white;
      width: max-content;

      svg {
        margin-bottom: 2px !important;
      }
    }
  }

  &__suggested-container {
    @include flex-body-column;
    gap: 16px;
  }

  &__similar-text {
    @include subtitle-600;
    color: $color-grey-600;
    line-height: 24px;
  }

  &__search {
    &__container {
      padding: 8px 16px;
      gap: 8px;
      background: $color-grey-20;
      flex: 1;
      height: auto;
      width: 100%;
    }

    &__icon {
      width: 24px;
      height: 24px;

      path {
        fill: $color-dark-grey;
      }
    }
  }

  hr {
    height: 1px;
    border: none;
    background-color: $color-border-grey-2;
  }
  &__back-btn {
    height: 40px;
    background-color: $color-unselected;
    border-radius: 6px;
    padding: 10px 16px;
    color: $color-grey-19;
    display: flex;
    align-items: center;
    width: max-content;

    &.bt_dashboard {
      svg {
        margin: 0px;
      }
    }
  }
}

.ant-tooltip.platforms-tooltip.ant-tooltip-placement-top {
  max-width: 350px !important;
  word-break: break-all;
}
