@import 'styles/colors.scss';

.sl-with-filters-container-wrapper {
  height: 100%;
  width: 100%;
}

.sl-with-filters-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  flex-direction: column;

  .ant-click-animating-node {
    display: none;
  }

  &:has(.ant-spin-spinning) {
    &::after {
      content: '';
      top: 0;
      left: 24px;
      right: 24px;
      bottom: 0;
      opacity: 0.2;
      position: absolute;
      background-color: $color-black;
    }
  }

  .ant-spin.ant-spin-spinning {
    top: 50%;
    left: 50%;
    z-index: 2;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &__header {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-border-grey;

    &__info {
      gap: 4px;
      display: flex;
      flex-direction: column;

      &-title {
        color: $color-dark-text;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }

      &-count {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &__search {
      display: flex;
      align-items: center;
      padding-right: 8px;

      &__close {
        display: flex;
        align-items: center;

        svg {
          transform: rotate(90deg);
        }
      }

      .ant-input {
        min-height: auto;
        font-size: 16px;
        font-weight: 400;
        color: $color-dark-text;
        box-shadow: none !important;
        outline: none !important;
        border: none !important;

        &::placeholder {
          color: $color-grey-22;
        }
      }
      .ant-input:focus {
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
      }
      .ant-input-affix-wrapper {
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
        min-height: auto;
        border-radius: 8px;
        border: 1px solid $color-grey-17;
        background-color: $color-grey-20;
        box-shadow: 0px 1px 2px 0px #1018280d;
        &:hover {
          border-color: $color-grey-17;
        }

        .ant-input {
          padding-left: 4px;
          min-height: auto;
          font-size: 16px;
          font-weight: 400;
          color: $color-dark-text;
          background-color: $color-grey-20;
          box-shadow: none !important;
          outline: none !important;
          border: none !important;

          &::placeholder {
            color: $color-grey-22;
          }
        }
      }

      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: $color-grey-17;
      }
    }
  }
  &__filters-section {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-border-grey;

    &__left-section {
      gap: 8px;
      display: flex;
      align-items: center;

      button {
        gap: 8px;
        display: flex;
        border: none;
        outline: none;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 6px;
        padding: 10px 16px;
        font-family: Roboto;
        align-items: center;
        color: $color-grey-19;
        background-color: $color-grey-18;

        &:hover,
        &:focus,
        &:active {
          color: $color-grey-19;
          background-color: $color-grey-18;
        }
      }

      button.ant-dropdown-open {
        color: $color-grey-19;
        background-color: $color-grey-18;
      }

      button svg {
        width: 20px;
        height: 20px;
        path {
          stroke: $color-grey-19;
        }
      }

      button.reset-btn {
        color: $color-black;
        background-color: transparent;
        svg {
          width: 20px;
          height: 20px;
          path {
            stroke: $color-black;
          }
        }
      }
    }

    &__right-section {
      .dots {
        display: inline-flex;
        padding: 4px;
        cursor: pointer;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: $color-grey-18;
        }
      }

      svg path {
        stroke: $color-grey-19;
      }
    }
  }

  .ant-drawer-body {
    padding: 16px 24px;
  }

  .snippet-list__bottom-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__count {
      color: $color-black;
      font-weight: 500;
    }

    &__actions {
      gap: 24px;
      display: flex;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        svg {
          width: 20px;
          height: 20px;
          path {
            stroke-width: 2;
            stroke: $color-grey-19;
          }
        }
      }
    }

    button.close-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      svg {
        width: 12px;
        height: 12px;
        path {
          stroke: $color-grey-19;
        }
      }
    }
  }
}

.snippet-list-filters-drawer.ant-drawer.ant-drawer-right {
  .ant-drawer-footer {
    gap: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-btn-primary {
      margin: 0px;
      height: auto;
      border: none;
      outline: none;
      font-weight: 500;
      padding: 10px 16px;
      border-radius: 6px;
      color: $color-white;
      background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);
    }
    .ant-btn-text {
      height: auto;
      border: none;
      outline: none;
      font-weight: 500;
      padding: 10px 16px;
    }
  }

  .snippet-list__right-actions {
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .platform-selection,
    .content-source-selection,
    .sentiment-selection,
    .risk-selection {
      gap: 6px;
      display: flex;
      flex-direction: column;

      &__sentiments,
      &__sources {
        gap: 8px;
        display: flex;
        align-items: center;
      }

      &__title {
        color: $color-grey-19;
        font-size: 14px;
        font-weight: 500;
        margin: 0px;
      }

      button {
        gap: 2px;
        height: auto;
        border: none;
        outline: none;
        display: flex;
        padding: 4px 10px;
        border-radius: 6px;
        align-items: center;
        color: $color-dark-grey;
        background-color: $color-unselected;
      }

      button.isActive {
        color: $color-white;
        background-color: $color-black;
      }
    }

    .ant-select.ant-select-multiple {
      .ant-select-selector {
        border-radius: 6px;
        border-color: $color-grey-17;
        outline: none;
        box-shadow: none;
        padding: 1px 12px;
      }
      .ant-tag.ant-tag-has-color {
        position: relative;
        padding: 0px;
        .anticon.anticon-close.ant-tag-close-icon {
          position: absolute;
          right: 6px;
          top: 5px;
          margin: 0px;
          svg {
            fill: $color-grey-19;
          }
        }
        .platform-batch {
          padding: 0px 20px 0px 6px;
          border-radius: 50px;
          gap: 4px;

          svg {
            height: 10px;
          }

          .icon svg {
            width: 12px;
          }

          .title {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.snippet-list__options__dropdown.ant-dropdown {
  .ant-dropdown-menu {
    padding: 4px;

    .ant-dropdown-menu-item {
      padding: 0px;
    }
    .pi-dropdown-item {
      padding: 8px 12px;
      gap: 8px;
    }
    li svg {
      path {
        stroke: $color-grey-19;
      }
    }
  }
}

.snippet-list__options__dropdown.language-dropdown.ant-dropdown {
  .ant-dropdown-menu {
    width: 201px;
    max-height: 204px;
    overflow-y: auto;
    padding: 0px;
    border-radius: 8px;
    border: 1px solid $color-border-grey-2;
    box-shadow: 0px 8px 14px 0px #0000001a;

    .ant-dropdown-menu-item {
      padding: 8px 12px;
      font-weight: 500;
      font-size: 14px;
      color: $color-black;
      &-active {
        background-color: $color-border-grey-2;
      }
    }
  }

  .language-dropdown__menu {
    z-index: 1000;
    border-radius: 8px;
    background-color: $color-white;
    border: 1px solid $color-border-grey-2;
    box-shadow: 0px 8px 14px 0px #0000001a;

    &__footer {
      gap: 8px;
      display: flex;
      padding: 8px 12px;
      align-items: center;
      cursor: pointer;
      padding: 8px 12px;
      border-top: 1px solid $color-border-grey;
      span {
        color: $color-grey-19;
        font-weight: 500;
      }

      svg {
        width: 1.3em;
        height: 1.3em;
        path {
          stroke-width: 2;
          stroke: $color-grey-19;
        }
      }
    }
    .ant-dropdown-menu {
      border: none;
      box-shadow: none;
      border-radius: none;
    }
  }

  &.bookmarks {
    .bookmarks-dropdown__menu {
      z-index: 1000;
      border-radius: 8px;
      background-color: $color-white;
      border: 1px solid $color-border-grey-2;
      box-shadow: 0px 8px 14px 0px #0000001a;

      .bookmark-option__label {
        gap: 8px;
        display: flex;
        align-items: center;
      }

      &__header,
      &__footer {
        gap: 8px;
        display: flex;
        padding: 8px 12px;
        align-items: center;
      }

      &__header {
        color: $color-grey-text;
        font-weight: 500;
      }
      &__footer {
        cursor: pointer;
        padding: 8px 12px;
        border-top: 1px solid $color-border-grey;
        span {
          color: $color-grey-19;
          font-weight: 500;
        }

        svg {
          width: 12px;
          height: 12px;
          path {
            stroke-width: 2;
            stroke: $color-grey-19;
          }
        }
      }
    }
    .ant-dropdown-menu {
      width: 330px;
      border: none;
      box-shadow: none;
      border-radius: none;
    }
  }
}

.snippet-list__options__dropdown.sort-dropdown.ant-dropdown {
  .sort-dropdown__menu {
    padding: 16px;
    border-radius: 5px;
    background-color: $color-white;
    border: 1px solid $color-border-grey-2;
    box-shadow: 0px 3px 28px 0px #00000029;
    gap: 20px;
    display: flex;
    flex-direction: column;

    &__title {
      color: $platform-brand-color-tiktok;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }

    &--sort-by-selection {
      gap: 4px;
      display: flex;
      flex-direction: column;
    }

    &__sort-by {
      gap: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &__text {
        color: $platform-brand-color-tiktok;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &__order-by {
      gap: 4px;
      display: flex;
      flex-direction: column;

      &__title {
        color: $platform-brand-color-tiktok;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }

      &__btn {
        gap: 6px;
        display: flex;
        align-items: center;

        button.isActive {
          margin: 0px;
          border: none;
          outline: none;
          height: auto;
          font-weight: 500;
          padding: 4px 10px;
          border-radius: 6px;
          color: $color-white;
          background: linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000000 100%),
            linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%);
        }
        .ant-btn-default {
          border: none;
          outline: none;
          height: auto;
          font-weight: 500;
          padding: 4px 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $color-dark-grey;
          background-color: $color-unselected;
        }
      }
    }
  }
}

.platform-selection__dropdown.ant-select-dropdown {
  .rc-virtual-list-holder-inner {
    .ant-select-item-option-content {
      .platform-batch {
        padding: 2px 8px;
        height: auto;
        border: none;
        background-color: transparent;
        svg {
          height: 10px;
        }

        .icon svg {
          width: 12px;
        }

        .title {
          font-size: 10px;
        }
      }
    }
    .ant-select-item.ant-select-item-option.ant-select-item-option-active {
      background-color: $color-border-grey-2;
    }
  }
}

.new-bookmark-modal {
  .ant-modal-content {
    .ant-modal-header .ant-modal-title {
      .bookmark-modal__title {
        gap: 8px;
        display: flex;
        flex-direction: column;

        &__description {
          color: #8598ad;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .ant-modal-body {
      .new-bookmark-modal__content {
        gap: 24px;
        display: flex;
        padding: 20px 32px;
        flex-direction: column;

        .ant-form-item {
          margin: 0px;
          .ant-col.ant-form-item-control {
            margin: 0px;
            .ant-form-item-control-input-content {
              min-height: auto;
            }
          }
        }

        label {
          gap: 6px;
          display: flex;
          flex-direction: column;

          .input-label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: $color-grey-19;

            .asterisk {
              color: $delete-label-text;
            }
          }

          .ant-input {
            min-height: auto;
            font-size: 16px;
            font-weight: 400;
            color: $color-dark-text;
            padding: 8px 12px;
            border-radius: 8px;
            border: 1px solid $color-grey-17;
            box-shadow: 0px 1px 2px 0px #1018280d;
          }

          textarea.ant-input {
            padding: 12px 14px;
            min-height: 131px;
            resize: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.ant-dropdown-menu-submenu {
  max-height: 500px;
  overflow-y: auto;
  .ant-dropdown-menu-submenu-title {
    padding-left: 4px;
  }
}

.language-label {
  display: flex;
  justify-content: space-between;

  &--selected {
    background-color: $color-border-grey-2;
  }
}
