@import 'styles/colors.scss';

.topics_container {
  display: flex;
  flex-direction: column;
  // background-color: pink;
  height: calc(100vh - 400px);
  .tc_chart_heading_container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 14px;
    .tc_chc_icon {
      cursor: pointer;
    }
    .tc_chc_text {
      color: $color-dark-text-2;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }
  }
  .tc_content_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    .tc_df_container {
      .data-fetching__title-icon {
        display: none;
      }
      .data-fetching__main-content {
        height: 100%;
        margin: 0;
      }
    }
    .tc_cc_loading_overlay {
      height: 100%;
    }
  }
}
