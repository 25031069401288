@import 'styles/colors.scss';
@import 'styles/titles.scss';

.ant-drawer.brand-list-drawer {
  .ant-drawer-content-wrapper {
    width: auto !important;
    left: 69px;
    .ant-drawer-content {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
  .ant-drawer-wrapper-body {
    background-color: $color-grey-21;
    box-shadow: -4px 0px 10px 0px #0000000d inset;
    .ant-drawer-header {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0px;
      .ant-drawer-header-title {
        width: 100%;
        justify-content: flex-end;
      }
    }
    .ant-drawer-body {
      padding: 0px;
    }
  }
}

.bd {
  position: relative;
  &--container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: $color-grey-18;

    .ant-spin.ant-spin-spinning.bd__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }

  &--container-content {
    width: 100%;
  }

  &__header {
    z-index: 2;
    width: 100%;
    height: 68px;
    padding: 0px 40px 0px 24px;
    background-color: $color-white;
    @include flexbox($justify: space-between);

    &__left-menu {
      @include flexbox($gap: 16px);

      &__assistant-btn.ant-btn.ant-btn-default {
        border: none;
        height: 40px;
        min-width: 64px;
        padding: 10px 16px;
        border-radius: 6px;
        @include button-text;
        color: $color-white;
        border-top: 1px solid $color-black;
        border-right: 1px solid $color-black;
        border-left: 1px solid $color-black;
        box-shadow: 0px 0px 9px 0px rgba(151, 71, 255, 0.4);
        @include flexbox($justify: center, $direction: row-reverse, $gap: 8px);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
          linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);

        &:hover,
        &:active,
        &:focus {
          border: none;
          color: $color-white;
          border-top: 1px solid $color-black;
          border-right: 1px solid $color-black;
          border-left: 1px solid $color-black;
          border-color: $color-black;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 2.5%, rgba(0, 0, 0, 0) 15.49%),
            linear-gradient(180deg, rgba(22, 25, 29, 0.85) 0%, #000 100%);
        }
      }
    }
    &__right-menu {
      @include flexbox($gap: 16px);

      &__snippets-btn.ant-btn.ant-btn-default {
        border: none;
        height: 40px;
        min-width: 64px;
        border-radius: 6px;
        padding: 10px 16px;
        font-family: Roboto;
        @include button-text;
        color: $color-grey-19;
        background: $color-grey-18;
        @include flexbox($justify: center);

        &:hover,
        &:active,
        &:focus {
          border: none;
          height: 40px;
          min-width: 64px;
          border-radius: 6px;
          padding: 10px 16px;
          font-family: Roboto;
          @include button-text;
          color: $color-grey-19;
          background: $color-grey-18;
          @include flexbox($justify: center);
        }
      }
    }
  }
  &__body {
    overflow-y: auto;
    height: calc(100vh - 128px);
    padding: 32px 40px 56px 19px;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    &__header {
      @include flexbox($gap: 8px, $direction: column, $align: flex-start);

      &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        color: $color-dark-text;
      }

      &__description {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &--left-section {
      @include flexbox($gap: 40px, $direction: column, $align: '');
    }

    &__brand-overview {
      @include flexbox($gap: 16px, $direction: column, $align: '');

      &--matrix {
        @include flexbox($gap: 20px);
      }

      &__card {
        flex: 1 0 0;
        padding: 24px;
        align-self: stretch;
        border-radius: 12px;
        background: $color-white;
        border: 1px solid $color-border-grey;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
        @include flexbox($gap: 24px, $direction: column, $align: flex-start);
      }
    }
    &__executive-summary {
      position: relative;
      @include flexbox($gap: 16px, $direction: column, $align: flex-start);

      &--empty-container {
        padding: 24px;
        min-height: 300px;
        width: 100%;
        border-radius: 12px;
        background: $color-white;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
      }

      &__error {
        gap: 16px;
        top: 50%;
        left: 50%;
        display: flex;
        position: absolute;
        align-items: center;
        flex-direction: column;
        transform: translate(-50%, -50%);
      }

      &--container {
        padding: 24px;
        border-radius: 12px;
        background: $color-white;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
      }

      &--content {
        padding: 24px;
        position: relative;
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid $color-border-grey;
        @include flexbox($gap: 16px, $direction: column, $align: flex-start);
      }

      &__heading,
      &__sub-heading,
      &__description {
        font-size: 16px;
        line-height: 28px;
        font-style: normal;
      }

      &__heading {
        font-weight: 600;
        color: $color-dark-text;
      }

      &__sub-heading {
        font-weight: 400;
        color: $color-dark-text;
      }

      &__description {
        font-weight: 400;
        color: $color-dark-grey;
      }

      &__profile-list {
        @include flexbox($gap: 8px);
      }

      &__profile-content {
        cursor: pointer;
        @include flexbox($gap: 16px);
        &__profile {
          position: relative;

          &__channel-icon {
            top: 50%;
            width: 16px;
            height: 16px;
            right: -12px;
            position: absolute;
            border-radius: 50px;
            background: $color-white;
            transform: translateY(-50%);
            @include flexbox($justify: center);

            svg {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
    &__influential-voices {
      @include flexbox($gap: 16px, $direction: column, $align: flex-start);

      &--container {
        align-self: stretch;
        @include flexbox($gap: 20px);
      }

      &--top-voices,
      &--followed-voices {
        padding: 24px;
        border-radius: 12px;
        background: $color-white;
        border: 1px solid $color-border-grey;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
        @include flexbox($direction: column, $gap: 24px, $align: center);
      }

      &--top-voices {
        width: 56%;
        height: 644px;

        .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
          width: 100%;
          display: block;
        }
      }

      &--followed-voices {
        width: 44%;
        height: 644px;
        .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
          width: 100%;
          display: block;
        }
      }

      &__header {
        align-self: stretch;
        @include flexbox($justify: space-between);

        &__title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          font-family: Roboto;
          color: $color-grey-900;
        }

        &__info-icon {
          cursor: pointer;
          @include flexbox($justify: center);

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__table {
        width: 100%;
        thead > tr > .ant-table-cell {
          border: none;
          height: 44px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          padding: 8px 12px;
          font-family: Roboto;
          color: $color-dark-grey;
          background: $color-grey-20;
          border-top: 1px solid $color-border-grey-2;
          border-bottom: 1px solid $color-border-grey-2;

          &::before {
            width: 0 !important;
          }
        }

        tbody > tr > .ant-table-cell {
          padding: 16px 12px;
          height: 72px;
          cursor: pointer;
        }
      }

      &-creator {
        @include flex-body;
        gap: 16px;

        &__container {
          @include flex-body;
          gap: 12px;
        }

        &__container2 {
          @include flex-body-column;
          gap: 4px;
          min-width: 120px;
        }

        &__container3 {
          @include flex-body;
          gap: 6px;
          width: 250px;
          line-break: anywhere;
        }

        &__name {
          @include info;
          max-width: 145px;
          overflow: hidden;
          line-height: 18px;
          white-space: nowrap;
          font-family: Roboto;
          color: $color-black;
          text-overflow: ellipsis;
        }

        &__count {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: $color-dark-grey;
          font-family: 'Roboto Mono';
        }

        &__link {
          @include footnote;
          color: $color-dark-grey;
        }

        &__avatar {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__platform {
          object-fit: fill;
        }
      }

      &--avatar-container {
        cursor: pointer;
        overflow-y: auto;
        align-self: stretch;
        @include flexbox($direction: column, $justify: flex-start, $align: flex-start, $gap: 24px);

        &::-webkit-scrollbar {
          width: 0;
          display: none;
        }
      }

      &__avatar-group {
        @include flexbox($gap: 20px);

        .ant-avatar {
          margin-inline-end: -12px;
        }

        &__title {
          @include body;
          color: $color-dark-text;
        }
      }
    }

    &__trending-posts {
      &--container {
        @include flexbox($direction: column, $gap: 16px, $align: flex-start);
      }

      &--empty-container {
        padding: 32px;
        overflow: hidden;
        min-height: 300px;
        border-radius: 12px;
        background: $color-white;
        border: 1px solid $color-border-grey;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
        @include flexbox($direction: column, $gap: 24px, $align: center, $justify: center);
      }

      &--content {
        padding: 32px;
        overflow: hidden;
        position: relative;
        border-radius: 12px;
        background-size: cover;
        background-size: 100% auto;
        border: 1px solid $color-border-grey;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
        background: url('../../../assets/images/lining-masks.svg') white left top;
        @include flexbox($direction: column, $gap: 24px, $align: center, $justify: center);
      }

      &--post-card {
        overflow: hidden;
        padding: 12px 24px;
        border-radius: 16px;
        background: $color-white;
        border: 1px solid $color-border-grey-2;
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
        @include flexbox($direction: column, $align: flex-start, $gap: 14px);
      }

      &__creator {
        width: 100%;
        @include flexbox($justify: space-between, $align: center);

        &--container {
          @include flexbox($gap: 16px);
        }

        &__profile {
          position: relative;
          @include flexbox($gap: 8px);

          &-name {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            font-family: Roboto;
            color: $color-border-black;
          }

          &-channel {
            @include flexbox();
            svg {
              width: 15px;
              height: 15px;
            }
          }

          &::after {
            top: 50%;
            content: '';
            width: 4px;
            height: 4px;
            right: -10px;
            position: absolute;
            border-radius: 50%;
            transform: translateY(-50%);
            background-color: $color-grey-17;
          }
        }

        &__channel-name,
        &__date-posted {
          font-family: Roboto;
          @include footnote;
          color: $color-dark-grey;
        }

        &__channel-name {
          position: relative;
          &::after {
            top: 50%;
            content: '';
            width: 4px;
            height: 4px;
            right: -10px;
            position: absolute;
            border-radius: 50%;
            transform: translateY(-50%);
            background-color: $color-grey-17;
          }
        }
      }

      &__description {
        min-height: 100px;
        max-height: 100px;
        overflow-y: auto;
        font-family: Roboto;
        @include description;
        color: $color-border-black;

        &::-webkit-scrollbar {
          width: 0;
          display: none;
        }
      }

      &__iframe {
        width: 100%;
        max-height: 400px;
      }

      &__stats {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 8px;
        @include flexbox($justify: center);
      }
    }

    &--right-section {
      width: 100%;
      @include flexbox($gap: 16px, $direction: column, $align: '');
    }

    &__shareOfVoice {
      width: 100%;
      @include flexbox($gap: 16px, $direction: column, $align: '');

      &__container {
        width: 100%;
        padding: 24px;
        align-self: stretch;
        border-radius: 12px;
        background: $color-white;
        border: 1px solid $color-grey-18;
        @include flexbox($direction: column, $gap: 24px);
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
      }
    }

    &__sentiments {
      @include flexbox($gap: 16px, $direction: column, $align: '');

      &__container {
        padding: 24px;
        align-self: stretch;
        border-radius: 12px;
        background: $color-white;
        border: 1px solid $color-grey-18;
        @include flexbox($direction: column, $gap: 24px);
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
      }
    }

    &__trendPlatform {
      width: 100%;
      @include flexbox($gap: 16px, $direction: column, $align: '');

      &__container {
        width: 100%;
        padding: 24px;
        align-self: stretch;
        border-radius: 12px;
        background: $color-white;
        border: 1px solid $color-grey-18;
        @include flexbox($direction: column, $gap: 24px);
        box-shadow: 0px 0px 8px 0px rgba(134, 148, 164, 0.15);
      }

      .ant-divider.ant-divider-horizontal {
        border-color: #f2f4f7;
        margin: 12px 0px;
      }
    }
  }
}

.bd--container.loading {
  position: relative;
  &::after {
    content: '';
    z-index: 1;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    background-color: $color-white;
  }
}

.bd--container:has(.no-brand-placeholder) {
  background-color: $color-grey-21;
}

.metrics-display {
  padding: 10px;
  border-radius: 16px;
  background: $color-white;
  border: 1px solid $color-grey-18;
  @include flexbox($align: flex-start);

  .metric-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0px 10px;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      right: -2px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color-grey-17;
    }

    .metric-content {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        width: 16px;
        height: 16px;
        color: $color-grey-22;
      }
    }

    .metric-value {
      @include subtitle;
      color: $color-dark-grey;
    }

    .metric-label {
      font-size: 0.75rem;
      color: $color-grey-22;
    }
  }
}

#newPortfolioChart,
#newBrandSentiment,
.new-trend-graph,
#newPlatformChart {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .selectMetric.ant-select {
    width: 100%;
  }

  .selectMetric.ant-select .ant-select-selector {
    height: 40px;
    border-radius: 8px;
    border-color: $color-grey-17;
    .ant-select-selection-item {
      line-height: 40px;
    }
  }

  .selectMetric.ant-select .ant-select-arrow svg {
    width: 16px;
    height: 16px;

    path {
      fill: $color-dark-grey;
      stroke-width: 6px;
    }
  }
}

.new-trend-graph {
  align-items: stretch;
}

#newPlatformChart {
  width: 100%;

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-spin-container {
    width: 100%;
    gap: 24px;
    display: flex;
    position: relative;
    flex-direction: column;
  }
}

.new-share-of-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding-top: 0px;

  .doghnut {
    svg {
      height: 216px;
      width: 216px;
    }
  }

  .skeleton-paragraph {
    display: grid;
    gap: 0px 30px;
    grid-template-columns: 1fr 1fr;
    &__small {
      width: 30px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }

    &__large {
      width: 60px;
      height: 15px;
      overflow: hidden;
      border-radius: 8px;
    }
  }
}

iframe::-webkit-media-controls-overlay-play-button,
iframe .sc-hknOHE {
  display: none !important;
}

.alert-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-grey-18;
  border-radius: 8px;
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: $color-dark-grey;
    }
  }
}
