@import 'styles/colors.scss';
.create_flag_modal_step4_container {
  flex-grow: 1;
  .clms4c_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px 12px 40px;
    gap: 74px;
    border-top: 1px solid $color-border-grey-2;
    border-bottom: 1px solid $color-border-grey-2;
    background: $color-white;
    .clms4c_h_total_results {
      color: $color-primary-11;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0;
      white-space: nowrap;
      .clms4c_h_tr_part {
        color: $color-dark-grey;
        font-size: 14px;
      }
    }
    .clms4c_h_input_container {
      padding: 8px 16px;
      width: 100%;
      height: 40px;
      gap: 8px;
      border-radius: 8px;
    }
  }
  .clms4c_snippets_container {
    background-color: $color-primary-3;
    height: calc(100vh - 324px);
    padding: 12px 24px;
    overflow-y: auto;
  }
}
