@import 'styles/variables.scss';
@import 'styles/colors.scss';
@import 'styles/titles.scss';

.twitter-expansion {
  margin-bottom: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $color-background;
  border: 1px solid $color-border-grey;
  padding: 10px 16px;
  border-radius: 4px;

  .twitter-expansion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .twitter-icon {
      width: 16px;
      height: 16px;
    }

    &__title {
      @include flex-body;
      gap: 8px;

      h2 {
        @include tab-selected;
        color: $color-black;
        margin-bottom: 0;
      }

      span {
        @include info;
        @include flex-body;
        width: 20px;
        height: 20px;
        justify-content: center;
        border-radius: 50%;
        background-color: $color-black;
        color: $color-white;
        padding: 2px 6px;
      }
    }
  }

  .ant-switch {
    background-color: $color-grey-text;
  }

  .ant-switch-checked {
    background-color: $color-prime !important;
  }
}

.create-narrative-overrides {
  padding: 0px !important;
}
