@import 'styles/variables.scss';
@import 'styles/titles.scss';
@import 'styles/colors.scss';

.snippet-list {
  background-color: $color-white;
  padding: 20px 40px 0px 40px;

  &__display {
    &--none {
      display: none !important;
    }

    &--block {
      display: block;

      .sl_db_back_btn_container {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .sl_db_back_btn {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: $color-prime;
          color: $color-white;
          padding: 7px 16px;
          justify-content: center;
          border-radius: 6px;
          border: none;
          cursor: pointer;
          margin-bottom: 16px;
        }
      }
    }
  }

  &__wrapper {
    height: 100%;

    .c-snippets-list__list {
      height: 100%;
      overflow-y: auto;
      padding-inline: 40px;

      &.dashboard_snippet_list {
        padding-inline: 0px;
        gap: 16px;
        display: flex;
        flex-direction: column;
        padding: 16px 24px;

        &:not(:has(.brand-snippets-empty-state)) {
          background-color: $color-grey-20;
        }
      }
    }
  }

  &__container {
    @include flex-body;
    padding: 0 40px;
  }

  &__backToTop {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
  }

  &__selected-community-chip {
    @include flex-body;
    @include description;
    width: max-content;
    padding: 2px 10px;
    gap: 8px;
    background: $color-prime;
    border-radius: 4px;
    color: $color-white;
    margin-top: 16px;

    svg {
      cursor: pointer;

      path {
        stroke: $color-white;
      }
    }
  }

  &__search-result {
    @include subtitle;
    color: $color-grey-text;
    margin-right: 7px;
    margin-top: 16px;
  }

  &__dot {
    @include flex-body-centered;
    background-color: $color-grey-text;
    width: 3px;
    height: 3px;
    border-radius: 4px;
    margin: auto 12px auto 4px;
  }
}

.snippet-list__wrapper::-webkit-scrollbar {
  width: 6px;
  border-radius: 16px;
}

.snippet-list__wrapper::-webkit-scrollbar-thumb {
  background: #00000080;
  border: 0px none #ffffff;
  border-radius: 50px;
}
