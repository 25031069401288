@import 'styles/colors.scss';
.extra_info_popup_container {
  display: none;
  position: absolute;
  width: 402px;
  padding: 24px;
  left: calc(100% + 6px);
  top: 2px;
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
  .exipc_title {
    color: $color-dark-text;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
  }
  .exipc_desc_text,
  .exipc_creator_text {
    color: $color-grey-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 12px;
    margin-bottom: 8px;
  }
  .exipc_desc {
    color: $color-dark-text;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
  .exipc_creator_info {
    display: flex;
    align-items: center;
    gap: 8px;
    .exipc_ci_img_container {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;
      .exipc_ci_ic_image {
        height: 100%;
        width: 100%;
        object-fit: contain;
        vertical-align: unset;
      }
    }
    .exipc_ci_content {
      .exipc_ci_c_name {
        color: $color-dark-text;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }
      .exipc_ci_c_created_date {
        color: $color-grey-text;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 0;
      }
    }
  }
}
