@import 'styles/colors.scss';
@import 'styles/titles.scss';

.modalFooter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  position: absolute;
  bottom: 0;
  width: 465px;
  margin-top: 32px;
  margin-bottom: 32px;

  button {
    height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 16px;
    border-radius: 4px;
    border: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .cancelButton {
    color: $color-black;
    outline: 1px solid $color-black;

    &:hover {
      color: $color-black;
      background: unset;
      border-color: unset;
    }
  }
  .createButton {
    margin-left: 0 !important;
    background-color: $color-black;
    color: $color-white;

    &:hover {
      background: $color-black;
      color: $color-white;
      border-color: unset;
    }

    &:disabled {
      background: $color-background-reports;
      cursor: not-allowed;
    }
  }
  .deleteButton {
    margin-left: 0 !important;
    background-color: red;
    color: $color-white;
  }
}
