@import 'styles/colors.scss';

.detail_view_left_section_view_history_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .dvlc_inner_container {
    padding: 16px 32px;
    border-bottom: 1px solid $color-border-grey;

    .dvlc_header {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;

      .dvlc_h_back_icon {
        cursor: pointer;
      }

      .dvlc_h_heading {
        color: $color-black;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  .dvlc_history_card_container {
    padding-left: 32px;
    height: calc(100vh - 292px);
    overflow-y: auto;

    .dvlc_hcc_card {
      padding: 16px 8px;
      border-bottom: 1px solid $color-border-grey;
      cursor: pointer;

      &.dvlc_hcc_card_active {
        background-color: $color-grey-16;
      }

      &:last-child {
        border: none;
      }

      .dvlc_hcc_c_date {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
      }

      .dvlc_hcc_c_users_list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

        .dvlc_hcc_c_ul_card {
          display: flex;
          align-items: center;
          gap: 8px;

          .dvlc_hcc_c_uc_image_container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            border-radius: 50%;
            overflow: hidden;

            .dvlc_hcc_c_uc_ic_image {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .dvlc_hcc_c_uc_name {
            color: $color-dark-grey;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
