@import 'styles/colors.scss';
@import 'styles/titles.scss';

.manage-admin-actions {
  display: flex;
  padding: 16px 40px 16px 24px;
  gap: 24px;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    &__title {
      @include warning;
      color: $color-dark-text;
      line-height: 28px;
    }

    &__description {
      width: 45%;
      @include description-16;
      color: $color-dark-grey;
      line-height: 24px;
    }
  }

  .list {
    width: 100%;


    &__checkbox_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      border: 1px solid $color-grey-17;
      cursor: pointer;
      margin: auto;


      &__selected {
        background-color: $color-prime;
        border-color: $color-prime;
      }

      .amc_th_cc_line {
        height: 2px;
        width: 10px;
        border-radius: 10px;
        background-color: $color-white;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 16px;

      &__icons {
        display: flex;
        align-items: center;
        gap: 8px;

        &__owner-btn {
          @include heading;
          background-color: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          display: flex;
          padding: 8px 0px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: $color-black;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: auto;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;
        }

        &__div {
          display: flex;
          padding: 4px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          border: 1px solid $color-border-grey-2;
          background: $color-grey-18;
          cursor: pointer;

          svg>path {
            stroke: $color-grey-19
          }
        }

      }
    }

    &__title {
      @include flex-body;
      gap: 4px;
    }

    &__name {
      @include description;
      color: $color-dark-text;
    }

    &__date {
      @include description;
      color: $color-grey-19;
    }

    &__owner-info {
      display: flex;
      align-items: center;
      gap: 12px;

      &__img {
        border-radius: 40px;
        background: lightgray 50% / cover no-repeat, lightgray 50% / cover no-repeat;
        width: 40px;
        height: 40px;
      }

      &__text {
        color: $color-grey-19;
        @include description;
      }
    }

    &__sortIcon {
      transform: rotate(180deg);
    }
  }

  &__report-modal {
    width: 529px !important;

    .email-subscribe-info {
      display: none
    }
  }

  .ant-table-thead>tr>th {
    @include description;
    height: 44px;
    padding: 12px 24px;
    border-top: 1px solid $color-border-grey-2;
    border-bottom: 1px solid $color-border-grey-2;
    background: $color-grey-20;
    color: $color-grey-text;


    &::before {
      width: 0 !important;
    }
  }

  .ant-table-tbody>tr>td {
    padding: 16px 24px;
    border-bottom: 1px solid $color-border-grey-2;
  }

  .ant-table-tbody>tr>:first-child {
    padding: 16px 0px;
  }

  .ant-table-column-sorter {
    display: none;
  }

  .ant-table-column-sort {
    background: none;
  }
}