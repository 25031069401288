@import 'styles/colors.scss';
@import 'styles/titles.scss';

.snippet-item {
  padding: 24px 0;
  border-bottom: 1px solid $color-border-grey;

  &.dashboard-snippet {
    gap: 14px;
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    border-radius: 8px;
    position: relative;
    border: 1px solid $color-border-grey-2;
    background-color: $color-white;
    box-shadow: 0px 0px 8px 0px $color-box-shadow;

    .dashboard-snippet__checkbox {
      right: 24px;
      opacity: 0;
      z-index: 1000;
      // opacity: 1;
      position: absolute;
      visibility: visible;
    }

    .snippet-item__description {
      word-break: break-word;
    }

    &:hover {
      border: 1px solid $color-grey-17;
    }
  }

  &.isSelected {
    // border: 1px solid $color-prime;
    // box-shadow: 0px 0px 8px 0px $color-box-shadow;
    &:hover {
      // border: 1px solid $color-prime;
    }
  }

  &__description {
    @include body;
    color: $color-dark-text;
    margin-bottom: 20px;
    padding-right: 10px;
  }

  &__button {
    @include subtitle-16;
    color: $color-blue;
    cursor: pointer;
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: $color-grey-9;
  }

  &__creator {
    @include flex-body;
    cursor: pointer;
    gap: 6px;

    span {
      @include footnote;
      color: $color-grey-15;
    }

    .author-channel {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .author-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      font-family: Inter;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-border-black;
    }

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }

  &__details {
    @include flex-body-centered;
    gap: 2px;

    &--icon {
      border-radius: 24px;
      width: 24px;
      height: 24px;
      margin: auto 4px auto 4px;
    }
  }

  &__sentiment {
    margin: auto;

    circle {
      fill: transparent;
    }

    path {
      fill: $color-grey-text;
    }
  }

  &__source {
    margin: auto;

    &--image {
      margin: auto;
    }
  }

  &__details__dropdown {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 5px;
  }

  &__tag-input-container {
    @include flex(row, center, flex-start);
    border: 1px solid $color-border-grey;

    &__left {
      @include flex(row, center, space-between);

      &__user-img {
        margin-right: 4px;
        height: 24px;
        width: 24px;
        border-radius: 24px;
        border: none;
      }

      &__title {
        @include info;
        color: $color-black;
      }

      &__breaker {
        @include title-500;
        color: $color-grey-text;
        margin-left: 4px;
        margin-right: 8px;
      }
    }

    &__right {
      @include flex-body;
      cursor: pointer;

      &__enter {
        @include footnote;
        color: $color-black;
        margin-left: 7px;
      }

      &__close {
        margin: 0 12px 0 8px;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  &__footer {
    @include flex(row, center, space-between);
    padding: 3px 0px;
    gap: 10px;
    width: 100%;
    min-height: 34px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    &__left {
      white-space: nowrap;
      @include flex-body;

      &__percentage {
        margin-top: auto;
        @include footnote;
        color: $color-grey-15;
      }

      &__description {
        @include footnote;
        color: $color-grey-15;
      }

      &__list {
        @include flex-body;
        @include footnote;
        color: $color-grey-22;
        margin: 0;
        padding: 0;
        gap: 6px;

        svg {
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
        }
      }

      &__tweet {
        display: flex;
      }

      &__tweet-icon,
      &__tweet-text {
        margin: auto 3px;
      }

      &__tooltip {
        .ant-tooltip-inner {
          min-height: max-content;
          padding: 15px 10px;
        }
      }

      &__tooltip2 {
        .ant-tooltip-inner {
          min-height: max-content;
          padding: 8px;
          border-radius: 4px;
          background: $color-black;
          color: $color-white;
          text-transform: capitalize;
        }

        .ant-tooltip-arrow {
          display: block;

          .ant-tooltip-arrow-content {
            --antd-arrow-background-color: #16191d;
          }
        }
      }

      &__translation-div {
        @include flex-body;
        padding: 2px 8px 2px 4px;
        background: $color-primary-4;
        border-radius: 3px;
        margin-left: 15px;

        &__text {
          @include footnote;
          color: $color-black;
        }

        svg {
          height: 10px;

          path {
            stroke: $color-prime;
          }
        }
      }
    }

    &__right {
      display: none;
      font-size: 12px;
      margin: 0;
      padding: 0;
      gap: 8px;

      &__div1 {
        @include flex-body;
        gap: 4px;
      }

      &__div2 {
        @include flex-body;
        gap: 4px;
      }

      &__bookmark-btn,
      &__preview-btn {
        &,
        &:focus,
        &:active {
          @include flex-body;
          gap: 4px;
          outline: none !important;
          border: none !important;
          cursor: pointer;
          padding: 4px 10px 4px 8px;
          color: #7a6336;
          background: $color-primary-6 !important;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          span {
            color: #7a6336;
          }

          svg {
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            path {
              stroke: #7a6336;
            }
          }
        }
      }

      &__bookmark-btn {
        svg {
          height: 16px;
          width: 16px;
        }
      }

      &__like-dislike-btn {
        &,
        &:focus,
        &:active {
          @include flex-body;
          outline: none !important;
          border: none !important;
          cursor: pointer;
          color: $color-prime;
          background: rgba(244, 202, 95, 0) !important;
        }
      }

      &__breaker {
        @include info;
        color: $color-grey-text;
        margin-left: 7px;
        margin-right: 7px;
      }

      &__like-dislike {
        @include flex-body;
        padding: 1px 8px;
        background: $color-primary-4;
        border-radius: 2px;
        gap: 6px;

        &--active {
          transform: rotate(30deg);
        }

        span {
          background-color: transparent;
        }

        // svg {
        //   path {
        //     fill: $color-prime;
        //   }
        // }

        // .snippet-item__footer__right__like-dislike_like_icon {
        //   path {
        //     stroke: $color-prime;
        //     fill: none;
        //   }
        // }
      }

      &__like-dislike.hover {
        cursor: pointer;
      }

      & > svg {
        margin: 0 px;
        cursor: pointer;
      }
    }
  }

  &__add-watchlist {
    &__first-option {
      @include subtitle;
      color: $color-dark-text;
      padding: 8px 22px 0 22px;
    }

    &__options {
      padding: 10px 22px 0 22px;
    }
  }

  &__popup-class {
    .ant-select-item-option-selected {
      cursor: pointer;
      background-color: transparent !important;

      &:hover {
        cursor: pointer;
        background-color: transparent !important;
      }
    }
  }

  &__create-watchlist-placeholder {
    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          display: none;
        }
      }

      &:before {
        @include footnote;
        @include flex-body;
        color: $color-grey-text;
        padding: 4px 12px;
        content: 'Enter watchlist name';
      }
    }
  }

  &__create-watchlist-placeholder2 {
    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          display: none;
        }
      }
    }
  }

  &__notification {
    border-left: 3px solid $color-prime;
    max-height: 140px;
    padding-bottom: 10px;

    &__description {
      display: flex;
      padding: 7px 10px;
      align-items: flex-start;
      border-radius: 4px;
      background: $color-unselected;
      color: $color-dark-text;
      @include description;
      margin-bottom: 8px;
    }

    .ant-notification-notice-message {
      @include subtitle;
      margin-bottom: 4px;
      margin-left: 38px;
      color: $color-black;
    }

    .ant-notification-notice-description {
      margin-bottom: 4px;
      margin-left: 38px;
      max-height: 70px;
      overflow: auto;
    }
  }

  &__notification-margin {
    margin-left: 70% !important;
    margin-bottom: -4%;
  }

  &:hover {
    .snippet-item__footer__right {
      @include flex-body;
    }

    .snippet-item__footer__left__translation-div {
      display: none;
    }

    // .snippet-item__sentiment {
    //   path {
    //     fill: $color-prime-bright;
    //   }

    //   circle {
    //     fill: transparent;
    //   }
    // }

    // .snippet-item__footer__view-count {
    //   path {
    //     stroke: $color-prime-bright;
    //   }
    // }

    // .snippet-item__source {
    //   path {
    //     fill: $color-prime-bright;
    //   }
    // }

    // .snippet-item__source--image {
    //   path {
    //     stroke: $color-prime-bright;
    //   }
    // }
  }

  .ant-input {
    min-height: inherit;
  }

  .ant-select-selector {
    min-height: 40px;
    max-height: max-content;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-select {
    .ant-select-open {
      .ant-select-focused {
        .ant-select-enabled {
          .ant-select-selection.ant-select-selection--single {
            .ant-select-selection__rendered {
              .ant-select-selection-selected-value {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
}

.snippet-item-bordered {
  border: 1px solid $color-border-grey-2;
  border-radius: 8px;
  background-color: white;
  padding: 16px 24px !important;
}
